import { StatusIndicatorProps } from '@amzn/awsui-components-react';

export default class TaskModel {
    [key: string]: any;

    name?: string;
    assignee?: string;
    status?: string;
    vote?: string;
    role?: string;
    comments?: string;
    dueDate?: Date;
    dateStarted?: Date;
    dateCompleted?: Date;
    completedBy?: string;
    taskId?: string;

    static fromJSON(json: any): TaskModel {
        const output = new TaskModel();
        for (const key of Object.keys(output)) {
            output[key] = json[key];
        }

        return output;
    }

    toString(): string {
        return JSON.stringify(this);
    }

    isOverdue(): boolean {
        const currentTime = new Date(Date.now());
        return currentTime.getTime() > this.dueDateTime.getTime() && this.taskStatus !== 'COMPLETED';
    }

    get dueDateTime(): Date {
        return this.dueDate ? new Date(this.dueDate) : new Date(Date.now());
    }

    get taskStatus(): string {
        return this.status ? this.status : '';
    }

    get stateString(): string {
        const stateMap: { [key: string]: string } = {
            COMPLETED: 'Completed',
            POTENTIAL: 'Potential',
        };
        return stateMap[this.status ?? ''] ?? this.status;
    }

    get statusType(): StatusIndicatorProps.Type {
        return TaskModel.statusType(this.status ?? '');
    }

    static statusType(state: string): StatusIndicatorProps.Type {
        switch (state?.toLowerCase()) {
            case 'completed':
                return 'success';

            default:
                return 'pending';
        }
    }
}
