import { Button } from '@amzn/geist-ui-components';
import React from 'react';
import { CSVLink } from 'react-csv';
import RequestHelper from '../utils/request_helper';
import { AnalyticsRoutes, ActionDetails } from '../utils/request_helper';
import { formatFromCamelCase } from '../utils/tools';
import TuxComponent from './tux_component';

export const escapeDoubleQuotes = (input: any): any => {
    if (typeof input === 'string' || input instanceof String) {
        return input.replaceAll('"', '""');
    }
    return input;
};

interface ExportToCSVButtonProps {
    filename: string;
    data: any[];
    keys?: string[];
    disabled?: boolean;
}

export default class ExportToCSVButton extends TuxComponent<ExportToCSVButtonProps, any> {
    static exportButtonCount = 0;
    private _id: string;

    constructor(props: ExportToCSVButtonProps) {
        super(props);
        ExportToCSVButton.exportButtonCount++;
        this._id = `${this.constructor.name}_${ExportToCSVButton.exportButtonCount}`;
        this.bindAll(this);
    }

    async loadData() {
        // Do nothing
    }

    private get keys(): string[] {
        if (this.props.keys) {
            return this.props.keys;
        }
        const keySet: Set<string> = new Set<string>();
        const keys: string[] = [];

        for (const item of this.props.data) {
            const itemKeys = Object.keys(item);
            for (const key of itemKeys) {
                if (!keySet.has(key)) {
                    keys.push(key);
                    keySet.add(key);
                }
            }
        }

        return keys;
    }

    private get csvData(): any[] {
        const keys: string[] = this.keys;
        const output: any[] = [keys.map((k) => formatFromCamelCase(k))];

        for (const item of this.props.data) {
            output.push(keys.map((key) => escapeDoubleQuotes(item[key])));
        }

        // console.log(keys);
        return output;
    }

    private exportPressed() {
        const csvLink = document.getElementById(`EXPORT_${this._id}`);
        if (!csvLink) {
            return;
        }

        RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails('export_table', this.props.filename));

        csvLink.click();
    }

    render() {
        return (
            <>
                <Button
                    onClick={this.exportPressed}
                    disabled={this.props.disabled ?? (this.props.data?.length ?? 0) === 0}
                >
                    Export
                </Button>
                {this.props.disabled ? null : (
                    <CSVLink data={this.csvData} id={`EXPORT_${this._id}`} filename={`${this.props.filename}`} />
                )}
            </>
        );
    }
}
