import React from 'react';
import TuxComponent from '../../components/tux_view';
import SiteModel from '../../data/site/site_model';
import { NoLoadTabs } from '../../components/components';
import { Badge, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import NonModuleListModel from '../../data/site/non_module_list_model';
import { NonModuleListDetailsView } from './tabs/non_module_list_details_view';
import { NonModuleListStructureView } from './tabs/non_module_list_structure_view';
import { NonModuleListHistoryView } from './tabs/non_module_list_history_view';
import SiteModelService from '../../data/site/site_model_service';
import { SiteModelType } from '../../data/site/site_base_model';
import { Box, LinearProgress } from '@mui/material';

interface NonModuleListViewProps {
    sitePromise: Promise<SiteModel>;
    systemId: string;
    initialTab?: string;
}

export default class NonModuleListView extends TuxComponent<NonModuleListViewProps, any> {
    isLoading = true;
    site: SiteModel;
    nonModuleList: NonModuleListModel;
    currentTab: string;

    constructor(props: NonModuleListViewProps) {
        super(props);
        this.site = SiteModel.LOADING_TEMPLATE;
        this.nonModuleList = NonModuleListModel.LOADING_TEMPLATE;
        this.currentTab = this.props.initialTab ?? 'details';
        this.bindAll(this);
        this.loadData();
    }

    public async loadData(): Promise<void> {
        this.site = await this.props.sitePromise;
        this.nonModuleList = await this.resolveNonModuleList(this.props.systemId);
        this.isLoading = false;
        this.dataUpdated();
    }

    private async resolveNonModuleList(systemId: string): Promise<NonModuleListModel> {
        //fetch latest nml
        for (let model of this.site._latestNonModuleList ?? []) {
            if (model._systemId === systemId) return model;
        }

        //fetch non-latest nml
        return (await SiteModelService.fetchModel(SiteModelType.NonModuleList, systemId)) as NonModuleListModel;
    }

    tabChanged(tabClicked: any) {
        if (!window.location.hash.includes(this.currentTab)) {
            window.location.hash += `/${this.currentTab}`;
        }
        window.history.replaceState(null, 'ES-PLM', window.location.hash.replace(this.currentTab, tabClicked));
        this.currentTab = tabClicked;
    }

    getHeader(): JSX.Element {
        if (this.isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            );
        }

        return (
            <Header>
                <SpaceBetween size="m" direction="horizontal">
                    <div>{`${this.nonModuleList.number}, ${this.nonModuleList.name}`}</div>
                    <Badge
                        color={this.nonModuleList.stateString === 'Released' ? 'green' : 'blue'}
                    >{`v${this.nonModuleList.displayVersionWithState}`}</Badge>
                </SpaceBetween>
            </Header>
        );
    }

    getContent(): JSX.Element {
        return (
            <>
                {this.getHeader()}
                <NoLoadTabs
                    initialTab={this.props.initialTab ?? 'details'}
                    onTabChanged={this.tabChanged}
                    tabs={[
                        {
                            id: 'details',
                            label: 'Details',
                            content: <NonModuleListDetailsView nonModuleList={this.nonModuleList} />
                        },
                        {
                            id: 'structure',
                            label: 'Structure',
                            content: <NonModuleListStructureView nonModuleList={this.nonModuleList} />
                        },
                        {
                            id: 'history',
                            label: 'History',
                            content: (
                                <NonModuleListHistoryView
                                    nonModuleListHistory={this.site._nonModuleList?? []}
                                    isLoading={this.isLoading}
                                    siteSystemId={this.site._systemId ?? ''}
                                />
                            )
                        }
                    ]}
                />
            </>
        );
    }

    render(): JSX.Element {
        return this.getContent();
    }
}
