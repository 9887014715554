import { EventTypes as EventType } from './event_types';

export class EventDispatcher {
    // Map of events and the keys for their subscribers
    private static SUBSCRIBERS: Map<EventType, Set<string>> = new Map<EventType, Set<string>>();

    // Map of subscriber key to callback
    private static CALLBACKS: { [key: string]: (value: any) => void } = {};

    /**
     * Listen for a specific event to be fired, when it does ```callback``` is run with the event details.
     * @param event Type of event to listen for
     * @param key ID for the listener, used to unsubscribe from an event
     * @param callback method to be called when an event is fired.
     */
    public static subscribe(event: EventType, key: string, callback: (value: any) => void) {
        if (!EventDispatcher.SUBSCRIBERS.has(event)) {
            EventDispatcher.SUBSCRIBERS.set(event, new Set<string>());
        }

        // Maps return by reference, so changes will be made in place
        const eventSubs: Set<string> = EventDispatcher.SUBSCRIBERS.get(event) ?? new Set<string>();
        if (!eventSubs.has(key)) {
            eventSubs.add(key);
        }

        EventDispatcher.CALLBACKS[key] = callback;
    }

    /**
     * Unsubscribe from an event
     * @param event - Event type to unsubscribe from
     * @param key - Unique key used when subscribing to an event
     * @returns True if event is unsubscribed, false if event was not subscribed or callback wasn't present
     */
    public static unsubscribe(event: EventType, key: string): boolean {
        if (!EventDispatcher.SUBSCRIBERS.has(event)) {
            return false;
        }
        const eventSubs = EventDispatcher.SUBSCRIBERS.get(event) ?? new Set<string>();
        if (!eventSubs.has(key)) {
            return false;
        }
        if (!EventDispatcher.CALLBACKS[key]) {
            return false;
        }
        delete EventDispatcher.CALLBACKS[key];
        eventSubs.delete(key);
        return true;
    }

    /**
     * Trigger an event to be consumed by subscribers
     * @param event Event type being triggered
     * @param value Event details, passed to subscribers
     */
    public static triggerEvent(event: EventType, value?: any) {
        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG) Event Triggered', event, value);
        }
        if (!EventDispatcher.SUBSCRIBERS.has(event)) {
            return;
        }
        const eventSubs = EventDispatcher.SUBSCRIBERS.get(event) ?? new Set<string>();
        const keys = eventSubs.keys();
        let currentPos = keys.next();
        while (!currentPos.done) {
            const key = currentPos.value;
            try {
                if (EventDispatcher.CALLBACKS[key]) {
                    EventDispatcher.CALLBACKS[key](value);
                }
            } catch (e) {
                console.error(e);
            }
            currentPos = keys.next();
        }
    }
}
