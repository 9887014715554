export default class TeamModel {
    [key: string]: any;

    role?: string;
    principals?: string;

    static fromJSON(json: any): TeamModel {
        const output = new TeamModel();
        for (const key of Object.keys(output)) {
            output[key] = json[key];
        }

        return output;
    }

    toString(): string {
        return JSON.stringify(this);
    }
}
