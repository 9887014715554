import { Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React from 'react';
import TuxView from '../components/tux_view';
import { CookieHelper } from '../modules';
import { CookieNames } from '../utils/cookie_helper';


interface HomeViewProps{

}

export default class HomeView extends TuxView<HomeViewProps, any>{
    private static VIDEOS_HOST = (process.env.NODE_ENV !== 'production' || window.location.hostname.includes("localhost")) ? "https://media.dev.tux.eng-plm.a2z.com" : `https://${window.location.hostname.startsWith("ui") ? window.location.hostname.replace("ui", "media") : (`media.${window.location.hostname}`)}`;

    public async loadData(){
        if (process.env.NODE_ENV !== 'production'){
            console.log("(DEBUG)", "Load Data called in home view");
        }
    }

    public get videoSource(): string{
        
        let output = `${HomeView.VIDEOS_HOST}/tux_sop.mp4?id_token=${CookieHelper.getCookieByName(CookieNames.id_token)}`;
        console.log(output);
        return output;
    }

    render() : JSX.Element{
        return (
            <SpaceBetween size={'s'}>
                <div/>
                {/* <Alert>This page is still a work in progress.</Alert> */}
                <Header>Welcome to TUX!</Header>
                
                { <div style={{height:"90%", width:"99%", marginLeft:"8px", marginRight:"8px", overflowX: "hidden", overflowY: "hidden"}}>
                    TUX Site Integration with ESPLM has been released.
                    <br></br>
                    Navigate to the <b>Sites</b> on the side bar to explore more!
                    <br></br>
                    Please find user guide <a href="https://w.amazon.com/bin/view/NAEng/GES_Product/Product_Catalog/GES_Planning_TUX/UserGuide/" target="_blank" rel="noopener noreferrer">here</a> for the new release.
                </div> }
                <video 
                    src={this.videoSource}
                    width="80%"
                    controls={true}
                ></video>
            </SpaceBetween>
        );
    }
    
}