import React from 'react';
import { TuxView, ValueWithLabel, ItemTable } from '../../components/components';
import { ModuleModel } from '../../data/data';
import { Grid, SpaceBetween } from '@amzn/awsui-components-react';
import BomModel from '../../data/site/bom_model';
import SiteModelService from '../../data/site/site_model_service';
import { SiteModelType } from '../../data/site/site_base_model';
import SessionHelper from '../../utils/session_helper';
import NonModuleListModel from '../../data/site/non_module_list_model';
import { BomItem, flattenNML } from '../nonModuleList/tabs/non_module_list_structure_view';
import { HistoryItem } from '../nonModuleList/tabs/non_module_list_history_view';
import CompareUtils from '../../utils/compare_utils';
import { ColumnLayout, ExpandableSection, Header, StatusIndicator } from '@amzn/geist-ui-components';

interface CompareNMLViewProps {
    nmlA: string;
    nmlAVersion: string;
    nmlB: string;
    nmlBVersion: string;
}

export default class CompareNMLView extends TuxView<CompareNMLViewProps, any> {
    nmlAModel: NonModuleListModel;
    nmlBModel: NonModuleListModel;
    nmlABOMOrig: BomModel[] = [];
    nmlBBOMOrig: BomModel[] = [];
    nmlABOM: BomItem[] = [];
    nmlBBOM: BomItem[] = [];
    nmlABOMNumbers: Set<string> = new Set<string>();
    nmlBBOMNumbers: Set<string> = new Set<string>();
    attributesExpanded = true;
    systemDetailsExpanded = true;

    constructor(props: CompareNMLViewProps) {
        super(props);

        this.nmlAModel = NonModuleListModel.LOADING_TEMPLATE;
        this.nmlBModel = NonModuleListModel.LOADING_TEMPLATE;

        this.bindAll(this);
        this.loadData();
    }

    private async resolveNonModuleList(number: string, version: string): Promise<NonModuleListModel> {
        return (await SiteModelService.fetchModelByNumberAndVersion(
            SiteModelType.NonModuleList,
            number,
            version,
        )) as NonModuleListModel;
    }

    async loadData() {
        this.nmlAModel = await this.resolveNonModuleList(this.props.nmlA, this.props.nmlAVersion);
        this.nmlBModel = await this.resolveNonModuleList(this.props.nmlB, this.props.nmlBVersion);

        this.nmlABOMOrig = this.nmlAModel._bom ?? [];
        this.nmlBBOMOrig = this.nmlBModel._bom ?? [];

        this.nmlABOM = flattenNML(this.nmlABOMOrig);
        this.nmlBBOM = flattenNML(this.nmlBBOMOrig);

        this.nmlABOMNumbers = new Set<string>(this.nmlABOM.map((b) => b.number ?? ''));
        this.nmlBBOMNumbers = new Set<string>(this.nmlBBOM.map((b) => b.number ?? ''));

        [this.nmlABOM, this.nmlBBOM] = CompareUtils.compareBOMs(this.nmlABOM, this.nmlBBOM) as BomItem[][];

        this.isLoading = false;
        this.dataUpdated();
    }

    get nmlAId() {
        return `${this.nmlAModel.number}_${this.nmlAModel.displayVersion}`;
    }

    get nml() {
        return `${this.nmlBModel.number}_${this.nmlBModel.displayVersion}`;
    }

    getNMLId(nml: NonModuleListModel) {
        return `${nml.number}_${nml.displayVersion}`;
    }

    attributeExpansionChanged() {
        this.attributesExpanded = !this.attributesExpanded;
        this.dataUpdated();
    }

    systemDetailsExpansionChange() {
        this.systemDetailsExpanded = !this.systemDetailsExpanded;
        this.dataUpdated();
    }

    static generateHREF(nmlA: HistoryItem, nmlB: HistoryItem): string {
        return `#/${SessionHelper.getRegion()}/compare/nml/${nmlA.number}/${nmlA.version}/${nmlB.number}/${
            nmlB.version
        }`;
    }

    private getAttributes(nml: NonModuleListModel, otherNML: NonModuleListModel): JSX.Element {
        const header = (
            <Header variant="h2">
                Attributes - {nml.number} ({nml.displayVersion})
            </Header>
        );

        return (
            <ExpandableSection
                variant="container"
                header={header}
                expanded={this.attributesExpanded}
                defaultExpanded={true}
                id={this.getNMLId(nml) + '_attributes'}
                onChange={this.attributeExpansionChanged}
            >
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel hasChanged={nml.name !== otherNML.name} label="Name">
                            {nml.name}
                        </ValueWithLabel>
                        <ValueWithLabel hasChanged={nml.number !== otherNML.number} label="Number">
                            {nml.number}
                        </ValueWithLabel>
                        <ValueWithLabel hasChanged={nml.displayVersion !== otherNML.displayVersion} label="Version">
                            {nml.displayVersion}
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel hasChanged={nml.stateString !== otherNML.stateString} label="State">
                            <StatusIndicator type={ModuleModel.statusType(nml.stateString)}>
                                {nml.stateString}
                            </StatusIndicator>
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </ExpandableSection>
        );
    }

    getSystem(nmlObj: NonModuleListModel, otherNMLObj: NonModuleListModel): JSX.Element {
        const header = <Header variant="h2">System - {nmlObj.number}</Header>;
        return (
            <>
                <ExpandableSection
                    variant="container"
                    header={header}
                    expanded={this.systemDetailsExpanded}
                    defaultExpanded={true}
                    id={nmlObj.name + '_system'}
                    onChange={this.systemDetailsExpansionChange}
                >
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel hasChanged={nmlObj.createdBy !== otherNMLObj.createdBy} label="Created By">
                                {nmlObj.createdBy}
                            </ValueWithLabel>
                            <ValueWithLabel hasChanged={nmlObj.createdOn !== otherNMLObj.createdOn} label="Created On">
                                {`${nmlObj.createdOn}`}
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                hasChanged={nmlObj.modifiedBy !== otherNMLObj.modifiedBy}
                                label="Modified By"
                            >
                                {nmlObj.modifiedBy}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={nmlObj.lastModified !== otherNMLObj.lastModified}
                                label="Last Modified"
                            >
                                {`${nmlObj.lastModified}`}
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExpandableSection>
            </>
        );
    }

    private _getAttributes() {
        return (
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                <SpaceBetween size="l">
                    {this.getAttributes(this.nmlAModel, this.nmlBModel)}
                    {this.getSystem(this.nmlAModel, this.nmlBModel)}
                </SpaceBetween>

                <SpaceBetween size="l">
                    {this.getAttributes(this.nmlBModel, this.nmlAModel)}
                    {this.getSystem(this.nmlBModel, this.nmlAModel)}
                </SpaceBetween>
            </Grid>
        );
    }

    getCurrencyFormatter(bomEntry: BomModel): Intl.NumberFormat {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: bomEntry.currency ?? 'USD',
        });
    }

    private formatCurrency(p: number | string, b?: BomModel): string {
        // console.log(p);
        if (p === null || b === null || p === undefined || b === undefined) {
            return '-';
        }

        if (typeof p === 'string') {
            return p;
        }

        if (!b?.currency) {
            return `$${p.toFixed(2)}`;
        }

        return this.getCurrencyFormatter(b).format(p);
    }

    private getBOM(
        nml: NonModuleListModel,
        bom: BomItem[],
        bomNumbers: Set<string>,
        otherBOMNumbers: Set<string>,
        otherBOM: BomItem[],
    ) {
        const temp: { [key: string]: any } = {};
        const getHasChanged = (val: number, b: BomItem, param: string) =>
            val != null
                ? otherBOMNumbers.has(b.number ?? '')
                    ? (otherBOM.find(((_b) => _b.number === b.number) ?? temp) as any)[param] === (b as any)[param]
                        ? val
                        : CompareUtils.getChangedText(val)
                    : val
                : '-';
        return (
            <ItemTable
                title={`${nml.number} (${nml.displayVersion})` ?? ''}
                items={bom}
                initialColumnOrder={['number', 'name', 'quantity', 'desc', 'price']}
                initiallyVisibleColumnCount={4}
                disableSearch={true}
                disableSelection={true}
                customDisplays={{
                    number: (val, bomItem: BomItem) =>
                        otherBOMNumbers.has(bomItem?.number ?? '')
                            ? bomNumbers.has(bomItem?.number ?? '')
                                ? bomItem?.number
                                : '-'
                            : CompareUtils.getChangedText(bomItem?.number),
                    quantity: (quantity: number, bomItem: BomItem) => getHasChanged(quantity, bomItem, 'quantity'),
                    price: (val) => this.formatCurrency(val),
                }}
                fixed
            />
        );
    }

    private _getBOMs() {
        return (
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                {this.getBOM(this.nmlAModel, this.nmlABOM, this.nmlABOMNumbers, this.nmlBBOMNumbers, this.nmlBBOM)}
                {this.getBOM(this.nmlBModel, this.nmlBBOM, this.nmlBBOMNumbers, this.nmlABOMNumbers, this.nmlABOM)}
            </Grid>
        );
    }

    render() {
        return (
            <>
                <Header variant="h1">
                    Compare:{' '}
                    {`${this.nmlAModel.number} (${this.nmlAModel.displayVersion}) & ${this.nmlBModel.number} (${this.nmlBModel.displayVersion})`}
                </Header>
                <SpaceBetween size="s" direction="vertical">
                    {this._getAttributes()}
                    {this._getBOMs()}
                </SpaceBetween>
                <div style={{ height: '12px' }} />
            </>
        );
    }
}
