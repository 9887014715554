import CookieHelper, { CookieNames } from './cookie_helper';
import SessionHelper from './session_helper';
import StorageHelper from './storage_helper';
import { downloadObject } from './tools';
import UserInfoHelper from './user_info_helper';

export enum AnalyticsRoutes {
    page_visit = '/analytics/pagevisit',
    performance = '/analytics/performance',
    action = '/analytics/action',
    error = '/analytics/error',
}

export class AnalyticsBase {
    user: string;
    date: Date;
    route: string;
    href: string;
    session_start: Date;

    constructor() {
        if (UserInfoHelper.username === '') {
            UserInfoHelper.loadUserInfoFromCookies();
        }
        this.user = UserInfoHelper.username;
        this.date = new Date();
        this.session_start = SessionHelper.sessionStart();
        this.route = window.location.hash;
        this.href = window.location.href;
    }
}

export class PageVisitDetails extends AnalyticsBase {
    from: string | null;

    constructor(from: string | null = null) {
        super();
        this.from = from;
    }
}

export class ErrorDetails extends AnalyticsBase {
    error: string;

    constructor(error: string) {
        super();
        this.error = error;
    }
}

export class PerformanceDetails extends AnalyticsBase {
    type: string;
    duration: number;

    constructor(type: string, duration: number) {
        super();
        this.type = type;
        this.duration = duration;
    }
}

export class ActionDetails extends AnalyticsBase {
    type: string;
    details: string;

    constructor(type: string, details: string) {
        super();
        this.type = type;
        this.details = details;
    }
}

export default class RequestHelper {
    //If in development environment (localhost), pass traffic to dev server
    private static SERVICE_HOST =
        process.env.NODE_ENV !== 'production' || window.location.hostname.includes('localhost')
            ? 'https://api.alpha.tux.eng-plm.a2z.com'
            : `https://${window.location.hostname.startsWith('ui') ? window.location.hostname.replace('ui', 'api') : `api.${window.location.hostname}`}`;
    private static ANALYTICS_HOST =
        process.env.NODE_ENV !== 'production' || window.location.hostname.includes('localhost')
            ? 'https://analytics.alpha.tux.eng-plm.a2z.com'
            : `https://${window.location.hostname.startsWith('ui') ? window.location.hostname.replace('ui', 'analytics') : `analytics.${window.location.hostname}`}`;
    private static FORGE_HOST =
        process.env.NODE_ENV !== 'production' || window.location.hostname.includes('localhost')
            ? 'https://forge.alpha.tux.eng-plm.a2z.com'
            : `https://${window.location.hostname.startsWith('ui') ? window.location.hostname.replace('ui', 'forge') : `forge.${window.location.hostname}`}`;
    private static _CONTENT_HOST =
        process.env.NODE_ENV !== 'production' || window.location.hostname.includes('localhost')
            ? 'https://content.alpha.tux.eng-plm.a2z.com'
            : `https://${window.location.hostname.startsWith('ui') ? window.location.hostname.replace('ui', 'content') : `content.${window.location.hostname}`}`;

    public static get CONTENT_HOST(): string {
        return `${RequestHelper._CONTENT_HOST}`;
    }

    /**
     * The URL for the host of this instances backend.
     */
    public static get HOST(): string {
        return `${RequestHelper.SERVICE_HOST}`;
    }

    /**
     * TODO: Make functional with Backend API
     *
     * Send a request to the analytics host.
     * @param url URL or path to send the request to
     * @param opts Options to add to the request Ex: headers, body
     * @param useText Should the response be in plain text instead of JSON
     * @returns Response from the analytics host
     */
    public static async analyticsRequest<T>(url: string, opts: any = {}, useText = false): Promise<T> {
        // Don't log analytics when developing
        if (process.env.NODE_ENV !== 'production') {
            return {} as T;
        }

        // If not on tux, they likely won't have tokens for validation anyways
        if (!window.location.hostname.includes('tux')) {
            return {} as T;
        }

        if (!url.startsWith('/')) {
            url = `/${url}`;
        }

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', `About to request from: ${RequestHelper.SERVICE_HOST}${url}`, opts);
        }

        if (opts.body && typeof opts.body !== 'string') {
            opts.body = JSON.stringify(opts.body);
        }

        if (!opts) {
            opts = {};
        }

        if (!opts.headers) {
            opts.headers = {};
        }

        if (!opts['method']) {
            opts['method'] = 'post';
        }

        opts.headers.Authorization = CookieHelper.getCookieByName(CookieNames.id_token);
        try {
            // return new Promise<T>((r) => r({} as T));
            const response: Response | string = await fetch(`${RequestHelper.ANALYTICS_HOST}${url}`, { ...opts }).catch(
                (r) => 'failed',
            );
            if (typeof response === 'string') {
                return {} as T;
            }
            if (!response.ok) {
                const errorMsg = `Encountered an error while calling url [${url}]: ${response.statusText}`;
                if (process.env.NODE_ENV !== 'production') {
                    console.error('(DEBUG', errorMsg);
                }
                throw Error(errorMsg);
            }
            const res = (await (useText ? response.text() : response.json())) as T;
            return res;
        } catch (e) {
            return {} as T;
        }
    }

    /**
     * TODO: Make functional with Backend API
     *
     * Send a request to the analytics host.
     * @param url URL or path to send the request to
     * @param opts Options to add to the request Ex: headers, body
     * @param useText Should the response be in plain text instead of JSON
     * @returns Response from the analytics host
     */
    public static async forgeRequest<T>(url: string, opts: any = {}, useText = false): Promise<T> {
        if (!url.startsWith('/')) {
            url = `/${url}`;
        }

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', `About to request from: ${RequestHelper.SERVICE_HOST}${url}`, opts);
        }

        if (opts.body && typeof opts.body !== 'string') {
            opts.body = JSON.stringify(opts.body);
        }

        if (!opts) {
            opts = {};
        }

        if (!opts.headers) {
            opts.headers = {};
        }

        if (!opts['method']) {
            opts['method'] = 'get';
        }

        opts.headers.Authorization = CookieHelper.getCookieByName(CookieNames.id_token);
        try {
            // return new Promise<T>((r) => r({} as T));
            const response: Response | string = await fetch(`${RequestHelper.FORGE_HOST}${url}`, { ...opts }).catch(
                (r) => 'failed',
            );
            if (typeof response === 'string') {
                return {} as T;
            }
            if (!response.ok) {
                const errorMsg = `Encountered an error while calling url [${url}]: ${response.statusText}`;
                if (process.env.NODE_ENV !== 'production') {
                    console.error('(DEBUG', errorMsg);
                }
                throw Error(errorMsg);
            }
            const res = (await (useText ? response.text() : response.json())) as T;
            return res;
        } catch (e) {
            return {} as T;
        }
    }

    /**
     * TODO: Make functional with Backend API
     *
     * Send a request to the analytics host.
     * @param url URL or path to send the request to
     * @param opts Options to add to the request Ex: headers, body
     * @param useText Should the response be in plain text instead of JSON
     * @returns Response from the analytics host
     */
    public static async logAnalytics<T>(url: string, content: AnalyticsBase): Promise<T> {
        // Don't log analytics when developing
        if (process.env.NODE_ENV !== 'production') {
            return {} as T;
        }

        if (!window.location.hostname.includes('tux')) {
            return {} as T;
        }

        if (!url.startsWith('/')) {
            url = `/${url}`;
        }

        const opts: any = {
            headers: {},
            method: 'post',
        };

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', `About to request from: ${RequestHelper.SERVICE_HOST}${url}`, content);
        }

        opts.body = JSON.stringify(content);

        opts.headers.Authorization = CookieHelper.getCookieByName(CookieNames.id_token);
        try {
            // return new Promise<T>((r) => r({} as T));
            const response: Response | string = await fetch(`${RequestHelper.ANALYTICS_HOST}${url}`, { ...opts }).catch(
                (r) => 'failed',
            );
            if (typeof response === 'string') {
                return {} as T;
            }
            if (!response.ok) {
                const errorMsg = `Encountered an error while calling url [${url}]: ${response.statusText}`;
                if (process.env.NODE_ENV !== 'production') {
                    console.error('(DEBUG', errorMsg);
                }
                throw Error(errorMsg);
            }
            const res = (await response.json()) as T;
            return res;
        } catch (e) {
            return {} as T;
        }
    }

    public static async download(url: string, filename: string, useAuth = false) {
        const result: ArrayBuffer = await RequestHelper.serviceRequest(url, {}, 'array', useAuth);

        downloadObject(new Blob([result]), filename);
    }

    /**
     * Send a request to the Services host.
     * @param url URL or path to send the request to
     * @param opts Options to add to the request Ex: headers, body
     * @param resultType Output result type, default: "json"
     * @returns
     */
    public static async serviceRequest(
        url: string,
        opts: any = {},
        resultType: 'text' | 'json' | 'array' | 'blob' = 'json',
        useAuth = true,
    ): Promise<any> {
        if (!url.startsWith('http')) {
            if (!url.startsWith('/')) {
                url = `/${url}`;
            }
        }

        if (process.env.NODE_ENV === 'production') {
            if (!window.location.hostname.includes('tux')) {
                return {};
            }
        }

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', `About to request from: ${RequestHelper.SERVICE_HOST}${url}`);
        }

        if (!opts) {
            opts = {};
        }

        if (!opts.headers) {
            opts.headers = {};
        }

        if (useAuth) {
            opts.headers.Authorization = CookieHelper.getCookieByName(CookieNames.id_token);
        }

        const requestURL = `${url.startsWith('/') ? RequestHelper.SERVICE_HOST : ''}${url}`;
        const response: Response = await fetch(requestURL, { ...opts });

        if (!response.ok) {
            const errorMsg = `Encountered an error while calling url [${url}]: ${response.statusText}`;
            if (process.env.NODE_ENV !== 'production') {
                console.error(errorMsg);
            }
            this.logAnalytics(AnalyticsRoutes.error, new ErrorDetails(`${response.status}: ${requestURL}`));
            throw Error(errorMsg);
        }

        if (resultType === 'json') {
            return await response.json();
        } else if (resultType === 'text') {
            return await response.text();
        } else if (resultType === 'blob') {
            return await response.blob();
        } else if (resultType === 'array') {
            return await response.arrayBuffer();
        }

        return response.json();
    }

    /**
     * Fetch an API's result from the cache and call it in the background and cache the result for
     * the next call and trigger a callback.
     * @param url URL to request data from
     * @param callback Callback used when request completed
     * @param processForStorage Process results to be stored for next call
     * @param onError Callback if query fails.
     * @returns Cached result for the query, null if no value has been cached
     */
    public static offsetCachedServiceRequest<T>(
        url: string,
        callback: (result: T) => void,
        processForStorage?: (result: T) => any,
        onError?: (e: any) => void,
    ): T | null {
        try {
            const itemKey = url + '_res';

            RequestHelper.serviceRequest(url).then((res) => {
                StorageHelper.setObject(itemKey, processForStorage ? processForStorage(res) : res);
                callback(res);
            });

            return StorageHelper.getObject<T>(itemKey);
        } catch (e) {
            if (onError) {
                onError(e);
            } else {
                throw e;
            }
        }
        return null;
    }

    /**
     * Checks cache for request, does not make call if present in cache
     * @param url
     * @param cacheString
     * @param cacheTime Time between caching and pulling new data in minutes
     * @param opts
     * @returns Response from request, null on error
     */
    public static cachedServiceRequest<T>(
        url: string,
        callback: (result: T) => void,
        onError?: (e: any) => void,
    ): T | null {
        try {
            const itemKey = url + '_res';

            const item = StorageHelper.getObject<T>(itemKey);

            if (item) {
                return item;
            }

            RequestHelper.serviceRequest(url).then((res) => {
                StorageHelper.setObject(itemKey, res);
                callback(res);
            });

            return item;
        } catch (e) {
            if (onError) {
                onError(e);
            } else {
                throw e;
            }
        }
        return null;
    }
}
