import RequestHelper from '../../utils/request_helper';
import { downloadObject } from '../../utils/tools';
import { ContentModel, ModuleModel } from '../data';

export default class ContentService {
    /**
     * Fetch a url from the Content Service API and download the result.
     * @param url url to fetch from the content service
     * @param filename filename to save teh response as
     */
    public static async downloadContent(url: string, filename: string) {
        const result: Blob = await RequestHelper.serviceRequest(url, {}, 'blob');
        downloadObject(result, filename);
    }

    /**
     * Attempts to fetch the content for a given Module.
     * @param module Module to fetch content for
     * @returns ContentModels for Primary and Secondary content, empty if none or an error occurs
     */
    public static async getContentForModule(module: ModuleModel): Promise<ContentModel[]> {
        try {
            const result = await RequestHelper.serviceRequest(`/content/module/${module._systemId}`);
            const output: ContentModel[] = [];

            if (Array.isArray(result)) {
                for (const res of result) {
                    output.push(ContentModel.fromJSON(res));
                }
            }

            return output;
        } catch (err) {
            return [];
        }
    }
}
