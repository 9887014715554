import React, { ReactNode } from 'react';
import {TuxComponent, ValueWithLabel} from './components';
import { ColumnLayout, ExpandableSection, Header, SpaceBetween, StatusIndicator, StatusIndicatorProps } from '@amzn/awsui-components-react-v3';

export interface PropertiesEntry {
    label: string;
    value: ReactNode;
    status?: StatusIndicatorProps.Type;
    color?: StatusIndicatorProps.Color;
}

interface ExpandablePropertiesProps{
    header: ReactNode;
    /** Default: h2 */
    variant?: ('h1' | 'h2' | 'h3');
    items: PropertiesEntry[][];
    /**
     * Placeholder for any null or undefined values
     * 
     * Default: -
     */
    placeholder?: ReactNode;
    /**
     * Should the item be expanded by default
     */
    defaultExpanded?: boolean;
    onExpansionChanged?: (isExpanded: boolean) => void;
}

export default class ExpandableProperties extends TuxComponent<ExpandablePropertiesProps, any>{
    private static keyCounter = 0;
    constructor(props: ExpandablePropertiesProps){
        super(props);
        this.bindAll(this);
        this.loadData();
    }

    async loadData(){
        // console.log("In loadData");
    }

    getRow(item: PropertiesEntry): JSX.Element{
        return (
            <ValueWithLabel key={`${item.label}_${ExpandableProperties.keyCounter++}`} label={item.label}>
                {item.status ? <StatusIndicator type={item.status} colorOverride={item.color}>{item.value}</StatusIndicator> : (item.value ?? (this.props.placeholder ?? "-") )}
            </ValueWithLabel>
        );
    }

    getColumn(items: PropertiesEntry[]): JSX.Element{
        return (
            <SpaceBetween key={`spacebetween_${ExpandableProperties.keyCounter++}`} size="l">
                {items.map((item) => this.getRow(item))}
            </SpaceBetween>
        );
    }

    render(){
        let header = (
            <Header variant={this.props.variant ?? "h2"}>
                {this.props.header}
            </Header>
        );
        return (
            <ExpandableSection variant="container" header={header} defaultExpanded={this.props.defaultExpanded??true} onChange={(e) => this.props.onExpansionChanged?this.props.onExpansionChanged(e.detail.expanded):null}>
                <ColumnLayout columns={this.props.items.length} variant="text-grid">
                    {this.props.items.map((items) => this.getColumn(items))}
                </ColumnLayout>
            </ExpandableSection>
        );
    }
}