import React from 'react';
import { Tabs, TabsProps } from '@amzn/awsui-components-react-v3';
import TuxComponent from './tux_component';

interface NoLoadTabsProps{
    tabs: Tab[];
    initialTab?: string | null;
    onTabChanged?: (tab_id: string) => void;
    /** Replace the current tab id in the url with the new one, if not present, appends to end of hash */
    updateHash?: boolean;
}

export interface Tab{
    id: string;
    label: string;
    content: React.ReactNode;
}

export default class NoLoadTabs extends TuxComponent<NoLoadTabsProps, any>{
    static totalIds: number = 0;
    id: string;
    currentTab = 0;
    // _tabs: TabsProps.Tab[];
    currentlyRenderedTabs : string[] = [];

    public constructor(props: NoLoadTabsProps) {
        super(props);
        NoLoadTabs.totalIds++;
        this.id = "NoLoadTabs" + NoLoadTabs.totalIds;
        // console.log(`Initial tab id: ${this.props.initialTab}`);
        this.currentTab = this.props.tabs.findIndex((t) => t.id === this.props.initialTab );
        // console.log(this.currentTab);

        if (this.currentTab < 0){
            this.currentTab = 0;
        }
        // console.log(this.currentTab);

        this.tabChanged = this.tabChanged.bind(this);
        
    }

    get _tabs(): TabsProps.Tab[]{
        let tabs: TabsProps.Tab[] = [];
        for (let index in this.props.tabs){
            let label = this.props.tabs[index].label;
            let id = this.props.tabs[index].id;
            tabs.push({
                label: label,
                id: id,
                content: <div/>
            });
        }
        return tabs;
    }

    async loadData(){
        // Do nothing
    }

     private tabChanged(e : any){

        // Unhide content
        let newTabID = e.detail.activeTabId;
        let currentTabID = this._tabs[this.currentTab].id;

        if (this.props.updateHash){
            if (!window.location.hash.includes(this._tabs[this.currentTab].id)){
                window.location.hash += `/${this._tabs[this.currentTab].id}`;
            }
            window.history.replaceState(null, document.title, window.location.hash.replace(currentTabID, newTabID));
        }

        // console.log(`Current Tab: ${this.tabs[this.currentTab].id}`);
        // console.log(`New Tab: ${newTabID}`);
        let index = this._tabs.findIndex((t) => t.id === newTabID);
        document.getElementById(this._tabs[this.currentTab].id)?.setAttribute("hidden", "true");
        document.getElementById(newTabID)?.setAttribute("hidden", "false");
        this.currentTab = index;
        try{
            if(this.props.onTabChanged){
                // console.log(`Calling onTabChanged(${newTabID})`);
                this.props.onTabChanged(newTabID);
            }
        } catch (e){
            // do nothing
        }

        this.dataUpdated();

     }

    private get divs(){
        let output = [];
        if (!this.currentlyRenderedTabs.includes(this.props.tabs[this.currentTab].id)){
            this.currentlyRenderedTabs.push(this.props.tabs[this.currentTab].id);
        }

        for (let index in this._tabs){
            let id: string = this._tabs[index].id;
            // Push the content as long as it's in the list of currently rendered tabs
            output.push(<div key={this._tabs[index].id} id={this._tabs[index].id} hidden={parseInt(index) !== this.currentTab}>
                { this.currentlyRenderedTabs.includes(id) ? this.props.tabs[index].content : <div/>}
            </div>);
        }
        return output;
    }

    render(): JSX.Element {
        // console.log(`Active Tab: ${this.tabs[this.currentTab].id}`);
        return (
            <div id={this.id}>
                <div style={{maxHeight:"80px", overflow:"hidden"}}>
                    <Tabs className="centeredTabs" tabs={this._tabs} activeTabId={this._tabs[this.currentTab].id} onChange={this.tabChanged}/>
                </div>
                {this.divs}
            </div>
        );
    }
}
