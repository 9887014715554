/**
 * Get a random value from an array
 * @param l Array to pick from
 * @returns Random value from the array
 */
export function pickRand(l: any[]) { 
    return l[Math.floor(Math.random() * l.length)];
}

/**
 * Wait for a given amount of time in an async function
 * @param ms Time to delay in ms
 * @returns Promise that will resolve in the specified amount of time
 */
export async function sleep(ms: number): Promise<void>{
    return new Promise(r => setTimeout(r, ms));
}

/**
 * Redirect the current tab to a new URL
 * @param url URL to redirect the tab to
 */
export function redirect(url: string){
    if (url.startsWith("http")){
        window.location.href = url;
    } else {
        if (!url.startsWith("#")){
            if (!url.startsWith("/")){
                url = "/" + url;
            }
            url = "#" + url;
        }
        // If in development use http instead of https
        if (process.env.NODE_ENV !== 'production'){
            window.location.href = `http://${window.location.host}/${url}`;
            return;
        }
        window.location.href = `https://${window.location.host}/${url}`;
    }
}

/**
 * Format a value from camelCase, ex: exampleCase to Example Case
 * @param value String to format
 * @returns Formatted string
 */
export function formatFromCamelCase(value: string): string{
    return (value.charAt(0).toUpperCase() + value.slice(1)).replace(/([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g, '$1$4 $2$3$5');
}

/**
 * Find the differences between two objects, shallow search
 * @param objA First object to compare
 * @param objB Second object to compare
 * @returns Array of keys that have differences between the two objects
 */
export function findDifferences(objA: any, objB: any): string[]{
    let keys: Set<string> = new Set<string>(Object.keys(objA).concat(Object.keys(objB)));
    let iter = keys.keys();
    let val = iter.next();
    let output: string[] = [];
    
    while (!val.done){
        let key = val.value;
        if (objA[key] !== objB[key]){
            output.push(key);
        }
        val = iter.next();
    }
    return output;
}

/**
 * Check if two objects are the same, shallow search
 * @param objA First object to check
 * @param objB Second object to check
 * @returns true if the objects are identical, false otherwise
 */
export function objectsAreSame(objA: any, objB: any): boolean{
    if ( (!objA && !!objB) || (!!objA && !objB)){
        return false;
    }
    let keys: Set<string> = new Set<string>(Object.keys(objA).concat(Object.keys(objB)));
    let iter = keys.keys();
    let val = iter.next();
    while (!val.done){
        let key = val.value;
        if (objA[key] !== objB[key]){
            return false;
        }
        val = iter.next();
    }
    return true;
}

/**
 * Download a given object to a file
 * @param object Object to download
 * @param filename Filename to save the object to
 */
export function downloadObject(object: any, filename: string){
    let url = window.URL.createObjectURL(object);
    let link = document.createElement('a');
    link.href = url;
    link.hidden = true;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}


export function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

/**
 * Await all promises concurrently and map errors to null,
 * @param promises Promises to await
 * @returns Array of results from the promises, null if promise failed
 */
export async function awaitAll(promises: Promise<any>[]): Promise<any[]>{
    let results = await Promise.all(promises.map((p) => p.catch((error) => error)));
    return results.map((r) => r instanceof Error ? null : r);
    
}