import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import TuxComponent from '../../components/tux_component';
import React from 'react';
import { BreadCrumbRoute } from '../../routing';
import { LEFT_NAV_FAVORITES, IS_PROD } from '../../utils/constants';
import { EventDispatcher } from '../../utils/events/event_dispatcher';
import { EventTypes } from '../../utils/events/event_types';
import StorageHelper from '../../utils/storage_helper';
import UserInfoHelper from '../../utils/user_info_helper';
import SessionHelper from '../../utils/session_helper';
import GlobalSearch from './global_search';
import { TUX_LOGO } from '../../resources/base_64_images';
import RequestHelper from '../../utils/request_helper';
import { AnalyticsRoutes, ActionDetails } from '../../utils/request_helper';
import Row from '@amzn/meridian/row';
import MastHead, { MastheadTitle } from '@amzn/meridian/masthead';
import {
    Button,
    SideNavigationProps,
    NonCancelableCustomEvent,
    ButtonDropdown,
    ButtonDropdownProps,
} from '@amzn/geist-ui-components';
import Box from '@amzn/meridian/box';
import Text from '@amzn/meridian/text';
import styled from '@emotion/styled';
import { TestIds } from './constants';

const FloatingRow = styled(Row)`
    position: absolute;
    left: 150px;
`;

const BannerText = styled(Text)`
    color: Yellow;
    margin-right: 40px;
`;

interface MainHeaderProps {}

export default class PersistentHeader extends TuxComponent<MainHeaderProps, any> {
    private static ROUTING_CHANGE_KEY = 'PERSISTENT_HEADER_ROUTING_CHANGE';
    private static RESET_PASSWORD = 'RESET_PASSWORD';
    private static CLEAR_RECENTS = 'CLEAR_RECENTS';
    private static CLEAR_FAVORITES = 'CLEAR_FAVORITES';
    private static SIGNOUT = 'SIGNOUT';

    private regionOptions: (ButtonDropdownProps.Item | ButtonDropdownProps.ItemGroup)[] = [];
    private currentPageName = '';

    constructor(props: MainHeaderProps) {
        super(props);
        this.bindAll(this);
        this.loadData();
        EventDispatcher.subscribe(EventTypes.RoutingChange, PersistentHeader.ROUTING_CHANGE_KEY, this.setCurrentPage);
    }

    private setCurrentPage(currentRoute: BreadcrumbGroupProps.Item) {
        this.currentPageName = currentRoute.text;
        this.dataUpdated();
    }

    async loadData() {
        this.regionOptions = [
            {
                id: 'Region',
                text: 'Update current region:',
                items: UserInfoHelper.availableRegions.map((r) => ({ id: r, text: r })),
            },
        ];
        this.dataUpdated();
    }

    get _favorites(): Set<string> {
        const output = new Set<string>();
        const favs = StorageHelper.getObject<SideNavigationProps.Link[]>(LEFT_NAV_FAVORITES) ?? [];

        for (const fav of favs) {
            output.add(fav.text);
        }

        return output;
    }

    menuButtonPressed(event: any) {
        this.menuOpen = true;
        this.dataUpdated();
    }

    accountIconPressed(event: React.MouseEvent<HTMLElement>) {
        this.anchorEl = event.currentTarget;
        this.open = true;
        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', 'Handle menu');
        }
        this.dataUpdated();
    }

    closeAccountMenu() {
        this.anchorEl = null;
        this.open = false;
        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', 'Handle close');
        }
        this.dataUpdated();
    }

    /**
     * Updates the region and reloads the current page
     * @param e
     * @returns
     */
    onRegionChange(e: any) {
        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', e);
        }
        const newRegion: string = e.detail.id;
        const oldRegion = SessionHelper.getRegion();
        if (newRegion === SessionHelper.getRegion()) {
            return;
        }
        RequestHelper.logAnalytics(
            AnalyticsRoutes.action,
            new ActionDetails('region_changed', `${SessionHelper.getRegion()}>${e.detail.id}`),
        );
        SessionHelper.setRegion(newRegion);
        window.location.hash = window.location.hash.replace(oldRegion, newRegion);
        window.location.reload();
    }

    favoritesPressed() {
        EventDispatcher.triggerEvent(EventTypes.FavoritesChange, BreadCrumbRoute.currentRoute);
        this.dataUpdated();
    }

    private clearCookies() {
        const cookies = document.cookie.split(';');

        for (const cookie of cookies) {
            const name = cookie.split('=')[0];
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
    }

    userActionRequested(e: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>) {
        switch (e.detail.id) {
            case PersistentHeader.CLEAR_RECENTS:
                RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails('button_click', 'clear_recents'));
                EventDispatcher.triggerEvent(EventTypes.RecentsCleared);
                return;
            case PersistentHeader.CLEAR_FAVORITES:
                RequestHelper.logAnalytics(
                    AnalyticsRoutes.action,
                    new ActionDetails('button_click', 'clear_favorites'),
                );
                StorageHelper.setObject(LEFT_NAV_FAVORITES, []);
                EventDispatcher.triggerEvent(EventTypes.FavoritesChange);
                this.dataUpdated();
                return;
            case PersistentHeader.RESET_PASSWORD:
                window.location.href = `http${process.env.NODE_ENV !== 'production' ? '' : 's'}://${
                    window.location.hostname
                }/forgotpassword`;
                return;
            case PersistentHeader.SIGNOUT:
                //this.clearCookies();
                window.location.href = `http${process.env.NODE_ENV !== 'production' ? '' : 's'}://${
                    window.location.hostname
                }/logout`;
                // window.location.replace(`${window.location.hostname}/logout`);
                return;
        }
    }

    get userActions() {
        const output = [
            { id: PersistentHeader.CLEAR_RECENTS, text: 'Clear Recents' },
            { id: PersistentHeader.CLEAR_FAVORITES, text: 'Clear Favorites' },
            {
                id: 'account_actions',
                text: 'Account Actions',
                items: [{ id: PersistentHeader.SIGNOUT, text: 'Sign Out' }].concat(
                    UserInfoHelper.isAmazonEmployee
                        ? []
                        : [{ id: PersistentHeader.RESET_PASSWORD, text: 'Reset Password (External Only)' }],
                ),
            },
        ] as (ButtonDropdownProps.Item | ButtonDropdownProps.ItemGroup)[];

        return output;
    }

    getTuxLogo() {
        return (
            <img
                height="35px"
                src={`data:image/png;base64, ${TUX_LOGO}`}
                alt="TuxLogo"
                style={{ backgroundColor: 'transparent' }}
            ></img>
            // // <div style={{ color: "#FFFFFF", fontSize: "15pt", fontWeight: "bold", height: "100%", display: "table" }}>
            //     {/* <span style={{ display: "table-cell", verticalAlign: "middle" }}>ES-PLM Tux</span> */}
            // // </div>
        );
    }

    getUserDropdown() {
        return (
            <ButtonDropdown className="headerDropdown" items={this.userActions} onItemClick={this.userActionRequested}>
                {`Welcome, ${UserInfoHelper.givenName}`}
            </ButtonDropdown>
        );
    }

    getRegionDropdown() {
        return (
            <ButtonDropdown className="headerDropdown" items={this.regionOptions} onItemClick={this.onRegionChange}>
                {`Region: ${SessionHelper.getRegion()}`}
            </ButtonDropdown>
        );
    }

    // Little heart for favoriting a page
    getFavoriteButton() {
        return (
            <div title={this._favorites.has(this.currentPageName) ? 'Unfavorite' : 'Favorite'}>
                <Button
                    variant="icon"
                    iconName="heart"
                    className={this._favorites.has(this.currentPageName) ? 'primaryIconButton' : 'secondaryIconButton'}
                    onClick={this.favoritesPressed}
                />
            </div>
        );
    }

    render(): JSX.Element {
        const nonProdBanner = IS_PROD ? <></> : <BannerText type="h300">This is NOT Production</BannerText>;

        return (
            <MastHead backgroundColor="#232F3E" size="large">
                <MastheadTitle>{this.getTuxLogo()}</MastheadTitle>
                <FloatingRow
                    spacing="none"
                    width="50%"
                    widths={IS_PROD ? ['100%'] : ['20%', '80%']}
                    data-testid={TestIds.FLOATING_ROW_HEADER}
                >
                    {nonProdBanner}
                    <Box width="50%">
                        <GlobalSearch />
                    </Box>
                </FloatingRow>
                <Row>
                    {this.getFavoriteButton()}
                    {this.getUserDropdown()}
                    {this.getRegionDropdown()}
                </Row>
            </MastHead>
        );
    }
}
