import React from 'react';
import ProgressTracker, { ProgressTrackerStep } from '@amzn/meridian/progress-tracker';
import Box from '@amzn/meridian/box';

type ProgressTrackerType = 'theme' | 'neutral';
type ProgressTrackerDirection = 'column' | 'row';
type ProgressTrackerRowLabelAlignment = 'center' | 'start';

export interface TuxProgressTrackerStep {
    type: 'past' | 'present' | 'future';
    label: string;
    showLabel?: boolean;
}

export interface TuxProgressTrackerProps {
    steps: TuxProgressTrackerStep[];
    type?: ProgressTrackerType;
    direction?: ProgressTrackerDirection;
    compact?: boolean;
    autoTrackLength?: boolean;
    rowLabelAlignment?: ProgressTrackerRowLabelAlignment;
    labelPast?: string;
    labelPresent?: string;
    labelFuture?: string;
    ['data-*']?: string;
    ['aria-*']?: string;
}

const TuxProgressTracker: React.FC<TuxProgressTrackerProps> = ({
    steps,
    type = 'theme',
    direction = 'column',
    compact = false,
    autoTrackLength = false,
    rowLabelAlignment = 'center',
    labelPast = 'Past',
    labelPresent = 'Present',
    labelFuture = 'Future',
    ...props
}) => {
    return (
        <Box spacingInset="400">
            <ProgressTracker
                type={type as 'theme' | 'neutral'}
                direction={direction as 'column' | 'row'}
                compact={compact}
                autoTrackLength={autoTrackLength}
                rowLabelAlignment={rowLabelAlignment as 'center' | 'start'}
                labelPast={labelPast}
                labelPresent={labelPresent}
                labelFuture={labelFuture}
                {...props}
            >
                {steps.map((step, index) => (
                    <ProgressTrackerStep
                        key={index}
                        type={step.type}
                        label={step.label}
                        showLabel={step.showLabel !== undefined ? step.showLabel : true}
                    />
                ))}
            </ProgressTracker>
        </Box>
    );
};

export default TuxProgressTracker;
