import { DocumentNode, gql } from '@apollo/client';
import { SiteModelType } from '../site_base_model';
import { MODEL_HEADER_FIELDS, getModelAttributes, getModelStructure} from './fragments';


export function getModelsQuery(type: SiteModelType) {
    const ATTRIBUTES = getModelAttributes(type);
    const STRUCTURE = getModelStructure(type, 'GetModels');

    return gql`
            ${MODEL_HEADER_FIELDS}, 
            ${ATTRIBUTES},
            ${STRUCTURE},
            query GetModels($modelType: ModelType!) {
                models(modelType: $modelType) {
                    ...ModelHeaderFields
                    ...Attributes
                    ...Structure
                }
            }
        `;
}

export function getModelQuery(type: SiteModelType, structureRequired: boolean) : DocumentNode {
    const ATTRIBUTES = getModelAttributes(type);

    if( structureRequired){
        const STRUCTURE = getModelStructure(type, 'GetModel');
        return gql`
                ${MODEL_HEADER_FIELDS}, 
                ${ATTRIBUTES},
                ${STRUCTURE}
                query GetModel($modelType: ModelType!, $systemId: ID!) {
                modelById(modelType: $modelType, systemId: $systemId) {
                        ...ModelHeaderFields
                        ...Attributes
                        ...Structure
                    }
                }
            `;
    }else{
        return gql`
                ${MODEL_HEADER_FIELDS}, 
                ${ATTRIBUTES},
                query GetModel($modelType: ModelType!, $systemId: ID!) {
                modelById(modelType: $modelType, systemId: $systemId) {
                        ...ModelHeaderFields
                        ...Attributes
                    }
                }
            `;
    }
}



/**
 * Get Site Model By given Criteria
 * @param type SiteModelType
 * @returns Graphql query to get SiteModel by Criteria
 */
export function getModelQueryByCriteria(type: SiteModelType, structureRequired: boolean) {
    const ATTRIBUTES = getModelAttributes(type);

    if(structureRequired){
        const STRUCTURE = getModelStructure(type, 'GetModel');
        return gql`
                ${MODEL_HEADER_FIELDS}, 
                ${ATTRIBUTES},
                ${STRUCTURE},
                query GetModel($modelType: ModelType!, $criteria: ModelCriteria!) {
                    models(modelType: $modelType, criteria: $criteria) {
                        ...ModelHeaderFields
                        ...Attributes
                        ...Structure
                    }
                }
            `;
    }else {
        return gql`
                ${MODEL_HEADER_FIELDS}, 
                ${ATTRIBUTES},
                query GetModel($modelType: ModelType!, $criteria: ModelCriteria!) {
                    models(modelType: $modelType, criteria: $criteria) {
                        ...ModelHeaderFields
                        ...Attributes
                    }
                }
            `;  
    }
}


