import { Button } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { CSVLink } from 'react-csv';
import { RequestHelper } from '../modules';
import { AnalyticsRoutes, ActionDetails } from '../utils/request_helper';
import { formatFromCamelCase } from '../utils/tools';
import { TuxComponent } from './components';

interface ExportToCSVButtonProps {
    filename: string;
    data: any[];
    keys?: string[];
    disabled?: boolean;
}

export default class ExportToCSVButton extends TuxComponent<ExportToCSVButtonProps, any>{
    static exportButtonCount: number = 0;
    private _id: string;

    constructor(props: ExportToCSVButtonProps) {
        super(props);
        ExportToCSVButton.exportButtonCount++;
        this._id = `${this.constructor.name}_${ExportToCSVButton.exportButtonCount}`;
        this.bindAll(this);
    }

    async loadData() {
        // Do nothing
    }

    private get keys(): string[]{
        if (this.props.keys){
            return this.props.keys;
        }
        let keySet: Set<string> = new Set<string>();
        let keys: string[] = [];

        for (let item of this.props.data){
            let itemKeys = Object.keys(item);
            for (let key of itemKeys){
                if (!keySet.has(key)){
                    keys.push(key);
                    keySet.add(key);
                }
            }
        }

        return keys;
    }

    private get csvData(): any[] {
        let keys: string[] = this.keys;
        let output: any[] = [ keys.map((k) => formatFromCamelCase(k)) ];

        for (let item of this.props.data){
            output.push(keys.map((key) => item[key]));
        }

        // console.log(keys);
        return output;
    }

    private exportPressed(){
        let csvLink = document.getElementById(`EXPORT_${this._id}`);
        if (!csvLink){
            return;
        }

        RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("export_table", this.props.filename));

        csvLink.click();
    }

    render() {
        return (
            <>
                <Button onClick={this.exportPressed} disabled={this.props.disabled ?? (this.props.data?.length ?? 0) === 0}>Export</Button>
                {this.props.disabled ? null : <CSVLink data={this.csvData} id={`EXPORT_${this._id}`} filename={`${this.props.filename}`} />}
            </>
        );
    }
}