import ModuleModel from './module_model';
import Bim360ModuleInfo from './bim360_module_info';
import RequestHelper from '../../utils/request_helper';
// import { getKeys } from "../../utils/tools";

interface CountResult {
    count: number;
}

export default class ModuleService {
    /**
     * Synchronously checks cache for modules, if not present returns []
     * @param region Region to get modules for
     * @param callback Callback with data from server
     * @returns ModuleModel[] if cached, [] if not
     */
    static offsetGetModulesFromRegion(
        region: string,
        callback: (mods: ModuleModel[]) => void,
        cacheProcess: (mods: ModuleModel[]) => any[]
    ): ModuleModel[] {
        if (!region) {
            throw new Error('Cannot get modules from library with no region.');
        }

        let response: any[] = [];

        response =
            RequestHelper.offsetCachedServiceRequest<any[]>(
                `/module/all/${region}/latest`,
                m => callback(m.map(r => ModuleModel.fromJSON(r))),
                cacheProcess
            ) ?? [];

        return response.map(r => ModuleModel.fromJSON(r));
    }

    /**
     * Synchronously checks cache for modules, if not present returns [], does not fire request if cache is present.
     * @param region Region to get modules for
     * @param callback Callback with data from server
     * @returns ModuleModel[] if cached, [] if not
     */
    static cachedGetModulesFromRegion(region: string, callback: (mods: ModuleModel[]) => void): ModuleModel[] {
        if (!region) {
            throw new Error('Cannot get modules from library with no region.');
        }

        let response: any[] = [];

        response =
            RequestHelper.cachedServiceRequest<any[]>(`/module/all/${region}/latest`, m =>
                callback(m.map(r => ModuleModel.fromJSON(r)))
            ) ?? [];

        return response.map(r => ModuleModel.fromJSON(r));
    }

    /**
     * Attempts to fetch all the modules for a given region
     * @param region Region to get the modules for
     * @returns Array of ModuleModels for the given region, if the region exists.
     * @throws Error if the endpoint cannot be reached or the region does not exist
     */
    static async getModulesFromRegion(region: string, page?: number, size?: number): Promise<ModuleModel[]> {
        if (!region) {
            throw new Error('Cannot get modules from blank region.');
        }

        let endpointPath = `/module/all/${region}/latest`

        if(page !== undefined && size !== undefined){
            endpointPath = `${endpointPath}?page=${page}&size=${size}`
        }

        let response: any[] = await RequestHelper.serviceRequest(endpointPath);

        return response.map(r => ModuleModel.fromJSON(r));
    }

    /**
     * Attempt to fetch a module from the server given a specific number and version.
     * @param number Unique module number to fetch
     * @param version Version of the module to look for
     * @returns Module at the specified version, if present
     */
    static async getModuleFromNumberAndVersion(number: string, version: string): Promise<ModuleModel> {
        if (!version || !number) {
            throw new Error('Cannot get module without version and number');
        }

        let response: any = await RequestHelper.serviceRequest(`/module/${number}/${version}`);

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', response);
        }

        return ModuleModel.fromJSON(response);
    }

    /**
     * Get a modules version History
     * @param module
     * @returns
     */
    static async getAllModuleVersions(module: ModuleModel): Promise<ModuleModel[]> {
        if (!module._versionId) {
            throw new Error('Cannot get version history for module without version ID');
        }

        let response: any = await RequestHelper.serviceRequest(`/module/group/${module._moduleId}`);

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', response);
        }

        let output: ModuleModel[] = response.map((r: any) => ModuleModel.fromJSON(r));

        output.sort((a, b) => {
            let aNum = Number(a.version?.split(' ')[0]);
            let bNum = Number(b.version?.split(' ')[0]);
            return aNum >= bNum ? (aNum === bNum ? 0 : -1) : 1;
        });

        return output;
    }

    /**
     * Trigger a single refresh of a given module.
     * @param module Module to refresh in the DB
     * @returns response from the server
     */
    static async forceRefresh(module: ModuleModel): Promise<any> {
        return RequestHelper.serviceRequest(`/adapter/module/sync/${module._moduleId}`, { method: 'post' }, 'text');
    }

    /**
     * Get the latest released iteration of a Module, returns latest iteration if no released version exits
     * @param number Module number to get the latest released version of
     * @returns Latest released version of a module, if no released version returns latest iteration
     */
    static async getLatestReleased(number: string): Promise<ModuleModel> {
        let versionZero = await ModuleService.getModuleFromNumberAndVersion(number, '0.0');
        let allVersions = await this.getAllModuleVersions(versionZero);

        let output = allVersions[0];

        for (let version of allVersions) {
            if (!version.state) {
                continue;
            }
            if (['released', 'releasedminor'].includes(version.state.toLowerCase())) {
                output = version;
                break;
            }
        }

        return output;
    }

    static async getBim360ModuleInfo(systemId: string): Promise<Bim360ModuleInfo> {
        let response: any[] = await RequestHelper.serviceRequest(`/bim360/module/v1/${systemId}`);

        return Bim360ModuleInfo.fromJSON(response);
    }

    static async getLatestVersion(moduleId: string): Promise<string> {
        if (!moduleId) {
            throw new Error('Cannot get module version without moduleId');
        }

        let response: any = await RequestHelper.serviceRequest(`/module/group/${moduleId}/latest`);

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', response);
        }

        return ModuleModel.fromJSON(response).version ?? '';
    }

    static async getModulesDistinctCount(region: string): Promise<number>{

        if (!region) {
            throw new Error('Cannot get module count without region');
        }

        return new Promise(resolve => {
            const cachedValue: CountResult = RequestHelper.offsetCachedServiceRequest<any>(
                `/module/all/${region}/distinctCount`,
                (result: CountResult) => {
                    resolve(result.count);
                }
            );
            if (cachedValue) {
                resolve(cachedValue.count);
            }
        });     
    }
}
