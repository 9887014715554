import React from 'react';
import NonModuleProposalModel from '../../../data/site/non_module_proposal_model';
import { ItemTable } from '../../../modules';
import BomModel from '../../../data/site/bom_model';

export class BomItem {
    number?: string;
    name?: string;
    desc?: string;
    quantity?: number;
    price?: string;
    carTab?: string;
    carLine?: string;
    partNumber?: string;
    supplier?: string;
    uin?: string;   
}

export function flattenNMP(bomModel: BomModel[]): BomItem[] {
    return bomModel?.map(
        customQuote =>
            ({
                number: customQuote.bomItem?.number,
                name: customQuote.bomItem?.name,
                desc: customQuote.bomItem?.desc,
                quantity: customQuote.quantity,
                price: customQuote.bomItem?.price.value,
                carTab: customQuote.bomItem?.carTab,
                carLine: customQuote.bomItem?.carLine,
                partNumber: customQuote.bomItem?.partNumber,
                supplier: customQuote.bomItem?.supplier,
                uin: customQuote.bomItem?.uin
            } as BomItem)
    );
}

export const NonModuleProposalStructureView = ({
    nonModuleProposal,
}: {
    nonModuleProposal: NonModuleProposalModel;
}) => {

    let bomModel: BomModel[] = nonModuleProposal._bom??[];
    const bomItems: BomItem[] = flattenNMP(bomModel);

    function getCurrencyFormatter(bomEntry: BomModel): Intl.NumberFormat {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: bomEntry.currency ?? 'USD'
        });
    }

    function formatCurrency(p: number | string, b?: BomModel): string {
        if (p === null || b === null || p === undefined) {
            return '-';
        }
    
        if (typeof p === 'string') {
            return p;
        }
    
        if (!b?.currency) {
            return `$${p.toFixed(2)}`;
        }
    
        return getCurrencyFormatter(b).format(p);
    }

    return (
        <ItemTable
            title={`BOM - ${nonModuleProposal.number}`}
            items={bomItems}
            customDisplays={{
                price: val => formatCurrency(val)
            }}
            customWidths={{
                "name": 300,
            }}
            initiallyVisibleColumnCount={9}
            disableSelection={true}
            canExport
        />
    );
};
