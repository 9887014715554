import React from 'react';
import { ModuleModel, ChangeRequestBaseModel, ChangeRequestService } from '../../../data/data';
import { TuxComponent, ItemTable } from '../../../components/components';
import { Box, Link } from '@amzn/awsui-components-react';
import SessionHelper from '../../../utils/session_helper';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { measuredAsync } from '../../../utils/decorators';
import { StatusIndicator } from '@amzn/geist-ui-components';

interface ModuleChangesViewProps {
    modulePromise: Promise<ModuleModel>;
}

export default class ModuleChangesView extends TuxComponent<ModuleChangesViewProps, any> {
    isLoading = true;
    module: ModuleModel;
    changeRequests: ChangeRequestBaseModel[] = [];

    constructor(props: ModuleChangesViewProps) {
        super(props);
        this.module = ModuleModel.LOADING_TEMPLATE;
        this.loadData();
    }

    @measuredAsync('load_time_tab')
    async loadData() {
        this.module = this.module.number?.startsWith('Loading') ? await this.props.modulePromise : this.module;
        this.changeRequests = await ChangeRequestService.getRelatedChangeRequests(this.module._systemId ?? '');
        this.isLoading = false;
        this.dataUpdated();
    }

    render() {
        return (
            <>
                <ItemTable
                    title="Change Requests"
                    items={this.changeRequests}
                    disableSelection={true}
                    disableSearch={true}
                    customDisplays={{
                        createdOn: (d: Date) => new Date(d).toLocaleDateString(),
                        state: (state: string, item: ChangeRequestBaseModel) => (
                            <StatusIndicator type={ChangeRequestBaseModel.statusType(state)}>
                                {item.stateString}
                            </StatusIndicator>
                        ),
                        number: (n: string, item: ChangeRequestBaseModel) => (
                            <Link href={`#/${SessionHelper.getRegion()}/changes/${item._changeType}/${n}`}>{n}</Link>
                        ),
                    }}
                    initiallyVisibleColumnCount={8}
                />
            </>
        );
    }
}
