import RequestHelper from '../../utils/request_helper';

export default class ContentModel {
    [key: string]: any;

    contentType = '';
    createdOn: Date = new Date();
    description = '';
    fileName = '';
    filePath = '';
    fileSize?: number;
    contentUrl?: string;
    refId = '';
    refType: 'module' | 'change' = 'module';
    role: 'Primary' | 'Secondary' = 'Primary';
    systemId = '';
    updatedOn: Date = new Date();
    modifiedOn?: Date;

    /**
     * Complete URL to downloadBytes endpoint on Content Service
     *
     * Format: https://{HOSTNAME}/content/{SYSTEM_ID}/downloadBytes
     */
    get downloadBytesURL(): string {
        return `${RequestHelper.HOST}/content/${this.systemId}/downloadBytes`;
    }

    /**
     * Get the path to the download endpoint with an attachment disposition
     */
    get downloadAttachmentPath(): string {
        return `/content/${this.systemId}/download?disposition=attachment`;
    }

    static contentTypeString(type: string): string {
        const contentTypeMap: { [key: string]: string } = {
            doc: 'Document',
            docx: 'Document',
            docm: 'Document',
            xlsm: 'Excel Workbook',
            xlsx: 'Excel Workbook',
            xlsb: 'Excel Workbook',
            xls: 'Excel Workbook',
            pptx: 'PowerPoint Presentation',
            pptm: 'PowerPoint Presentation',
            pps: 'PowerPoint Presentation',
            ppt: 'PowerPoint Presentation',
            htm: 'Web Page',
            html: 'Web Page',
            mht: 'Single File Web Page',
            mhtml: 'Single File Web Page',
            zip: 'Zip File',
            txt: 'Text',
            msg: 'Email Message',
            skp: 'SketchUp File',
            pub: 'Microsoft Publisher',
            vsdx: 'Visio File',
        };
        return contentTypeMap[type.toLowerCase()] ?? type.toUpperCase();
    }

    static fileSize(size: number): string {
        const mb: number = 1024 * 1024;
        const kb = 1024;

        if (size != null) {
            if (size > mb) {
                return Math.round((size / mb) * 100) / 100 + ' MB';
            } else {
                return Math.round((size / kb) * 100) / 100 + ' KB';
            }
        }

        return '-';
    }

    static fromJSON(json: any): ContentModel {
        const output = new ContentModel();

        const contentKeys = Object.keys(output);
        const keySet: Set<string> = new Set(contentKeys);

        // Copy over the values of they keys present in a ModuleModel;
        for (const key of contentKeys) {
            if (output[key] instanceof Date) {
                output[key] = new Date(json[key]);
            } else {
                output[key] = json[key];
            }
        }

        for (const key of Object.keys(json)) {
            // Ignore keys already present in ModuleModel
            if (!key.startsWith('_') && keySet.has(key)) {
                continue;
            }
            output['_' + key] = json[key];
        }

        return output;
    }
}
