import { gql } from '@apollo/client';

export function getPreferencesQuery() {
    return gql`
            query GetPreferences {
                preferences{
                    multiValue
                    name
                    systemId
                    value
                  }
            }
        `;
}

export function getPreferenceByCriteria(){
    return gql`
        query GetPreferenceByName($criteria: PreferenceCriteria!) {
            preferences(criteria: $criteria) {
                multiValue
                name
                systemId
                value
            }
        }
    `;  

}