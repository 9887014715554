import React from 'react';
import TuxComponent from "../../../components/tux_component";
import {ModuleModel, ModuleService} from '../../../data/data';
import ItemTable, { CustomDisplayProps } from '../../../components/item_table';
import { Alert, AlertProps, Link, StatusIndicator } from '@amzn/awsui-components-react-v3';
import CompareModulesView from '../../compare/compare_modules_view';
import { measuredAsync } from '../../../utils/decorators';
import { RequestHelper } from '../../../modules';
import { AnalyticsRoutes, ActionDetails } from '../../../utils/request_helper';

interface ModuleHistoryViewProps{
    modulePromise: Promise<ModuleModel>;
}

export default class ModuleHistoryView extends TuxComponent<ModuleHistoryViewProps, any>{
    private alertMessage: string = "";
    private isAlertVisible: boolean = false;
    private alertType: AlertProps.Type = "info";
    isLoading: boolean = true;
    history: ModuleModel[] = [];
    module: ModuleModel;
    customDisplays:CustomDisplayProps;

    constructor(props: ModuleHistoryViewProps){
        super(props);
        this.module = ModuleModel.LOADING_TEMPLATE;
        this.customDisplays = {
            createdOn:(d:Date) => new Date(d).toLocaleString(),
            number: (n: string, item:ModuleModel) => <Link href={`#/${item.region}/modules/${n}/${item.version?.split(" ")[0]}`} onFollow={(e) =>{window.location.hash=e.detail.href??window.location.hash; window.location.reload();}}>{n}</Link>,
            state: (state: string, item:ModuleModel) => <StatusIndicator type={ModuleModel.statusType(state)}>{item.stateString}</StatusIndicator>,
        };

        this.bindAll(this);
        this.loadData();
    }

    @measuredAsync("load_time_tab")
    async loadData(){
        try{
            this.module = await this.props.modulePromise;
            this.history = await ModuleService.getAllModuleVersions(this.module);
        }catch(err){
            this.showAlert("Failed to load module history.", "error");
        }
        this.isLoading = false;
        this.dataUpdated();
    }

    comparePressed(modules: ModuleModel[]){
        if (modules.length !== 2){
            this.showAlert("You need to select 2 versions in order to compare.");
            return;
        }
        RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("button_pressed", "compare_modules"));
        let compareHash = CompareModulesView.generateHREF(modules[0], modules[1]);
        if (process.env.NODE_ENV !== 'production'){
            console.log("(DEBUG)", compareHash);
        }
        let href = window.location.href.replace(window.location.hash, compareHash);
        if (process.env.NODE_ENV !== 'production'){
            console.log("(DEBUG)", href);
        }

        window.open(href);
    }

    private showAlert(message: string, type: AlertProps.Type = "info"){
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }

    hideAlert(){
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    render(){
        return (
            <>
            <Alert type={this.alertType} header={this.alertMessage} visible={this.isAlertVisible} dismissible onDismiss={this.hideAlert}></Alert>
            {this.isAlertVisible ? <br/> : null}
            <ItemTable 
                    title={`${this.module?.number ? (this.module?.number + " - History") : "Loading..."}`} 
                    items={this.history}
                    actions={[{label:"Compare", callback:this.comparePressed, icon:"external"}]} 
                    hiddenColumns={["bom"]}
                    isLoading={this.isLoading}
                    customDisplays={this.customDisplays}
                    selectionLimit={2}
                    initiallyVisibleColumnCount={7}
                    customWidths={{
                        number: 150,
                        state: 150,
                        version: 150,
                        comments:"33%"
                    }}
                    initialColumnOrder={["number", "version", "state", "comments", "lastModified", "modifiedBy", "createdOn"]}
                    />
            </>
        );
    }
}
