import CookieHelper, { CookieNames } from './cookie_helper';

interface IdentityEntry {
    [key: string]: string;
    userId: string;
}

interface IDTokenPayload {
    [key: string]: any;
    given_name: string;
    identities: IdentityEntry[];
    family_name: string;
    email: string;
    'cognito:groups': string[];
}

export default class UserInfoHelper {
    public static UNKOWN_USERNAME = 'UNKNOWN';
    private static _username = '';
    private static _fullName = '';
    private static _givenName = '';
    private static _email = '';
    private static _groups: string[] = [];

    public static get isAdmin(): boolean {
        if (process.env.NODE_ENV !== 'production') {
            return true;
        }
        return this._groups.includes('AdminAccess');
    }

    public static get isAmazonEmployee(): boolean {
        return this._groups.join(',').includes('_AmazonEmployee');
    }

    public static get availableRegions(): string[] {
        // if (process.env.NODE_ENV !== 'production'){
        //     return ["NA", "EU"];
        // }
        const output = this._groups.filter((g) => g.includes('Module_User_')).map((g) => g.split('Module_User_')[1]);
        if (this.isAmazonEmployee) {
            if (!output.includes('NA')) {
                output.push('NA');
            }
            if (!output.includes('EU')) {
                output.push('EU');
            }
        }
        return output;
    }

    public static get username(): string {
        return `${UserInfoHelper._username}`;
    }

    public static get fullName(): string {
        return `${UserInfoHelper._fullName}`;
    }

    public static get givenName(): string {
        return `${UserInfoHelper._givenName}`;
    }

    public static get email(): string {
        return `${UserInfoHelper._email}`;
    }

    public static get groups(): string[] {
        return this._groups.map((g) => `${g}`);
    }

    /**
     * Updates the user's details in UserInfoHelper, called before rendering App
     */
    static loadUserInfoFromCookies() {
        const id_token = CookieHelper.getCookieByName(CookieNames.id_token);
        // if (process.env.NODE_ENV === 'production' && id_token.length === 0){
        //     // Token wasn't present, redirect to home page so user will be logged in
        //     window.location.href = `http${process.env.NODE_ENV !== 'production'?'':'s'}://${window.location.host}/`;
        // }

        const encodedUserDetails = id_token.split('.')[1];
        if (!encodedUserDetails) {
            UserInfoHelper._username = UserInfoHelper.UNKOWN_USERNAME;
            return;
        }
        const userDetails: IDTokenPayload = JSON.parse(atob(encodedUserDetails)) as IDTokenPayload;
        UserInfoHelper._username =
            userDetails.identities?.length > 0 ? userDetails.identities[0].userId : userDetails['cognito:username'];
        UserInfoHelper._email = userDetails.email;
        UserInfoHelper._givenName = userDetails.given_name;
        UserInfoHelper._fullName = userDetails.given_name + ' ' + userDetails.family_name;
        UserInfoHelper._groups = userDetails['cognito:groups'] ?? [];
    }
}
