import React, { ErrorInfo, PropsWithChildren } from 'react';
import RequestHelper, { AnalyticsRoutes, ErrorDetails } from '../utils/request_helper';
import { objectsAreSame } from '../utils/tools';

// interface LoadsData{
//     loadData():Promise<void>;
// }

export default abstract class TuxComponent<P, S> extends React.Component<PropsWithChildren<P>, S> {
    // implements LoadsData{
    [key: string]: any;
    _isMounted = false;
    static __ignoredMethods: string[] = ['constructor', 'render', 'dataUpdated'];

    public constructor(props: PropsWithChildren<P>) {
        super(props);
        this.dataUpdated = this.dataUpdated.bind(this);
    }

    public abstract loadData(): Promise<void>;

    componentDidUpdate(newProps: P) {
        if (!objectsAreSame(this.props, newProps)) {
            this.loadData();
        }
    }

    /**
     * Bind all functions of an object to itself (other than render and the constructor)
     * @param obj - Object to bind all methods of
     * @param ignoredMethods - Methods to not bind
     */
    bindAll(obj: any, ignoredMethods: string[] = []) {
        const propertyNames = Object.getOwnPropertyNames(Object.getPrototypeOf(obj)).filter(
            (name) => typeof obj[name] === 'function',
        );
        // if (process.env.NODE_ENV !== 'production'){
        //     console.log("Keys: " + Object.keys(obj));
        //     console.log("Properties: " + Object.getOwnPropertyNames(obj));
        //     console.log("Prototype Properties: " + Object.getOwnPropertyNames(Object.getPrototypeOf(obj)));
        //     console.log("Property Names: " + propertyNames);
        // }
        ignoredMethods = ignoredMethods.concat(TuxComponent.__ignoredMethods);
        for (const propertyName of propertyNames) {
            if (ignoredMethods.includes(propertyName)) {
                continue;
            }
            obj[propertyName] = obj[propertyName].bind(obj);
        }
    }

    /**
     * Callback used when some data has been changed on the page, will refresh the content on the page
     */
    dataUpdated() {
        if (this._isMounted) {
            // if (process.env.NODE_ENV !== 'production'){
            //     console.log("Setting state in: " + this.constructor.name);
            // }
            this.setState({}, () => null);
            return;
        }
        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG) Attempted to update the state of an unmounted component: ' + this.constructor.name);
        }
    }

    /**
     * This is called after a function is shown ("mounted")\
     *
     * If you override this method, don't forget to call super.componentDidMount()
     */
    componentDidMount() {
        this._isMounted = true;
    }

    /**
     * This is called when a component will no longer be shown ("mounted")
     *
     * If you override this method, don't forget to call super.componentWillUnmount()
     */
    componentWillUnmount() {
        this._isMounted = false;
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        RequestHelper.logAnalytics(AnalyticsRoutes.error, new ErrorDetails(`${error.name}, ${error.message}`));
        throw error;
    }

    render(): JSX.Element {
        return <>{this.props.children || null}</>;
    }
}
