import React, {  } from 'react';
import ModuleListModel from '../../../data/site/module_list_module';
import { ItemTable, RequestHelper, SessionHelper } from '../../../modules';
import { Data } from 'aws-sdk/clients/firehose';
import { Link, SpaceBetween, StatusIndicator, StatusIndicatorProps } from '@amzn/awsui-components-react-v3';
import { ActionDetails, AnalyticsRoutes } from '../../../utils/request_helper';
import CompareMDLView, { MDLItem } from '../../compare/compare_mdl_view';

interface HistoryItem {
    _systemId: string;
    number: string;
    name: string;
    version: string;
    state: string;
    mdgTemplate: string;
    createdOn: Date;
    lastModified: Data;
    erpStatus: string;
    moduleCountsTemplate: string;
}

export const ModuleListHistoryView = ({
    moduleListHistory,
    isLoading,
    siteSystemId
}: {
    moduleListHistory: ModuleListModel[];
    isLoading: boolean;
    siteSystemId: string;
}) => {
    const historyItems: HistoryItem[] = moduleListHistory.map(
        mdl =>
            ({
                _systemId: mdl._systemId,
                number: mdl.number,
                name: mdl.name,
                version: mdl.displayVersion,
                state: mdl.stateString,
                mdgTemplate: mdl.mdgTemplate,
                erpStatus: mdl.erpStatus,
                moduleCountsTemplate: mdl.moduleCountsTemplate,
                createdOn: mdl.createdOn,
                lastModified: mdl.lastModified
            } as HistoryItem)
    );

    historyItems.sort((a, b) => {
        let aNum = Number(a.version?.split(' ')[0]);
        let bNum = Number(b.version?.split(' ')[0]);
        return aNum >= bNum ? (aNum === bNum ? 0 : -1) : 1;
    });

    const getStatus = (state: string): StatusIndicatorProps.Type => {
        if (state === 'Released' || state === 'Approved') return 'success';

        if (state === 'Closed') return 'stopped';

        return 'pending';
    };

    const comparePressed = (mdls: MDLItem[]) => {

        RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("button_pressed", "compare_mdls"));

        let compareHash = CompareMDLView.generateHREF(mdls[0], mdls[1]);
        if (process.env.NODE_ENV !== 'production'){
            console.log("(DEBUG)", compareHash);
        }
        let href = window.location.href.replace(window.location.hash, compareHash);
        if (process.env.NODE_ENV !== 'production'){
            console.log(href);
        }

        window.open(href);
    }

    return (
        <>
        <SpaceBetween size='l'>
            <ItemTable
                items={historyItems}
                isLoading={isLoading}
                initiallyVisibleColumnCount={5}
                actions={[{label:"Compare", callback:comparePressed, icon:"external", enableAt:2}]}
                selectionLimit={2}
                customDisplays={{
                    number: (n: string, item: ModuleListModel) => (
                        <Link
                            href={`#/${SessionHelper.getRegion()}/sites/${siteSystemId}/relatedItems/ModuleList/${
                                item._systemId
                            }`}
                            onFollow={e => {
                                window.location.hash = e.detail.href ?? window.location.hash;
                                window.location.reload();
                            }}
                        >
                            {n}
                        </Link>
                    ),
                    state: (state: string) => <StatusIndicator type={getStatus(state)}>{state}</StatusIndicator>
                }}
            />
        </SpaceBetween>
        </>
    );
};
