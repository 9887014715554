import { KeyValueSection, KeyValueTemplate } from "../../components/dynamic_expandable_properties";
import ChangeRequestBaseModel from "./change_request_base_model";

export default class MinorChangeRequestModel extends ChangeRequestBaseModel {
    [key: string]: any;

    public static LOADING_TEMPLATE: MinorChangeRequestModel = new MinorChangeRequestModel({
        number: "Loading...",
        name: "Loading...",
        buildingType: "Loading...",
        region: "Loading...",        
        state: "Loading...",
        createdBy: "Loading...",
        createdOn: new Date(),
        modifiedBy: "Loading...",
        lastModified: new Date(),
        businessJustification: "Loading...",
        whitePaperLink: "Loading...",
        severity: "Loading..."
    })

    businessJustification?: string;
    whitePaperLink?: string;
    severity?: string;

    constructor(json: any) {
        super(json);
        this.init(json);
    }

    public getType(): string {
        return "minor";
    }

    public keyValueTemplate(): KeyValueTemplate {
        let template: KeyValueTemplate = {
            title: "Attributes",
            type: "key-value",
            defaultExpanded: true,
            sections: [
                [
                    { title: "Number", key: "number" },
                    { title: "Name", key: "name" },
                    { title: "Business Justification", key: "businessJustification" },
                    { title: "Building Type", key: "buildingType", type: "new-line-separated", separator: ",", sorted: true },
                    { title: "White Paper Link", key: "whitePaperLink" },
                ] as KeyValueSection[],
                [
                    {
                        title: "State", key: "stateString", type: "status", states: {
                            Approved: "success",
                            Rework: "warning",
                            Released: "success",
                            Rejected: "error",
                            Implementation: "in-progress",
                            default: "pending"
                        }
                    },
                    { title: "CCM Status", key: "ccmStatus" },
                    { title: "Severity", key: "severity" },
                    { title: "Last Modified", key: "lastModified", type: "datetime" },
                    { title: "Last Modified By", key: "modifiedBy" }
                ],
            ],
        };

        return template;
    }

}