import React from 'react';
import { BreadcrumbGroupProps, SpaceBetween } from '@amzn/awsui-components-react';
import TuxView from '../../components/tux_view';
import SiteModel from '../../data/site/site_model';
import { NoLoadTabs } from '../../components/components';
import ErrorView from '../../components/error_page';
import { SiteModelService } from '../../data/data';
import { SiteModelType } from '../../data/site/site_base_model';
import SiteDetailsView from './tabs/site_details_view';
import SiteRelatedItemsView from './tabs/site_related_items_view';
import ModuleListView from '../moduleList/module_list_view';
import SiteModelView from './tabs/site_model_view';
import NonModuleListView from '../nonModuleList/non_module_list_view';
import PostCARProposalView from '../postCarProposal/post_car_proposal_view';
import NonModuleProposalView from '../nonModuleProposal/non_module_proposal_view';
import { EventDispatcher } from '../../utils/events/event_dispatcher';
import { EventTypes } from '../../utils/events/event_types';
import FolderView from './folder/site_folder_view';
import { Header, Alert, AlertProps } from '@amzn/geist-ui-components';
import Box from '@amzn/meridian/box';

interface SiteViewProps {
    match: {
        params: {
            region: string;
            systemId: string;
            initialTab: string;
            relatedItemType: string;
            relatedItamId: string;
        };
    };
}

export default class SiteView extends TuxView<SiteViewProps, any> {
    isLoading = true;
    encounteredError = false;
    currentTab: string = this.props.match.params.initialTab ?? 'details';
    site: SiteModel = SiteModel.LOADING_TEMPLATE;
    sitePromise!: Promise<SiteModel>;

    private alertType: AlertProps.Type = 'info';
    private isAlertVisible = false;
    private alertMessage = '';

    constructor(props: SiteViewProps) {
        super(props);
        const systemId = props.match.params.systemId;
        this.state = { siteId: systemId };
        this.bindAll(this);
        this.loadData();
        EventDispatcher.subscribe(EventTypes.RoutingChange, EventTypes.SiteReload, (t: BreadcrumbGroupProps.Item) => {
            this.setCurrentPage(t);
        });
    }

    // Function to reload component when route changed
    private setCurrentPage(currentRoute: BreadcrumbGroupProps.Item) {
        const newSiteId = currentRoute['text'];
        // If the URL is for site and Not MDL or NML, reload component
        if (!newSiteId.toLowerCase().includes('list') && !newSiteId.toLowerCase().includes('proposal')) {
            this.setState({ siteId: newSiteId }, this.loadData);
            this.dataUpdated();
        }
    }

    public async loadData(): Promise<void> {
        this.sitePromise = SiteModelService.fetchModel(SiteModelType.Site, this.state.siteId).catch((e) => {
            this.showAlert(`Please contact site administrator. ${(e as Error).message}`, 'error');
            return this.site;
        }) as Promise<SiteModel>;
        this.site = await this.sitePromise;
        this.isLoading = false;
        this.dataUpdated();
    }

    tabChanged(tabClicked: any) {
        if (!window.location.hash.includes(this.currentTab)) {
            window.location.hash += `/${this.currentTab}`;
        }
        window.history.replaceState(null, 'ES-PLM', window.location.hash.replace(this.currentTab, tabClicked));
        this.currentTab = tabClicked;
    }

    private showAlert(message: string, type: AlertProps.Type = 'info') {
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }

    private alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    getHeader(): JSX.Element {
        if (this.isLoading) {
            return <Header children={'Loading...'} />;
        }
        return (
            <Header description={this.site.projectNumber}>
                <SpaceBetween size="xs" direction="horizontal">
                    <div key="site_header_title">{`${this.site.name}`}</div>
                </SpaceBetween>
            </Header>
        );
    }

    getContent(): JSX.Element {
        if (
            this.props.match?.params?.relatedItemType &&
            this.props.match?.params?.relatedItemType === SiteModelType.ModuleList
        ) {
            return (
                <ModuleListView
                    sitePromise={this.sitePromise}
                    systemId={this.props.match?.params?.relatedItamId}
                    initialTab={this.props.match?.params?.initialTab}
                />
            );
        }
        if (
            this.props.match?.params?.relatedItemType &&
            this.props.match?.params?.relatedItemType === SiteModelType.NonModuleList
        ) {
            return (
                <NonModuleListView
                    sitePromise={this.sitePromise}
                    systemId={this.props.match?.params?.relatedItamId}
                    initialTab={this.props.match?.params?.initialTab}
                />
            );
        }
        if (
            this.props.match?.params?.relatedItemType &&
            this.props.match?.params?.relatedItemType === SiteModelType.PostCARProposal
        ) {
            return (
                <PostCARProposalView
                    sitePromise={this.sitePromise}
                    systemId={this.props.match?.params?.relatedItamId}
                    initialTab={this.props.match?.params?.initialTab}
                />
            );
        }
        if (
            this.props.match?.params?.relatedItemType &&
            this.props.match?.params?.relatedItemType === SiteModelType.NonModuleProposal
        ) {
            return (
                <NonModuleProposalView
                    sitePromise={this.sitePromise}
                    systemId={this.props.match?.params?.relatedItamId}
                    initialTab={this.props.match?.params?.initialTab}
                />
            );
        }
        if (
            this.props.match?.params?.relatedItemType &&
            this.props.match?.params?.relatedItemType === SiteModelType.NonModuleProposalFolder
        ) {
            return (
                <FolderView
                    sitePromise={this.sitePromise}
                    systemId={this.props.match?.params?.systemId}
                    relatedItemType={this.props.match?.params?.relatedItemType}
                />
            );
        }
        if (
            this.props.match?.params?.relatedItemType &&
            this.props.match?.params?.relatedItemType === SiteModelType.PostCARProposalFolder
        ) {
            return (
                <FolderView
                    sitePromise={this.sitePromise}
                    systemId={this.props.match?.params?.systemId}
                    relatedItemType={this.props.match?.params?.relatedItemType}
                />
            );
        }

        return (
            <>
                {this.getHeader()}
                <NoLoadTabs
                    initialTab={this.props.match?.params?.initialTab ?? 'details'}
                    onTabChanged={this.tabChanged}
                    tabs={[
                        {
                            id: 'details',
                            label: 'Details',
                            content: <SiteDetailsView sitePromise={this.sitePromise} />,
                        },
                        {
                            id: 'relatedItems',
                            label: 'Related Items',
                            content: <SiteRelatedItemsView sitePromise={this.sitePromise} />,
                        },
                        {
                            id: 'model',
                            label: '3D',
                            content: <SiteModelView sitePromise={this.sitePromise} />,
                        },
                    ]}
                />
            </>
        );
    }

    render() {
        if (this.encounteredError) {
            return <ErrorView />;
        }
        return (
            <div style={{height: '100%'}}>
                <Box spacingInset={this.isAlertVisible ? '400 0' : 0}>
                    <Alert
                        alertType={this.alertType}
                        visible={this.isAlertVisible}
                        dismissible={true}
                        onDismiss={this.alertDismissed}
                        header={this.alertMessage}
                    />
                </Box>
                {this.alertMessage.toLocaleLowerCase().includes('permissions') ? <></> : this.getContent()}
            </div>
        );
    }
}
