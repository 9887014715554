import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import CookieHelper, { CookieNames } from './cookie_helper';
import RequestHelper from './request_helper';
import 'cross-fetch/polyfill';

export const siteGQL = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Model: {
                keyFields: ['systemId', 'modelType'],
            },
        },
    }),

    link: new HttpLink({
        uri: `${RequestHelper.HOST}/site/graphql`,
        headers: {
            Authorization: CookieHelper.getCookieByName(CookieNames.id_token),
        },
        fetch,
    }),
});

export const preferenceGQL = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Model: {
                keyFields: ['systemId', 'modelType'],
            },
        },
    }),

    link: new HttpLink({
        uri: `${RequestHelper.HOST}/common/graphql`,
        headers: {
            Authorization: CookieHelper.getCookieByName(CookieNames.id_token),
        },
        fetch,
    }),
});
