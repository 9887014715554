/**
 * Safe alternative to localStorage, doesn't throw error when access is denied
 */
export default class StorageHelper {
    /** True if localStorage is enabled or no attempts to check have been made, false otherwise */
    private static isEnabled = true;

    private constructor() {}

    /**
     * Set an item in localStorage, if enabled
     * @param key Key to assign a value for
     * @param value Value to be stored
     */
    public static setString(key: string, value: string) {
        if (key == null || value == null) {
            return;
        }
        try {
            localStorage.setItem(key, value);
        } catch (err) {
            StorageHelper.isEnabled = false;
            console.error(err);
        }
    }

    /**
     * Set an item in localStorage, if enabled
     * @param key Key to assign a value for
     * @param value Value to be stored
     */
    public static setObject(key: string, value: any) {
        if (key == null || value == null) {
            return;
        }
        try {
            localStorage.setItem(key, JSON.stringify(value));
        } catch (err) {
            StorageHelper.isEnabled = false;
            console.error(err);
        }
    }

    /**
     * Retrieve a value from local storage
     * @param key Key to retrieve the value for
     * @param defaultValue Default value to return if key is missing, default: null
     * @returns Value stored for key, or ```defaultValue``` if missing
     */
    public static getString(key: string, defaultValue?: string | null): string | null {
        if (defaultValue === undefined) {
            defaultValue = null;
        }
        if (key == null) {
            return defaultValue;
        }
        let value: string | null = null;

        try {
            value = localStorage.getItem(key);
        } catch (err) {
            StorageHelper.isEnabled = false;
            console.error(err);
        }

        return value || defaultValue;
    }

    /**
     * Retrieve a value from local storage
     * @param key Key to retrieve the value for
     * @param defaultValue Default value to return if key is missing, default: null
     * @returns Value stored for key, or ```defaultValue``` if missing
     */
    public static getObject<P>(key: string, defaultValue?: P | null): P | null {
        if (defaultValue === undefined) {
            defaultValue = null;
        }
        if (key == null) {
            return defaultValue;
        }
        let value: P | null = null;

        try {
            const strValue = localStorage.getItem(key);
            if (strValue) {
                value = JSON.parse(strValue) as P;
            }
        } catch (err) {
            StorageHelper.isEnabled = false;
            console.error(err);
        }

        return value || defaultValue;
    }

    // /**
    //  *
    //  * @param url
    //  * @param cacheString
    //  * @param cacheTime Time between caching and pulling new data in minutes
    //  * @param opts
    //  * @returns Response from request, null on error
    //  */
    //  public static async cachedServiceRequest<T>(url: string, cacheString: string, cacheTime: number = 15, onError?: (e: any) => void): Promise<T | null> {
    //     try{
    //         let dateKey = cacheString + "_date";
    //         let itemKey = cacheString + "_res";
    //         let lastCacheDateString = StorageHelper.getString(dateKey);
    //         let lastCachedItemString = StorageHelper.getString(itemKey);

    //         if (lastCacheDateString && lastCachedItemString){
    //             let lastCacheDate = new Date(Date.parse(lastCacheDateString));
    //             if (`${lastCacheDate}` === "Invalid Date"){
    //                 localStorage.removeItem(dateKey);
    //                 localStorage.removeItem(itemKey);
    //             }
    //             let timeSince = new Date().getTime() - lastCacheDate.getTime();
    //             // 60,000 milliseconds in a minute
    //             if (timeSince >= cacheTime * 60000){
    //                 let response = await RequestHelper.serviceRequest(url);
    //                 StorageHelper.setString(dateKey, new Date().toISOString());
    //                 StorageHelper.setString(itemKey, JSON.stringify(response));
    //                 return response;
    //             }

    //             return JSON.parse(lastCachedItemString) as T;
    //         }

    //         let response = await RequestHelper.serviceRequest(url);
    //         StorageHelper.setString(dateKey, new Date().toISOString());
    //         StorageHelper.setString(itemKey, JSON.stringify(response));
    //         return response;
    //     } catch (e){
    //         if(onError){
    //             onError(e);
    //         } else {
    //             throw e;
    //         }
    //     }
    //     return null;
    // };
}
