import React from 'react';
import { BOMService, ModuleModel } from '../../../data/data';
import { TuxComponent, ItemTable } from '../../../components/components';
import BOMEntryModel from '../../../data/bom/bom_model';
import { Box, Link } from '@amzn/awsui-components-react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { measuredAsync } from '../../../utils/decorators';
import UserInfoHelper from '../../../utils/user_info_helper';
import { Alert, AlertProps, StatusIndicator } from '@amzn/geist-ui-components';

interface ModuleStructureViewProps {
    modulePromise: Promise<ModuleModel>;
    bom?: BOMEntryModel[];
}

export default class ModuleStructureView extends TuxComponent<ModuleStructureViewProps, any> {
    private alertType: AlertProps.Type = 'info';
    private isAlertVisible = false;
    private alertMessage = '';
    private isUsingSubAssembly = false;
    isLoading = true;
    module: ModuleModel;
    bomItems: BOMEntryModel[] = [];
    currencyFormatter?: Intl.NumberFormat;

    constructor(props: ModuleStructureViewProps) {
        super(props);

        this.module = ModuleModel.LOADING_TEMPLATE;
        this.bindAll(this);
        this.loadData();
    }

    @measuredAsync('load_time_tab')
    async loadData() {
        this.module = await this.props.modulePromise;
        this.isUsingSubAssembly = this.module.usageType?.toUpperCase() === 'ASSEMBLY';
        try {
            this.bomItems = this.props.bom ?? (await BOMService.getModuleBOM(this.module));
        } catch (err) {
            this.showAlert('Failed to load module structure', 'error');
        }
        this.isLoading = false;
        this.dataUpdated();
    }

    private showAlert(message: string, type: AlertProps.Type = 'info') {
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }

    private alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    getCurrencyFormatter(bomEntry: BOMEntryModel): Intl.NumberFormat {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: bomEntry.currency ?? 'USD',
        });
    }

    private formatCurrency(p: number | string, b?: BOMEntryModel): string {
        if (p === null || b === null || p === undefined) {
            return '-';
        }

        if (typeof p === 'string') {
            return p;
        }

        if (!b?.currency) {
            return `$${p.toFixed(2)}`;
        }

        return this.getCurrencyFormatter(b).format(p);
    }

    getSubAssemblyDescription(): React.ReactNode {
        if (!this.module) {
            return null;
        }
        return (
            <>
                To view the full BOM:&nbsp;
                <Link key="module_href" href={this.module.getURL()} target="_blank">
                    View in Windchill
                </Link>
            </>
        );
    }

    /**
     * Gets a warning message for the user if the current module uses subassemblies.
     *
     * Using a separate alert than default to avoid overlapping uses.
     *
     * @returns Alert for subassembly usage or null if not using any
     */
    private getSubAssemblyAlert(): React.ReactNode {
        if (!this.isUsingSubAssembly) {
            return null;
        }

        return (
            <Alert
                alertType="warning"
                dismissible={false}
                header={
                    <>
                        <Box variant="strong">
                            This module uses subassemblies, so the BOM you see here might not be accurate.
                        </Box>
                        <Box>{UserInfoHelper.isAmazonEmployee ? this.getSubAssemblyDescription() : null}</Box>
                    </>
                }
            />
        );
    }

    render() {
        return (
            <>
                <Alert
                    alertType={this.alertType}
                    visible={this.isAlertVisible}
                    dismissible={true}
                    onDismiss={this.alertDismissed}
                    header={this.alertMessage}
                />
                {this.getSubAssemblyAlert()}
                <ItemTable
                    title={`BOM - ${this.module.number}`}
                    items={this.bomItems}
                    isLoading={this.isLoading}
                    hiddenColumns={['version']}
                    customDisplays={{
                        priceInUSD: (val) => this.formatCurrency(val),
                        price: this.formatCurrency,
                        totalCost: (val) => this.formatCurrency(val),
                        active: (active: boolean) => (
                            <StatusIndicator type={active ? 'success' : 'error'}>
                                {active ? 'Yes' : 'No'}
                            </StatusIndicator>
                        ),
                    }}
                    customWidths={{
                        active: 100,
                        quantity: 125,
                    }}
                    initialPageSize={100}
                    initialColumnOrder={[
                        'active',
                        'number',
                        'name',
                        'quantity',
                        'priceInUSD',
                        'carTab',
                        'description',
                        'partNumber',
                        'supplier',
                    ]}
                    initiallyVisibleColumnCount={9}
                    disableSearch={true}
                    disableSelection={true}
                    preferenceCacheId="module-bom-table"
                    canExport
                />
            </>
        );
    }
}
