import React from 'react';
import UserInfoHelper from '../../utils/user_info_helper';
import { TuxComponent } from '../components';

interface PersistentFooterProps{

}

export default class PersistentFooter extends TuxComponent<PersistentFooterProps, any>{

    constructor(props: PersistentFooterProps){
        super(props);
        this.loadData();
    }

    async loadData(){
        console.log("In loadData");
    }

    render(){
        if (UserInfoHelper.username === UserInfoHelper.UNKOWN_USERNAME){
            return <></>;
        }

        return (
            <div className="footer" id="footer">
                <a href="https://w.amazon.com/bin/view/NAEng/NACF/EP&A/StrategicProjects/PLM/TUX/">Wiki</a>
                {/* <a href="#/home">FAQ's</a> */}
                <a href="https://sim.amazon.com/issues/create?template=ec73ec96-7ba0-433c-b953-fc8b925b56dd">Feature Request</a>
                {/* <a href="#/home">Access Request</a> */}
                <a href="https://sim.amazon.com/issues/create?template=f3a1530f-70aa-4a3e-ab4c-e53537d915ca">File a Bug</a>
                <a href="mailto:esplm-team@amazon.com">Contact Us</a>
            </div>
        );
    }
}