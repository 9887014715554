import { ReactNode } from 'react';
import { BOMEntryModel } from '../data/data';
import { MDLItem } from '../pages/compare/compare_mdl_view';
import { BomItem } from '../pages/nonModuleList/tabs/non_module_list_structure_view';
import { Box } from '@amzn/awsui-components-react';
import React from 'react';

/**
 * Common functions used in part comparison
 */
export default class CompareUtils {
    static getObjectType(bomEntry: MDLItem | BomItem | BOMEntryModel) {
        if (bomEntry instanceof MDLItem) {
            return new MDLItem();
        } else if (bomEntry instanceof BomItem) {
            return new BomItem();
        } else if (bomEntry instanceof BOMEntryModel) {
            return new BOMEntryModel();
        }
    }

    static compareBOMs(
        partABOM: (MDLItem | BomItem | BOMEntryModel)[],
        partBBOM: (MDLItem | BomItem | BOMEntryModel)[],
    ) {
        // Declare Sets to store Part Numbers only
        const partABOMNumbers = new Set<string>(partABOM.map((b) => b.number ?? ''));
        const partBBOMNumbers = new Set<string>(partBBOM.map((b) => b.number ?? ''));

        // List of Parts Exclusive to Part A & Part B
        const partABOMExclusive: (MDLItem | BomItem | BOMEntryModel)[] = [];
        const partBBOMExclusive: (MDLItem | BomItem | BOMEntryModel)[] = [];

        // List of Parts present in Part B but missing in Part A
        const partABOMMissing: (MDLItem | BomItem | BOMEntryModel)[] = [];
        // List of Parts present in Part A but missing in Part B
        const partBBOMMissing: (MDLItem | BomItem | BOMEntryModel)[] = [];

        // Find out part numbers that are exclusive to each part
        for (const bomEntry of partABOM) {
            if (!bomEntry.number) {
                continue;
            }
            if (partBBOMNumbers.has(bomEntry.number)) {
                continue;
            }
            partABOMExclusive.push(bomEntry);

            // Only part's number is needed for partMissing Array
            const temp = this.getObjectType(bomEntry);
            if (temp) {
                temp.number = bomEntry.number;
                partBBOMMissing.push(temp);
            }
        }

        for (const bomEntry of partBBOM) {
            if (!bomEntry.number) {
                continue;
            }
            if (partABOMNumbers.has(bomEntry.number)) {
                continue;
            }
            partBBOMExclusive.push(bomEntry);
            const temp = this.getObjectType(bomEntry);
            if (temp) {
                temp.number = bomEntry.number;
                partABOMMissing.push(temp);
            }
        }

        // Sort exclusive and missing arrays
        partBBOMExclusive.sort((a, b) => (a.number ?? '').localeCompare(b.number ?? ''));
        partABOMExclusive.sort((a, b) => (a.number ?? '').localeCompare(b.number ?? ''));

        partBBOMMissing.sort((a, b) => (a.number ?? '').localeCompare(b.number ?? ''));
        partABOMMissing.sort((a, b) => (a.number ?? '').localeCompare(b.number ?? ''));

        // Sort part BOM
        partABOM.sort((a: any, b: any) => (a.number ?? '').localeCompare(b.number ?? ''));
        partBBOM.sort((a: any, b: any) => (a.number ?? '').localeCompare(b.number ?? ''));

        // Filter only common items to Part A and Part B
        partABOM = partABOM.filter(
            (b: MDLItem | BomItem | BOMEntryModel) =>
                !(partABOMNumbers.has(b.number ?? '') && !partBBOMNumbers.has(b.number ?? '')),
        );

        // Filter only common items to Part B and Part A
        partBBOM = partBBOM.filter(
            (b: MDLItem | BomItem | BOMEntryModel) =>
                !(partBBOMNumbers.has(b.number ?? '') && !partABOMNumbers.has(b.number ?? '')),
        );

        // Concact Part A exclusive and Part A missing
        partABOM = partABOM.concat(partABOMExclusive).concat(partABOMMissing);
        // Concact Part B exclusive and Part B missing
        partBBOM = partBBOM.concat(partBBOMMissing).concat(partBBOMExclusive);

        return [partABOM, partBBOM];
    }

    static getChangedText(text: ReactNode): JSX.Element {
        return (
            <Box color={'text-status-info'} fontWeight={'bold'}>
                {text}
            </Box>
        );
    }
}
