import React from 'react';
import SiteModel from '../../../data/site/site_model';
import TuxView from '../../../components/tux_view';
import { Link, SpaceBetween } from '@amzn/awsui-components-react-v3';
import { ItemTable, SessionHelper } from '../../../modules';
import { CustomDisplayProps } from '../../../components/item_table';
import { SiteModelType } from '../../../data/site/site_base_model';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import RequestQuoteTwoToneIcon from '@mui/icons-material/RequestQuoteTwoTone';
import FolderIcon from '@mui/icons-material/Folder';


interface SiteRelatedItemsViewProps {
  sitePromise: Promise<SiteModel>;
}

interface SiteRelatedItem {
  _systemId: string;
  _type: SiteModelType;
  number: string;
  name: string;
  version: string;
}

export default class SiteRelatedItemsView extends TuxView<SiteRelatedItemsViewProps, any> {
  isLoading = true;
  site: SiteModel;
  relatedItems: SiteRelatedItem[] = [];

  private customDisplays: CustomDisplayProps;

  constructor(props: SiteRelatedItemsViewProps) {
    super(props);
    this.site = SiteModel.LOADING_TEMPLATE;
    this.customDisplays = {
      number: (n: string, item: SiteRelatedItem) => {
        if(item._type === SiteModelType.PostCARProposalFolder || item._type === SiteModelType.NonModuleProposalFolder){
          return (
            <Link href={`#/${SessionHelper.getRegion()}/sites/${this.site._systemId}/relatedItems/${item._type}`}>
              <SpaceBetween direction="horizontal" size={'s'}>
                {(() => {

                          if(item._type === SiteModelType.NonModuleProposalFolder)
                            return ((<FolderIcon fontSize='medium'/>))
                          if(item._type === SiteModelType.PostCARProposalFolder)
                            return ((<FolderIcon fontSize='medium'/>))
                        return ""
                        })()
                }
                <span>{n}</span>
              </SpaceBetween>
            </Link>
          )
        }else{
          return(
            <Link href={`#/${SessionHelper.getRegion()}/sites/${this.site._systemId}/relatedItems/${item._type}/${item._systemId}`}>
              <SpaceBetween direction="horizontal" size={'s'}>
                {(() => {
                          if(item._type === SiteModelType.ModuleList)
                            return ((<BusinessTwoToneIcon fontSize="medium" />))
                          if(item._type === SiteModelType.NonModuleList)
                            return ((<RequestQuoteTwoToneIcon fontSize="medium" />))
                          if(item._type === SiteModelType.NonModuleProposal)
                            return ((<RequestQuoteTwoToneIcon fontSize="medium" />))
                          if(item._type === SiteModelType.PostCARProposal)
                            return ((<RequestQuoteTwoToneIcon fontSize="medium" />))
                        return ""
                        })()
                }
                <span>{n}</span>
              </SpaceBetween>
            </Link>
          )
        }
      }
    };
    this.bindAll(this);
    this.loadData();
  }

  public async loadData(): Promise<void> {
    this.site = await this.props.sitePromise;

    this.relatedItems = this.site._latestModuleList!.map((model) => {

      return {
        _systemId: model._systemId,
        _type: model._modelType,
        number: model.number,
        name: model.name,
        version: model.displayVersionWithState,

      } as SiteRelatedItem
    });

    this.site._latestNonModuleList!.forEach((nml) => this.relatedItems.push({
        _systemId: nml._systemId,
        _type: nml._modelType,
        number: nml.number,
        name: nml.name,
        version: nml.displayVersionWithState,

      } as SiteRelatedItem
    ));

    this.relatedItems.push({
      _systemId: "NonModuleProposalFolder",
      _type: 'NonModuleProposalFolder',
      number: 'Non Module Proposals',
      name: '',
      version: '',

    } as SiteRelatedItem
    );
    this.relatedItems.push({
      _systemId: "PostCarProposalFolder",
      _type: 'PostCARProposalFolder',
      number: 'Post CAR Proposals',
      name: '',
      version: '',

    } as SiteRelatedItem
    );

    this.isLoading = false;
    this.dataUpdated();
  }

  render(): JSX.Element {
    return (
      <>
        <ItemTable items={this.relatedItems}
          disableSelection
          customDisplays={this.customDisplays}
        />

      </>
    );
  }
}
