import React from 'react';
import TuxComponent from './tux_component';
import { Alert } from '@amzn/geist-ui-components';
interface ErrorViewProps {}

export default class ErrorView extends TuxComponent<ErrorViewProps, any> {
    props: ErrorViewProps;
    constructor(props: ErrorViewProps) {
        super(props);
        this.props = props;
        this.loadData();
    }

    async loadData() {
        // console.log("In loadData");
    }

    render() {
        return (
            <Alert alertType="error" header="Something went wrong!">
                The page you tried visiting does not exist. If you believe this is an error, contact an admin.
            </Alert>
        );
    }
}
