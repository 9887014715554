import { SiteBaseModel } from './site_base_model';
import BomModel from './bom_model';

export default class NonModuleProposalModel extends SiteBaseModel {
    state?: string;
    _bom?: BomModel[];

    constructor(json: any) {
        super(json);
        this.init(json);
    }

    protected resolveStructureKey(key: string, json: any): SiteBaseModel[] {
        if (key.includes('_bom') || key.includes('bom')) {
            const result: BomModel[] = [];

            json.map((bomJson: any) => result.push(new BomModel(bomJson)));

            return result;
        }

        return [];
    }

    public static LOADING_TEMPLATE: NonModuleProposalModel = new NonModuleProposalModel({
        systemId: 'Loading...',
        name: 'Loading...',
        number: 'Loading...',
        version: {
            version: 'Loading...',
            iteration: 'Loading...',
        },
        attributes: {
            state: 'Loading...',
        },
    });

    get stateString(): string {
        const stateMap: { [key: string]: string } = {
            INWORK: 'In Work',
            SUBMITTED: 'Submitted',
            UNDERREVIEW: 'Under Review',
            APPROVED: 'Approved',
            REJECTED: 'Rejcted',
        };
        return stateMap[this.state ?? ''] ?? this.state;
    }

    get displayVersion(): string {
        return `${this._version?.version}.${this._version?.iteration}`;
    }

    get displayVersionWithState(): string {
        return `${this.displayVersion} - ${this.stateString}`;
    }
}
