import React from 'react';
import { TuxView, ValueWithLabel, ItemTable } from '../../components/components';
import { ModuleModel } from '../../data/data';
import {
    ColumnLayout,
    ExpandableSection,
    Grid,
    Header,
    SpaceBetween,
    StatusIndicator
} from '@amzn/awsui-components-react-v3';
import BomModel from '../../data/site/bom_model';
import SiteModelService from '../../data/site/site_model_service';
import { SiteModelType } from '../../data/site/site_base_model';
import { SessionHelper } from '../../modules';
import NonModuleProposalModel from '../../data/site/non_module_proposal_model';
import { BomItem, flattenNMP } from '../nonModuleProposal/tabs/non_module_proposal_structure_view';
import { HistoryItem } from '../nonModuleProposal/tabs/non_module_proposal_history_view';
import CompareUtils from '../../utils/compare_utils';

interface CompareNMPViewProps {
    nmpA: string;
    nmpAVersion: string;
    nmpB: string;
    nmpBVersion: string;
}

export default class CompareNMLView extends TuxView<CompareNMPViewProps, any> {

    nmpAModel: NonModuleProposalModel;
    nmpBModel: NonModuleProposalModel;
    nmpABOMOrig: BomModel[] = [];
    nmpBBOMOrig: BomModel[] = [];
    nmpABOM: BomItem[] = [];
    nmpBBOM: BomItem[] = []
    nmpABOMNumbers: Set<string> = new Set<string>();
    nmpBBOMNumbers: Set<string> = new Set<string>();
    attributesExpanded: boolean = true;
    systemDetailsExpanded: boolean = true;

    constructor(props: CompareNMPViewProps) {
        super(props);

        this.nmpAModel = NonModuleProposalModel.LOADING_TEMPLATE;
        this.nmpBModel = NonModuleProposalModel.LOADING_TEMPLATE;

        this.bindAll(this);
        this.loadData();
    }

    private async resolveNonModuleProposal(number: string, version: string): Promise<NonModuleProposalModel> {
        return (await SiteModelService.fetchModelByNumberAndVersion(
            SiteModelType.NonModuleProposal,
            number,
            version
        )) as NonModuleProposalModel;
    }


    async loadData() {
        this.nmpAModel = await this.resolveNonModuleProposal(this.props.nmpA, this.props.nmpAVersion);
        this.nmpBModel = await this.resolveNonModuleProposal(this.props.nmpB, this.props.nmpBVersion);

        this.nmpABOMOrig = this.nmpAModel._bom ?? [];
        this.nmpBBOMOrig = this.nmpBModel._bom ?? [];

        this.nmpABOM = flattenNMP(this.nmpABOMOrig);
        this.nmpBBOM = flattenNMP(this.nmpBBOMOrig);

        this.nmpABOMNumbers = new Set<string>(this.nmpABOM.map(b => b.number ?? ''));
        this.nmpBBOMNumbers = new Set<string>(this.nmpBBOM.map(b => b.number ?? ''));

        [this.nmpABOM, this.nmpBBOM] = CompareUtils.compareBOMs(this.nmpABOM, this.nmpBBOM) as BomItem[][];

        this.isLoading = false;
        this.dataUpdated();
    }

    get nmlAId() {
        return `${this.nmpAModel.number}_${this.nmpAModel.displayVersion}`;
    }

    get nml() {
        return `${this.nmpBModel.number}_${this.nmpBModel.displayVersion}`;
    }

    getNMPId(nml: NonModuleProposalModel) {
        return `${nml.number}_${nml.displayVersion}`;
    }

    attributeExpansionChanged() {
        this.attributesExpanded = !this.attributesExpanded;
        this.dataUpdated();
    }

    systemDetailsExpansionChange() {
        this.systemDetailsExpanded = !this.systemDetailsExpanded;
        this.dataUpdated();
    }

    static generateHREF(nmpA: HistoryItem, nmpB: HistoryItem): string {
        return `#/${SessionHelper.getRegion()}/compare/nmp/${nmpA.number}/${nmpA.version}/${nmpB.number}/${nmpB.version}`;
    }

    private getAttributes(nmp: NonModuleProposalModel, otherNMP: NonModuleProposalModel): JSX.Element {
        let header = (
            <Header variant="h2">
                Attributes - {nmp.number} ({nmp.displayVersion})
            </Header>
        );

        return (
            <ExpandableSection
                variant="container"
                header={header}
                expanded={this.attributesExpanded}
                defaultExpanded={true}
                id={this.getNMPId(nmp) + '_attributes'}
                onChange={this.attributeExpansionChanged}
            >
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel hasChanged={nmp.name !== otherNMP.name} label="Name">
                            {nmp.name}
                        </ValueWithLabel>
                        <ValueWithLabel hasChanged={nmp.number !== otherNMP.number} label="Number">
                            {nmp.number}
                        </ValueWithLabel>
                        <ValueWithLabel hasChanged={nmp.displayVersion !== otherNMP.displayVersion} label="Version">
                            {nmp.displayVersion}
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel hasChanged={nmp.stateString !== otherNMP.stateString} label="State">
                            <StatusIndicator type={ModuleModel.statusType(nmp.stateString)}>
                                {nmp.stateString}
                            </StatusIndicator>
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </ExpandableSection>
        );
    }

    getSystem(nmpObj: NonModuleProposalModel, otherNMPObj: NonModuleProposalModel): JSX.Element {
        let header = <Header variant="h2">System - {nmpObj.number}</Header>;
        return (
            <>
                <ExpandableSection
                    variant="container"
                    header={header}
                    expanded={this.systemDetailsExpanded}
                    defaultExpanded={true}
                    id={nmpObj.name + '_system'}
                    onChange={this.systemDetailsExpansionChange}
                >
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                hasChanged={nmpObj.createdBy !== otherNMPObj.createdBy}
                                label="Created By"
                            >
                                {nmpObj.createdBy}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={nmpObj.createdOn !== otherNMPObj.createdOn}
                                label="Created On"
                            >
                                {nmpObj.createdOn}
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                hasChanged={nmpObj.modifiedBy !== otherNMPObj.modifiedBy}
                                label="Modified By"
                            >
                                {nmpObj.modifiedBy}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={nmpObj.lastModified !== otherNMPObj.lastModified}
                                label="Last Modified"
                            >
                                {nmpObj.lastModified}
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExpandableSection>
            </>
        );
    }

    private _getAttributes() {
        return (
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
            
                    <SpaceBetween size='l'>
                        {this.getAttributes(this.nmpAModel, this.nmpBModel)}
                        {this.getSystem(this.nmpAModel, this.nmpBModel)}
                    </SpaceBetween>
            
                    <SpaceBetween size='l'>
                        {this.getAttributes(this.nmpBModel, this.nmpAModel)}
                        {this.getSystem(this.nmpBModel, this.nmpAModel)}
                    </SpaceBetween>
            
            </Grid>
        );
    }

    getCurrencyFormatter(bomEntry: BomModel): Intl.NumberFormat {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: bomEntry.currency ?? 'USD'
        });
    }

    private formatCurrency(p: number | string, b?: BomModel): string {
        // console.log(p);
        if (p === null || b === null || p === undefined || b === undefined) {
            return '-';
        }

        if (typeof p === 'string') {
            return p;
        }

        if (!b?.currency) {
            return `$${p.toFixed(2)}`;
        }

        return this.getCurrencyFormatter(b).format(p);
    }

    private getBOM(
        nmp: NonModuleProposalModel,
        bom: BomItem[],
        bomNumbers: Set<string>,
        otherBOMNumbers: Set<string>,
        otherBOM: BomItem[]
    ) {
        let temp: {[key:string]:any} = {};
        let getHasChanged = (val: number, b: BomItem, param: string) =>
            val != null
                ? otherBOMNumbers.has(b.number ?? '')
                    ? (otherBOM.find((_b => _b.number === b.number) ?? temp) as any)[param] === (b as any)[param]
                        ? val
                        : CompareUtils.getChangedText(val)
                    : val
                : '-';
        return (
            <ItemTable
                title={`${nmp.number} (${nmp.displayVersion})` ?? ''}
                items={bom}
                initialColumnOrder={['number', 'name', 'quantity', 'desc', 'price']}
                initiallyVisibleColumnCount={4}
                disableSearch={true}
                disableSelection={true}
                customDisplays={{
                    number: (val, bomItem: BomItem) =>
                        otherBOMNumbers.has(bomItem?.number ?? '')
                            ? bomNumbers.has(bomItem?.number ?? '')
                                ? bomItem?.number
                                : '-'
                            : CompareUtils.getChangedText(bomItem?.number),
                    quantity: (quantity: number, bomItem: BomItem) => getHasChanged(quantity, bomItem, 'quantity'),
                    price: val => this.formatCurrency(val),
                }}
            />
        );
    }

    private _getBOMs() {
        return (
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                {this.getBOM(this.nmpAModel, this.nmpABOM, this.nmpABOMNumbers, this.nmpBBOMNumbers, this.nmpBBOM)}
                {this.getBOM(this.nmpBModel, this.nmpBBOM, this.nmpBBOMNumbers, this.nmpABOMNumbers, this.nmpABOM)}
            </Grid>
        );
    }

    render() {
        return (
            <>
                <Header variant="h1">
                    Compare:{' '}
                    {`${this.nmpAModel.number} (${this.nmpAModel.displayVersion}) & ${this.nmpBModel.number} (${this.nmpBModel.displayVersion})`}
                </Header>
                <SpaceBetween size="s" direction="vertical">
                    {this._getAttributes()}
                    {this._getBOMs()}
                </SpaceBetween>
                <div style={{ height: '12px' }} />
            </>
        );
    }
}
