import { gql } from '@apollo/client';
import { SiteModelType } from '../site_base_model';

export const MODEL_HEADER_FIELDS = gql`
    fragment ModelHeaderFields on Model {
        systemId
        name
        number
        createdOn
        lastModified
        createdBy
        modifiedBy
        modelType
        version {
            groupId
            version
            iteration
        }
    }
`;

export const SITE_ATTRIBUTES = gql`
    fragment Attributes on Model {
        attributes {
            country
            region
            buildingType
            shieldId
            program
            porYear
            facilityName
            facilityType
            projectNumber
            siteSize
            projectStatus
            firstReceive
            privateAccess
        }
    }
`;

export const ALL_SITES_QUERY_STRUCTURE = gql`
    fragment Structure on Model {
        structure {
            latestModuleList {                
                systemId
                modelType
                name
                attributes {
                    mdgTemplate
                }
            }
        }
    }
`;

export const SITE_QUERY_STRUCTURE = gql`
    fragment Structure on Model {
        structure {
            latestModuleList {
                ...ModelHeaderFields 
                attributes {
                    mdgTemplate
                    erpStatus
                    moduleCountsTemplate
                    state
                }
                structure{
                    bom {
                        ...ModelHeaderFields
                        attributes {
                            quantity
                            bomItem {
                                ...ModelHeaderFields
                                version {              
                                    version
                                    iteration              
                                }
                                properties
                            }
                        }
                    }
                }                
            }
            moduleList {
                ...ModelHeaderFields 
                attributes {
                    mdgTemplate
                    erpStatus
                    moduleCountsTemplate
                    state
                }              
            }
            latestNonModuleList {
                ...ModelHeaderFields 
                attributes {
                    state
                }
                structure{
                    bom {
                        ...ModelHeaderFields
                        attributes {
                            quantity
                            bomItem {
                                ...ModelHeaderFields
                                properties
                            }
                        }
                    }
                }                
            }
            nonModuleList {
                ...ModelHeaderFields 
                attributes {
                    state
                }              
            }    
            latestNonModuleProposal {
                ...ModelHeaderFields 
                attributes {
                    state
                }
                structure{
                    bom {
                        ...ModelHeaderFields
                        attributes {
                            quantity
                            bomItem {
                                ...ModelHeaderFields
                                properties
                            }
                        }
                    }
                }                                
            }        
            latestPostCarProposal {
                ...ModelHeaderFields 
                attributes {
                    simLink
                    affectedModule
                    affectedSites
                    affectsComposite
                    changeCategory
                    changeOrigin
                    subOrigin
                    changeType
                    costDelta
                    newQuantity
                    newVersion
                    notes
                    requestingTeam
                    state
                }              
            }
            nonModuleProposal {
                ...ModelHeaderFields 
                attributes {
                    state
                }              
            }        
            postCarProposal {
                ...ModelHeaderFields 
                attributes {
                    simLink
                    affectedModule
                    affectedSites
                    affectsComposite
                    changeCategory
                    changeOrigin
                    subOrigin
                    changeType
                    costDelta
                    newQuantity
                    newVersion
                    notes
                    requestingTeam
                    state
                }              
            }             
        }
    }
`;

export const MDL_ATTRIBUTES = gql`
    fragment Attributes on Model {
        attributes {
            mdgTemplate
            erpStatus
            moduleCountsTemplate
            state
        }
    }
`;

export const PCP_ATTRIBUTES = gql`
    fragment Attributes on Model {
        attributes {
            simLink
            affectedModule
            affectedSites
            affectsComposite
            changeCategory
            changeOrigin
            subOrigin
            changeType
            costDelta
            newQuantity
            newVersion
            notes
            requestingTeam
            state
        }
    }
`;

export const MDL_STRUCTURE = gql`
    fragment Structure on Model {
        structure {
            bom {
                ...ModelHeaderFields
                attributes {
                    quantity
                    bomItem {
                        ...ModelHeaderFields
                        version {
                            version
                            iteration
                        }
                        properties
                    }
                }
            }
        }
    }
`;

export const NML_ATTRIBUTES = gql`
    fragment Attributes on Model {
        attributes {
            state
        }
    }
`;

export const NML_STRUCTURE = gql`
    fragment Structure on Model {
        structure {
            bom {
                ...ModelHeaderFields
                attributes {
                    quantity
                    bomItem {
                        ...ModelHeaderFields
                        properties
                    }
                }
            }
        }
    }
`;
export function getModelAttributes(type: SiteModelType) {
    switch (type) {
        case SiteModelType.Site:
            return SITE_ATTRIBUTES;
        case SiteModelType.ModuleList:
            return MDL_ATTRIBUTES;
        case SiteModelType.NonModuleList:
            return NML_ATTRIBUTES;   
        case SiteModelType.PostCARProposal:
            return PCP_ATTRIBUTES;     
        case SiteModelType.NonModuleProposal:
            return NML_ATTRIBUTES;                  
        default:
            return gql`
      fragment Attributes on Model {
          attributes {}
      }
  `;
    }
}

export function getModelStructure(type: SiteModelType, queryName: string) {
    switch (type) {
        case SiteModelType.Site:
            return queryName === 'GetModels' ? ALL_SITES_QUERY_STRUCTURE : SITE_QUERY_STRUCTURE;
        case SiteModelType.ModuleList:
            return MDL_STRUCTURE;
        case SiteModelType.NonModuleList:
            return NML_STRUCTURE; 
        case SiteModelType.NonModuleProposal:
            return NML_STRUCTURE;             
        default:
            return gql`
      fragment Structure on Model {
        structure {}
      }
  `;
    }
}
