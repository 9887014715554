import React, { useEffect, useState } from 'react';
import ModuleListModel from '../../../data/site/module_list_module';
import BomModel from '../../../data/site/bom_model';
import { Link } from '@amzn/awsui-components-react';
import ModuleModel from '../../../data/site/module_model';
import BOMEntryModel from '../../../data/bom/bom_model';
import BOMService from '../../../data/bom/bom_service';
import Module from '../../../data/module/module_model';
import ItemTable from '../../../components/item_table';
import { SiteBaseModel } from '../../../data/site/site_base_model';
import ContentModel from '../../../data/content/content_model';
import ContentService from '../../../data/content/content_service';
import { StatusIndicator, Button } from '@amzn/geist-ui-components';
import Table, { TableRow, TableCell, TableActionBar } from '@amzn/meridian/table';
import Row from '@amzn/meridian/row';
import Loader from '@amzn/meridian/loader';
import Tooltip from '@amzn/meridian/tooltip';
import MeridianButton from '@amzn/meridian/button';
import Icon from '@amzn/meridian/icon';
import documentTokens from '@amzn/meridian-tokens/base/icon/document';

function getCurrencyFormatter(bomEntry: BOMEntryModel): Intl.NumberFormat {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: bomEntry.currency ?? 'USD',
    });
}

function formatCurrency(p: number | string, b?: BOMEntryModel): string {
    if (p === null || b === null || p === undefined) {
        return '-';
    }

    if (typeof p === 'string') {
        return p;
    }

    if (!b?.currency) {
        return `$${p.toFixed(2)}`;
    }

    return getCurrencyFormatter(b).format(p);
}

function compare<T extends Object>(a: T, b: T, property: keyof T, sort_order: 'ascending' | 'descending') {
    const val1 = a[property];
    const val2 = b[property];
    const order = sort_order !== 'descending' ? 1 : -1;

    switch (typeof val1) {
        case 'number': {
            const result = val1 - (val2 as unknown as number);
            return result * order;
        }
        case 'string': {
            const result = val1.localeCompare(val2 as unknown as string);
            return result * order;
        }

        default:
            return 0;
    }
}

const BomRow = ({ bomModel }: { bomModel: BomModel }) => {
    const [open, setOpen] = useState(false);

    const mdlBomItem: ModuleModel = bomModel.bomItem as ModuleModel;

    const [moduleBom, setModuleBom] = useState<BOMEntryModel[]>([]);

    const [moduleContent, setModuleContent] = useState<ContentModel>();

    const [contentNotFound, setContentNotFound] = useState(false);

    const [moduleBomIsLoaded, setModuleBomIsLoaded] = useState(false);

    useEffect(() => {
        if (!moduleBomIsLoaded && open) {
            const fetchModuleBom = async () => {
                const module = { _systemId: mdlBomItem._systemId } as Module;

                const bom: BOMEntryModel[] = await BOMService.getModuleBOM(module);

                setModuleBom(bom);
                setModuleBomIsLoaded(true);
            };

            fetchModuleBom();
        }
    }, [open, mdlBomItem, moduleBomIsLoaded]);

    const onContentDownload = async () => {
        if (moduleContent !== undefined) {
            await ContentService.downloadContent(
                moduleContent.downloadAttachmentPath,
                moduleContent.fileName ?? `${mdlBomItem.number}.pdf`,
            );
        } else {
            const module = { _systemId: mdlBomItem._systemId } as Module;

            const content: ContentModel[] = await ContentService.getContentForModule(module);

            const secondaryContent = content.find((v) => v.role === 'Secondary');

            if (secondaryContent) {
                setModuleContent(secondaryContent);

                await ContentService.downloadContent(
                    secondaryContent.downloadAttachmentPath,
                    secondaryContent.fileName ?? `${mdlBomItem.number}.pdf`,
                );
            } else {
                setContentNotFound(true);
            }
        }
    };

    return (
        <>
            <TableRow>
                <TableCell>
                    <Button
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        variant="icon"
                        iconName={open ? 'caret-down-filled' : 'caret-right'}
                    />
                </TableCell>
                <TableCell alignmentHorizontal="center">
                    <Link
                        href={`#/${mdlBomItem.region}/modules/${mdlBomItem.number}/${mdlBomItem.displayVersion}`}
                        onFollow={(e) => {
                            window.location.hash = e.detail.href ?? window.location.hash;
                            window.location.reload();
                        }}
                    >
                        {mdlBomItem?.number}
                    </Link>
                </TableCell>
                <TableCell alignmentHorizontal="center">{mdlBomItem.name}</TableCell>
                <TableCell alignmentHorizontal="center">{`${bomModel.quantity}`}</TableCell>
                <TableCell alignmentHorizontal="center">{mdlBomItem.displayVersion}</TableCell>
                <TableCell alignmentHorizontal="center">{mdlBomItem.onComposite}</TableCell>
                <TableCell>
                    <Tooltip title="Module PDF" position="start">
                        <MeridianButton onClick={() => onContentDownload()} disabled={contentNotFound} type="icon">
                            <Icon tokens={documentTokens} />
                        </MeridianButton>
                    </Tooltip>
                </TableCell>
            </TableRow>
            {open && (
                <TableRow>
                    <TableCell columnSpan={7} width="100%">
                        <div style={{ marginLeft: -16, marginRight: -16 }}>
                            <Row width="100%" overflowX="scroll">
                                {!moduleBomIsLoaded ? (
                                    <Row width="100%">
                                        <Loader type="linear" />
                                    </Row>
                                ) : (
                                    <ItemTable
                                        title={`${mdlBomItem.number}`}
                                        items={moduleBom}
                                        hiddenColumns={['version']}
                                        customDisplays={{
                                            priceInUSD: (val) => formatCurrency(val),
                                            price: formatCurrency,
                                            totalCost: (val) => formatCurrency(val),
                                            active: (active: boolean) => (
                                                <StatusIndicator type={active ? 'success' : 'error'}>
                                                    {active ? 'Yes' : 'No'}
                                                </StatusIndicator>
                                            ),
                                        }}
                                        customWidths={{
                                            active: 100,
                                            quantity: 125,
                                        }}
                                        initialPageSize={100}
                                        initialColumnOrder={[
                                            'active',
                                            'number',
                                            'name',
                                            'quantity',
                                            'priceInUSD',
                                            'carTab',
                                            'description',
                                            'partNumber',
                                            'supplier',
                                        ]}
                                        initiallyVisibleColumnCount={9}
                                        disableSearch={true}
                                        disableSelection={true}
                                        preferenceCacheId="module-bom-table"
                                        canExport
                                    />
                                )}
                            </Row>
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

const NoResourcesRow = ({ colSpan }: { colSpan: number }) => {
    return (
        <TableRow>
            <TableCell columnSpan={colSpan}>No Resources</TableCell>
        </TableRow>
    );
};

export const ModuleListStructureView = ({ moduleList }: { moduleList: ModuleListModel }) => {
    const [sortKey, setSortKey] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('descending');

    const [rows, setRows] = useState<BomModel[]>(moduleList._bom ?? []);

    useEffect(() => {
        setRows([...(moduleList._bom ?? [])]);
    }, [moduleList._bom]);

    useEffect(() => {
        if (sortKey === 'quantity') {
            setRows((rows) => [...rows.sort((a, b) => compare(a, b, 'quantity', sortDirection))]);
        } else if (sortKey === 'displayVersion') {
            setRows((rows) => [
                ...rows.sort((a, b) =>
                    compare(
                        a.bomItem ?? ({} as SiteBaseModel),
                        b.bomItem ?? ({} as SiteBaseModel),
                        'displayVersion',
                        sortDirection,
                    ),
                ),
            ]);
        } else {
            setRows((rows) => [
                ...rows.sort((a, b) =>
                    compare(
                        a.bomItem ?? ({} as SiteBaseModel),
                        b.bomItem ?? ({} as SiteBaseModel),
                        sortKey,
                        sortDirection,
                    ),
                ),
            ]);
        }
    }, [sortKey, sortDirection]);

    const onSortClick = ({
        sortColumn,
        sortDirection,
    }: {
        sortColumn: string;
        sortDirection: 'ascending' | 'descending';
    }) => {
        setSortKey(sortColumn);
        setSortDirection(sortDirection);
    };

    return (
        <Table
            aria-label="collapsible table"
            headerRows={1}
            showDividers={true}
            rowComponents={[TableRow, NoResourcesRow, BomRow]}
            sortColumn={sortKey}
            sortDirection={sortDirection}
            onSort={onSortClick}
            spacing="small"
            layout="fixed"
        >
            <TableActionBar>
                <h2>
                    <span style={{ fontWeight: 600 }}>{moduleList.name}</span>
                    <span style={{ fontWeight: 400, color: '#687078' }}> ({moduleList._bom?.length})</span>
                </h2>
            </TableActionBar>
            <TableRow>
                <TableCell width={60}>
                    {/* Empty table header cell. Non-header cells will have an expandable Icon button */}
                </TableCell>
                <TableCell alignmentHorizontal="center" sortColumn="number">
                    Number
                </TableCell>
                <TableCell alignmentHorizontal="center" sortColumn="name">
                    Name
                </TableCell>
                <TableCell alignmentHorizontal="center" sortColumn="quantity">
                    Quantity
                </TableCell>
                <TableCell alignmentHorizontal="center" sortColumn="displayVersion">
                    Version
                </TableCell>
                <TableCell alignmentHorizontal="center" sortColumn="onComposite">
                    On Composite
                </TableCell>
                <TableCell width={60}>{/* Empty table header cell. For PDF */}</TableCell>
            </TableRow>
            {rows.length === 0 ? (
                <NoResourcesRow colSpan={7} />
            ) : (
                rows.map((bomModel) => <BomRow key={bomModel._systemId} bomModel={bomModel} />)
            )}
        </Table>
    );
};
