import { StatusIndicatorProps } from "@amzn/awsui-components-react-v3";

export default class TaskModel {
    [key: string]: any;

    name?: string;
    assignee?: string;
    status?: string;
    vote?: string;
    role?: string;
    comments?: string;
    dueDate?: Date;
    dateStarted?: Date;
    dateCompleted?: Date;
    completedBy?: string;
    taskId?: string;



    static fromJSON(json: any): TaskModel {
        let output = new TaskModel();
        for (let key of Object.keys(output)) {
            output[key] = json[key];
        }

        return output;
    }

    toString(): string {
        return JSON.stringify(this);
    }

    isOverdue(): boolean {
        let currentTime = new Date(Date.now());
        return currentTime.getTime() > this.dueDateTime.getTime() && this.taskStatus !== "COMPLETED";
    }

    get dueDateTime(): Date {
        return this.dueDate ? new Date(this.dueDate) : new Date(Date.now())
    }

    get taskStatus(): string {
        return this.status? this.status : "";
    }

    get stateString(): string {
        let stateMap: { [key: string]: string } = {
            "COMPLETED": "Completed",
            "POTENTIAL": "Potential"
        }
        return stateMap[this.status ?? ""] ?? this.status;
    }

    get statusType(): StatusIndicatorProps.Type {
        return TaskModel.statusType(this.status ?? "");
    }

    static statusType(state: string): StatusIndicatorProps.Type {
        switch (state?.toLowerCase()) {
            case "completed":
                return "success";

            default:
                return "pending";
        }
    }
}