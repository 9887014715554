import React from 'react';
import TuxComponent from '../../components/tux_view';
import SiteModel from '../../data/site/site_model';
import SessionHelper from '../../utils/session_helper';
import { SpaceBetween } from '@amzn/awsui-components-react';
import ItemTable, { CustomDisplayProps } from '../../components/item_table';
import { SiteModelService } from '../../data/data';
import { SiteModelType } from '../../data/site/site_base_model';
import { ModelInput } from '../../data/site/gql/mutations';
import UserInfoHelper from '../../utils/user_info_helper';
import { StatusIndicator, Alert, AlertProps, Header } from '@amzn/geist-ui-components';
import Box from '@amzn/meridian/box';

interface SitesViewProps {}

export default class SitesView extends TuxComponent<SitesViewProps, any> {
    private sites: SiteModel[] = [];
    private isLoading = true;
    private customDisplays: CustomDisplayProps;

    private alertType: AlertProps.Type = 'info';
    private isAlertVisible: boolean = false;
    private alertMessage: string = '';

    constructor(props: SitesViewProps) {
        super(props);
        this.region = SessionHelper.getRegion();
        this.customDisplays = {
            name: (n: string, item: SiteModel) => (
                <a
                    href={`#/${SessionHelper.getRegion()}/sites/${item._systemId}/details`}
                    style={{ textDecoration: 'none' }}
                >
                    {n}
                </a>
            ),
            lastModified: (d: Date) => new Date(d).toLocaleTimeString(),
            createdOn: (d: Date) => new Date(d).toLocaleDateString(),
            projectStatus: (projectStatus: string, item: SiteModel) => (
                <StatusIndicator
                    type={
                        projectStatus === 'Active'
                            ? SiteModel.statusType('Completed')
                            : SiteModel.statusType(projectStatus)
                    }
                    colorOverride={projectStatus === 'Active' ? 'blue' : undefined}
                >
                    {item.projectStatus}
                </StatusIndicator>
            ),
            privateAccess: (privateAccess: boolean) => (
                <StatusIndicator type={privateAccess ? 'success' : 'warning'}>
                    {privateAccess ? 'Yes' : 'No'}
                </StatusIndicator>
            ),
        };
        this.bindAll(this);
        this.loadData();
    }

    public async loadData(): Promise<void> {
        try {
            this.sites = (await SiteModelService.fetchModels(SiteModelType.Site)) as SiteModel[];
        } catch (err) {
            this.showAlert(`Please contact site administrator. ${(err as Error).message}`, 'error');
        }

        this.isLoading = false;
        this.dataUpdated();
    }

    private showAlert(message: string, type: AlertProps.Type = 'info') {
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }

    private alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    private async restrictAccess(sites: SiteModel[]) {
        this.changeModelsPrivacy(true, sites);
    }

    private async allowAccess(sites: SiteModel[]) {
        this.changeModelsPrivacy(false, sites);
        sites = [];
    }

    private async createSitePressed() {
        window.location.href=`#/${SessionHelper.getRegion()}/site/createSite`
    }

    private async changeModelsPrivacy(isPrivate: boolean, sites: SiteModel[]) {
        const modelsInput: ModelInput[] = this.buildModelInput(isPrivate, sites);

        try {
            await SiteModelService.patchModels(SiteModelType.Site, modelsInput);
            window.location.reload();
        } catch (err) {
            this.showAlert(`Please contact site administrator. ${(err as Error).message}`, 'error');
        }
    }

    private buildModelInput(isPrivate: boolean, sites: SiteModel[]): ModelInput[] {
        const modelsInput: ModelInput[] = [];

        sites.map((site) =>
            modelsInput.push({
                systemId: site._systemId ?? '',
                attributes: {
                    privateAccess: isPrivate,
                },
            }),
        );

        return modelsInput;
    }

    private userHasPrivateAccessPermissions(): boolean {
        return UserInfoHelper.groups.includes('Site_Write_User') && UserInfoHelper.groups.includes('Site_Admin_User');
    }

    render() {
        return (
            <div>
                <SpaceBetween size="m">
                    <Header variant="h1">Building Standards - {this.region}</Header>
                    <Box spacingInset={this.isAlertVisible ? '400 0' : '0'}>
                        <Alert
                            alertType={this.alertType}
                            visible={this.isAlertVisible}
                            dismissible={true}
                            onDismiss={this.alertDismissed}
                            header={this.alertMessage}
                        />
                    </Box>
                    <ItemTable
                        title={`Sites`}
                        items={this.sites}
                        height={'62vh'}
                        actions={
                            this.userHasPrivateAccessPermissions()
                                ? [
                                      { label: 'Restrict Access', callback: this.restrictAccess, enableAt: 1 },
                                      { label: 'Allow Access', callback: this.allowAccess, enableAt: 1 },
                                      { label: 'Create Site', callback: this.createSitePressed, enableAt: 0, href: `#/${SessionHelper.getRegion()}/site/createSite`},
                                  ]
                                : []
                        }
                        hiddenColumns={
                            this.userHasPrivateAccessPermissions()
                                ? ['siteSize', 'number']
                                : ['siteSize', 'number', 'privateAccess']
                        }
                        isLoading={this.isLoading}
                        customDisplays={this.customDisplays}
                        customWidths={{
                            region: 105,
                        }}
                        initiallyVisibleColumnCount={this.userHasPrivateAccessPermissions() ? 10 : 9}
                        initialColumnOrder={[
                            'name',
                            'shieldId',
                            'mdgTemplate',
                            'region',
                            'country',
                            'porYear',
                            'program',
                            'buildingType',
                            'facilityType',
                            'facilityName',
                            'privateAccess',
                        ]}
                        defaultSortBy="name"
                        canExport
                    />
                </SpaceBetween>
            </div>
        );
    }
}
