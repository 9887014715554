import {} from 'aws-sdk';

export enum CookieNames{
    id_token = "id_token",
    user_id = "ajs_user_id",
    anon_id = "ajs_anonymous_id",
    access_token = "access_token",
    group_id = "ajs_group_id",
}

export default class CookieHelper{
    private static COOKIES: {[key:string]:string} = {};
 
    /**
     * Fetches the access token from the cookies
     */
    static get AccessToken(){
        return CookieHelper.getCookieByName(CookieNames.access_token);
    }

    /**
     * Fetch a cookie by name
     * @param name Name of the cookie
     * @returns cookie contents if present, '' if not
     */
    static getCookieByName(name: string): string{
        if (process.env.NODE_ENV !== 'production'){
            if (name === CookieNames.id_token){
                return `eyJraWQiOiJZcGMxZGdCXC9FM1lZa1ArT1VrZklYbmVYSXp1MUZrOHNXeFNEbUdYT3VKST0iLCJhbGciOiJSUzI1NiJ9.eyJhdF9oYXNoIjoiLWdGY1FmN3Z0Y0tsd3J2TkhVdENOUSIsInN1YiI6IjNlZjhlNTc2LTUyMTEtNDI5Yy1iMjE2LTU5Mjg1M2NhYjhkMiIsImNvZ25pdG86Z3JvdXBzIjpbIlNpdGVXcml0ZUFjY2VzcyIsInVzLXdlc3QtMl9UQzN4U2N4V3VfQW1hem9uRW1wbG95ZWUiLCJSZWdpb25fTkEiLCJBZG1pbkFjY2VzcyIsIlNpdGVQcml2YXRlQWNjZXNzIiwiU2l0ZVJlYWRBY2Nlc3MiLCJSZWdpb25fRVUiXSwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtd2VzdC0yLmFtYXpvbmF3cy5jb21cL3VzLXdlc3QtMl9UQzN4U2N4V3UiLCJjb2duaXRvOnVzZXJuYW1lIjoiYW1hem9uZW1wbG95ZWVfdmxtdW50ZWEiLCJnaXZlbl9uYW1lIjoiVmxhZCIsIm5vbmNlIjoiMW5qVVNUdXU0NnEzcGN3WGl5d0dLNXVqc251ZGhGc0tfSzBnelRoV1BpQkZTT3kzSXBNdDZiSVhwaW9YNFRwYjBOLTJWdzBTTmVuWE93UUozUnRFdzY2dFM3OFlEQmg1SWpYaUlRQnVnZDFKX1J0SG1vYW1wV1JhdEdVYlNnN1dXR2xBRGl0RUd0VlA1dk54QjB0OVZDd0dMVnE3eko1bWtpS1B5c3BKUEhRIiwib3JpZ2luX2p0aSI6ImE2MTU2OThiLTEyNTgtNDg2OS1hNmY2LTFiYzkzNTM3NWE1MCIsImF1ZCI6Ijc1cjF1ZW10OG1nc2FsZ2JiZjVlcXRvNGtmIiwiaWRlbnRpdGllcyI6W3sidXNlcklkIjoidmxtdW50ZWEiLCJwcm92aWRlck5hbWUiOiJBbWF6b25FbXBsb3llZSIsInByb3ZpZGVyVHlwZSI6Ik9JREMiLCJpc3N1ZXIiOm51bGwsInByaW1hcnkiOiJ0cnVlIiwiZGF0ZUNyZWF0ZWQiOiIxNjMxNjYwODA3ODMzIn1dLCJ0b2tlbl91c2UiOiJpZCIsImF1dGhfdGltZSI6MTcwNDczNDY2MiwiZXhwIjoxNzA0NzYzNDYyLCJpYXQiOjE3MDQ3MzQ2NjIsImZhbWlseV9uYW1lIjoiTXVudGVhbnUiLCJqdGkiOiJkMGE3MzIxNC1iMDU0LTQ1ZDAtYTc0Ni04MjQ0MDkwZDI1ODMiLCJlbWFpbCI6InZsbXVudGVhQGFtYXpvbi5jb20ifQ.bzf9oVTg70KC6h-3p5ADj0Xc5Qu_t_eCaBBJ4MR3YYo5NmigxsF0GnD7Z6kDRr6gVGeAn71R6uwLh3SVGytvOE6uBAKBlpz49O3ElxXFYpZjodOLPEEKavVFL6HnNEA1-agU_cMWzfvpSBGqi8tccccnD8tF9w8T8sIDS4LRZARNibSoldtaZKLD0512qbYRqMCmnm408YXBXuE_hxrYrh9CYAZhETzcR_J58GfnTJUxfBPNSbFKRgL14CaQZOHRa2gdS3CDQv48NabeqPH6r_Am7SLhuEjPIUKX2pikaHLvg-8FzZlllhze74rskRYZuXzf02_Xuz1v4Ed03ybAtQ`;
            }
    
            return "";
        }

        if (CookieHelper.COOKIES[name]){
            return CookieHelper.COOKIES[name];
        }

        let cookie = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';
        CookieHelper.COOKIES[name] = cookie;
        return cookie;
    }
}