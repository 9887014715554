import React from 'react';
import UserInfoHelper from '../../utils/user_info_helper';
import TuxComponent from '../tux_component';
import Row from '@amzn/meridian/row';
import Link from '@amzn/meridian/link';
import Text from '@amzn/meridian/text';
interface PersistentFooterProps {}

export default class PersistentFooter extends TuxComponent<PersistentFooterProps, any> {
    constructor(props: PersistentFooterProps) {
        super(props);
        this.loadData();
    }

    async loadData() {
        console.log('In loadData');
    }

    render() {
        if (UserInfoHelper.username === UserInfoHelper.UNKOWN_USERNAME) {
            return <></>;
        }

        return (
            <Row className="footer" backgroundColor="#232f3e" alignmentHorizontal="justify" spacingInset="400">
                <Link
                    href="https://w.amazon.com/bin/view/NAEng/NACF/EP&A/StrategicProjects/PLM/TUX/"
                    type="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Text color="inverted" truncate>
                        Wiki
                    </Text>
                </Link>
                {/* <Link href="#/home">FAQ's</Link> */}
                <Link
                    href="https://sim.amazon.com/issues/create?template=ec73ec96-7ba0-433c-b953-fc8b925b56dd"
                    type="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Text color="inverted" truncate>
                        Feature Request
                    </Text>
                </Link>
                {/* <Link href="#/home">Access Request</Link> */}
                <Link
                    href="https://sim.amazon.com/issues/create?template=f3a1530f-70aa-4a3e-ab4c-e53537d915ca"
                    type="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Text color="inverted" truncate>
                        File a Bug
                    </Text>
                </Link>
                <Link href="mailto:esplm-team@amazon.com" type="secondary" target="_blank" rel="noopener noreferrer">
                    <Text color="inverted" truncate>
                        Contact Us
                    </Text>
                </Link>
            </Row>
        );
    }
}
