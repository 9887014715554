import React, { CSSProperties, useEffect, useState } from 'react';
import ModuleListModel from '../../../data/site/module_list_module';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';

import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import BomModel from '../../../data/site/bom_model';
import { Button, IconProps, Link, StatusIndicator } from '@amzn/awsui-components-react-v3';
import ModuleModel from '../../../data/site/module_model';
import BOMEntryModel from '../../../data/bom/bom_model';
import LinearProgress from '@mui/material/LinearProgress';
import BOMService from '../../../data/bom/bom_service';
import Module from '../../../data/module/module_model';
import { TableHead } from '@mui/material';
import ItemTable from '../../../components/item_table';
import { SiteBaseModel } from '../../../data/site/site_base_model';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ContentModel from '../../../data/content/content_model';
import ContentService from '../../../data/content/content_service';
import Tooltip from '@mui/material/Tooltip';

interface SortIcon {
    down: IconProps.Name;
    default: IconProps.Name;
    up: IconProps.Name;
}

const SORT_ICON: SortIcon = {
    down: 'caret-down-filled',
    default: 'caret-down',
    up: 'caret-up-filled'
};

const HEAD_CELL_STYLE: CSSProperties = { fontWeight: 'bold', color: '#545b64' };

function getCurrencyFormatter(bomEntry: BOMEntryModel): Intl.NumberFormat {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: bomEntry.currency ?? 'USD'
    });
}

function formatCurrency(p: number | string, b?: BOMEntryModel): string {
    if (p === null || b === null || p === undefined) {
        return '-';
    }

    if (typeof p === 'string') {
        return p;
    }

    if (!b?.currency) {
        return `$${p.toFixed(2)}`;
    }

    return getCurrencyFormatter(b).format(p);
}

function compare<T extends Object>(a: T, b: T, property: keyof T, sort_order: 'asc' | 'desc') {
    const val1 = a[property];
    const val2 = b[property];
    const order = sort_order !== 'desc' ? 1 : -1;

    switch (typeof val1) {
        case 'number': {
            const result = val1 - ((val2 as unknown) as number);
            return result * order;
        }
        case 'string': {
            const result = val1.localeCompare((val2 as unknown) as string);
            return result * order;
        }

        default:
            return 0;
    }
}

const HeadCell = ({
    title,
    sortIcon,
    onClick,
    align
}: {
    title: string;
    sortIcon: IconProps.Name;
    onClick: Function;
    align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}) => {
    let style: any = HEAD_CELL_STYLE;

    style.minWidth = '160px';

    return (
        <TableCell sx={style} align={align ?? 'inherit'} className='headCell'>
            <Button iconAlign="right" iconName={sortIcon} variant="link" onClick={() => onClick()}>
                {title}
            </Button>
        </TableCell>
    );
};

const Row = ({ bomModel }: { bomModel: BomModel }) => {
    const [open, setOpen] = useState(false);

    const mdlBomItem: ModuleModel = bomModel.bomItem as ModuleModel;

    const [moduleBom, setModuleBom] = useState<BOMEntryModel[]>([]);

    const [moduleContent, setModuleContent] = useState<ContentModel>();

    const [contentNotFound, setContentNotFound] = useState(false);

    const [contentDownloaded, setContentDownloaded] = useState(false);

    const [moduleBomIsLoaded, setModuleBomIsLoaded] = useState(false);

    useEffect(() => {
        if (!moduleBomIsLoaded && open) {
            const fetchModuleBom = async () => {
                const module = { _systemId: mdlBomItem._systemId } as Module;

                const bom: BOMEntryModel[] = await BOMService.getModuleBOM(module);

                setModuleBom(bom);
                setModuleBomIsLoaded(true);
            };

            fetchModuleBom();
        }
    }, [open, mdlBomItem, moduleBomIsLoaded]);

    const onContentDownload = async () => {
        if (moduleContent !== undefined) {
            await ContentService.downloadContent(
                moduleContent.downloadAttachmentPath,
                moduleContent.fileName ?? `${mdlBomItem.number}.pdf`
            );

            setContentDownloaded(true);
        } else {
            const module = { _systemId: mdlBomItem._systemId } as Module;

            const content: ContentModel[] = await ContentService.getContentForModule(module);

            const secondaryContent = content.find(v => v.role === 'Secondary');

            if (secondaryContent) {
                setModuleContent(secondaryContent);

                await ContentService.downloadContent(
                    secondaryContent.downloadAttachmentPath,
                    secondaryContent.fileName ?? `${mdlBomItem.number}.pdf`
                );

                setContentDownloaded(true);
            } else {
                setContentNotFound(true);
            }
        }
    };

    const getContentStatus = (): any => {
        if (contentNotFound) return 'disabled';

        if (contentDownloaded) return 'success';

        return 'info';
    };

    return (
        <>
            <TableRow selected={open} sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowDownRoundedIcon /> : <KeyboardArrowRightRoundedIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Link
                        href={`#/${mdlBomItem.region}/modules/${mdlBomItem.number}/${mdlBomItem.displayVersion}`}
                        onFollow={e => {
                            window.location.hash = e.detail.href ?? window.location.hash;
                            window.location.reload();
                        }}
                    >
                        {mdlBomItem?.number}
                    </Link>
                </TableCell>
                <TableCell>{mdlBomItem.name}</TableCell>
                <TableCell align="center">{bomModel.quantity}</TableCell>
                <TableCell align="center">{mdlBomItem.displayVersion}</TableCell>
                <TableCell align="center">{mdlBomItem.onComposite}</TableCell>
                <TableCell align="center">
                    <Tooltip title = 'Module PDF' placement="left" arrow>
                        <IconButton disabled={contentNotFound} onClick={() => onContentDownload()}>
                            <PictureAsPdfIcon fontSize="small" color={getContentStatus()} />
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>

            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                            {!moduleBomIsLoaded ? (
                                <Box sx={{ width: '100%' }}>
                                    <LinearProgress />
                                </Box>
                            ) : (
                                <ItemTable
                                    title={`${mdlBomItem.number}`}
                                    items={moduleBom}
                                    hiddenColumns={['version']}
                                    customDisplays={{
                                        priceInUSD: val => formatCurrency(val),
                                        price: formatCurrency,
                                        totalCost: val => formatCurrency(val),
                                        active: (active: boolean) => (
                                            <StatusIndicator type={active ? 'success' : 'error'}>
                                                {active ? 'Yes' : 'No'}
                                            </StatusIndicator>
                                        )
                                    }}
                                    customWidths={{
                                        active: 100,
                                        quantity: 125
                                    }}
                                    initialPageSize={100}
                                    initialColumnOrder={[
                                        'active',
                                        'number',
                                        'name',
                                        'quantity',
                                        'priceInUSD',
                                        'carTab',
                                        'description',
                                        'partNumber',
                                        'supplier'
                                    ]}
                                    initiallyVisibleColumnCount={9}
                                    disableSearch={true}
                                    disableSelection={true}
                                    preferenceCacheId="module-bom-table"
                                    canExport
                                />
                            )}
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

const NoResourcesRow = ({ colSpan }: { colSpan: number }) => {
    return (
        <TableRow>
            <TableCell
                sx={{ fontWeight: 'bold', color: '#545b64', padding: '25px', backgroundColor: '#f2f3f3' }}
                colSpan={colSpan}
                align="center"
            >
                No Resources
            </TableCell>
        </TableRow>
    );
};

export const ModuleListStructureView = ({ moduleList }: { moduleList: ModuleListModel }) => {
    const [sortKey, setSortKey] = useState<string>('');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

    const [rows, setRows] = useState<BomModel[]>(moduleList._bom ?? []);

    useEffect(() => {
        setRows([...(moduleList._bom ?? [])]);
    }, [moduleList._bom]);

    useEffect(() => {
        if (sortKey === 'quantity') {
            setRows(rows => [...rows.sort((a, b) => compare(a, b, 'quantity', sortDirection))]);
        } else if (sortKey === 'displayVersion') {
            setRows(rows => [
                ...rows.sort((a, b) =>
                    compare(
                        a.bomItem ?? ({} as SiteBaseModel),
                        b.bomItem ?? ({} as SiteBaseModel),
                        'displayVersion',
                        sortDirection
                    )
                )
            ]);
        } else {
            setRows(rows => [
                ...rows.sort((a, b) =>
                    compare(
                        a.bomItem ?? ({} as SiteBaseModel),
                        b.bomItem ?? ({} as SiteBaseModel),
                        sortKey,
                        sortDirection
                    )
                )
            ]);
        }
    }, [sortKey, sortDirection]);

    const getSortIcon = (key: string): IconProps.Name => {
        if (key === sortKey) {
            if (sortDirection === 'asc') return SORT_ICON.up;

            if (sortDirection === 'desc') return SORT_ICON.down;
        }

        return SORT_ICON.default;
    };

    //update sortKey and/or sortDirection
    const onSortClick = (key: string) => {
        setSortKey(key);

        if (sortDirection === 'asc') setSortDirection('desc');
        else setSortDirection('asc');
    };

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table" size="small">
                <TableHead sx={{ backgroundColor: '#fafafa' }}>
                    <TableRow>
                        <TableCell align="left" colSpan={7} sx={{ borderBottom: 'none' }}>
                            <h2>
                                <span style={{ fontWeight: 600 }}>{moduleList.name}</span>
                                <span style={{ fontWeight: 400, color: '#687078' }}> ({moduleList._bom?.length})</span>
                            </h2>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{ width: 10 }} />
                        <HeadCell
                            title="Number"
                            sortIcon={getSortIcon('number')}
                            onClick={() => onSortClick('number')}
                        />
                        <HeadCell title="Name" sortIcon={getSortIcon('name')} onClick={() => onSortClick('name')} />
                        <HeadCell
                            title="Quantity"
                            sortIcon={getSortIcon('quantity')}
                            onClick={() => onSortClick('quantity')}
                            align="center"
                        />
                        <HeadCell
                            title="Version"
                            sortIcon={getSortIcon('displayVersion')}
                            onClick={() => onSortClick('displayVersion')}
                            align="center"
                        />
                        <HeadCell
                            title="On Composite"
                            sortIcon={getSortIcon('onComposite')}
                            onClick={() => onSortClick('onComposite')}
                            align="center"
                        />
                        <TableCell sx={{ width: 10 }} />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.length === 0 ? (
                        <NoResourcesRow colSpan={7} />
                    ) : (
                        <>
                            {rows.map(bomModel => (
                                <Row key={bomModel._systemId} bomModel={bomModel} />
                            ))}
                        </>
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
