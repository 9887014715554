import { SiteBaseModel } from './site_base_model';

export default class PostCARProposalModel extends SiteBaseModel {

    state?: string;
    simLink?: string;
    affectedModule?: string;
    affectedSites?: string;
    affectsComposite?: string;
    changeCategory?: string;
    changeOrigin?: string;
    subOrigin?: string;
    changeType?: string;
    costDelta?: string;
    newQuantity?: string;
    newVersion?: string;
    notes?: string;
    requestingTeam?: string;
  

    constructor(json: any) {
        super(json);
        this.init(json);
    }

    protected resolveStructureKey(key: string, json: any): SiteBaseModel[] {
        return [];
    }

    public static LOADING_TEMPLATE: PostCARProposalModel = new PostCARProposalModel({
        systemId: 'Loading...',
        name: 'Loading...',
        number: 'Loading...',
        version: {
            version: 'Loading...',
            iteration: 'Loading...'
        },
        attributes: {
            simLink: 'Loading...', 
            affectedModule: 'Loading...', 
            affectedSites: 'Loading...', 
            affectsComposite: 'Loading...', 
            changeCategory: 'Loading...', 
            changeOrigin: 'Loading...', 
            subOrigin: 'Loading...', 
            changeType: 'Loading...', 
            costDelta: 'Loading...', 
            newQuantity: 'Loading...', 
            newVersion: 'Loading...', 
            notes: 'Loading...', 
            requestingTeam: 'Loading...', 
            state: 'Loading...',            
        }
    });

    get stateString(): string{
        let stateMap: {[key:string]:string} = {
            "OPEN":"Open",
            "SUBMITTED":"Submitted",
            "CCM Assigned":"CCM Assigned",
            "CCM Unassigned":"CCM Unassigned",
            "PENDINGAPPROVAL": "Pending Approval",
            "APPROVED": "Approved",
            "IMPLEMENTATION":"Implementation",
            "REWORK":"Rework",
            "RELEASED": "Released",
            "REJECTED":"Rejected",
            "PROPOSAL":"Proposal"            
        }
        return stateMap[this.state??""] ?? this.state;
    }
    
    get displayVersion(): string{
        return `${this._version?.version}.${this._version?.iteration}`;
    }

    get displayVersionWithState(): string{
        return `${this.displayVersion} - ${this.stateString}`;
    }
}