import ModuleDesignGuideModel from '../../../data/module/module_design_guide_model';
import React from 'react';
import DynamicExpandableProperties, { KeyValueTemplate } from '../../../components/dynamic_expandable_properties';
import TuxView from '../../../components/tux_view';
import { Alert } from '@amzn/geist-ui-components';
import { SpaceBetween } from '@amzn/awsui-components-react';
const MDG_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Name', key: 'name' },
            { title: 'Building Type', key: 'buildingType' },
            { title: 'Gen', key: 'gen' },
            { title: 'Floors', key: 'floors' },
        ],
        [
            { title: 'Country', key: 'country' },
            { title: 'Capacity Type', key: 'capacityType' },
            { title: 'Capacity Description', key: 'capacityDescription' },
            { title: 'State', key: 'state' },
        ],
    ],
};

const TEMPLATES: KeyValueTemplate[] = [MDG_ATTR_TEMPLATE];

interface ModuleDesignGuideDetailsViewProps {
    moduleDesignGuide: ModuleDesignGuideModel;
}

export default class ModuleDesignGuideDetailsView extends TuxView<ModuleDesignGuideDetailsViewProps, any> {
    isLoading = true;
    moduleDesignGuide: ModuleDesignGuideModel;

    constructor(props: ModuleDesignGuideDetailsViewProps) {
        super(props);
        this.moduleDesignGuide = ModuleDesignGuideModel.LOADING_TEMPLATE;

        this.bindAll(this);
        this.loadData();
    }

    async loadData(): Promise<void> {
        this.moduleDesignGuide = await this.props.moduleDesignGuide;
        this.isLoading = false;
        this.dataUpdated();
    }

    render() {
        if (!this.moduleDesignGuide && !this.isLoading) {
            return <Alert type="error" header="Failed to get the specified Module Design Guide" />;
        }

        return (
            <SpaceBetween size="m">
                {TEMPLATES.map((template) => (
                    <DynamicExpandableProperties
                        key={Math.random()}
                        object={this.moduleDesignGuide}
                        template={template}
                    />
                ))}
            </SpaceBetween>
        );
    }
}
