import React, { useState } from 'react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import TuxProgressTracker, { TuxProgressTrackerStep } from './tux_progress_tracker';
import './styles.css';

export interface TuxWizardProps {
    steps: TuxProgressTrackerStep[];
    initialStepIndex?: number;
    children: React.ReactNode[];
}

const TuxWizard: React.FC<TuxWizardProps> = ({ steps, initialStepIndex = 0, children }) => {
    const [currentStep, setCurrentStep] = useState(initialStepIndex);

    const handleNext = () => {
        if (currentStep < steps.length - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const updatedSteps: TuxProgressTrackerStep[] = steps.map((step, index) => {
        if (index < currentStep) {
            return { ...step, type: 'past' };
        } else if (index === currentStep) {
            return { ...step, type: 'present' };
        } else {
            return { ...step, type: 'future' };
        }
    });

    return (
        <Box className="tux-wizard-container">
            <Box className="tux-wizard-progress-tracker">
                <TuxProgressTracker steps={updatedSteps} type="theme" direction="column" compact={false} />
            </Box>
            <Box className="tux-wizard-content-container">
                <Box className="tux-wizard-content">{children[currentStep]}</Box>
                <Box className="tux-wizard-buttons">
                    <Button onClick={handlePrevious} disabled={currentStep === 0}>
                        Previous
                    </Button>
                    <Button onClick={handleNext}>{currentStep === steps.length - 1 ? 'Submit' : 'Next'}</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default TuxWizard;
export type { TuxProgressTrackerStep };
