import { Alert, BreadcrumbGroup, BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import ModuleView from './pages/module/module_view';
import ModulesView from './pages/modules/modules_view';
import ChangeView from './pages/change/change_view';
import ChangesView from './pages/changes/changes_view';
import SitesView from './pages/sites/sites_view';
import SiteView from './pages/site/site_view';
import ModuleDesignGuidesView from './pages/moduleDesignGuides/module_design_guides_view';
import React, { ReactNode } from 'react';
import { HashRouter as Router, Route, Switch, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import HomeView from './pages/home_view';
import TestView from './pages/test_view';
import SessionHelper from './utils/session_helper';
import ErrorView from './components/error_page';
import CompareModulesView from './pages/compare/compare_modules_view';
import { EventDispatcher, EventTypes } from './utils/events/events';
import { capitalizeFirstLetter, objectsAreSame } from './utils/tools';
import RequestHelper from './utils/request_helper';
import UserInfoHelper from './utils/user_info_helper';
import { AnalyticsRoutes, ErrorDetails, PageVisitDetails } from './utils/request_helper';
import CompareMDLView from './pages/compare/compare_mdl_view';
import CompareSiteView from './pages/compare/compare_site_view';
import CompareNMLView from './pages/compare/compare_nml_view';
import CompareNMPView from './pages/compare/compare_nmp_view';
import SiteModel from './data/site/site_model';
import { SiteModelDisplayNameType, SiteModelType } from './data/site/site_base_model';
import { SiteModelService } from './data/data';
import MDLModifyTaskView from './pages/mdlModifyTask/mdl_modify_task_view';
import MDLReviewTaskView from './pages/mdlReviewTask/mdl_review_task_view';
import MDLReleaseTaskView from './pages/mdlReleaseTask/mdl_release_task_view';
import CreateSiteScreen from './pages/site/create_site';
import ModuleDesignGuideView from './pages/moduleDesignGuide/module_design_guide_view';
import ModuleDesignGuideModel from './data/module/module_design_guide_model';
import ModuleDesignGuideService from './data/module/module_design_guide_service';

interface RoutingProps {
    hideError?: boolean;
}

interface BreadCrumbRouteProps extends RouteProps {
    [key: string]: any;
    // displayName: string;
    name: string | ((params: any) => string);
    parents?: BreadcrumbGroupProps.Item[] | ((params: any) => BreadcrumbGroupProps.Item[]);
    /** Disables the Routing change event if set to true */
    eventsDisabled?: boolean;
    type?: string;
}

export class BreadCrumbRoute extends Route<BreadCrumbRouteProps> {
    [key: string]: any;

    static HOME_ROUTE: BreadcrumbGroupProps.Item = { text: 'Home', href: '#/home' };
    static MODULES_ROUTE: BreadcrumbGroupProps.Item = { text: 'Modules', href: '#/modules' };
    static CHANGES_ROUTE: BreadcrumbGroupProps.Item = { text: 'Major Changes', href: '#/changes/major' };
    static MINOR_CHANGES_ROUTE: BreadcrumbGroupProps.Item = { text: 'Minor Changes', href: '#/changes/minor' };
    static ARCHIVE_CHANGES_ROUTE: BreadcrumbGroupProps.Item = { text: 'Archive Changes', href: '#/changes/archive' };
    // static MOULE_PARENTS = [BreadCrumbRoute.MODULES_ROUTE];
    static CHANGE_PARENTS = [BreadCrumbRoute.CHANGES_ROUTE];
    static MINOR_CHANGE_PARENTS = [BreadCrumbRoute.MINOR_CHANGES_ROUTE];
    static ARCHIVE_CHANGE_PARENTS = [BreadCrumbRoute.ARCHIVE_CHANGES_ROUTE];

    static CHANGE_PARENTS_MAP: { [key: string]: any } = {
        major: BreadCrumbRoute.CHANGE_PARENTS,
        minor: BreadCrumbRoute.MINOR_CHANGE_PARENTS,
        archive: BreadCrumbRoute.ARCHIVE_CHANGE_PARENTS,
    };

    async getObjectName(type: SiteModelType, siteId: string): Promise<string> {
        const site = (await SiteModelService.fetchModel(type, siteId)) as SiteModel;
        return site.name ?? '';
    }

    async getModuleDesignGuideName(systemId: string): Promise<string> {
        const moduleDesignGuide: ModuleDesignGuideModel = await ModuleDesignGuideService.getModuleDesignGuide(systemId);
        return moduleDesignGuide.name ?? '';
    }

    static get MOULE_PARENTS(): BreadcrumbGroupProps.Item[] {
        return [{ text: 'Modules', href: `#/${SessionHelper.getRegion()}/modules` }];
    }

    static get SITE_PARENTS(): BreadcrumbGroupProps.Item[] {
        return [{ text: 'Sites', href: `#/${SessionHelper.getRegion()}/sites` }];
    }

    static get MODULE_DESIGN_GUIDE_PARENTS(): BreadcrumbGroupProps.Item[] {
        return [{ text: 'Module Design Guides', href: `#/${SessionHelper.getRegion()}/moduleDesignGuides` }];
    }

    static SITE_RELATED_ITEMS_PARENTS(siteId: string, relatedItemType: string): BreadcrumbGroupProps.Item[] {
        if (relatedItemType === 'NonModuleProposal') {
            return [
                { text: 'Sites', href: `#/${SessionHelper.getRegion()}/sites` },
                { text: `${siteId}`, href: `#/${SessionHelper.getRegion()}/sites/${siteId}/relatedItems` },
                {
                    text: SiteModelDisplayNameType.get('NonModuleProposalFolder') ?? '',
                    href: `#/${SessionHelper.getRegion()}/sites/${siteId}/relatedItems/NonModuleProposalFolder`,
                },
            ];
        } else if (relatedItemType === 'PostCARProposal') {
            return [
                { text: 'Sites', href: `#/${SessionHelper.getRegion()}/sites` },
                { text: `${siteId}`, href: `#/${SessionHelper.getRegion()}/sites/${siteId}/relatedItems` },
                {
                    text: SiteModelDisplayNameType.get('PostCARProposalFolder') ?? '',
                    href: `#/${SessionHelper.getRegion()}/sites/${siteId}/relatedItems/PostCARProposalFolder`,
                },
            ];
        } else {
            return [
                { text: 'Sites', href: `#/${SessionHelper.getRegion()}/sites` },
                { text: `${siteId}`, href: `#/${SessionHelper.getRegion()}/sites/${siteId}/relatedItems` },
            ];
        }
    }

    static currentRoute?: BreadcrumbGroupProps.Item;

    constructor(props: BreadCrumbRouteProps) {
        super(props);
        this.state = { siteName: '', objName: '' };
        SessionHelper.initIfNot();
    }

    get name(): string {
        if (typeof this.props.name === 'string') {
            return this.props.name;
        }

        return this.props.name(this.params);
    }

    get href(): string {
        return `#${this.props.location?.pathname}` ?? '';
    }

    get route(): BreadcrumbGroupProps.Item {
        return { text: this.name, href: this.href };
    }

    get parents(): BreadcrumbGroupProps.Item[] | undefined {
        if (typeof this.props.parents === 'function') {
            return this.props.parents(this.params);
        }

        return this.props.parents;
    }

    get routes(): BreadcrumbGroupProps.Item[] {
        const output: BreadcrumbGroupProps.Item[] = [BreadCrumbRoute.HOME_ROUTE];
        if (this.href.endsWith('home')) {
            return output;
        }

        if (this.parents) {
            for (const parent of this.parents) {
                // If site related items entry, push state.siteName, else push parent.text
                output.push({
                    text:
                        parent.href.includes('site') && parent.href.endsWith('relatedItems')
                            ? this.state.siteName
                            : parent.text,
                    href: parent.href,
                });
            }
        }

        if (this.href.includes('site') && (this.href.endsWith('/relatedItems') || this.href.endsWith('/details'))) {
            output.push({
                text: this.state.siteName,
                href: this.href,
            });
        } else if (this.href.includes('moduleDesignGuide')) {
            output.push({
                text: this.state.objName,
                href: this.href,
            });
        } else if (this.params.relatedItamId) {
            output.push({
                text: this.state.objName,
                href: this.href,
            });
        } else {
            output.push({
                text: this.name,
                href: this.href,
            });
        }
        return output;
    }

    private get params() {
        return this.props['computedMatch']?.params;
    }

    /**
     * Triggers the RoutingChange event if routes have been updated
     */
    private triggerRoutingChange(previousRoute?: string) {
        if (!objectsAreSame(BreadCrumbRoute.currentRoute, this.route) && !this.props.eventsDisabled) {
            // Set Site Name when route changes
            this.setObjectNameInState();
            EventDispatcher.triggerEvent(EventTypes.RoutingChange, this.route);

            // Trigger page visit analytics event here,
            Routing.logRoutingEvent(this.props.location?.pathname ?? 'unknown', previousRoute);
        }
        BreadCrumbRoute.currentRoute = this.route;
    }

    private setObjectNameInState() {
        console.log(this.props.type);
        // Get Site ID from Params. Is called only for Site related routes
        if (this.props.type === 'site' || this.props.type === 'site-related' || this.props.type === 'site-folder') {
            if (this.params['systemId']) {
                this.getObjectName(SiteModelType.Site, this.params['systemId'])
                    .then((siteName) => this.setState({ siteName: siteName }))
                    .catch(() => console.debug('Error in fetching Site details'));
            }
            if (this.params['relatedItamId']) {
                this.getObjectName(this.params['relatedItemType'], this.params['relatedItamId'])
                    .then((nmlName) => this.setState({ objName: nmlName }))
                    .catch(() => console.debug('Error in fetching Object details'));
            }
        }
        if (this.props.type === 'moduleDesignGuide') {
            if (this.params['systemId']) {
                this.getModuleDesignGuideName(this.params['systemId'])
                    .then((moduleDesignGuideName) => this.setState({ objName: moduleDesignGuideName }))
                    .catch(() => console.debug('Error in fetching Module Deisgn Guide details'));
            }
        }
    }
    // Trigger routing change on initial mount
    componentDidMount() {
        this.triggerRoutingChange();
    }

    // Trigger routing change all all subsequent updates
    componentDidUpdate(props: BreadCrumbRouteProps) {
        this.triggerRoutingChange(props.location?.pathname);
    }

    render() {
        return (
            <>
                <div style={{ marginBottom: '6px' }}>
                    <BreadcrumbGroup items={this.routes} />
                </div>
                {super.render()}
            </>
        );
    }
}

export default class Routing extends React.Component<RoutingProps, any> {
    getModuleComparisonView(props: RouteComponentProps<{ [x: string]: string | undefined }, any, any>): ReactNode {
        const match = props.match;
        const moduleANumber = match.params.moduleA;
        const moduleBNumber = match.params.moduleB;
        const moduleAVersion = match.params.versionA ?? '0.0';
        const moduleBVersion = match.params.versionB ?? '0.0';

        if (moduleANumber == null || moduleBNumber == null) {
            return <ErrorView />;
        }

        return (
            <CompareModulesView
                moduleANumber={moduleANumber}
                moduleBNumber={moduleBNumber}
                moduleAVersion={moduleAVersion}
                moduleBVersion={moduleBVersion}
            />
        );
    }

    getMDLComparisonView(props: RouteComponentProps<{ [x: string]: string | undefined }, any, any>): ReactNode {
        const match = props.match;
        const mdlANumber = match.params.mdlA;
        const mdlBNumber = match.params.mdlB;
        const mdlAVersion = match.params.versionA ?? '0.0';
        const mdlBVersion = match.params.versionB ?? '0.0';

        if (mdlANumber == null || mdlBNumber == null) {
            return <ErrorView />;
        }

        return (
            <CompareMDLView mdlA={mdlANumber} mdlB={mdlBNumber} mdlAVersion={mdlAVersion} mdlBVersion={mdlBVersion} />
        );
    }

    getNMLComparisonView(props: RouteComponentProps<{ [x: string]: string | undefined }, any, any>): ReactNode {
        const match = props.match;
        const nmlANumber = match.params.nmlA;
        const nmlBNumber = match.params.nmlB;
        const nmlAVersion = match.params.versionA ?? '0.0';
        const nmlBVersion = match.params.versionB ?? '0.0';

        if (nmlANumber == null || nmlBNumber == null) {
            return <ErrorView />;
        }

        return (
            <CompareNMLView nmlA={nmlANumber} nmlB={nmlBNumber} nmlAVersion={nmlAVersion} nmlBVersion={nmlBVersion} />
        );
    }

    getNMPComparisonView(props: RouteComponentProps<{ [x: string]: string | undefined }, any, any>): ReactNode {
        const match = props.match;
        const nmpANumber = match.params.nmpA;
        const nmpBNumber = match.params.nmpB;
        const nmpAVersion = match.params.versionA ?? '0.0';
        const nmpBVersion = match.params.versionB ?? '0.0';

        if (nmpANumber == null || nmpBNumber == null) {
            return <ErrorView />;
        }

        return (
            <CompareNMPView nmpA={nmpANumber} nmpB={nmpBNumber} nmpAVersion={nmpAVersion} nmpBVersion={nmpBVersion} />
        );
    }

    getSiteComparisonView(props: RouteComponentProps<{ [x: string]: string | undefined }, any, any>): ReactNode {
        const match = props.match;
        const siteA = match.params.siteA;
        const siteB = match.params.siteB;

        if (siteA == null || siteB == null) {
            return <ErrorView />;
        }

        return <CompareSiteView siteA={siteA} siteB={siteB} />;
    }
    static async logRoutingEvent(route: string, previousRoute?: string) {
        const event = new PageVisitDetails(previousRoute);
        try {
            RequestHelper.logAnalytics(AnalyticsRoutes.page_visit, event);
        } catch (err) {
            if (process.env.NODE_ENV !== 'production') {
                console.log('(DEBUG)', err);
            }
        }
    }

    async logError(errorDetails: string) {
        if (this.props.hideError) {
            return;
        }
        const event = new ErrorDetails(errorDetails);

        try {
            RequestHelper.logAnalytics(AnalyticsRoutes.error, event);
        } catch (err) {
            if (process.env.NODE_ENV !== 'production') {
                console.log('(DEBUG)', err);
            }
        }
    }

    render(): JSX.Element {
        if (UserInfoHelper.availableRegions.length === 0) {
            this.logError('no_regions');
            return (
                <Alert type="error" header="Something went wrong!">
                    You are not included in any region. Contact an admin to be added
                </Alert>
            );
        }

        return (
            <Router>
                <Switch>
                    {' '}
                    {/*NOTE: Order matters, /test if before /:region to test won't be treated as a region */}
                    {/* {process.env.NODE_ENV !== 'production' ? <BreadCrumbRoute name="Test" exact path="/test" component={TestView}/> : null }  */}
                    {process.env.NODE_ENV !== 'production' ? (
                        <BreadCrumbRoute name="Test" path="/:region?/test" component={TestView} />
                    ) : null}
                    {/* Module List Routes */}
                    {/* <BreadCrumbRoute name={(_:any) => `Modules`} exact path="/modules" component={ModulesView}/> */}
                    <BreadCrumbRoute
                        name={(_: any) => `Modules - ${_.region ?? SessionHelper.getRegion()}`}
                        exact
                        path="/:region?/modules"
                        component={ModulesView}
                    />
                    {/* Single Module  Routes */}
                    <BreadCrumbRoute
                        parents={BreadCrumbRoute.MOULE_PARENTS}
                        name={(_: any) => `${_.number} ${_.version ? `(${_.version})` : ''}`}
                        path="/:region/modules/:number/:version?/:initialTab?"
                        component={ModuleView}
                    />
                    {/* Modify MDL */}
                    <BreadCrumbRoute
                        type="site-related"
                        parents={(_: any) => BreadCrumbRoute.SITE_RELATED_ITEMS_PARENTS(_.systemId, _.relatedItemType)}
                        name={(_: any) => SiteModelDisplayNameType.get(_.relatedItemType) ?? ''}
                        exact
                        path="/:region/sites/:systemId/relatedItems/:relatedItemType/:relatedItamId/process/:task_id/mdlModifyTask"
                        component={MDLModifyTaskView}
                    />
                    {/* Review MDL */}
                    <BreadCrumbRoute
                        type="site-related"
                        parents={(_: any) => BreadCrumbRoute.SITE_RELATED_ITEMS_PARENTS(_.systemId, _.relatedItemType)}
                        name={(_: any) => SiteModelDisplayNameType.get(_.relatedItemType) ?? ''}
                        exact
                        path="/:region/sites/:systemId/relatedItems/:relatedItemType/:relatedItamId/process/:task_id/mdlReviewTask"
                        component={MDLReviewTaskView}
                    />
                    {/* Release MDL */}
                    <BreadCrumbRoute
                        type="site-related"
                        parents={(_: any) => BreadCrumbRoute.SITE_RELATED_ITEMS_PARENTS(_.systemId, _.relatedItemType)}
                        name={(_: any) => SiteModelDisplayNameType.get(_.relatedItemType) ?? ''}
                        exact
                        path="/:region/sites/:systemId/relatedItems/:relatedItemType/:relatedItamId/process/:task_id/mdlReleaseTask"
                        component={MDLReleaseTaskView}
                    />
                    {/* Module Comparison Route */}
                    <BreadCrumbRoute
                        name={(_: any) => `Compare ${_.moduleA} (${_.versionA}) & ${_.moduleB} (${_.versionB})`}
                        exact
                        path="/:region/compare/modules/:moduleA/:versionA/:moduleB/:versionB"
                        render={this.getModuleComparisonView}
                    />
                    {/* MDL Comparison Route */}
                    <BreadCrumbRoute
                        name={(_: any) => `Compare ${_.mdlA} (${_.versionA}) & ${_.mdlB} (${_.versionB})`}
                        exact
                        path="/:region/compare/mdl/:mdlA/:versionA?/:mdlB/:versionB?/"
                        render={this.getMDLComparisonView}
                    />
                    {/* Site Comparison Route */}
                    <BreadCrumbRoute
                        name={(_: any) => `Compare ${_.siteA} & ${_.siteB}`}
                        exact
                        path="/:region/compare/site/:siteA/:siteB/"
                        render={this.getSiteComparisonView}
                    />
                    {/* NML Comparison Route */}
                    <BreadCrumbRoute
                        name={(_: any) => `Compare ${_.nmlA} (${_.versionA}) & ${_.nmlB} (${_.versionB})`}
                        exact
                        path="/:region/compare/nml/:nmlA/:versionA?/:nmlB/:versionB?/"
                        render={this.getNMLComparisonView}
                    />
                    {/* NMP Comparison Route */}
                    <BreadCrumbRoute
                        name={(_: any) => `Compare ${_.nmpA} (${_.versionA}) & ${_.nmpB} (${_.versionB})`}
                        exact
                        path="/:region/compare/nmp/:nmpA/:versionA?/:nmpB/:versionB?/"
                        render={this.getNMPComparisonView}
                    />
                    {/* Change List Routes */}
                    <BreadCrumbRoute
                        name={(_: any) =>
                            `${capitalizeFirstLetter(_.crType)} Changes - ${_.region ?? SessionHelper.getRegion()}`
                        }
                        exact
                        path="/:region?/changes/:crType"
                        render={({ match }) =>
                            match.params.crType ? <ChangesView crType={match.params.crType} /> : <Redirect to="#" />
                        }
                    />
                    {/* Single Change  Routes */}
                    <BreadCrumbRoute
                        parents={(_: any) => BreadCrumbRoute.CHANGE_PARENTS_MAP[_.crType]}
                        name={(_: any) => `${_.id}`}
                        path="/:region/changes/:crType/:id/:tab?"
                        render={({ match }) =>
                            match.params.id ? (
                                <ChangeView
                                    number={match.params.id}
                                    crType={match.params.crType}
                                    initialTab={match.params.tab}
                                />
                            ) : (
                                <Redirect to="#" />
                            )
                        }
                    />
                    {/* Sites List Routes */}
                    <BreadCrumbRoute name={(_: any) => `Sites`} exact path="/:region/sites" component={SitesView} />
                    <BreadCrumbRoute
                        name={(_: any) => `Create Site`}
                        parents={[{ text: 'Sites', href: `#/${SessionHelper.getRegion()}/sites` }]}
                        exact
                        path="/:region/site/createSite"
                        component={CreateSiteScreen}
                    />
                    {/* Single Site  Routes */}
                    <BreadCrumbRoute
                        type="site-related"
                        parents={(_: any) => BreadCrumbRoute.SITE_RELATED_ITEMS_PARENTS(_.systemId, _.relatedItemType)}
                        name={
                            (_: any) => SiteModelDisplayNameType.get(_.relatedItemType) ?? ''
                            //(_: any) => _.relatedItemType === 'ModuleList' ? 'Module List' : 'Non Module List'
                        }
                        exact
                        path="/:region/sites/:systemId/relatedItems/:relatedItemType/:relatedItamId/:initialTab?"
                        component={SiteView}
                    />
                    <BreadCrumbRoute
                        type="site-folder"
                        parents={(_: any) => BreadCrumbRoute.SITE_RELATED_ITEMS_PARENTS(_.systemId, _.relatedItemType)}
                        name={(_: any) => SiteModelDisplayNameType.get(_.relatedItemType) ?? ''}
                        exact
                        path="/:region/sites/:systemId/relatedItems/:relatedItemType"
                        //exact path="/folder"
                        component={SiteView}
                    />
                    <BreadCrumbRoute
                        type="site"
                        parents={BreadCrumbRoute.SITE_PARENTS}
                        name={(_: any) => `${_.systemId}`}
                        path="/:region/sites/:systemId/:initialTab?"
                        component={SiteView}
                    />
                    {/* Sites List Routes */}
                    {/* Module Design Guide Routes*/}
                    <BreadCrumbRoute
                        name={(_: any) => `Module Design Guides`}
                        exact
                        path="/:region/moduleDesignGuides"
                        component={ModuleDesignGuidesView}
                    />
                    <BreadCrumbRoute
                        type="moduleDesignGuide"
                        parents={BreadCrumbRoute.MODULE_DESIGN_GUIDE_PARENTS}
                        name={(_: any) => `${_.systemId}`}
                        path="/:region/moduleDesignGuide/:systemId/:initialTab?"
                        component={ModuleDesignGuideView}
                    />
                    <Redirect exact path="/home" to={`/${SessionHelper.getRegion()}/home`} />
                    <BreadCrumbRoute name="Home" exact path="/:region/home" component={HomeView} />
                    <Redirect exact path="/" to={`/${SessionHelper.getRegion()}/home`} />
                    {/* <Redirect exact path="/:region" to={`/:region/home`}/> */}
                    <Route path="/:region/site/createSite" component={CreateSiteScreen} />
                    <Route
                        path="*"
                        render={(_) => {
                            Routing.logRoutingEvent(_.location.hash);
                            this.logError('invalid_page');
                            return this.props.hideError ? <></> : <ErrorView />;
                        }}
                    />
                </Switch>
            </Router>
        );
    }
}
