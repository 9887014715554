import React from "react";
import { SpaceBetween } from '@amzn/awsui-components-react-v3';
import { DynamicExpandableProperties, KeyValueTemplate } from '../../../modules';
import NonModuleListModel from '../../../data/site/non_module_list_model';

const NML_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Number', key: 'number' },
            { title: 'Name', key: 'name' },
            { title: 'Version', key: 'displayVersion' },
        ],
        [
            { title: "State", key: "stateString", type: "status", states: { Released: "success", Approved: "success", Closed: "stopped", default: "pending"} },
        ],
    ]
};

const NML_SYSTEM_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'System',
    type: 'key-value',
    defaultExpanded: false,
    sections: [
        [
            { title: 'Created By', key: 'createdBy' },
            { title: 'Created On', key: 'createdOn', type: 'datetime' }
        ],
        [
            { title: 'Modified By', key: 'modifiedBy' },
            { title: 'Last Modified', key: 'lastModified', type: 'datetime' }
        ]
    ]
};

const TEMPLATES: KeyValueTemplate[] = [NML_ATTR_TEMPLATE, NML_SYSTEM_ATTR_TEMPLATE];

export const NonModuleListDetailsView = ({ nonModuleList }: { nonModuleList: NonModuleListModel }) => {

    return (
        <SpaceBetween size="m">
            {TEMPLATES.map(template => (
                <DynamicExpandableProperties key= {template.title} object={nonModuleList} template={template} />
            ))}
        </SpaceBetween>
    );
}