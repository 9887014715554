import { SiteBaseModel } from "./site_base_model";

export default class ModuleModel extends SiteBaseModel {

    country?: string;
    onComposite?: string;    
    state?: string;
    region?: string;
    buildingType?: string;
    

    constructor(json: any) {        
        super(json);
        this.init(json);        
    }

    protected resolveStructureKey(key: string, json: any): SiteBaseModel[] {
        return [];
    }

    get displayVersion(): string{
        return `${this._version?.version}.${this._version?.iteration}`;
    }
}