// This should not be used, using the process.env.NODE_ENV direclty will remove the code from production builds
export const IS_DEV: boolean = process.env.NODE_ENV !== 'production';
export const IS_DEV_OR_LOCALHOST: boolean =
    process.env.NODE_ENV === 'development' || window.location.hostname.includes('localhost');
export const IS_PROD: boolean = window.location.host === 'tux.eng-plm.a2z.com';
export const CURRENT_HOST: string = window.location.host;
export const LEFT_NAV_FAVORITES = 'ESPLM_LEFT_NAV_FAVORITES';
export const MODULES_TABLE_PREFS_CACHE = 'MODULES_TABLE_PREFS_CACHE_';
export const PDF_WORKER_URL: string =
    Array.from(document.getElementsByTagName('script')).pop()?.src?.split('/static')[0] + '/pdf.worker.js';
export const CAR_TABS: Array<string> = [
    'AMAZON ROBOTICS',
    'LOSS PREVENTION',
    'MATERIAL HANDLING',
    'OPERATIONS IT',
    'PIT',
    'PROCESSING',
    'SAFETY',
    'STORAGE',
    'SUPPORT',
];
// export function PDF_WORKER_URL(): string{
//     let g = Array.from(document.getElementsByTagName("script"));

//     let scripts = document.getElementsByTagName("script");
//     let lastScript = scripts[scripts.length-1];
//     return lastScript.src.split("/static")[0] + "/pdf.worker.js";
// };
// Replace with db port
// export const DB_HOST: string = window.location.host.replace(":3000", "1180");
