import RequestHelper from "../../utils/request_helper";

export default class ContentModel {
    [key: string]: any;

    contentType: string = "";
    createdOn: Date = new Date();
    description: string = "";
    fileName: string = "";
    filePath: string = "";
    fileSize?: number;
    contentUrl?: string;
    refId: string = "";
    refType: "module" | "change" = "module";
    role: "Primary" | "Secondary" = "Primary";
    systemId: string = "";
    updatedOn: Date = new Date();
    modifiedOn?: Date;

    /**
     * Complete URL to downloadBytes endpoint on Content Service
     * 
     * Format: https://{HOSTNAME}/content/{SYSTEM_ID}/downloadBytes
     */
    get downloadBytesURL(): string {
        return `${RequestHelper.HOST}/content/${this.systemId}/downloadBytes`;
    }

    /**
     * Get the path to the download endpoint with an attachment disposition
     */
    get downloadAttachmentPath(): string {
        return `/content/${this.systemId}/download?disposition=attachment`
    }

    static contentTypeString(type: string): string {
        let contentTypeMap: { [key: string]: string } = {
            "doc": "Document",
            "docx": "Document",
            "docm": "Document",
            "xlsm": "Excel Workbook",
            "xlsx": "Excel Workbook",
            "xlsb": "Excel Workbook",
            "xls": "Excel Workbook",
            "pptx": "PowerPoint Presentation",
            "pptm": "PowerPoint Presentation",
            "pps": "PowerPoint Presentation",
            "ppt": "PowerPoint Presentation",
            "htm": "Web Page",
            "html": "Web Page",
            "mht": "Single File Web Page",
            "mhtml": "Single File Web Page",
            "zip": "Zip File",
            "txt": "Text",
            "msg": "Email Message",
            "skp": "SketchUp File",
            "pub": "Microsoft Publisher",
            "vsdx": "Visio File"
        }
        return contentTypeMap[type.toLowerCase()] ?? type.toUpperCase();
    }

    static fileSize(size: number): string {
        const mb: number = 1024 * 1024;
        const kb: number = 1024;

        if (size != null) {
            if (size > mb)
                return (Math.round((size / mb) * 100) / 100) + " MB"
            else
                return (Math.round((size / kb) * 100) / 100) + " KB"
        }

        return "-";
    }


    static fromJSON(json: any): ContentModel {
        let output = new ContentModel();

        let contentKeys = Object.keys(output);
        let keySet: Set<string> = new Set(contentKeys);

        // Copy over the values of they keys present in a ModuleModel;
        for (let key of contentKeys) {
            if (output[key] instanceof Date) {
                output[key] = new Date(json[key]);
            } else {
                output[key] = json[key];
            }
        }

        for (let key of Object.keys(json)) {
            // Ignore keys already present in ModuleModel
            if (!key.startsWith("_") && keySet.has(key)) {
                continue;
            }
            output["_" + key] = json[key];
        }

        return output;
    }
}