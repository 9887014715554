import TuxComponent from '../../components/tux_view';
import { NoLoadTabs } from '../../components/components';
import React from 'react';
import ModuleDesignGuideModel from '../../data/module/module_design_guide_model';
import ModuleDesignGuideDetailsView from './tabs/module_design_guide_details_view';
import ModuleDesignGuideStructureView from './tabs/module_design_guide_structure_view';
import Box from '@amzn/meridian/box';
import { Alert, AlertProps, Header } from '@amzn/geist-ui-components';
import { BreadcrumbGroupProps, SpaceBetween } from '@amzn/awsui-components-react';
import ModuleDesignGuideService from '../../data/module/module_design_guide_service';
import ModuleDesignGuidePlacementModel from '../../data/module/module_design_guide_placement_model';
import { EventDispatcher } from '../../utils/events/event_dispatcher';
import { EventTypes } from '../../utils/events/event_types';

export interface ModuleDesignGuideViewProps {
    match: {
        params: {
            region: string;
            systemId: string;
            initialTab: string;
        };
    };
}

class ModuleDesignGuideView extends TuxComponent<ModuleDesignGuideViewProps, any> {
    isLoading = true;
    moduleDesignGuide: ModuleDesignGuideModel = ModuleDesignGuideModel.LOADING_TEMPLATE;
    moduleDesignGuidePlacements: ModuleDesignGuidePlacementModel[] = [];
    initialTab?: string;
    systemId: string;

    private alertType: AlertProps.Type = 'info';
    private isAlertVisible = false;
    private alertMessage = '';

    constructor(props: ModuleDesignGuideViewProps) {
        super(props);
        this.systemId = props.match.params.systemId;
        this.state = { mdgSystemId: this.systemId };
        this.currentTab = props.match.params.initialTab ?? 'details';
        this.bindAll(this);
        this.loadData();
        EventDispatcher.subscribe(
            EventTypes.RoutingChange,
            EventTypes.ModuleDesignGideReload,
            (t: BreadcrumbGroupProps.Item) => {
                this.setCurrentPage(t);
            },
        );
    }

    // Function to reload component when route changed
    private setCurrentPage(currentRoute: BreadcrumbGroupProps.Item) {
        const newMDGId = currentRoute['text'];
        this.setState({ mdgSystemId: newMDGId }, this.loadData);
        this.dataUpdated();
    }
    async loadData(): Promise<void> {
        try {
            this.moduleDesignGuide = await ModuleDesignGuideService.getModuleDesignGuide(this.state.mdgSystemId);
        } catch (err) {
            this.showAlert(
                `Failed to fetch Module Design Guide. Please contact site administrator. ${(err as Error).message}`,
                'error',
            );
        }
        try {
            this.moduleDesignGuidePlacements =
                await ModuleDesignGuideService.getModuleDesignGuideSiteTemplatePlacements(this.systemId);
        } catch (err) {
            this.showAlert(
                `Failed to fetch Module Design Guide Structure. Please contact site administrator. ${(err as Error).message}`,
                'error',
            );
        }
        this.isLoading = false;
        this.dataUpdated();
    }

    private showAlert(message: string, type: AlertProps.Type = 'info') {
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }

    alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    tabChanged(tabClicked: any) {
        if (!window.location.hash.includes(this.currentTab)) {
            window.location.hash += `/${this.currentTab}`;
        }
        window.history.replaceState(null, 'ES-PLM', window.location.hash.replace(this.currentTab, tabClicked));
        this.currentTab = tabClicked;
    }

    getHeader(): JSX.Element {
        if (this.isLoading) {
            return <Header children={'Loading...'}></Header>;
        }
        return (
            <Header>
                <SpaceBetween size="xs" direction="horizontal">
                    <div key="mdg_header_title">{this.moduleDesignGuide.name}</div>
                </SpaceBetween>
            </Header>
        );
    }

    getContent(): JSX.Element {
        return (
            <>
                <Box spacingInset={this.isAlertVisible ? '400 0' : 'none'}>
                    <Alert
                        data-testid="alert_box"
                        alertType={this.alertType}
                        visible={this.isAlertVisible}
                        dismissible={true}
                        onDismiss={this.alertDismissed}
                        header={this.alertMessage}
                    />
                </Box>
                {this.getHeader()}
                <NoLoadTabs
                    initialTab={this.props.match?.params?.initialTab ?? 'details'}
                    onTabChanged={this.tabChanged}
                    tabs={[
                        {
                            id: 'details',
                            label: 'Details',
                            content: <ModuleDesignGuideDetailsView moduleDesignGuide={this.moduleDesignGuide} />,
                        },
                        {
                            id: 'structure',
                            label: 'Structure',
                            content: (
                                <ModuleDesignGuideStructureView
                                    moduleDesignGuidePlacements={this.moduleDesignGuidePlacements}
                                    systemId={this.systemId}
                                />
                            ),
                        },
                    ]}
                />
            </>
        );
    }

    render(): JSX.Element {
        return this.getContent();
    }
}

export default ModuleDesignGuideView as React.ComponentType<ModuleDesignGuideViewProps>;
