import React from "react";
import { SpaceBetween } from '@amzn/awsui-components-react-v3';
import { DynamicExpandableProperties, KeyValueTemplate } from '../../../modules';
import NonModuleProposalModel from '../../../data/site/non_module_proposal_model';

const NMP_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Number', key: 'number' },
            { title: 'Name', key: 'name' },
            { title: 'Version', key: 'displayVersion' },
        ],
        [
            { title: "State", key: "stateString", type: "status", states: { Released: "success", Approved: "success", Closed: "stopped", default: "pending"} },
        ],
    ]
};

const NMP_SYSTEM_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'System',
    type: 'key-value',
    defaultExpanded: false,
    sections: [
        [
            { title: 'Created By', key: 'createdBy' },
            { title: 'Created On', key: 'createdOn', type: 'datetime' }
        ],
        [
            { title: 'Modified By', key: 'modifiedBy' },
            { title: 'Last Modified', key: 'lastModified', type: 'datetime' }
        ]
    ]
};

const TEMPLATES: KeyValueTemplate[] = [NMP_ATTR_TEMPLATE, NMP_SYSTEM_ATTR_TEMPLATE];

export const NonModuleProposalDetailsView = ({ nonModuleProposal }: { nonModuleProposal: NonModuleProposalModel }) => {

    return (
        <SpaceBetween size="m">
            {TEMPLATES.map(template => (
                <DynamicExpandableProperties key= {template.title} object={nonModuleProposal} template={template} />
            ))}
        </SpaceBetween>
    );
}