import React from 'react';
import TuxComponent from '../../components/tux_view';
import SessionHelper from '../../utils/session_helper';
import { ChangeRequestBaseModel, ChangeRequestService } from '../../data/data';
import { Header, Link } from '@amzn/awsui-components-react-v3';
import ItemTable, { CustomDisplayProps } from '../../components/item_table';
import {StatusIndicator} from '@amzn/awsui-components-react-v3';
import { measuredAsync } from '../../utils/decorators';

interface ChangesViewProps {
    crType: string;
}

export default class ChangesView extends TuxComponent<ChangesViewProps, any>{

    region: string;
    isLoading: boolean = true;
    customDisplays: CustomDisplayProps;
    changes: ChangeRequestBaseModel[] = [];
    useCounterSuffix: boolean = true;

    constructor(props: ChangesViewProps) {
        super(props);
        this.region = SessionHelper.getRegion();

        this.customDisplays = {
            createdOn: (d: Date) => new Date(d).toLocaleDateString(),
            number: (n: string) => <Link href={`#/${SessionHelper.getRegion()}/changes/${this.props.crType}/${n}`}>{n}</Link>,
            state: (state: string, item: ChangeRequestBaseModel) => <StatusIndicator type={ChangeRequestBaseModel.statusType(state)}>{item.stateString}</StatusIndicator>,
        }

        this.bindAll(this);
        this.loadData();
        console.log(this.props.crType)
    }

    @measuredAsync("load_time")
    async loadData(): Promise<void> {
        this.isLoading = true;
        this.dataUpdated();

        this.region = SessionHelper.getRegion();
        
        this.changes = [];
        
        let canStop = false;

        let page = 0;
        let size = 2000;

        while(!canStop){
            
            let changesChunk = await ChangeRequestService.getChangeRequestsFromRegion(this.region, this.props.crType, page, size);

            if(changesChunk.length === size)
                page++;
            else
                canStop = true;

            this.changes.push(...changesChunk);    
            this.isLoading = false;
            this.dataUpdated();        
        }

        this.useCounterSuffix=false;
        this.dataUpdated();
    }


    public getViewName(): string {
        if(this.props.crType === "major")
            return "Major Changes";
        
        if(this.props.crType === "minor")
            return "Minor Changes";

        return "Archive Changes";
    }

    render() {
        return (
            <>
                <Header variant="h1">
                    Building Standards - {this.region}
                </Header>
                <ItemTable
                    title={`${this.getViewName()}- ${this.region ?? 'Loading...'}`}
                    items={this.changes}
                    counterSuffix={this.useCounterSuffix ? "..." : ""}
                    isLoading={this.isLoading}
                    customDisplays={this.customDisplays}
                    // disableSelection={true}
                    initiallyVisibleColumnCount={8}
                    customHeaders={new Map<string, string>([["ccmStatus","CCM Status"]])}
                    canExport
                />
            </>
        );
    }


}