import React from 'react';
import {TuxComponent, } from './components';
import {Alert} from '@amzn/awsui-components-react-v3';

interface ErrorViewProps{

}

export default class ErrorView extends TuxComponent<ErrorViewProps, any>{

    constructor(props: ErrorViewProps){
        super(props);
        this.loadData();
    }

    async loadData(){
        // console.log("In loadData");
    }

    render(){
        return (
            <Alert type="error" header="Something went wrong!">
                The page you tried visiting does not exist. If you believe this is an error, contact an admin.
            </Alert>
        );
    }
}