import React from 'react';
import { Link, StatusIndicatorProps } from '@amzn/awsui-components-react';
import SessionHelper from '../../utils/session_helper';

export default class ModuleModel {
    [key: string]: any;

    public getURL() {
        // Instance in the format of: tuxdev.developer.eng-plm.a2z.com
        return `https://${SessionHelper.getWindchillInstance()}/Windchill/app/?identity_provider=COGNITO#ptc1/tcomp/infoPage?oid=OR%3Awt.part.WTPart%3A${this._versionId}`;
    }

    public static LOADING_TEMPLATE: ModuleModel = ModuleModel.fromJSON({
        number: 'Loading...',
        name: 'Loading...',
        version: 'Loading...',
        region: 'Loading...',
        buildingType: 'Loading...',
        state: 'Loading...',
        createdOn: new Date(),
        drawingNumber: 'Loading...',
        placementNotes: 'Loading...',
        modulePDF: 'Loading...',
        on_composite: false,
        status: 'Loading...',
        _latest: true,
    });

    _systemId?: string;
    _versionId?: string;
    _moduleId?: string;
    number?: string;
    name?: string;
    version?: string;
    _version?: number;
    _iteration?: number;
    region?: string;
    buildingType?: string;
    state?: string;
    createdOn?: Date;
    // checkOutStatus?: string;
    checkoutState?: string;
    lastModified?: Date;
    // drawingNumber?: string;
    placementNotes?: string;
    modulePDF?: string;
    onComposite?: string;
    country?: string;
    createdBy?: string;
    modifiedBy?: string;
    comments?: string;
    usageType?: string;
    _latest?: boolean;
    ownerGroup?: string;
    purpose?: string;
    layoutAdjustable?: string;
    powerRequired?: string;
    dataRequired?: string;

    get statusType(): StatusIndicatorProps.Type {
        return ModuleModel.statusType(this.state ?? '');
    }

    get stateString(): string {
        const stateMap: { [key: string]: string } = {
            RELEASED: 'Released',
            INWORK: 'In Work',
            REJECTED: 'Rejected',
            CANCELLED: 'Archived',
            INWORKMINOR: 'In Work - Minor',
            UNDERREVIEW: 'Under Review',
            UNDERREVIEWMINOR: 'Under Review - Minor',
            RELEASEDMINOR: 'Released - Minor',
            RELEASEDARCHIVED: 'Released - Archived',
        };
        return stateMap[this.state ?? ''] ?? this.state;
    }

    // get checkOutStatusString(): string{
    //     return ModuleModel.getCheckOutStatusString(this.checkOutStatus??"");
    // }

    get versionNumber(): string {
        return (this.version?.split(' ') ?? ' ')[0];
    }

    static statusType(state: string): StatusIndicatorProps.Type {
        switch (state?.toLowerCase()) {
            case 'released - minor':
            case 'releasedminor':
            case 'released':
                return 'success';

            case 'rejected':
                return 'error';
            case 'cancelled':
            case 'releasedarchived':
            case 'archived':
                return 'stopped';
            default:
                return 'pending';
        }
    }

    get modulePDFLink(): React.ReactNode {
        if (this.modulePDF?.startsWith('http')) {
            return (
                <Link href={this.modulePDF} external={true}>
                    {this.number} ({this.versionNumber}) - PDF
                </Link>
            );
        }
        return this.modulePDF;
    }

    static fromJSON(json: any): ModuleModel {
        const output = new ModuleModel();

        const moduleKeys = Object.keys(output);
        const keySet: Set<string> = new Set(moduleKeys);

        // Copy over the values of they keys present in a ModuleModel;
        for (const key of moduleKeys) {
            // if (output[key] instanceof Date){
            //     output[key] = new Date(json[key]);
            //     continue;
            // }

            output[key] = json[key];
        }

        for (const key of Object.keys(json)) {
            // Ignore keys already present in ModuleModel
            if (!key.startsWith('_') && keySet.has(key)) {
                continue;
            }

            output['_' + key] = json[key];
        }

        // Any custom items are here
        output.version = json['versionDisplay'];
        output._version = json['version'];

        // output.modulePDF = "https://github.com/wojtekmaj/react-pdf/raw/main/sample/webpack5/sample.pdf";

        return output;
    }

    toString(): string {
        return JSON.stringify(this);
    }
}
