import React, { ReactNode } from 'react';
import {TuxView, ValueWithLabel, ItemTable, DynamicExpandableProperties } from '../../components/components';
import { BOMEntryModel, BOMService, ContentModel, ContentService, ModuleModel, ModuleService } from '../../data/data';
import SessionHelper from '../../utils/session_helper';
import { Box, ColumnLayout, ExpandableSection, Grid, Header, Link, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react-v3';
import { KeyValueTemplate } from '../../components/dynamic_expandable_properties';
import { CookieHelper, RequestHelper } from '../../modules';
import { measuredAsync } from "../../utils/decorators";
import { downloadObject } from '../../utils/tools';
import { BaseNavigationDetail } from '@amzn/awsui-components-react-v3/polaris/internal/events';
import { AnalyticsRoutes, ActionDetails } from '../../utils/request_helper';
import { CookieNames } from '../../utils/cookie_helper';
import CompareUtils from '../../utils/compare_utils';


interface CompareModulesViewProps{
    moduleANumber: string;
    moduleAVersion: string;
    moduleBNumber: string;
    moduleBVersion: string;
}

export default class CompareModulesView extends TuxView<CompareModulesViewProps, any>{
    private static MODULE_PDF_TEMPLATE: KeyValueTemplate = {
        title: "Drawing",
        type:"pdf",
        defaultExpanded: false,
        sections: [],
        sourceKey: "modulePDF",
        filenameKey: "name"
    };
    isLoading: boolean = true;
    moduleAPromise: Promise<ModuleModel>;
    moduleBPromise: Promise<ModuleModel>;
    moduleA: ModuleModel = ModuleModel.LOADING_TEMPLATE;
    moduleB: ModuleModel = ModuleModel.LOADING_TEMPLATE;
    moduleABOM: BOMEntryModel[] = [];
    moduleBBOM: BOMEntryModel[] = [];
    attributesExpanded: boolean = true;
    moduleABOMNumbers: Set<string> = new Set<string>();
    moduleBBOMNumbers: Set<string> = new Set<string>();

    constructor(props: CompareModulesViewProps){
        super(props);

        this.moduleAPromise = ModuleService.getModuleFromNumberAndVersion(this.props.moduleANumber, this.props.moduleAVersion);
        this.moduleBPromise = ModuleService.getModuleFromNumberAndVersion(this.props.moduleBNumber, this.props.moduleBVersion);

        this.bindAll(this);
        this.loadData();
    }

    static generateHREF(moduleA: ModuleModel, moduleB: ModuleModel): string{
        return `#/${SessionHelper.getRegion()}/compare/modules/${moduleA.number}/${(moduleA.version?.split(" ")??["0.0"])[0]}/${moduleB.number}/${(moduleB.version?.split(" ")??["0.0"])[0]}`;
    }

    @measuredAsync("load_time")
    async loadData(){
        let results: ModuleModel[] = await Promise.all([this.moduleAPromise, this.moduleBPromise]);
        this.moduleA = results[0];
        this.moduleB = results[1];
        let boms: BOMEntryModel[][] = await Promise.all([BOMService.getModuleBOM(this.moduleA), BOMService.getModuleBOM(this.moduleB),]);
        let contents: ContentModel[][] = await Promise.all([ContentService.getContentForModule(this.moduleA), ContentService.getContentForModule(this.moduleB)]);

        if (!this.moduleA.modulePDF){
            let drawingDetails: ContentModel[] = contents[0];
            // Drawing URL should use the content system id
            if (drawingDetails.length > 0){
                let drawingId = drawingDetails.find((v) => v.role==="Primary")?.systemId;
                if (drawingId){
                    this.moduleA.modulePDF = `${RequestHelper.CONTENT_HOST}/module/${this.moduleA._systemId}/${drawingId}?id_token=${CookieHelper.getCookieByName(CookieNames.id_token)}`;
                    // this.moduleA.modulePDF = `${RequestHelper.HOST}/content/${drawingId}/downloadBytes`;
                }
            }
        }
        
        if (!this.moduleB.modulePDF){
            let drawingDetails: ContentModel[] = contents[1];
            // Drawing URL should use the content system id
            if (drawingDetails.length > 0){
                let drawingId = drawingDetails.find((v) => v.role==="Primary")?.systemId;
                if (drawingId){
                    this.moduleB.modulePDF = `${RequestHelper.CONTENT_HOST}/module/${this.moduleB._systemId}/${drawingId}?id_token=${CookieHelper.getCookieByName(CookieNames.id_token)}`;
                    // this.moduleB.modulePDF = `${RequestHelper.HOST}/content/${drawingId}/downloadBytes`;
                }
            }
        }

        this.moduleABOM = boms[0];//.sort((a,b) => (a.number??"").localeCompare(b.number??""));
        this.moduleBBOM = boms[1];//.sort((a,b) => (a.number??"").localeCompare(b.number??""));

        this.moduleABOMNumbers = new Set<string>(this.moduleABOM.map((b) => b.number??""));
        this.moduleBBOMNumbers = new Set<string>(this.moduleBBOM.map((b) => b.number??""));

        [this.moduleABOM, this.moduleBBOM] = CompareUtils.compareBOMs(this.moduleABOM, this.moduleBBOM) as BOMEntryModel[][];

        this.isLoading = false;
        this.dataUpdated();
    }

    get moduleAId(){
        return `${this.moduleA.number}_${this.moduleA.versionNumber}`;
    }

    get moduleBId(){
        return `${this.moduleB.number}_${this.moduleB.versionNumber}`;
    }

    getModuleId(module: ModuleModel){
        return `${module.number}_${module.versionNumber}`;
    }

    attributeExpansionChanged(){
        this.attributesExpanded = !this.attributesExpanded;
        this.dataUpdated();
    }

    private async downloadPressed(e: CustomEvent<BaseNavigationDetail>, module: ModuleModel){
        e.preventDefault(); e.stopPropagation();
        if (!e.detail.href){
            return;
        }
        if (module){
            RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("pdf_download", `${module.number} (${module.versionNumber})`));
        }
        let result: ArrayBuffer = await RequestHelper.serviceRequest(e.detail.href, {}, "array");
        
        // Get filename from the object if the key is present
        let filename: string = `${module.name}`;
        if (!filename.endsWith(".pdf")){
            filename = `${filename}.pdf`;
        }
        downloadObject(new Blob([result]), filename ?? "drawing_download.pdf");
    }

    private getPDFDownloadButton(module: ModuleModel): React.ReactNode{
        let link = module.modulePDF;
        if (!link){
            return null;
        }

        return (<Link href={link} onFollow={(e) => this.downloadPressed(e, module)} external>{module.number} ({module.versionNumber}) - PDF</Link>)
    }

    private getAttributes(module: ModuleModel, otherModule: ModuleModel): JSX.Element{
        let header = (
            <Header variant="h2">
                Attributes - {module.number} ({module.versionNumber})
            </Header>
        );

        return (
                <ExpandableSection variant="container" header={header} expanded={this.attributesExpanded} defaultExpanded={true} id={this.getModuleId(module)+"_attributes"} onChange={this.attributeExpansionChanged}>
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel hasChanged={module.name !== otherModule.name} label="Name">{module.name}</ValueWithLabel>
                            <ValueWithLabel hasChanged={module.buildingType !== otherModule.buildingType}  label="Building Type">
                                {module.buildingType?.split(",").map((b)=>
                                    <div style={otherModule.buildingType?.includes(b) ? {color:"black"} : undefined} key={b}>{b}</div>
                                )}
                            </ValueWithLabel>
                            <ValueWithLabel hasChanged={module.state !== otherModule.state} label="State">
                                <StatusIndicator type={module.statusType}>{module.stateString}</StatusIndicator>
                            </ValueWithLabel>
                            {/* <ValueWithLabel hasChanged={module.placementNotes !== otherModule.placementNotes} label="Placement Notes">{module.placementNotes??"-"}</ValueWithLabel> */}
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel hasChanged={module.checkoutState !== otherModule.checkoutState} label="Checkout Status">
                                {module.checkoutState}
                            </ValueWithLabel>
                            {/* <ValueWithLabel hasChanged={module.drawingNumber !== otherModule.drawingNumber} label="Module Drawing">
                                {module.drawingNumber ?? "-"}
                            </ValueWithLabel> */}
                            <ValueWithLabel hasChanged={module.modulePDFLink !== otherModule.modulePDFLink} label="Module PDF">
                                {module.modulePDFLink ? this.getPDFDownloadButton(module) : "Not Published"}
                            </ValueWithLabel>
                            <ValueWithLabel hasChanged={module.onComposite !== otherModule.onComposite} label="On Composite">
                                {module.onComposite}
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExpandableSection>
        );
    }

    private _getAttributes(){
        return (
            <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
                {this.getAttributes(this.moduleA, this.moduleB)}
                {this.getAttributes(this.moduleB, this.moduleA)}
            </Grid>
        );
    }

    getChangedText(text: ReactNode){
        return <Box color={"text-status-info"} fontWeight={"bold"}>{text}</Box>;
    }

    getAddedText(text: ReactNode){
        return <Box color={"text-status-info"} fontWeight={"bold"}>{text}</Box>;
    }

    getRemovedText(text: ReactNode){
        return <Box color={"text-status-inactive"} fontWeight={"bold"}>-</Box>;
    }

    getCurrencyFormatter(bomEntry: BOMEntryModel): Intl.NumberFormat{
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: bomEntry.currency ?? "USD",
        });
    }

    private formatCurrency(p: number|string, b?: BOMEntryModel): string{
        // console.log(p);
        if (p === null || b === null || p === undefined || b === undefined){
            return "-";
        }

        if (typeof p === "string"){
            return p;
        }
        
        if (!b?.currency){
            return `$${p.toFixed(2)}`;
        }

        return this.getCurrencyFormatter(b).format(p);
    }

    private getBOM(module:ModuleModel, bom: BOMEntryModel[], bomNumbers:Set<string>, otherBOMNumbers: Set<string>, otherBOM: BOMEntryModel[]){
        let temp: {[key:string]:any} = {};
        let getHasChanged = (val: number, b:BOMEntryModel, param: string) => val != null ? (otherBOMNumbers.has(b.number??"") ? ( (otherBOM.find((_b) => _b.number === b.number)??temp)[param] === b[param] ? val : this.getChangedText(val) ) :  val) : "-";

        return (
            <ItemTable 
                title={`${module.number} (${module.versionNumber})`??""} 
                items={bom} 
                initialColumnOrder={["number", "name", "quantity", "totalCost"]} 
                initiallyVisibleColumnCount={4}
                disableSearch={true}
                disableSelection={true}
                customDisplays={{
                    "priceInUSD": (val) => this.formatCurrency(val),
                    "price": this.formatCurrency,
                    "totalCost": (val, b:BOMEntryModel) => this.formatCurrency(val, b),
                    quantity: (quantity: number, b:BOMEntryModel) => getHasChanged(quantity, b, "quantity"),
                    number: (n: string) => otherBOMNumbers.has(n) ? (bomNumbers.has(n) ? n : "-") : this.getChangedText(n),
                }}
            />
        );
    }
    
    private _getBOMs(){
        return (
            <Grid gridDefinition={[{colspan: 6},{colspan: 6}]}>
                {this.getBOM(this.moduleA, this.moduleABOM, this.moduleABOMNumbers, this.moduleBBOMNumbers, this.moduleBBOM)}
                {this.getBOM(this.moduleB, this.moduleBBOM, this.moduleBBOMNumbers, this.moduleABOMNumbers, this.moduleABOM)}
            </Grid>
        );
    }

    private getDrawing(module: ModuleModel){
        return (
            <DynamicExpandableProperties key={Math.random()} object={module} template={CompareModulesView.MODULE_PDF_TEMPLATE}/>
        )
    }

    private _getDrawings(){
        return (
            <Grid gridDefinition={[{colspan: 6}, {colspan:6}]}>
                {this.getDrawing(this.moduleA)}
                {this.getDrawing(this.moduleB)}
            </Grid>
        )
    }

    render(){
        return (
            <>
                <Header variant="h1">Compare: {`${this.props.moduleANumber} (${this.props.moduleAVersion}) & ${this.props.moduleBNumber} (${this.props.moduleBVersion})`}</Header>
                <SpaceBetween size="s" direction="vertical">
                    {this._getAttributes()}
                    {this._getBOMs()}
                    {this._getDrawings()}
                </SpaceBetween>

                {/* <ColumnLayout columns={2}>
                    <SpaceBetween size="l">
                        {this.getAttributes(this.moduleA, this.moduleB)}
                        
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        {this.getAttributes(this.moduleB, this.moduleA)}

                    </SpaceBetween>
                </ColumnLayout> */}
                <div style={{height:"12px"}}/>
            </>
        );
    }
}