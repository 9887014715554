import ModuleModel from './module_model';
import CustomQuoteModel from './custom_quote_model';
import { SiteBaseModel } from './site_base_model';

//This class represents fragment of Link Collection : Model -> Structure -> bom -> attributes -> bomItem
export default class BomModel extends SiteBaseModel {
    quantity?: number;
    bomItem?: SiteBaseModel;

    constructor(json: any) {
        super(json);
        this.init(json);

        if (json['modelType'] && json['modelType'] === 'ModuleListLink' && json['attributes']['bomItem']) {
            this.bomItem = new ModuleModel(json['attributes']['bomItem']);
        }

        if (
            json['modelType'] &&
            (json['modelType'] === 'NonModuleListLink' || json['modelType'] === 'NonModuleProposalLink') &&
            json['attributes']['bomItem']
        ) {
            this.bomItem = new CustomQuoteModel(json['attributes']['bomItem']);
        }
    }

    protected resolveStructureKey(key: string, json: any): SiteBaseModel[] {
        return [];
    }
}
