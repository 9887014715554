import axios, { AxiosResponse, AxiosRequestConfig } from 'axios';
import { ShieldProjectModel } from '../../pages/site/create_site';
import { CookieHelper, SessionHelper } from '../../modules';

export default class ShieldIntegrationAPIService {
    static async fetchProjectInfo(
        porYear: string,
        businessUnit: string,
        facilityName: string,
    ): Promise<ShieldProjectModel[]> {

    const client = axios.create({
            baseURL: SessionHelper.getShieldAPIInstance(),
        });

        const config: AxiosRequestConfig = {
            headers: {
                Accept: 'application/json',
                Authorization: `${CookieHelper.IdToken}`,
            },
        };

    const queryString = "query= SELECT Id,Name,Launch_Year__c,Requestor_Org__c,POR_Site_Code__c,Facility_Type_Short__c,Design_Type__c,Facilty_Name__c, Project_Stage__c, Site__r.Country__c, Site__r.Region__c FROM Project__c where Launch_Year__c='"+porYear+"' and Requestor_Org__c = '"+businessUnit+"' and Name like'%2525"+facilityName+"%2525'";
        const response: AxiosResponse = await client.get(`/objects?${queryString}`, config);
    let responseEntry : ShieldProjectModel[] = response.data.records.map((item: any) =>({
            buildingType: item.Design_Type__c,
            facilityType: item.Facility_Type_Short__c,
            facilityName: item.Facilty_Name__c,
            shieldId: item.Id,
            projectName: item.Name,
            porYear: item.Launch_Year__c,
            region: item.Site__r ? item.Site__r.Region__c : "",
            projectStatus: item.Project_Stage__c,
            program: item.Requestor_Org__c,
            country: item.Site__r ? item.Site__r.Country__c : ""
        }));
        console.log(response);
        return responseEntry;
    }
}
