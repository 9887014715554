import React from 'react';
import SiteModel from '../../../data/site/site_model';
import TuxView from '../../../components/tux_view';
import { Alert, SpaceBetween } from '@amzn/awsui-components-react-v3';
import DynamicExpandableProperties, { KeyValueTemplate } from '../../../components/dynamic_expandable_properties';

const SITE_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Name', key: 'name' },
            { title: 'Shield ID', key: 'shieldId' },
            { title: 'Region', key: 'region' },
            { title: 'Country', key: 'country' },
            { title: 'POR Year', key: 'porYear' }
        ],
        [
            { title: 'Program', key: 'program' },
            { title: 'Building Type', key: 'buildingType' },
            { title: 'Facility Type', key: 'facilityType' },
            { title: 'Facility Name', key: 'facilityName' }
        ],
        [
            { title: 'Project Number', key: 'projectNumber' },
            {
                title: 'Project Status',
                key: 'projectStatus',
                type: 'status',
                states: {
                    Active: 'active',
                    Completed: 'success',
                    'On-Hold': 'pending',
                    Cancelled: 'stopped',
                    default: 'pending'
                }
            },
            { title: 'First Receive', key: 'firstReceive', type: 'date' }
        ]
    ]
};

const SITE_SYSTEM_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'System',
    type: 'key-value',
    defaultExpanded: false,
    sections: [
        [
            { title: 'Created By', key: 'createdBy' },
            { title: 'Created On', key: 'createdOn', type: 'datetime' }
        ],
        [
            { title: 'Modified By', key: 'modifiedBy' },
            { title: 'Last Modified', key: 'lastModified', type: 'datetime' }
        ]
    ]
};

const TEMPLATES: KeyValueTemplate[] = [SITE_ATTR_TEMPLATE, SITE_SYSTEM_ATTR_TEMPLATE];

interface SiteDetailsViewProps {
    sitePromise: Promise<SiteModel>;
}

export default class SiteDetailsView extends TuxView<SiteDetailsViewProps, any> {
    isLoading = true;
    site: SiteModel;

    constructor(props: SiteDetailsViewProps) {
        super(props);
        this.site = SiteModel.LOADING_TEMPLATE;
        this.bindAll(this);
        this.loadData();
    }

    public async loadData(): Promise<void> {
        this.site = await this.props.sitePromise;
        this.isLoading = false;
        this.dataUpdated();
    }

    render() {
        if (!this.site && !this.isLoading) {
            return <Alert type="error" header="Failed to get the specified site"></Alert>;
        }

        return (
            <SpaceBetween size="m">
                {TEMPLATES.map(template => (
                    <DynamicExpandableProperties key={Math.random()} object={this.site} template={template} />
                ))}
            </SpaceBetween>
        );
    }
}
