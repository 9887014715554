/* eslint no-eval: 0 */
import React from 'react';
import {TuxView} from '../components/components';
// import {Socket, io} from "socket.io-client";
// import { Editor } from "react-draft-wysiwyg";
// import Draft from "draft-js";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

// const OPERATION_TEMP = {
//     type:"operation",
//     code: "let result = '';",
// };

// interface Template {
//     type: "operation" | "display" | "request"; // Request fetches data and passes it to next step, display is end result
//     displayType?: "key-value" | "status" | "datetime";
//     requestPrefix?: string;
//     requestSuffix?: string;
//     objectKey?: string; // Some key to pull from object (result of previous operation(or initial object if first step) or request)
//     operation?: string;
// }

// const TEMPLATES: any[] = [

// ];

interface TestViewProps{

}

export default class TestView extends TuxView<TestViewProps, any>{
    static DEFAULT_OPERATIONS: string = 'let result = "";\n';

    private executionResults: string = "";
    private getCookiesResult: string = "";
    
    private operations: string = TestView.DEFAULT_OPERATIONS;
    // private socket?: Socket;
    // private socketText: string = "";
    // private editorState: Draft.EditorState = Draft.EditorState.createEmpty();
    // private contentState: Draft.RawDraftContentState = Draft.convertToRaw(Draft.ContentState.createFromText(""));
    private textCounterText: string = "";
    
    constructor(props: TestViewProps){
        super(props);
        this.bindAll(this);
        this.loadData();
    }

    async loadData(){
        // console.log("In loadData");
        // this.socket = io("http://localhost:3001");
        // this.socket.send("TEST");
        // this.socket.on("message", this.onSocketUpdate);

        // window.addEventListener("close", (e) => {
        //     e.preventDefault();
        //     this.socket?.disconnect();
        // })
    }

    // onSocketUpdate(e: ({content: string}) ){
    //     this.socketText = e.content;
    //     console.log(this.socketText);

    //     this.updateFromString(e.content);
    // }

    // componentWillUnmount(){
    //     super.componentWillUnmount();
    //     this.socket?.disconnect();
    // }

    // private operationsChanged(e: any){
    //     if (e.detail.value.length < TestView.DEFAULT_OPERATIONS.length){
    //         return;
    //     }
    //     this.operations = e.detail.value;
    //     this.dataUpdated();
    // }

    // private executeOperations(){
    //     let temp: any = "NO RESULTS";
    //     try{
    //         temp = eval(this.operations);
    //     }catch(e){
    //         temp = `${e}`;
    //     }
    //     this.executionResults = JSON.stringify(temp);
    //     this.dataUpdated();
    // }

    // private async getCookiesPressed(){
    //     this.getCookiesResult = "ID Data: " + JSON.stringify(JSON.parse(atob(CookieHelper.getCookieByName(CookieNames.id_token).split('.')[1])), null, 4);
    //     this.dataUpdated();
    // }
    
    // private updateFromString(contentString: string, update: boolean = true){

    //     // let contentJSON = JSON.parse(contentString);
    //     // let content: Draft.ContentState = Draft.convertFromRaw(contentJSON);
    //     // this.editorState = Draft.EditorState.push(this.editorState, content, "insert-characters");
    //     if (update){
    //         this.dataUpdated();
    //     }

    // }

    // private findContentDifferenceKeys(contentA: Draft.RawDraftContentState, contentB: Draft.RawDraftContentState): Set<string>{
    //     let output: Set<string> = new Set<string>();

    //     let content: ({[key:string]: string}) = {};

    //     for (let block of contentA.blocks){
    //         content[block.key] = block.text
    //     }

    //     return output;
    // }

    // private editorChanged(e: Draft.EditorState){

    //     if (e.getCurrentContent() !== this.editorState.getCurrentContent()){

    //         // let contentString: string = JSON.stringify(Draft.convertToRaw(e.getCurrentContent()));
    //         // console.log(contentString);
    
    //         // this.updateFromString(contentString, false);

    //         // this.socket?.send({"content": contentString});

    //     }

    //     this.editorState = e;
    //     // let content: Draft.ContentState = Draft.convertFromRaw(JSON.parse(contentString));
    //     // this.editorState = Draft.EditorState.push(this.editorState, content, "insert-characters");
    //     // this.editorState = Draft.EditorState.acceptSelection(this.editorState, e.getSelection());

    //     // console.log(Draft.convertToRaw(e.getCurrentContent()));

        
    //     this.dataUpdated();
    // }

    // private textCounterTextChanged(e: NonCancelableCustomEvent<BaseChangeDetail>){
    //     this.textCounterText = e.detail.value;
    //     this.dataUpdated();
    // }

    render(){
        if (process.env.NODE_ENV !== 'production'){
            return (
                <>
                    {/* <Header id="test">Test</Header>
                    <SpaceBetween size="m" direction="vertical"> */}
                        {/* <Grid gridDefinition={[{colspan: 2}, {colspan:5,}, {colspan:5,}]}>
                            <div style={{width:"100%", height:"100%", textAlign:"center", top:"50%"}}>
                                <Button onClick={this.executeOperations}>
                                    Execute
                                </Button>
                            </div>
                            <Textarea value={this.operations} placeholder="Insert code here" onChange={this.operationsChanged}/>
                            <Textarea value={this.executionResults} placeholder="Results will display here" disabled={true}/>
                        </Grid>
                        <Grid gridDefinition={[{colspan: 2}, {colspan:10}]}>
                            <div style={{width:"100%", height:"100%", textAlign:"center", top:"50%"}}>
                                <Button onClick={this.getCookiesPressed}>
                                    Get Cookies
                                </Button>
                            </div>
                            <Textarea value={this.getCookiesResult} placeholder="Results will display here" disabled={true}/>
                        </Grid> */}
                        {/* <Grid gridDefinition={[{colspan: 3}, {colspan:9}]}>
                            <div>
                                Character Count: {this.textCounterText.length}
                                <br/>
                                Local Storage Used: {JSON.stringify(localStorage).length}
                                {Object.keys(localStorage).map((key) => {
                                    return (<div> {key}: {JSON.stringify(localStorage.getItem(key)).length} </div>);
                                })}
                            </div>
                            <Textarea value={this.textCounterText} onChange={this.textCounterTextChanged} placeholder="Count Characters" />
                        </Grid>
                        <ExpandableSection variant="container" header={<Header>Document</Header>} defaultExpanded disableContentPaddings>
                            <Container disableContentPaddings> */}
                                {/* <Editor
                                    editorState={this.editorState}
                                    // contentState={this.contentState}
                                    onEditorStateChange={this.editorChanged} 
                                    // onContentStateChange={this.contentChanged} 
                                /> */}
                            {/* </Container>
                        </ExpandableSection>
                    </SpaceBetween> */}
                </>
            );
        }
        return <></>;
    }
}