export default class PreferenceModel {
    [key: string]: any;
    _systemId?: string;
    name?: string;
    value?: [string] | string;
    multiValue?: boolean;

    constructor(json: any) {
        this.init(json);
    }
    protected init(json: any) {
        for (let key of Object.keys(this)) {
            if (json[key]) {
                this[key] = json[key];
            }
        }
    }
    public static LOADING_TEMPLATE: PreferenceModel = new PreferenceModel({
        systemId: 'Loading...',
        name: 'Loading...',
        value: 'Loading...',
        multiValue: false,
 
    });
}