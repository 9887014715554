import UINModel from '../uin/uin_model';

export class CostBreakdown {
    [key: string]: any;
    totalCost:number = 0;
    carTabs: {[key:string]:number} = {};

    static fromJSON(json: any): CostBreakdown {
        let output = new CostBreakdown();
        let breakdown : any = json.costBreakdown ?? {};
        output.totalCost = json.totalCost ?? 0;

        for (let key of Object.keys(breakdown)){
            let outputKey = key;

            if (key.startsWith("UIN - ")){
                outputKey = key.split(" - ")[1];
            }

            output.carTabs[outputKey] = breakdown[key];
        }

        return output;
    }
}

export default class BOMEntryModel extends UINModel {
    [key: string]: any;

    quantity?: number;
    unit?: string;
    totalCost?: number;

    _moduleSsytemId?: string;
    _systemId?: string;
    _uinLinkId?: string;
    _uinSystemId?: string;

    static fromJSON(json: any) : BOMEntryModel{
        let output = new BOMEntryModel();

        let bomKeys = Object.keys(output);
        // console.log("BOM Keys: " + JSON.stringify(bomKeys));

        let keySet: Set<string> = new Set(bomKeys);
        let uinItem = json.uinItem;

        // Copy over the values of they keys present in a BOMEntryModel;
        for (let key of bomKeys){
            output[key] = json[key] ?? uinItem[key];
        }

        for (let key of Object.keys(json).concat(Object.keys(uinItem))){
            if (key === "uinItem"){
                continue;
            }
            // Ignore keys already present in BOMEntryModel
            if (!key.startsWith("_") && keySet.has(key)){
                // console.log("Ignoring: " + key + " already: " + output[key]);
                continue;
            }
            // console.log(`Setting _${key} to ${JSON.stringify(json[key])}`)
            output["_"+key] = json[key] ?? uinItem[key];
        }

        return output;
    }
}