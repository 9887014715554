import ModuleModel from './module_model';
import ModuleDesignGuideModel from './module_design_guide_model';

export default class ModuleDesignGuidePlacementModel {
    [key: string]: any;
    _systemId?: string;
    floorLevel?: string;
    moduleDeployedArea?: string;
    moduleDeployedProcess?: string;
    placementNotes?: string;
    totalQuantityDeployed?: string;
    supportDocuments?: string;
    module?: ModuleModel;
    siteTemplate?: ModuleDesignGuideModel;

    constructor(json: any) {
        Object.assign(this, json);
        this.init(json);
    }

    protected init(json: any) {
        for (const key of Object.keys(this)) {
            {
                if (key.startsWith('_')) {
                    if (json[key.substring(1)]) {
                        this[key] = json[key.substring(1)];
                    }
                } else {
                    if (json[key]) {
                        this[key] = json[key];
                    }
                }
            }
        }
    }
}
