import React from 'react';
import NonModuleListModel from '../../../data/site/non_module_list_model';
import ItemTable from '../../../components/item_table';
import BomModel from '../../../data/site/bom_model';

export class BomItem {
    number?: string;
    name?: string;
    desc?: string;
    quantity?: number;
    price?: string;
    carTab?: string;
    carLine?: string;
    partNumber?: string;
    supplier?: string;
    uin?: string;
}

export function flattenNML(bomModel: BomModel[]): BomItem[] {
    return bomModel?.map(
        (customQuote) =>
            ({
                number: customQuote.bomItem?.number,
                name: customQuote.bomItem?.name,
                desc: customQuote.bomItem?.desc,
                quantity: customQuote.quantity,
                price: customQuote.bomItem?.price.value,
                carTab: customQuote.bomItem?.carTab,
                carLine: customQuote.bomItem?.carLine,
                partNumber: customQuote.bomItem?.partNumber,
                supplier: customQuote.bomItem?.supplier,
                uin: customQuote.bomItem?.uin,
            }) as BomItem,
    );
}

export const NonModuleListStructureView = ({ nonModuleList }: { nonModuleList: NonModuleListModel }) => {
    const bomModel: BomModel[] = nonModuleList._bom ?? [];
    const bomItems: BomItem[] = flattenNML(bomModel);

    function getCurrencyFormatter(bomEntry: BomModel): Intl.NumberFormat {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: bomEntry.currency ?? 'USD',
        });
    }

    function formatCurrency(p: number | string, b?: BomModel): string {
        if (p === null || b === null || p === undefined) {
            return '-';
        }

        if (typeof p === 'string') {
            return p;
        }

        if (!b?.currency) {
            return `$${p.toFixed(2)}`;
        }

        return getCurrencyFormatter(b).format(p);
    }

    return (
        <ItemTable
            title={`BOM - ${nonModuleList.number}`}
            items={bomItems}
            customDisplays={{
                price: (val) => formatCurrency(val),
            }}
            customWidths={{
                name: 300,
            }}
            initiallyVisibleColumnCount={9}
            disableSelection={true}
            canExport
        />
    );
};
