import PreferenceModel from './preference_model'
import {getPreferenceByCriteria, getPreferencesQuery} from './gql/queries'
import { preferenceGQL } from '../../utils/apollo_clients';
import { ApolloQueryResult } from '@apollo/client';

interface PreferenceServiceResponse{
    preferences: any[]
}

export default class PreferenceService{
    static async fetchPreferences(): Promise<PreferenceModel[]>{
        const query = getPreferencesQuery();
        let response: ApolloQueryResult<PreferenceServiceResponse> = await preferenceGQL.query({
            query: query
        });
        return response.data.preferences.map(m => new PreferenceModel(m));
    }

    static async fetchPreferenceByName(name: String): Promise<PreferenceModel>{
        const query = getPreferenceByCriteria();
        let response: ApolloQueryResult<PreferenceServiceResponse> = await preferenceGQL.query({
            query: query,
            variables: { criteria:{and: [{where: "name", is: name}]}},
        });
        return new PreferenceModel(response.data.preferences[0]);
    }
}