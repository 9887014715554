import { Alert, AlertProps, Header, StatusIndicator} from '@amzn/geist-ui-components';
import Box from '@amzn/meridian/box';
import ItemTable from '../../components/item_table';
import React from 'react';
import TuxComponent from '../../components/tux_view';
import SessionHelper from '../../utils/session_helper';
import ModuleDesignGuideModel from '../../data/module/module_design_guide_model';
import ModuleDesignGuideService from '../../data/module/module_design_guide_service';

interface ModuleDesignGuidesViewProps {}
const PAGE_SIZE = 100;

class ModuleDesignGuidesView extends TuxComponent<ModuleDesignGuidesViewProps, any> {
    private moduleDesignGuides: ModuleDesignGuideModel[] = [];
    private isLoading = false;
    private alertType: AlertProps.Type = 'info';
    private isAlertVisible = false;
    private alertMessage = '';

    constructor(props: ModuleDesignGuidesViewProps) {
        super(props);
        this.region = SessionHelper.getRegion();
        this.customDisplays = {
            name: (n: string, item: ModuleDesignGuideModel) => (
                <a
                    href={`#/${SessionHelper.getRegion()}/moduleDesignGuide/${item._systemId}/details`}
                    style={{ textDecoration: 'none' }}
                >
                    {n}
                </a>
            ),
        };
        this.bindAll(this);
        this.loadData();
    }
    async loadData() {
        this.isLoading = true;
        this.dataUpdated();
        //get pages number based on mdg count (always round up)
        const moduleDesignGuidesCount = await ModuleDesignGuideService.getModuleDesignGuidesCount();
        //get pages number based on mdg count (always round up)
        const pages = Math.ceil(moduleDesignGuidesCount / PAGE_SIZE);
        //build page promises
        const pagesToLoad: Promise<void>[] = [];

        for (let i = 0; i < pages; i++) {
            pagesToLoad.push(this.pageLoadPromise(i));
        }

        //load all pages async
        Promise.all(pagesToLoad).then(() => {
            this.useCounterSuffix = false;
            this.dataUpdated();
        });
    }

    async pageLoadPromise(page: number): Promise<void> {
        const chunk = await ModuleDesignGuideService.getAllModuleDesignGuides(this.region, page, PAGE_SIZE);
        this.moduleDesignGuides.push(...chunk);
        this.isLoading = false;
        this.dataUpdated();
    }

    private alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    render() {
        return (
            <div>
                <Header variant="h1">Building Standards - {this.region}</Header>
                <Box spacingInset={this.isAlertVisible ? '400 0' : '0'}>
                    <Alert
                        alertType={this.alertType}
                        visible={this.isAlertVisible}
                        dismissible={true}
                        onDismiss={this.alertDismissed}
                        header={this.alertMessage}
                    />
                </Box>
                <ItemTable
                    title={`Module Design Guides`}
                    items={this.moduleDesignGuides}
                    isLoading={this.isLoading}
                    customDisplays={this.customDisplays}
                    customWidths={{
                        region: 105,
                    }}
                    initiallyVisibleColumnCount={9}
                    initialColumnOrder={[
                        'name',
                        'buildingType',
                        'gen',
                        'floors',
                        'capacityType',
                        'capacityDescription',
                        'country',
                        'state',
                        'systemId',
                    ]}
                    defaultSortBy="name"
                    canExport
                />
            </div>
        );
    }
}
export default ModuleDesignGuidesView as React.ComponentType;
