import { SiteBaseModel } from './site_base_model';
import BomModel from './bom_model';

export default class NonModuleListModel extends SiteBaseModel {

    state?: string;
    _bom?: BomModel[];

    constructor(json: any) {
        super(json);
        this.init(json);
    }

    protected resolveStructureKey(key: string, json: any): SiteBaseModel[] {
        if(key.includes('_bom') || key.includes("bom")){

            let result: BomModel[] = [];

            json.map((bomJson: any) => result.push(new BomModel(bomJson)))

            return result;
        }

        return [];
    }

    public static LOADING_TEMPLATE: NonModuleListModel = new NonModuleListModel({
        systemId: 'Loading...',
        name: 'Loading...',
        number: 'Loading...',
        version: {
            version: 'Loading...',
            iteration: 'Loading...'
        },
        attributes: {
            state: 'Loading...',            
        }
    });

    get stateString(): string{
        let stateMap: {[key:string]:string} = {
            "RELEASED": "Released",
            "APPROVED": "Approved",
            "INWORK": "In Work",            
            "CLOSED": "Closed",            
            "UNDERREVIEW": "Under Review",
            "CONCEPT": "Concept",
            "PENDINGAPPROVAL": "Pending Approval",
        }
        return stateMap[this.state??""] ?? this.state;
    }
    
    get displayVersion(): string{
        return `${this._version?.version}.${this._version?.iteration}`;
    }

    get displayVersionWithState(): string{
        return `${this.displayVersion} - ${this.stateString}`;
    }
}