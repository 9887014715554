import { StatusIndicatorProps } from '@amzn/awsui-components-react';

export default class UINModel {
    [key: string]: any;

    number?: string;
    name?: string;
    version?: string;
    active?: boolean;
    price?: number;
    priceInUSD?: number;
    region?: string;
    carTab?: string;
    carline?: string;
    createdBy?: string;
    description?: string;
    modifiedBy?: string;
    partNumber?: string;
    scopeThreeCarbon?: string;
    supplier?: string;
    type?: string;

    _systemId?: string;
    _uinId?: string;
    _createdOn?: string;
    _lastModified?: string;
    _currency?: any;

    get activeType(): StatusIndicatorProps.Type {
        return this.active ? 'success' : 'error';
    }

    get currency(): string {
        return this._currency?.symbol ?? '';
    }

    get lastModified(): Date {
        return this._lastModified ? new Date(this._lastModified) : new Date();
    }

    get createdOn(): Date {
        return this._createdOn ? new Date(this._createdOn) : new Date();
    }

    static fromJSON(json: any): UINModel {
        const output = new UINModel();

        const moduleKeys = Object.keys(output);
        const keySet: Set<string> = new Set(moduleKeys);

        // Copy over the values of they keys present in a UINModel;
        for (const key of moduleKeys) {
            output[key] = json[key];
        }

        for (const key of Object.keys(json)) {
            // Ignore keys already present in UINModel
            if (!key.startsWith('_') && keySet.has(key)) {
                // console.log("Ignoring: " + key);
                continue;
            }
            // console.log(`Setting _${key} to ${JSON.stringify(json[key])}`)
            output['_' + key] = json[key];
        }

        return output;
    }
}
