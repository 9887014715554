import React, { createRef, ReactNode } from 'react';
import {TuxComponent, ValueWithLabel} from './components';
import { ColumnLayout, ExpandableSection, Header, Link, SpaceBetween, StatusIndicator, StatusIndicatorProps } from '@amzn/awsui-components-react-v3';
import { Page, Document, pdfjs } from 'react-pdf';
import { downloadObject, formatFromCamelCase } from '../utils/tools';
import { EventDispatcher, EventTypes } from '../modules';
import { BaseNavigationDetail } from '@amzn/awsui-components-react-v3/polaris/internal/events';
import RequestHelper, { ActionDetails, AnalyticsRoutes } from "../utils/request_helper";
import { PDF_WORKER_URL } from '../utils/constants';
pdfjs.GlobalWorkerOptions.workerSrc = PDF_WORKER_URL;

export interface KeyValueTemplate{
    title: string;
    type: "key-value" | "pdf";
    /** Default: false */
    defaultExpanded?: boolean;
    /** empty array if pdf */
    sections: KeyValueSection[][];
    /** pdf only */
    sourceKey?: string;
    filenameKey?: string;
}

export interface KeyValueSection{
    title: string;
    /**
     * Key that will be used to index the object for the attribute
     */
    key: string;
    /**Default: key-value */
    type?: "key-value" | "status" | "new-line-separated" | "date" | "datetime",// | "time",
    
    /** Default: false */
    defaultExpanded?: boolean;
    separator?: string;
    sorted?: boolean;
    states?: StatusMap;
}

export interface StatusMap{
    [key: string]: StatusIndicatorProps.Type | 'active';
    default: StatusIndicatorProps.Type;
    
}

//
// Regular functionality below
//

interface DynamicExpandablePropertiesProps{
    template: KeyValueTemplate;
    object: any;
    onExpansionChanged?: (isExpanded: boolean) => void;
}

export default class DynamicExpandableProperties extends TuxComponent<DynamicExpandablePropertiesProps, any>{
    private static INSTANCE_COUNTER: number = 0;
    private static keyCounter = 0;
    private static dateFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
    private static dateTimeFormatter = new Intl.DateTimeFormat('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:"2-digit", minute:"2-digit", second:"2-digit" });
    private _id: string;
    private hasLoadedPDF: boolean = false;
    private loadDrawing: boolean = this.template.defaultExpanded === true;
    private pdfPageCount: number = 0;
    private isExpanded: boolean = this.props.template.defaultExpanded??false;
    private pdfFile: any = {};
    // private resizeDelayTimer?: number;
    private pdfWidth?: number;
    private pdfRefs: React.RefObject<any>[] = [];

    constructor(props: DynamicExpandablePropertiesProps){
        super(props);
        this._id = `DynExProp_${DynamicExpandableProperties.INSTANCE_COUNTER++}`;
        this.bindAll(this);
        this.loadData();
        if(props.template.type==="pdf"){
            window.addEventListener("resize", this.onWindowResize);
            EventDispatcher.subscribe(EventTypes.SideNavExpansionChange, this._id, this.onWindowResize);
        }
    }

    componentWillUnmount(){
        super.componentWillUnmount();
        if (this.props.template.type === "pdf"){
            EventDispatcher.unsubscribe(EventTypes.SideNavExpansionChange, this._id);
        }
    }

    onWindowResize(e: any){
        if (this.resizeDelayTimer){
            // console.log("Clearing timeout", this.resizeDelayTimer);
            window.clearTimeout(this.resizeDelayTimer);
            this.resizeDelayTimer = undefined;
        }
        // this.pdfWidth = undefined;
        this.resizeDelayTimer = window.setTimeout(this.resizeCallback, 100);
    }

    resizeCallback(){
        // for (let ref of this.pdfRefs){
        //     console.log(ref.current);
        // }
        this.dataUpdated();
    }

    async loadData(){
        // console.log("In loadData");
    }

    get template(){
        return this.props.template;
    }

    get object(){
        return this.props.object;
    }

    get sections(): KeyValueSection[][]{
        if (this.template.sections.length === 0){
            // If no sections, generate them from all usable keys
            let output: KeyValueSection[][] = [[],[],[]];
            if (!this.object){
                return output;
            }
            
            let keys: string[] = Object.keys(this.object).filter((k) => !k.startsWith("_"));

            for (let i = 0; i<keys.length; i++){
                let key = keys[i];
                let title = formatFromCamelCase(key);
                let temp: KeyValueSection = {title: title, key:key};
                output[i%3].push(temp);
            }

            return output;
        }

        return this.template.sections;
    }

    private getRow(item: KeyValueSection): JSX.Element{
        let title = item.title;

        if (!this.object || !this.object[item.key]){
            return (
                <ValueWithLabel key={`empty_${DynamicExpandableProperties.keyCounter++}`} label={title}>
                    -
                </ValueWithLabel>
            );
        }

        let value: any = this.object ? this.object[item.key] : "-";
        let state = item.states? item.states[value] ?? item.states.default : "error";

        if (item.type === "status"){
            //for Active status, OOTB StatusIndicator types are not fitting the requirement. Need to override little bit.
            value = (
                <StatusIndicator 
                        type={state === 'active' ? 'success' : state}
                        colorOverride={state === 'active' ? 'blue' : undefined}>
                    {value}
                </StatusIndicator>);
        } else if (item.type === "new-line-separated"){
            let valueArray = `${value}`.split(item.separator??",");
            if (item.sorted){
                valueArray.sort();
            }
            value = valueArray.map((b)=><div key={b}>{b}</div>);
        } else if (value.length > 1 && (item.type === "date") ){
            value = DynamicExpandableProperties.dateFormatter.format(new Date(value));
        } else if (item.type === "datetime"){
            value = DynamicExpandableProperties.dateTimeFormatter.format(new Date(value));
        }
        
        return (
            <ValueWithLabel key={`${title}_${DynamicExpandableProperties.keyCounter++}`} label={title}>
                {value}
            </ValueWithLabel>
        );
    }

    private getColumn(section: KeyValueSection[]): JSX.Element{
        return (
            <SpaceBetween key={`spacebetween_${DynamicExpandableProperties.keyCounter++}`} size="l">
                {section.map((item) => this.getRow(item))}
            </SpaceBetween>
        );
    }

    private getKeyValue(): JSX.Element{
        return (
            <ColumnLayout columns={this.sections?.length??0} variant="text-grid">
                {this.sections.map((section) => this.getColumn(section))}
            </ColumnLayout>
        );
    }

    private getPDF(): ReactNode{
        if (!this.loadDrawing){
            return "This module doesn't have an associated published PDF";
        }
        return this.getDrawingPDF();
    }

    private pdfLoaded(pdf: any){
        if (this.props.template.filenameKey){
            RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("pdf_loaded", this.object[this.props.template.filenameKey]));
        }
        // Add this to prevent infinte reload loop
        // if (this.pdfPageCount === 0){
        // }
        this.pdfPageCount = pdf.numPages;
        this.dataUpdated();
    }

    private getDrawingPDF(): React.ReactNode {
        let link = (this.template.sourceKey && this.object) ? this.object[this.template.sourceKey] : null;
        if (!this.loadDrawing || !link){
            if (process.env.NODE_ENV !== 'production'){
                console.log("(DEBUG)", link);
            }
            return "This module doesn't have an associated published drawing.";
        }

        if (!this.pdfFile["url"]){
            this.pdfFile = {url: link,};// httpHeaders: {Authorization: CookieHelper.getCookieByName(CookieNames.id_token)}};
        }

        if (!this.loadDrawing){
            return null;
        }

        let newWidth = document.getElementById(this._id)?.getElementsByClassName("react-pdf__Document")[0]?.clientWidth;
        if (newWidth === 0){
            newWidth = undefined;
        }
        // Get the first document child element, all PDFs should be the same width so just use the first
        this.pdfWidth = this.pdfWidth !== newWidth ? (newWidth ?? this.pdfWidth) : newWidth;

        return (
            <Document file={this.pdfFile} onLoadSuccess={this.pdfLoaded} onLoadError={console.log}>
                {
                    Array.from(
                        new Array(this.pdfPageCount),
                        (_, index) => {
                            if (!this.pdfRefs[index]){
                                this.pdfRefs[index] = createRef();
                            }
                            return <div id={`${this._id}_${index}`} style={{maxWidth:"100%"}} key={`no_click_${index}`} onClick={(e) => {e.stopPropagation(); e.preventDefault();}}>
                                <Page
                                    ref={this.pdfRefs[index]}
                                    width={this.pdfWidth}
                                    key={`page_${index}`}
                                    pageNumber={index + 1}
                                />
                            </div>;
                        }
                    )
                }
            </Document>
        );
    }

    private async downloadPressed(e: CustomEvent<BaseNavigationDetail>){
        e.preventDefault(); e.stopPropagation();
        if (!e.detail.href){
            return;
        }
        if (this.props.template.filenameKey){
            RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("pdf_download", this.object[this.props.template.filenameKey]??window.location.href));
        }
        let result: ArrayBuffer = await RequestHelper.serviceRequest(e.detail.href, {}, "array", false);
        
        // Get filename from the object if the key is present
        let filename: string = `${(this.template.filenameKey && this.object) ? this.object[this.template.filenameKey] : null}`;
        if (!filename.endsWith(".pdf")){
            filename = `${filename}.pdf`;
        }
        downloadObject(new Blob([result]), filename ?? "drawing_download.pdf");
    }

    private getPDFDownloadButton(): React.ReactNode{
        let link = (this.template.sourceKey && this.object) ? this.object[this.template.sourceKey] : null;
        if (!link){
            return null;
        }

        return (<Link href={link} onFollow={this.downloadPressed}>Download</Link>)
    }

    private onExpand(isExpanded: boolean){
        if (this.props.onExpansionChanged){
            this.props.onExpansionChanged(isExpanded);
        }
        if (!this.object){
            this.isExpanded = isExpanded;
            this.dataUpdated();
            return;
        }
        this.loadDrawing = (!!this.object[this.template.sourceKey??""]) && (this.loadDrawing || isExpanded);
        this.hasLoadedPDF = !!this.object[this.template.sourceKey??""];
        this.isExpanded = isExpanded;
        this.dataUpdated();
    }

    private getContent(): React.ReactNode{
        if (this.template.type === "key-value"){
            return this.getKeyValue();
        } else if (this.template.type === "pdf"){
            return this.getPDF();
        }
    }

    render(){
        let header = (
            <Header variant={"h2"}>
                {this.template.title}&nbsp;{this.getPDFDownloadButton()}
            </Header>
        );
        return (
            // <div id={this._id}>
                <ExpandableSection id={this._id} key={`ES_${DynamicExpandableProperties.keyCounter}`} variant="container" expanded={this.isExpanded} header={header} defaultExpanded={this.template.defaultExpanded??false} onChange={(e) => this.onExpand(e.detail.expanded)}>
                    { this.getContent() }
                </ExpandableSection>
            // </div>
        );
    }
}