import React from 'react';
import TuxComponent from '../../components/tux_view';
import SessionHelper from '../../utils/session_helper';
import { LibraryModel, ModuleModel, ModuleService } from '../../data/data';
import { Link } from '@amzn/awsui-components-react';
import ItemTable, { CustomDisplayProps, ItemTableController } from '../../components/item_table';
import CompareModulesView from '../../pages/compare/compare_modules_view';
import { MODULES_TABLE_PREFS_CACHE } from '../../utils/constants';
import { downloadObject } from '../../utils/tools';
import RequestHelper from '../../utils/request_helper';
import { AnalyticsRoutes, ActionDetails } from '../../utils/request_helper';
import { Alert, StatusIndicator, Header } from '@amzn/geist-ui-components';
import Box from '@amzn/meridian/box';

const PAGE_SIZE = 2000;

interface ModulesViewProps {}

export default class ModulesView extends TuxComponent<ModulesViewProps, any> {
    private initiallyVisibleColumns: string[] = [
        'number',
        'name',
        'version',
        'buildingType',
        'state',
        'onComposite',
        'country',
    ];
    modules: ModuleModel[] = [];
    library: LibraryModel | null = null;
    isLoading = true;
    region: string;
    customDisplays: CustomDisplayProps;
    isAlertVisible = false;
    useCounterSuffix = true;
    alertHeader = 'You need to select 2 modules in order to compare.';
    controller: ItemTableController = new ItemTableController();

    constructor(props: ModulesViewProps) {
        super(props);
        this.region = SessionHelper.getRegion();

        this.customDisplays = {
            lastModified: (d: Date) => new Date(d).toLocaleDateString(),
            createdOn: (d: Date) => new Date(d).toLocaleDateString(),
            number: (n: string, item: ModuleModel) => (
                <Link href={`#/${this.region}/modules/${n}/${item.version?.split(' ')[0]}/details`}>{n}</Link>
            ),
            state: (state: string, item: ModuleModel) => (
                <StatusIndicator
                    colorOverride={item.stateString.includes('Archived') ? 'grey' : undefined}
                    type={ModuleModel.statusType(state)}
                >
                    {item.stateString}
                </StatusIndicator>
            ),
        };

        this.bindAll(this);
        this.loadData();
    }

    get headerText(): string {
        return '';
    }

    async loadData() {
        this.isLoading = true;
        this.dataUpdated();

        this.region = SessionHelper.getRegion();

        this.modules = [];

        //retrieve the modules count
        const modulesCount = await ModuleService.getModulesDistinctCount(this.region);

        //get pages number based on modules count (always round up)
        const pages = Math.ceil(modulesCount / PAGE_SIZE);

        //build page promises
        const pagesToLoad: Promise<void>[] = [];

        for (let i = 0; i < pages; i++) {
            pagesToLoad.push(this.pageLoadPromise(i));
        }

        //load all pages async
        Promise.all(pagesToLoad).then(() => {
            this.useCounterSuffix = false;
            this.dataUpdated();
        });
    }

    async pageLoadPromise(page: number): Promise<void> {
        const modulesChunk = await ModuleService.getModulesFromRegion(this.region, page, PAGE_SIZE);
        this.modules.push(...modulesChunk);
        this.isLoading = false;
        this.dataUpdated();
    }

    comparePressed(modules: ModuleModel[]) {
        if (modules.length !== 2) {
            this.alertHeader = 'You need to select 2 modules in order to compare.';
            this.isAlertVisible = true;
            this.dataUpdated();
            return;
        }

        RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails('button_pressed', 'compare_modules'));

        const compareHash = CompareModulesView.generateHREF(modules[0], modules[1]);
        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', compareHash);
        }
        const href = window.location.href.replace(window.location.hash, compareHash);
        if (process.env.NODE_ENV !== 'production') {
            console.log(href);
        }

        window.open(href);
    }

    hideAlert() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    private async bulkDownload(modules: ModuleModel[]) {
        const chunkSize = 150;
        if (modules.length > chunkSize) {
            this.alertHeader = `Generating PDF Zip file, download will begin shortly... (You have selected >${chunkSize} modules, results will be split into ${Math.ceil(
                modules.length / chunkSize,
            )} files)`;
        } else {
            this.alertHeader = 'Generating PDF Zip file, download will begin shortly...';
        }
        RequestHelper.logAnalytics(
            AnalyticsRoutes.action,
            new ActionDetails('button_pressed', 'bulk_download_modules'),
        );
        this.isAlertVisible = true;
        this.dataUpdated();
        const totalChunks = Math.ceil(modules.length / chunkSize);

        const responses: Promise<Blob>[] = [];

        for (let chunk = 0; chunk < totalChunks; chunk++) {
            const start = chunk * chunkSize;
            const end = chunk * chunkSize + chunkSize;
            const queryString =
                '/content/module/Secondary/bulkDownload?refId=' +
                modules
                    .slice(start, end)
                    .map((m) => m._systemId)
                    .join('&refId=');
            responses.push(RequestHelper.serviceRequest(queryString, {}, 'blob'));
        }

        const responsesResolved = await Promise.all(responses);
        let didDownload = false;

        for (let i = 0; i < responsesResolved.length; i++) {
            const response = responsesResolved[i];
            if (totalChunks > 1) {
                downloadObject(response, `bulk_download_${i + 1}_of_${totalChunks}.zip`);
                didDownload = true;
            } else {
                console.log(response.size);
                if (response.size > 50) {
                    downloadObject(response, 'bulk_download.zip');
                    didDownload = true;
                }
            }
        }

        if (!didDownload) {
            this.alertHeader = "Selected module(s) don't have files to download.";
        }

        this.isAlertVisible = !didDownload;
        this.dataUpdated();
    }

    render() {
        return (
            <>
                <Header variant="h1">Building Standards - {this.region}</Header>
                <Box spacingInset={this.isAlertVisible ? '400 0' : 0}>
                    <Alert
                        alertType="info"
                        header={this.alertHeader}
                        visible={this.isAlertVisible}
                        dismissible
                        onDismiss={this.hideAlert}
                    />
                </Box>
                <ItemTable
                    controller={this.controller}
                    title={`Modules - ${this.region ?? 'Loading...'}`}
                    counterSuffix={this.useCounterSuffix ? '...' : ''}
                    items={this.modules}
                    actions={[
                        { label: 'Compare', callback: this.comparePressed, icon: 'external', enableAt: 2 },
                        { label: 'Download PDF(s)', callback: this.bulkDownload, enableAt: 1 },
                    ]}
                    hiddenColumns={['bom', 'modulePDF', 'placementNotes']}
                    isLoading={this.isLoading}
                    customDisplays={this.customDisplays}
                    customWidths={{
                        region: 105,
                    }}
                    customExports={{
                        URL: (item: ModuleModel) =>
                            `https://${window.location.host}/#/${this.region}/modules/${item.number}/${
                                item.version?.split(' ')[0]
                            }`,
                    }}
                    initialColumnOrder={this.initiallyVisibleColumns}
                    initiallyVisibleColumnCount={7}
                    preferenceCacheId={`${MODULES_TABLE_PREFS_CACHE}_${this.region}`}
                    canExport
                />
            </>
        );
    }
}
