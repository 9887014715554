import { StatusIndicatorProps } from '@amzn/awsui-components-react';

export default class AffectedItemModel {
    [key: string]: any;

    number?: string;
    version?: string;
    name?: string;
    state?: string;
    _itemType?: string;

    get statusType(): StatusIndicatorProps.Type {
        return AffectedItemModel.statusType(this.state ?? '');
    }

    static statusType(state: string): StatusIndicatorProps.Type {
        switch (state?.toLowerCase()) {
            case 'released - minor':
            case 'releasedminor':
            case 'released':
                return 'success';

            case 'rejected':
                return 'error';
            case 'cancelled':
            case 'archived':
                return 'stopped';
            default:
                return 'pending';
        }
    }

    static fromJSON(json: any): AffectedItemModel {
        const output = new AffectedItemModel();
        for (const key of Object.keys(output)) {
            output[key] = json[key];
        }

        output['_itemType'] = 'modules';
        return output;
    }

    toString(): string {
        return JSON.stringify(this);
    }
}
