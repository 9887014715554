import React from 'react';
import TuxComponent from '../../components/tux_view';
import SiteModel from '../../data/site/site_model';
import { NoLoadTabs } from '../../components/components';
import { Badge, Header, SpaceBetween } from '@amzn/awsui-components-react';
import NonModuleProposalModel from '../../data/site/non_module_proposal_model';
import { NonModuleProposalDetailsView } from './tabs/non_module_proposal_details_view';
import { NonModuleProposalStructureView } from './tabs/non_module_proposal_structure_view';
import { NonModuleProposalHistoryView } from './tabs/non_module_proposal_history_view';
import SiteModelService from '../../data/site/site_model_service';
import { SiteModelType, Version } from '../../data/site/site_base_model';
import { Box } from '@mui/material';
import Loader from '@amzn/meridian/loader';

interface NonModuleProposalViewProps {
    sitePromise: Promise<SiteModel>;
    systemId: string;
    initialTab?: string;
}

export default class NonModuleProposalView extends TuxComponent<NonModuleProposalViewProps, any> {
    isLoading = true;
    site: SiteModel;
    nonModuleProposal: NonModuleProposalModel;
    nonModuleProposalVersions: NonModuleProposalModel[] = [];
    currentTab: string;
    nmpNumber: string | undefined;
    nmpVersion: Version | undefined;

    constructor(props: NonModuleProposalViewProps) {
        super(props);
        this.site = SiteModel.LOADING_TEMPLATE;
        this.nonModuleProposal = NonModuleProposalModel.LOADING_TEMPLATE;
        this.currentTab = this.props.initialTab ?? 'details';
        this.bindAll(this);
        this.loadData();
    }

    public async loadData(): Promise<void> {
        this.site = await this.props.sitePromise;
        this.nonModuleProposal = await this.resolveNonModuleProposal(this.props.systemId);
        this.nonModuleProposalVersions = await this.getNonModuleProposalVersions(
            this.site._nonModuleProposal ?? [],
            this.nmpNumber,
            this.nmpVersion,
        );
        this.isLoading = false;
        this.dataUpdated();
    }

    private async resolveNonModuleProposal(systemId: string): Promise<NonModuleProposalModel> {
        //fetch latest nmp
        for (const model of this.site._latestNonModuleProposal ?? []) {
            if (model._systemId === systemId) {
                this.nmpNumber = model.number;
                this.nmpVersion = model._version;
                return model;
            }
        }

        //fetch non-latest nmp
        this.nonModuleProposal = (await SiteModelService.fetchModel(
            SiteModelType.NonModuleProposal,
            systemId,
        )) as NonModuleProposalModel;
        this.nmpNumber = this.nonModuleProposal.number;
        return this.nonModuleProposal;
    }

    private async getNonModuleProposalVersions(
        nonModuleProposalsOfSite: NonModuleProposalModel[],
        nmpNumber: string | undefined,
        nmpVersion: Version | undefined,
    ): Promise<NonModuleProposalModel[]> {
        for (const model of nonModuleProposalsOfSite ?? []) {
            if (
                model.number === nmpNumber &&
                !this.nonModuleProposalVersions.some((obj) => obj._version?.iteration === model._version?.iteration)
            ) {
                this.nonModuleProposalVersions.push(model);
            }
        }
        return this.nonModuleProposalVersions;
    }

    tabChanged(tabClicked: any) {
        if (!window.location.hash.includes(this.currentTab)) {
            window.location.hash += `/${this.currentTab}`;
        }
        window.history.replaceState(null, 'ES-PLM', window.location.hash.replace(this.currentTab, tabClicked));
        this.currentTab = tabClicked;
    }

    getHeader(): JSX.Element {
        if (this.isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <Loader type="linear" />
                </Box>
            );
        }

        return (
            <Header>
                <SpaceBetween size="m" direction="horizontal">
                    <div>{`${this.nonModuleProposal.number}, ${this.nonModuleProposal.name}`}</div>
                    <Badge
                        color={this.nonModuleProposal.stateString === 'Released' ? 'green' : 'blue'}
                    >{`v${this.nonModuleProposal.displayVersionWithState}`}</Badge>
                </SpaceBetween>
            </Header>
        );
    }

    getContent(): JSX.Element {
        return (
            <>
                {this.getHeader()}
                <NoLoadTabs
                    initialTab={this.props.initialTab ?? 'details'}
                    onTabChanged={this.tabChanged}
                    tabs={[
                        {
                            id: 'details',
                            label: 'Details',
                            content: <NonModuleProposalDetailsView nonModuleProposal={this.nonModuleProposal} />,
                        },
                        {
                            id: 'structure',
                            label: 'Structure',
                            content: <NonModuleProposalStructureView nonModuleProposal={this.nonModuleProposal} />,
                        },
                        {
                            id: 'history',
                            label: 'History',
                            content: (
                                <NonModuleProposalHistoryView
                                    nonModuleProposalHistory={this.nonModuleProposalVersions ?? []}
                                    isLoading={this.isLoading}
                                    siteSystemId={this.site._systemId ?? ''}
                                />
                            ),
                        },
                    ]}
                />
            </>
        );
    }

    render(): JSX.Element {
        return this.getContent();
    }
}
