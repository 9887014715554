import { ApolloQueryResult, FetchResult } from '@apollo/client';
import { siteGQL } from '../../utils/apollo_clients';
import { SiteBaseModel, SiteModelType } from './site_base_model';
import SiteModel from './site_model';
import { getModelQuery, getModelQueryByCriteria, getModelsQuery } from './gql/queries';
import { ModelInput, getPatchModelMutation } from './gql/mutations';
import ModuleListModel from './module_list_module';
import NonModuleListModel from './non_module_list_model';
import RequestHelper from '../../utils/request_helper';
import Bim360SiteInfo from "./bim360_site_info";
import NonModuleProposalModel from './non_module_proposal_model';
import PostCARProposalModel from './post_car_proposal_model';

interface SiteServiceResponse {
    models: any[];
    modelById: any;
    patchModel: any;
}

export default class SiteModelService {
    static async fetchModels(type: SiteModelType): Promise<SiteBaseModel[]> {
        const query = getModelsQuery(type);

        let response: ApolloQueryResult<SiteServiceResponse> = await siteGQL.query({
            query: query,
            variables: { modelType: type }
        });

        //cast resutl to coresponding model type
        switch (type) {
            case SiteModelType.Site:
                return response.data.models.map(m => new SiteModel(m));
            default:
                return [];
        }
    }

    static async fetchModel(type: SiteModelType, systemId: String): Promise<SiteBaseModel> {
        let query;
        if(type === SiteModelType.PostCARProposal){
            query = getModelQuery(type, false);
        }else{
            query = getModelQuery(type, true);
        }
        let response: ApolloQueryResult<SiteServiceResponse> = await siteGQL.query({
            query: query,
            variables: { modelType: type, systemId: systemId},
        });

        switch (type) {
            case SiteModelType.Site:
                return new SiteModel(response.data.modelById);
            case SiteModelType.ModuleList:
                return new ModuleListModel(response.data.modelById);
            case SiteModelType.NonModuleList:
                    return new NonModuleListModel(response.data.modelById);
            case SiteModelType.NonModuleProposal:
                return new NonModuleProposalModel(response.data.modelById);
            case SiteModelType.PostCARProposal:
                return new PostCARProposalModel(response.data.modelById);
            default:
                return {} as SiteBaseModel;
        }

    }

    static async fetchModelByNumberAndVersion(type: SiteModelType, number: String, version: string): Promise<SiteBaseModel>{
        let query ;
        if(type === SiteModelType.PostCARProposal){
            query = getModelQueryByCriteria(type, false);
        }else{
            query = getModelQueryByCriteria(type, true);
        }
        let response: ApolloQueryResult<SiteServiceResponse> = await siteGQL.query({
            query: query,
            variables: { modelType: type, criteria:  {and: [{where: "number", is: number}, {where: "version.version", is: Number(version.split(".")[0])}, {where: "version.iteration", is: Number(version.split(".")[1])}]} },
        });

        //cast resutl to coresponding model type
        switch (type) {
            case SiteModelType.Site:
                return new SiteModel(response.data.models[0]);
            case SiteModelType.ModuleList:
                return new ModuleListModel(response.data.models[0]);
            case SiteModelType.NonModuleList:
                    return new NonModuleListModel(response.data.models[0]);
            case SiteModelType.NonModuleProposal:
                return new NonModuleProposalModel(response.data.models[0]);
            case SiteModelType.PostCARProposal:
                return new PostCARProposalModel(response.data.models[0]);
            default:
                return {} as SiteBaseModel;
        }
        
    }

    static async patchModel(modelType: SiteModelType, modelInput: ModelInput): Promise<SiteBaseModel> {
        const mutation = getPatchModelMutation(modelType);

        let response: FetchResult<SiteServiceResponse> = await siteGQL.mutate({
            mutation: mutation,
            variables: { modelType: SiteModelType.Site, model: modelInput }
        });

        //cast resutl to coresponding model type
        switch (modelType) {
            case SiteModelType.Site:
                return new SiteModel(response.data?.patchModel);
            default:
                return {} as SiteBaseModel;
        }
    }

    static async patchModels(modelType: SiteModelType, modelsInput: ModelInput[]): Promise<void> {
        await Promise.all(
            modelsInput.map(async modelInput => {
                const mutation = getPatchModelMutation(modelType);

                siteGQL.mutate({
                    mutation: mutation,
                    variables: { modelType: SiteModelType.Site, model: modelInput }
                });
            })
        );
    }

    static async getBim360SiteInfo(systemId: string): Promise<Bim360SiteInfo> {
        let response: any[] = await RequestHelper.serviceRequest(`/bim360/site/v1/${systemId}`);

        return Bim360SiteInfo.fromJSON(response);
    }
}
