import React from 'react';
import TuxView from '../../../components/tux_view';
import ModuleDesignGuidePlacementModel from '../../../data/module/module_design_guide_placement_model';
import { Alert, AlertProps } from '@amzn/geist-ui-components';
import Box from '@amzn/meridian/box';
import ItemTable, { CustomDisplayProps } from '../../../components/item_table';
import ModuleDesignGuideService from '../../../data/module/module_design_guide_service';
import SessionHelper from '../../../utils/session_helper';

export class Placement {
    moduleNumber?: string;
    moduleName?: string;
    moduleVersion?: string;
    ownerGroup?: number;
    purpose?: string;
    onComposite?: string;
    layoutAdjustable?: string;
    powerRequired?: string;
    dataRequired?: string;
    totalQuantityDeployed?: string;
    floorLevel?: string;
    moduleDeployedArea?: string;
    moduleDeployedProcess?: string;
    placementNotes?: string;
    supportDocuments?: string;
}

interface ModuleDesignGuideStructureViewProps {
    moduleDesignGuidePlacements: ModuleDesignGuidePlacementModel[];
    systemId: string;
}
class ModuleDesignGuideStructureView extends TuxView<ModuleDesignGuideStructureViewProps, any> {
    private isLoading = true;
    private placements: Placement[] = [];
    private customDisplays: CustomDisplayProps;

    private alertType: AlertProps.Type = 'info';
    private isAlertVisible = false;
    private alertMessage = '';

    constructor(props: ModuleDesignGuideStructureViewProps) {
        super(props);
        this.customDisplays = {
            moduleNumber: (n: string, item: Placement) => (
                <a
                    href={`#/${SessionHelper.getRegion()}/modules/${item.moduleNumber}/${item.moduleVersion}/details`}
                    style={{ textDecoration: 'none' }}
                >
                    {n}
                </a>
            ),
        };
        this.bindAll(this);
        this.loadData();
    }

    async loadData(): Promise<void> {
        this.isLoading = true;
        this.dataUpdated();
        try {
            this.placements = this.getPlacements(
                this.props.moduleDesignGuidePlacements ??
                    (await ModuleDesignGuideService.getModuleDesignGuideSiteTemplatePlacements(this.props.systemId)),
            );
        } catch (err) {
            this.showAlert(
                `Failed to fetch Module Design Guide Structure. Please contact site administrator. ${(err as Error).message}`,
                'error',
            );
        }
        this.isLoading = false;
        this.dataUpdated();
    }

    getPlacements(placement: ModuleDesignGuidePlacementModel[]): Placement[] {
        return placement?.map(
            (placementModel) =>
                ({
                    moduleNumber: placementModel.module?.number,
                    moduleName: placementModel.module?.name,
                    moduleVersion: placementModel.module?.version + '.' + placementModel.module?.iteration,
                    ownerGroup: placementModel.module?.ownerGroup,
                    purpose: placementModel.module?.purpose,
                    onComposite: placementModel.module?.onComposite,
                    layoutAdjustable: placementModel.module?.layoutAdjustable,
                    powerRequired: placementModel.module?.powerRequired,
                    dataRequired: placementModel.module?.dataRequired,
                    totalQuantityDeployed: placementModel.totalQuantityDeployed,
                    floorLevel: placementModel.floorLevel,
                    moduleDeployedArea: placementModel.moduleDeployedArea,
                    placementNotes: placementModel.placementNotes,
                    supportDocuments: placementModel.supportDocuments,
                }) as Placement,
        );
    }

    private showAlert(message: string, type: AlertProps.Type = 'info') {
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }

    private alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }
    render() {
        return (
            <div>
                <Box spacingInset={this.isAlertVisible ? '400 0' : 0}>
                    <Alert
                        alertType={this.alertType}
                        visible={this.isAlertVisible}
                        dismissible={true}
                        onDismiss={this.alertDismissed}
                        header={this.alertMessage}
                    />
                </Box>
                <ItemTable
                    items={this.placements}
                    isLoading={this.isLoading}
                    customDisplays={this.customDisplays}
                    customWidths={{
                        region: 105,
                    }}
                    initialColumnOrder={[
                        'moduleNumber',
                        'moduleName',
                        'moduleVersion',
                        'onComposite',
                        'totalQuantityDeployed',
                        'ownerGroup',
                        'purpose',
                        'layoutAdjustable',
                        'powerRequired',
                        'dataRequired',
                        'floorLevel',
                        'moduleDeployedArea',
                        'moduleDeployedProcess',
                        'placementNotes',
                        'supportDocuments',
                    ]}
                    defaultSortBy="moduleNumber"
                    canExport
                />
            </div>
        );
    }
}

export default ModuleDesignGuideStructureView as React.ComponentType<ModuleDesignGuideStructureViewProps>;
