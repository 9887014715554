
export default class Bim360ModuleInfo {
    [key: string]: any;

    token?: string;
    derivativeId?: string;
    bim360FilePath?: string;
    strategyType?: string;

    static fromJSON(json: any) : Bim360ModuleInfo{
        let output = new Bim360ModuleInfo();

        let moduleKeys = Object.keys(output);

        // Copy over the values of they keys present in a ModuleModel;
        for (let key of moduleKeys){
            output[key] = json[key];
        }

        return output;
    }

    toString(): string{
        return JSON.stringify(this);
    }
}
