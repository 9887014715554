import React from "react";
import { SpaceBetween } from '@amzn/awsui-components-react-v3';
import { DynamicExpandableProperties, KeyValueTemplate } from '../../../modules';
import ModuleListModel from '../../../data/site/module_list_module';

const MDL_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Number', key: 'number' },
            { title: 'Name', key: 'name' },
            { title: 'Version', key: 'displayVersion' },
        ],
        [
            { title: "State", key: "stateString", type: "status", states: { Released: "success", Approved: "success", Closed: "stopped", default: "pending"} },
            { title: 'MDG Template', key: 'mdgTemplate' },
            { title: 'ERP Status', key: 'erpStatus' },
            { title: 'Module Counts Template', key: 'moduleCountsTemplate' },
        ],
    ]
};

const MDL_SYSTEM_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'System',
    type: 'key-value',
    defaultExpanded: false,
    sections: [
        [
            { title: 'Created By', key: 'createdBy' },
            { title: 'Created On', key: 'createdOn', type: 'datetime' }
        ],
        [
            { title: 'Modified By', key: 'modifiedBy' },
            { title: 'Last Modified', key: 'lastModified', type: 'datetime' }
        ]
    ]
};

const TEMPLATES: KeyValueTemplate[] = [MDL_ATTR_TEMPLATE, MDL_SYSTEM_ATTR_TEMPLATE];

export const ModuleListDetailsView = ({ moduleList }: { moduleList: ModuleListModel }) => {

    return (
        <SpaceBetween size="m">
            {TEMPLATES.map(template => (
                <DynamicExpandableProperties key={template.title} object={moduleList} template={template} />
            ))}
        </SpaceBetween>
    );
}