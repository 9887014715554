import { Alert, AlertProps, ProgressBar, SpaceBetween } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { TuxView } from '../../../components/components';
import ForgeViewer from '../../react_forge_viewer';
import SiteModel from '../../../data/site/site_model';
import Bim360SiteInfo from '../../../data/site/bim360_site_info';
import { SiteModelService } from '../../../data/data';

const globalWindow = window as any;

interface SiteModelViewProps {
    sitePromise: Promise<SiteModel>;
}

/**
 * Component to get Site Composite 3D file from BIM360 service and display
 */
export default class SiteModelView extends TuxView<SiteModelViewProps, any> {
    isLoading = true;
    docFound = false;
    site: SiteModel;
    bim360SiteInfo: Bim360SiteInfo = {};
    headerAlertVisible: boolean = true;
    headerAlertType: AlertProps.Type = 'info';
    loadingProgress: number = 0;
    loadingAdditionalInfo: string = '';
    loadingErrorMessage: string = '';

    constructor(props: SiteModelViewProps) {
        super(props);
        this.site = SiteModel.LOADING_TEMPLATE;
        this.bindAll(this);
        this.state = {
            view: null
        };
        this.loadData();
    }

    static get Autodesk(): any {
        return globalWindow.Autodesk;
    }

    async loadData() {
        try {
            this.site = await this.props.sitePromise;
            await this.updateLoadingProgress(25, 'Searching the document in Bim360...');
            this.bim360SiteInfo = await SiteModelService.getBim360SiteInfo(this.site._systemId ?? '');
            this.docFound = true;
            await this.updateLoadingProgress(50, 'Loading the document from Autodesk...');
        } catch (err) {
            console.log('Failed to load Site Bim360 Info.', err);
            await this.updateLoadingProgress(100, '');
            this.headerAlertType = 'warning';
            this.isLoading = false;
        }
        this.dataUpdated();
    }

    private async updateLoadingProgress(progress: number, additionalInfo: string) {
        this.loadingAdditionalInfo = additionalInfo;
        if (progress === 100) {
            this.loadingProgress = progress;
            this.loadingAdditionalInfo = '';
            this.dataUpdated();
            const sleep = (ms: number) => new Promise(r => setTimeout(r, ms));
            await sleep(500); //wait 500 ms to capture 100% on progress bar
        } else {
            this.loadingProgress = progress;
        }
        this.dataUpdated();
    }

    async handleModelLoaded(viewer: any, model: any) {
        console.log('Loaded model:', model);
        await this.updateLoadingProgress(100, '');
        this.headerAlertType = 'success';
        this.isLoading = false;
        this.dataUpdated();
    }

    async handleDocumentLoaded(doc: any, viewables: string | any[]) {
        if (viewables.length === 0) {
            this.logError('Document contains no viewables. Please contact administrator.', undefined);
        } else {
            //Select the first viewable in the list to use in our viewer component
            this.setState({ view: viewables[0] });
            await this.updateLoadingProgress(75, 'Loading the model from Autodesk...');
        }
    }

    handleDocumentError(viewer: any, error: any) {
        this.logError('Error loading Forge document. Please contact administrator.', error);
    }

    handleModelError(viewer: any, error: any) {
        this.logError('Error loading the model. Please contact administrator.', error);
    }

    handleViewerError(error: any) {
        this.logError('Error loading viewer. Please contact administrator.', error);
    }

    logError(message: string, error: any) {
        this.loadingErrorMessage = message;
        console.error(message, error);
        this.headerAlertType = 'warning';
        this.isLoading = false;
        this.dataUpdated();
    }

    async handleTokenRequested(onAccessToken: (token: string, expires: number) => void) {
        console.log('Token requested by the viewer.', onAccessToken);
        if (onAccessToken) {
            const exp = 3599;
            onAccessToken(this.bim360SiteInfo.token ?? '', exp);
        }
    }

    getHeader(): JSX.Element {
        return (
            <Alert
                dismissible
                visible={this.headerAlertVisible}
                onDismiss={() => {
                    this.headerAlertVisible = false;
                    this.dataUpdated();
                }}
                type={this.headerAlertType}
            >
                {this.getAlertContent()}
            </Alert>
        );
    }

    getViewer(): JSX.Element {
        if (this.docFound) {
            return (
                <ForgeViewer
                    version="7.*"
                    urn={this.bim360SiteInfo.derivativeId}
                    view={this.state.view}
                    headless={false}
                    onViewerError={this.handleViewerError}
                    onTokenRequest={this.handleTokenRequested}
                    onDocumentLoad={this.handleDocumentLoaded}
                    onDocumentError={this.handleDocumentError}
                    onModelLoad={this.handleModelLoaded}
                    onModelError={this.handleModelError}
                />
            );
        }

        return <></>;
    }

    getAlertContent(): JSX.Element {
        if (this.isLoading) {
            return (
                <ProgressBar
                    value={this.loadingProgress}
                    additionalInfo={this.loadingAdditionalInfo}
                    label="Loading Progress"
                />
            );
        }

        let loadingFilePath: string = this.bim360SiteInfo.bim360FilePath ?? 'No 3D view available for this Site.';

        let loadingStrategyType: string = this.bim360SiteInfo.strategyType ?? '';

        let loadingResult = loadingStrategyType + " : " + loadingFilePath;

        return <>{this.loadingErrorMessage !== '' ? this.loadingErrorMessage : loadingResult}</>;
    }

    render() {
        return (
            <>
                <SpaceBetween size="xs">
                    {this.getHeader()}
                    {this.getViewer()}
                </SpaceBetween>
            </>
        );
    }
}
