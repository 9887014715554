import React from 'react';
import SiteModel from '../../../data/site/site_model';
import TuxView from '../../../components/tux_view';
import { SpaceBetween } from '@amzn/awsui-components-react';
import DynamicExpandableProperties, { KeyValueTemplate } from '../../../components/dynamic_expandable_properties';
import { CostBreakdown } from '../../../data/bom/bom_model';
import { BOMService, SiteModelService } from '../../../data/data';
import { SiteModelType } from '../../../data/site/site_base_model';
import ModuleListModel from '../../../data/site/module_list_module';
import NonModuleListModel from '../../../data/site/non_module_list_model';
import { Alert, ExpandableSection, Header, StatusIndicator, Table } from '@amzn/geist-ui-components';
import { CAR_TABS } from '../../../utils/constants';

const SITE_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Name', key: 'name' },
            { title: 'Shield ID', key: 'shieldId' },
            { title: 'Region', key: 'region' },
            { title: 'Country', key: 'country' },
            { title: 'POR Year', key: 'porYear' },
        ],
        [
            { title: 'Program', key: 'program' },
            { title: 'Building Type', key: 'buildingType' },
            { title: 'Facility Type', key: 'facilityType' },
            { title: 'Facility Name', key: 'facilityName' },
        ],
        [
            { title: 'Project Number', key: 'projectNumber' },
            {
                title: 'Project Status',
                key: 'projectStatus',
                type: 'status',
                states: {
                    Active: 'active',
                    Completed: 'success',
                    'On-Hold': 'pending',
                    Cancelled: 'stopped',
                    default: 'pending',
                },
            },
            { title: 'First Receive', key: 'firstReceive', type: 'date' },
        ],
    ],
};

const SITE_SYSTEM_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'System',
    type: 'key-value',
    defaultExpanded: false,
    sections: [
        [
            { title: 'Created By', key: 'createdBy' },
            { title: 'Created On', key: 'createdOn', type: 'datetime' },
        ],
        [
            { title: 'Modified By', key: 'modifiedBy' },
            { title: 'Last Modified', key: 'lastModified', type: 'datetime' },
        ],
    ],
};

const TEMPLATES: KeyValueTemplate[] = [SITE_ATTR_TEMPLATE, SITE_SYSTEM_ATTR_TEMPLATE];

interface SiteDetailsViewProps {
    sitePromise: Promise<SiteModel>;
}

export default class SiteDetailsView extends TuxView<SiteDetailsViewProps, any> {
    isLoading = true;
    site: SiteModel;
    mdlCostBreakdown?: CostBreakdown;
    nmlCostBreakdown?: CostBreakdown;
    mdl?: ModuleListModel;
    nml?: NonModuleListModel;
    columnDefinitions = [
        { id: 'carTabs', header: 'Car Tabs', cell: (item: any) => item.carTab },
        { id: 'mdl', header: 'MDL', cell: (item: any) => item.mdl },
        { id: 'nml', header: 'NML', cell: (item: any) => item.nml },
        { id: 'site', header: 'Site', cell: (item: any) => item.site },
    ];

    constructor(props: SiteDetailsViewProps) {
        super(props);
        this.site = SiteModel.LOADING_TEMPLATE;
        this.state = {
            failedBreakdownFetch: false,
            items: [],
            breakdownLoading: false,
            breakdownExpanded: false,
        };
        this.bindAll(this);
        this.loadData();
    }

    public async getCostBreakdownMDL(moduleList: ModuleListModel | undefined): Promise<CostBreakdown> {
        const bomItemQuantities: Map<string, number> = new Map();
        moduleList?._bom?.forEach((bomItem) => {
            if (bomItem.bomItem?._systemId && bomItem.quantity) {
                bomItemQuantities.set(bomItem.bomItem?._systemId, bomItem.quantity);
            }
        });
        const response: any = await BOMService.getCostBreakdownOfModules(bomItemQuantities);
        return response;
    }

    public async getCostBreakdownNML(nonModuleList: NonModuleListModel | undefined): Promise<CostBreakdown> {
        const response: any = await SiteModelService.getCostBreakdown(
            SiteModelType.NonModuleList,
            1,
            nonModuleList?._systemId ? nonModuleList._systemId : '',
        );
        return response;
    }

    public async loadData(): Promise<void> {
        this.site = await this.props.sitePromise;
        this.mdl = this.site._latestModuleList ? this.site._latestModuleList[0] : undefined;
        this.nml = this.site._latestNonModuleList ? this.site._latestNonModuleList[0] : undefined;

        this.isLoading = false;
        this.dataUpdated();
    }

    public async onExpansionChanged(): Promise<void> {
        if (this.state.breakdownExpanded) {
            this.setState({ breakdownExpanded: false });
            return;
        }
        this.setState({ breakdownLoading: true });
        try {
            this.mdlCostBreakdown = await this.getCostBreakdownMDL(this.mdl);
            this.nmlCostBreakdown = await this.getCostBreakdownNML(this.nml);
        } catch (err) {
            if (process.env.NODE_ENV !== 'production') {
                console.log(err);
            }
        }

        if (
            (!this.mdlCostBreakdown || !this.mdlCostBreakdown.totalCost) &&
            (!this.nmlCostBreakdown || !this.nmlCostBreakdown.totalCost)
        ) {
            this.setState({ failedBreakdownFetch: true });
        }

        const tableRows: any[][] = [];
        tableRows.push(['TOTAL COST', ...this.getBreakdownRow(this.mdlCostBreakdown, this.nmlCostBreakdown, true, '')]);
        CAR_TABS.forEach((carTab) =>
            tableRows.push([
                carTab,
                ...this.getBreakdownRow(this.mdlCostBreakdown, this.nmlCostBreakdown, false, carTab),
            ]),
        );
        this.setState({ items: tableRows });

        const formattedItems = tableRows.map((row) => {
            return { carTab: row[0], mdl: row[1], nml: row[2], site: row[3] };
        });
        this.setState({ items: formattedItems, breakdownLoading: false, breakdownExpanded: true });
    }

    public formatCombinedCost(mdlCost: string | null | undefined, nmlCost: string | null | undefined): string {
        mdlCost = mdlCost?.toString();
        nmlCost = nmlCost?.toString();
        if (!mdlCost && !nmlCost) {
            return 'Loading...';
        }
        if (!mdlCost || !nmlCost) {
            return `$${(parseFloat(mdlCost?.replace(/,/g, '') ?? '0') + parseFloat(nmlCost?.replace(/,/g, '') ?? '0')).toLocaleString('en-US')}`;
        } else if (Number.isNaN(mdlCost) || Number.isNaN(nmlCost)) {
            return mdlCost;
        } else {
            return `$${(parseFloat(mdlCost.replace(/,/g, '')) + parseFloat(nmlCost.replace(/,/g, ''))).toLocaleString('en-US')}`;
        }
    }

    public getBreakdownRow(
        mdlbreakdown: CostBreakdown | undefined,
        nmlbreakdown: CostBreakdown | undefined,
        totalCost: boolean,
        carTab: string,
    ): string[] {
        const row: string[] = [];
        if (totalCost) {
            row.push(mdlbreakdown?.totalCost?.toString() ? `$${mdlbreakdown.totalCost}` : 'Loading...');
            row.push(nmlbreakdown?.totalCost?.toString() ? `$${nmlbreakdown.totalCost}` : 'Loading...');
            row.push(this.formatCombinedCost(mdlbreakdown?.totalCost, nmlbreakdown?.totalCost));
        } else {
            row.push(mdlbreakdown?.carTabs[carTab]?.toString() ? `$${mdlbreakdown.carTabs[carTab]}` : 'Loading...');
            row.push(nmlbreakdown?.carTabs[carTab]?.toString() ? `$${nmlbreakdown.carTabs[carTab]}` : 'Loading...');
            row.push(this.formatCombinedCost(mdlbreakdown?.carTabs[carTab], nmlbreakdown?.carTabs[carTab]));
        }
        return row;
    }

    render() {
        if (!this.site && !this.isLoading) {
            return <Alert type="error" header="Failed to get the specified site" />;
        }

        return (
            <SpaceBetween size="m">
                {TEMPLATES.map((template) => (
                    <DynamicExpandableProperties key={Math.random()} object={this.site} template={template} />
                ))}
                {this.state.failedBreakdownFetch ? (
                    <Alert type="warning" header="Failed to get cost breakdown" />
                ) : (
                    <ExpandableSection
                        variant="container"
                        header={<Header variant={'h2'}>{'Cost Breakdown'}</Header>}
                        defaultExpanded={false}
                        onChange={this.onExpansionChanged}
                    >
                        {this.state.breakdownLoading ? (
                            <StatusIndicator type={'loading'} />
                        ) : (
                            <Table columnDefinitions={this.columnDefinitions} items={this.state.items} />
                        )}
                    </ExpandableSection>
                )}
            </SpaceBetween>
        );
    }
}
