import React from 'react';
import {Box} from '@amzn/awsui-components-react-v3';

interface ValueWithLabelProps{
    label: string;
    hasChanged?: boolean;
}

export default class ValueWithLabel extends React.Component<ValueWithLabelProps, any>{
    render(){
        return (<div>
            <Box margin={{ bottom: 'xxxs' }} color={this.props.hasChanged?"text-status-info":"text-label"} fontWeight={this.props.hasChanged?"bold":undefined}>
            {this.props.label}
            </Box>
            {
                this.props.hasChanged
                ? <Box color={"text-status-info"}>{this.props.children}</Box>
                : <div>{this.props.children}</div>
            }
        </div>);
    }
}