import React from 'react';
import TuxView from '../../components/tux_view';
import { Header, Link, SpaceBetween } from '@amzn/awsui-components-react';
import {
    ArchiveChangeRequestModel,
    ChangeRequestBaseModel,
    ChangeRequestService,
    MajorChangeRequestModel,
    MinorChangeRequestModel,
} from '../../data/data';
import ChangeDetailsView from './tabs/change_details_view';
import ChangeProcessView from './tabs/change_process_view';
import { NoLoadTabs } from '../../components/components';
import ErrorView from '../../components/error_page';
import UserInfoHelper from '../../utils/user_info_helper';

interface ChangeViewProps {
    number?: string;
    initialTab?: string;
    changeRequest?: ChangeRequestBaseModel;
    crType?: string;
}

export default class ChangeView extends TuxView<ChangeViewProps, any> {
    isLoading = true;
    encounteredError = false;
    currentTab: string = this.props.initialTab ?? 'details';
    _changeRequest: ChangeRequestBaseModel;
    _changeRequestPromise: Promise<ChangeRequestBaseModel>;

    constructor(props: ChangeViewProps) {
        super(props);
        this._changeRequest = this.props.changeRequest ?? this.getLoadingTemplate();
        this._changeRequestPromise = ChangeRequestService.getCRFromNumber(
            this.props.number ? this.props.number : '',
            this.props.crType ? this.props.crType : 'major',
        );
        this.bindAll(this);
        this.loadData();
    }

    async loadData(): Promise<void> {
        try {
            this._changeRequestPromise = this.isLoading
                ? this._changeRequestPromise
                : ChangeRequestService.getCRFromNumber(
                      this.props.number ? this.props.number : '',
                      this.props.crType ? this.props.crType : 'major',
                  ).catch((e) => this._changeRequest);
            this.isLoading = true;
            this.dataUpdated();

            this._changeRequest = await this._changeRequestPromise;

            this.isLoading = false;
        } catch (e) {
            this.encounteredError = true;
            if (process.env.NODE_ENV !== 'production') {
                console.error(e);
            }
        } finally {
            this.dataUpdated();
        }
    }

    tabChanged(tabClicked: any) {
        // console.log(tabClicked);
        if (!window.location.hash.includes(this.currentTab)) {
            window.location.hash += `/${this.currentTab}`;
        }
        window.history.replaceState(null, 'ES-PLM', window.location.hash.replace(this.currentTab, tabClicked));
        this.currentTab = tabClicked;
    }

    getHeader(): JSX.Element {
        if (this.isLoading) {
            return <Header>{'Loading...'}</Header>;
        }
        return (
            <Header>
                <SpaceBetween size="xs" direction="horizontal">
                    <div key="cr_header_title">{`${this._changeRequest.number}, ${this._changeRequest.name}`}</div>
                    {UserInfoHelper.isAmazonEmployee ? (
                        <Link key="cr_wc_link" href={this._changeRequest.getURL()} target="_blank">
                            (View in Windchill)
                        </Link>
                    ) : null}
                </SpaceBetween>
            </Header>
        );
    }

    getLoadingTemplate(): ChangeRequestBaseModel {
        if (this.props.crType === 'archive') {
            return ArchiveChangeRequestModel.LOADING_TEMPLATE;
        } else if (this.props.crType === 'minor') {
            return MinorChangeRequestModel.LOADING_TEMPLATE;
        }

        return MajorChangeRequestModel.LOADING_TEMPLATE;
    }

    render() {
        if (this.encounteredError) {
            return <ErrorView />;
        }
        return (
            <>
                {this.getHeader()}
                <NoLoadTabs
                    initialTab={this.props.initialTab}
                    onTabChanged={this.tabChanged}
                    tabs={[
                        {
                            id: 'details',
                            label: 'Details',
                            content: <ChangeDetailsView changeRequestPromise={this._changeRequestPromise} />,
                        },
                        {
                            id: 'process',
                            label: 'Process',
                            content: <ChangeProcessView changeRequestPromise={this._changeRequestPromise} />,
                        },
                    ]}
                />
            </>
        );
    }
}
