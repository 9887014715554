import React from 'react';
import TuxComponent from '../../../components/tux_component';
import ItemTable, { CustomDisplayProps } from '../../../components/item_table';
import SiteModel from '../../../data/site/site_model';
import SessionHelper from '../../../utils/session_helper';
import { SiteModelType } from '../../../data/site/site_base_model';
import RequestQuoteTwoToneIcon from '@mui/icons-material/RequestQuoteTwoTone';
import { Link, SpaceBetween } from '@amzn/awsui-components-react';

interface FolderViewProps {
    sitePromise: Promise<SiteModel>;
    systemId: string;
    relatedItemType: string;
}

interface SiteRelatedItem {
    _systemId: string;
    _type: SiteModelType;
    number: string;
    name: string;
    version: string;
}

export default class FolderView extends TuxComponent<FolderViewProps, any> {
    isLoading = true;
    site: SiteModel;
    relatedItems: SiteRelatedItem[] = [];

    private customDisplays: CustomDisplayProps;
    constructor(props: FolderViewProps) {
        super(props);
        this.site = SiteModel.LOADING_TEMPLATE;
        this.region = SessionHelper.getRegion();
        this.customDisplays = {
            number: (n: string, item: SiteRelatedItem) => (
                <Link
                    href={`#/${SessionHelper.getRegion()}/sites/${this.site._systemId}/relatedItems/${item._type}/${item._systemId}`}
                >
                    <SpaceBetween direction="horizontal" size={'s'}>
                        {(() => {
                            if (item._type === SiteModelType.NonModuleProposal) {
                                return <RequestQuoteTwoToneIcon fontSize="medium" />;
                            }
                            if (item._type === SiteModelType.PostCARProposal) {
                                return <RequestQuoteTwoToneIcon fontSize="medium" />;
                            }
                            return '';
                        })()}
                        <span>{n}</span>
                    </SpaceBetween>
                </Link>
            ),
        };

        this.bindAll(this);
        this.loadData();
    }

    async loadData(): Promise<void> {
        this.site = await this.props.sitePromise;

        if (this.props.relatedItemType === 'NonModuleProposalFolder') {
            this.relatedItems = this.site._latestNonModuleProposal!.map((nmp) => {
                return {
                    _systemId: nmp._systemId,
                    _type: nmp._modelType,
                    number: nmp.number,
                    name: nmp.name,
                    version: nmp.displayVersionWithState,
                } as SiteRelatedItem;
            });
        } else if (this.props.relatedItemType === 'PostCARProposalFolder') {
            this.relatedItems = this.site._latestPostCarProposal!.map((pcp) => {
                return {
                    _systemId: pcp._systemId,
                    _type: pcp._modelType,
                    number: pcp.number,
                    name: pcp.name,
                    version: pcp.displayVersionWithState,
                } as SiteRelatedItem;
            });
        }

        this.isLoading = false;
        this.dataUpdated();
    }

    render(): JSX.Element {
        return (
            <>
                <ItemTable items={this.relatedItems} disableSelection customDisplays={this.customDisplays} />
            </>
        );
    }
}
