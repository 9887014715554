import React from 'react';
import TuxComponent from '../../components/tux_view';
import SiteModel from '../../data/site/site_model';
import { NoLoadTabs } from '../../components/components';
import { Badge, Header, SpaceBetween } from '@amzn/awsui-components-react-v3';
import PostCARProposalModel from '../../data/site/post_car_proposal_model';
import { PostCARProposalDetailsView } from './tabs/post_car_proposal_details_view';
//import { NonModuleListStructureView } from './tabs/post_car_proposal_structure_view';
//import { PostCARProposalHistoryView } from './tabs/post_car_proposal_history_view';
import SiteModelService from '../../data/site/site_model_service';
import { SiteModelType } from '../../data/site/site_base_model';
import { Box, LinearProgress } from '@mui/material';

interface PostCARProposalViewProps {
    sitePromise: Promise<SiteModel>;
    systemId: string;
    initialTab?: string;
}

export default class PostCARProposalView extends TuxComponent<PostCARProposalViewProps, any> {
    isLoading = true;
    site: SiteModel;
    postCarProposal: PostCARProposalModel;
    currentTab: string;

    constructor(props: PostCARProposalViewProps) {
        super(props);
        this.site = SiteModel.LOADING_TEMPLATE;
        this.postCarProposal = PostCARProposalModel.LOADING_TEMPLATE;
        this.currentTab = this.props.initialTab ?? 'details';
        this.bindAll(this);
        this.loadData();
    }

    public async loadData(): Promise<void> {
        this.site = await this.props.sitePromise;
        this.postCarProposal = await this.resolvePostCARProposal(this.props.systemId);
        this.isLoading = false;
        this.dataUpdated();
    }

    private async resolvePostCARProposal(systemId: string): Promise<PostCARProposalModel> {
        //fetch latest nml
        for (let model of this.site._latestPostCarProposal ?? []) {
            if (model._systemId === systemId) return model;
        }

        //fetch non-latest nml
        return (await SiteModelService.fetchModel(SiteModelType.PostCARProposal, systemId)) as PostCARProposalModel;
    }

    tabChanged(tabClicked: any) {
        if (!window.location.hash.includes(this.currentTab)) {
            window.location.hash += `/${this.currentTab}`;
        }
        window.history.replaceState(null, 'ES-PLM', window.location.hash.replace(this.currentTab, tabClicked));
        this.currentTab = tabClicked;
    }

    getHeader(): JSX.Element {
        if (this.isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <LinearProgress />
                </Box>
            );
        }
 
        return (
            <Header>
                <SpaceBetween size="m" direction="horizontal">
                    <div>{`${this.postCarProposal.number}, ${this.postCarProposal.name}`}</div>
                    <Badge
                        color={this.postCarProposal.stateString === 'Released' ? 'green' : 'blue'}
                    >{`v${this.postCarProposal.displayVersionWithState}`}</Badge>
                </SpaceBetween>
            </Header>
        );
    }

    getContent(): JSX.Element {
        return (
            <>
                {this.getHeader()}
                <NoLoadTabs
                    initialTab={this.props.initialTab ?? 'details'}
                    onTabChanged={this.tabChanged}
                    tabs={[
                        {
                            id: 'details',
                            label: 'Details',
                            content: <PostCARProposalDetailsView postCarProposal={this.postCarProposal} />
                        },
                        /*{
                            id: 'structure',
                            label: 'Structure',
                            content: <NonModuleListStructureView nonModuleList={this.nonModuleList} />
                        },*/
                        /*{
                            id: 'history',
                            label: 'History',
                            content: (
                                <PostCARProposalHistoryView
                                    postCarProposalHistory={this.site._postCarProposal?? []}
                                    isLoading={this.isLoading}
                                    siteSystemId={this.site._systemId ?? ''}
                                />
                            )
                        }*/
                    ]}
                />
            </>
        );
    }

    render(): JSX.Element {
        return this.getContent();
    }
}
