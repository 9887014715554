import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import PersistentHeader from './components/frame/header';
import PersistentFooter from './components/frame/footer';
import LeftNav from './components/frame/left_nav';
import Routing from './routing';
import SessionHelper from './utils/session_helper';
import UserInfoHelper from './utils/user_info_helper';
import MeridianAppLayout from '@amzn/meridian/app-layout';

import { appLayoutID } from './components/constants';

interface BaseAppProps {}

export default class BaseApp extends React.Component<BaseAppProps, any> {
    navigationOpen = true;

    render(): JSX.Element {
        return (
            <MeridianAppLayout
                footerComponent={PersistentFooter}
                headerComponent={PersistentHeader}
                sidebarComponent={LeftNav}
                spacingInset="400"
                alwaysShowScrollbar
            >
                <PersistentHeader />
                <LeftNav />
                <div id={appLayoutID} className="awsui">
                    <Routing />
                </div>
                <PersistentFooter />
            </MeridianAppLayout>
        );
    }
}

UserInfoHelper.loadUserInfoFromCookies();
SessionHelper.init();
const rootElement: Element = document.getElementById('root')!;
const root = ReactDOM.createRoot(rootElement);
root.render(<BaseApp />);
