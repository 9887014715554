import React, { useEffect } from 'react';
import CodexTooltip from 'codex-tooltip';
import './tooltip_style.css';

interface TooltipProps {
    elementId: string;
    text: string;
    children?: React.ReactNode;
}

export function Tooltip({ elementId, text, children }: TooltipProps): JSX.Element {
    useEffect(() => {
        const tooltip = new CodexTooltip();

        const tooltipElement = document.getElementById(elementId);
        if (tooltipElement) {
            tooltip.onHover(tooltipElement, text, {
                placement: 'top',
                delay: 150,
            });
        }
    }, [elementId, text]);

    return (
        <div className="tooltip_content" id={elementId}>
            {children}
        </div>
    );
}
