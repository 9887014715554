import React from 'react';
import { ChangeRequestBaseModel, ChangeRequestService } from '../../../data/data';
import { TuxComponent } from '../../../components/components';
import { Box, SpaceBetween, StatusIndicator, } from '@amzn/awsui-components-react-v3';
import ExpandableTable from '../../../components/expandable_table';
import { CustomDisplayProps } from '../../../components/item_table';
import TaskModel from '../../../data/change_request/task_model';
import TeamModel from '../../../data/change_request/team_model';
import {Tooltip} from '../../../components/tooltip';

interface ChangeProcessViewProps {
    changeRequestPromise: Promise<ChangeRequestBaseModel>;
}

export default class ChangeProcessView extends TuxComponent<ChangeProcessViewProps, any> {
    isLoading: boolean = true;
    changeRequest?: ChangeRequestBaseModel;
    changeTasks?: TaskModel[];
    changeTeams?: TeamModel[];
    tasksCustomDisplays: CustomDisplayProps;

    constructor(props: ChangeProcessViewProps) {
        super(props);
        this.tasksCustomDisplays = {
            dueDate: (dueDate: Date, item: TaskModel) => (
                <Box
                    color={item.isOverdue() ? 'text-status-error' : 'inherit'}
                    fontWeight={item.isOverdue() ? 'bold' : 'normal'}
                    variant="span"
                    children={new Date(dueDate).toLocaleString()}
                />
            ),
            dateStarted: (d: Date) => d ? new Date(d).toLocaleDateString() : "-",
            dateCompleted: (d: Date) => d ? new Date(d).toLocaleDateString() : "-",
            status: (status: string, item: TaskModel) => <StatusIndicator type={TaskModel.statusType(status)}>{item.stateString}</StatusIndicator>,
            comments: (comment: string, item: TaskModel) => <Tooltip elementId={item.taskId ?? ''} text={comment}>{comment}</Tooltip>,
        };
        this.bindAll(this);
        this.loadData();
    }

    async loadData(): Promise<void> {
        this.changeRequest = await this.props.changeRequestPromise;
        this.changeTasks = await ChangeRequestService.getTasksForCR(this.changeRequest._systemId ? this.changeRequest._systemId : "", this.changeRequest.getType());
        this.changeTeams = await ChangeRequestService.getTeamsForCR(this.changeRequest._systemId ? this.changeRequest._systemId : "", this.changeRequest.getType());

        this.bindAll(this);
        this.isLoading = false;
        this.dataUpdated();
    }

    getMembersSection(): JSX.Element {
        this.changeTeams?.sort((a, b) => (a.role ? a.role : "").localeCompare(b.role ? b.role : ""));
        return (
            <ExpandableTable
                header="Members"
                tableItems={this.changeTeams ?? []}
                tableDisableSelection
                tableIsLoading={this.isLoading}
                defaultExpanded={false}
            />
        );
    }

    getTasksSection(): JSX.Element {
        return (
            <ExpandableTable
                header="Tasks"
                tableItems={this.changeTasks ?? []}
                tableDisableSelection
                tableIsLoading={this.isLoading}
                tableCustomDisplays={this.tasksCustomDisplays}
                tableInitiallyVisibleColumnCount={7}
                tableInitialColumnOrder={["name", "assignee", "vote", "dueDate", "dateStarted", "dateCompleted", "comments"]}   
                defaultSortBy={"dateStarted"}           
                canExport
            />
        );
    }

    render() {
        return (
            <>
                <SpaceBetween size="m">
                    {this.getMembersSection()}
                    {this.getTasksSection()}
                </SpaceBetween>
            </>
        );
    }
}