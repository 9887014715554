import RequestHelper, { AnalyticsRoutes, ErrorDetails, PerformanceDetails } from "./request_helper";

export function measured(type: string) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor){
      const orig = descriptor.value;
      descriptor.value = function (...args: any[]) {
        let start = Date.now();
        let output: any = orig.apply(this, args);
        let end = Date.now();
        if (process.env.NODE_ENV === 'production'){
          RequestHelper.logAnalytics(AnalyticsRoutes.performance, new PerformanceDetails(type, end-start));
        }
        return output;
      }
    };
}

export function measuredAsync(type: string) {
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor){
      const orig = descriptor.value;
      descriptor.value = async function (...args: any[]) {
        let start = Date.now();
        let output: any = await orig.apply(this, args);
        let end = Date.now();
        // if (process.env.NODE_ENV === 'production'){
          RequestHelper.logAnalytics(AnalyticsRoutes.performance, new PerformanceDetails(type, end-start));
        // }
        return output;
      }
    };
}

export function errorLoggedAsync() {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor){
    const orig = descriptor.value;
    descriptor.value = async function (...args: any[]) {
      try{
        let output: any = await orig.apply(this, args);
        return output;
      }catch(e){
        console.log(e);
        RequestHelper.logAnalytics(AnalyticsRoutes.error, new ErrorDetails( (e as Error).message ));
        throw e;
      }
    }
  };
}