import React from 'react';
import TuxComponent from '../../components/tux_view';
import SiteModel from '../../data/site/site_model';
import { NoLoadTabs } from '../../components/components';
import { Badge, Header, SpaceBetween } from '@amzn/awsui-components-react';
import ModuleListModel from '../../data/site/module_list_module';
import { ModuleListDetailsView } from './tabs/module_list_details_view';
import { ModuleListStructureView } from './tabs/module_list_structure_view';
import { ModuleListHistoryView } from './tabs/module_list_history_view';
import SiteModelService from '../../data/site/site_model_service';
import { SiteModelType } from '../../data/site/site_base_model';
import { Box } from '@mui/material';
import Loader from '@amzn/meridian/loader';
import { ModuleListTaskView } from './tabs/module_list_task_view';

interface ModuleListViewProps {
    sitePromise: Promise<SiteModel>;
    systemId: string;
    initialTab?: string;
}

export default class ModuleListView extends TuxComponent<ModuleListViewProps, any> {
    isLoading = true;
    site: SiteModel;
    moduleList: ModuleListModel;
    moduleListHistory: ModuleListModel[] = [];
    currentTab: string;

    constructor(props: ModuleListViewProps) {
        super(props);
        this.site = SiteModel.LOADING_TEMPLATE;
        this.moduleList = ModuleListModel.LOADING_TEMPLATE;
        this.currentTab = this.props.initialTab ?? 'details';
        this.bindAll(this);
        this.loadData();
    }

    public async loadData(): Promise<void> {
        this.site = await this.props.sitePromise;
        this.moduleList = await this.resolveModuleList(this.props.systemId);
        //fetch mdl history by groupId
        this.moduleListHistory =
            this.site._moduleList?.filter((model) => model._version?.groupId === this.moduleList._version?.groupId) ??
            [];
        this.isLoading = false;
        this.dataUpdated();
    }

    private async resolveModuleList(systemId: string): Promise<ModuleListModel> {
        //fetch latest mdl
        for (const model of this.site._latestModuleList ?? []) {
            if (model._systemId === systemId) {
                return model;
            }
        }

        //fetch non-latest mdl
        return (await SiteModelService.fetchModel(SiteModelType.ModuleList, systemId)) as ModuleListModel;
    }

    tabChanged(tabClicked: any) {
        if (!window.location.hash.includes(this.currentTab)) {
            window.location.hash += `/${this.currentTab}`;
        }
        window.history.replaceState(null, 'ES-PLM', window.location.hash.replace(this.currentTab, tabClicked));
        this.currentTab = tabClicked;
    }

    getHeader(): JSX.Element {
        if (this.isLoading) {
            return (
                <Box sx={{ width: '100%' }}>
                    <Loader type="linear" />
                </Box>
            );
        }

        return (
            <Header>
                <SpaceBetween size="m" direction="horizontal">
                    <div>{`${this.moduleList.number}, ${this.moduleList.name}`}</div>
                    <Badge
                        color={this.moduleList.stateString === 'Released' ? 'green' : 'blue'}
                    >{`v${this.moduleList.displayVersionWithState}`}</Badge>
                </SpaceBetween>
            </Header>
        );
    }

    getContent(): JSX.Element {
        return (
            <>
                {this.getHeader()}
                <NoLoadTabs
                    initialTab={this.props.initialTab ?? 'details'}
                    onTabChanged={this.tabChanged}
                    tabs={[
                        {
                            id: 'details',
                            label: 'Details',
                            content: <ModuleListDetailsView moduleList={this.moduleList} />,
                        },
                        {
                            id: 'structure',
                            label: 'Structure',
                            content: <ModuleListStructureView moduleList={this.moduleList} />,
                        },
                        {
                            id: 'history',
                            label: 'History',
                            content: (
                                <ModuleListHistoryView
                                    moduleListHistory={this.moduleListHistory}
                                    isLoading={this.isLoading}
                                    siteSystemId={this.site._systemId ?? ''}
                                />
                            ),
                        },
                        // hiding process column
                        /*
                        {
                            id: 'process',
                            label: 'Process',
                            content: <ModuleListTaskView moduleList={this.moduleList} />,
                        },

                         */
                    ]}
                />
            </>
        );
    }

    render(): JSX.Element {
        return this.getContent();
    }
}
