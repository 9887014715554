import { gql } from '@apollo/client';
import { MODEL_HEADER_FIELDS, getModelAttributes } from './fragments';
import { SiteModelType } from '../site_base_model';

export interface ModelInput {
    systemId: string;
    name?: String;
    number?: String;
    attributes: any;
}

export function getPatchModelMutation(modelType: SiteModelType) {
    const ATTRIBUTES = getModelAttributes(modelType);

    return gql`
    ${MODEL_HEADER_FIELDS}, 
    ${ATTRIBUTES}
    mutation PachModel($modelType: ModelType!, $model: ModelInput!) {
        patchModel(modelType: $modelType, model: $model) {
            ...ModelHeaderFields
            ...Attributes
        }
      }
`;
}

export function getSaveModelMutation(modelType: SiteModelType) {
    const ATTRIBUTES = getModelAttributes(modelType);

    return gql`
    ${MODEL_HEADER_FIELDS}, 
    ${ATTRIBUTES}
    mutation SaveModel($modelType: ModelType!, $model: ModelInput!) {
        saveModel(modelType: $modelType, model: $model) {
            ...ModelHeaderFields
            ...Attributes
        }
      }
`;
}
