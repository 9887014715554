import { AppLayout, AppLayoutProps, NonCancelableCustomEvent } from '@amzn/awsui-components-react-v3';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import PersistentHeader from './components/frame/header';
import PersistentFooter from './components/frame/footer';
import LeftNav from './components/frame/left_nav';
import Routing from './routing';
import SessionHelper from './utils/session_helper';
import UserInfoHelper from './utils/user_info_helper';
import { EventDispatcher, EventTypes } from './modules';

interface BaseAppProps{

}

export default class BaseApp extends React.Component<BaseAppProps, any>{
    navigationOpen: boolean = true;

    render() : JSX.Element{
        return (
            <div id="base_app" style={{visibility:"hidden"}}>
                <PersistentHeader/>
                <AppLayout
                    className="awsui"
                    navigation={UserInfoHelper.availableRegions.length > 0 ? <LeftNav/> : undefined}
                    navigationHide={UserInfoHelper.availableRegions.length === 0}
                    navigationWidth={240}
                    onNavigationChange={(e: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) =>{ EventDispatcher.triggerEvent(EventTypes.SideNavExpansionChange, e.detail.open); return true;}}
                    headerSelector="#header"
                    footerSelector="#footer"
                    content={<Routing/>}
                    toolsHide
                    disableBodyScroll
                />
                <PersistentFooter/>
            </div>
        );
    }
}

UserInfoHelper.loadUserInfoFromCookies();
SessionHelper.init();

ReactDOM.render(
    <BaseApp />,
  document.getElementById('root')
);