import React from 'react';
import TuxView from '../../components/tux_view';
import { Alert, AlertProps, BreadcrumbGroupProps, Button, Header, Link, SpaceBetween, StatusIndicator } from '@amzn/awsui-components-react-v3';
import { BOMEntryModel, BOMService, ContentModel, ContentService, ModuleModel, ModuleService } from '../../data/data';
import ModuleDetailsView from './tabs/module_details_view';
import NoLoadTabs from '../../components/no_load_tabs';
import ModuleStructureView from './tabs/module_structure_view';
import ModuleChangesView from './tabs/module_changes_view';
import ModuleHistoryView from './tabs/module_history_view';
import ModuleModelView from './tabs/module_model_view';
import { ErrorView } from '../../components/components';
import { CookieHelper, RequestHelper, UserInfoHelper } from '../../modules';
import { measuredAsync } from '../../utils/decorators';
import { AnalyticsRoutes, ActionDetails } from '../../utils/request_helper';
import { CookieNames } from '../../utils/cookie_helper';
import SessionHelper from '../../utils/session_helper';
import {Tooltip} from '../../components/tooltip';
import { EventDispatcher } from '../../utils/events/event_dispatcher';
import { EventTypes } from '../../utils/events/event_types';

interface ModuleViewProps{
    number: string;
    version: string;
    initialTab?: string;
    module?: ModuleModel;
    match?: {
        params: {
            region?: string;
            number?: string;
            version?: string;
            initialTab?: string;
        }
    }
}

export default class ModuleView extends TuxView<ModuleViewProps, any> {
                   private alertType: AlertProps.Type = 'info';
                   private isAlertVisible: boolean = false;
                   private alertMessage: string = '';
                   private encounteredCriticalError: boolean = false;
                   private isLoading: boolean = true;
                   private currentTab: string =
                       this.props.initialTab ?? this.props.match?.params?.initialTab ?? 'details';
                   private _modulePromise!: Promise<ModuleModel>;
                   private _module: ModuleModel;
                   private _bom?: BOMEntryModel[];
                   private _content: ContentModel[] = [];
                   private region: string;
                   private latestVersion: string = '';

                   constructor(props: ModuleViewProps) {
                       super(props);
                       this.region = SessionHelper.getRegion();
                       this._module = this.props.module ?? ModuleModel.LOADING_TEMPLATE;
                       this.state = {
                           moduleNumber: this.props.number ?? this.props.match?.params?.number,
                           moduleVersion: this.providedModuleVersion
                       };
                       this.bindAll(this);
                       this.loadData();
                       EventDispatcher.subscribe(EventTypes.RoutingChange, EventTypes.ModuleReload, (t: BreadcrumbGroupProps.Item) => {this.setCurrentPage(t)});
                   }

                   // Function to reload component when route is changed
                   private setCurrentPage(currentRoute: BreadcrumbGroupProps.Item){
                    if(currentRoute.text.includes('MOD')){
                        let newModuleNumber = currentRoute.text.split(' ')[0];
                        // Get the version from route -> Format - MOD-NA-XXX (X.X)
                        let newModuleVersion = currentRoute.text
                            .split(' ')[1]
                            .replaceAll('(', '')
                            .replaceAll(')', '');
                        this.setState({moduleNumber: newModuleNumber, moduleVersion: newModuleVersion}, this.loadData)
                    }
                }

                   get providedModuleVersion(): string {
                       return this.props.version ?? this.props.match?.params.version;
                   }

                   @measuredAsync('load_time')
                   async loadData() {

                        try {
                            this._modulePromise = ModuleService.getModuleFromNumberAndVersion(
                                this.state.moduleNumber, this.state.moduleVersion
                            ).catch(e => this._module);
                        } catch (e) {
                            if (process.env.NODE_ENV !== 'production') {
                                console.log('(DEBUG)', e);
                            }
                            this._modulePromise = new Promise<ModuleModel>(r => r(this._module));
                        }

                       this.isLoading = true;
                       this.dataUpdated();

                       try {
                           this._modulePromise =
                               this.isLoading && this.state.moduleVersion
                                   ? this._modulePromise
                                   : ModuleService.getLatestReleased(
                                         this.state.moduleNumber
                                     );
                           this._module = await this._modulePromise;
                           this.latestVersion = await ModuleService.getLatestVersion(this._module._moduleId ?? '');
                       } catch (e) {
                           this.encounteredCriticalError = true;
                           if (process.env.NODE_ENV !== 'production') {
                               console.error(e);
                           }
                       }

                       try {
                           this._content = await ContentService.getContentForModule(this._module);
                           // Drawing URL should use the content system id
                           if (this._content.length > 0) {
                               let primaryContent = this._content.find(v => v.role === 'Primary');
                               if (primaryContent) {
                                   this.module.modulePDF = `${RequestHelper.CONTENT_HOST}/module/${
                                       this.module._systemId
                                   }/${primaryContent.systemId}?id_token=${CookieHelper.getCookieByName(
                                       CookieNames.id_token
                                   )}`;
                                   // this._module.modulePDF = primaryContent.downloadBytesURL;
                               }
                               console.log('loadData module in Module View:', this.module);
                           }
                       } catch (err) {
                           if (process.env.NODE_ENV !== 'production') {
                               console.log(err);
                           }
                       }

                       this.isLoading = false;
                       this.dataUpdated();
                   }

                   //
                   //#region Getters
                   //

                   /**
                    * Gets the secondary attached content on the current module, if present.
                    * This should be the PDF with the generated BOM.
                    */
                   private get secondaryContent(): ContentModel | undefined {
                       return this._content.find(v => v.role === 'Secondary');
                   }

                   private get module(): ModuleModel {
                       return this._module;
                   }

                   //
                   //#endregion Getters
                   //

                   //
                   //#region Functions
                   //

                   private async getStructure(): Promise<BOMEntryModel[]> {
                       this._bom = await BOMService.getModuleBOM(this._module);

                       this.dataUpdated();
                       return this._bom;
                   }

                   private showAlert(message: string, type: AlertProps.Type = 'info') {
                       this.isAlertVisible = true;
                       this.alertMessage = message;
                       this.alertType = type;
                       this.dataUpdated();
                   }

                   //
                   //#endregion Functions
                   //

                   //
                   //#region Callbacks
                   //

                   private tabChanged(tabClicked: any) {
                       // console.log(tabClicked);
                       if (!window.location.hash.includes(this.providedModuleVersion)) {
                           if (!window.location.hash.endsWith('/')) {
                               window.location.hash += '/';
                           }
                           window.location.hash += `${this.module.versionNumber}`;
                       }
                       if (!window.location.hash.includes(this.currentTab)) {
                           if (!window.location.hash.endsWith('/')) {
                               window.location.hash += '/';
                           }
                           window.location.hash += `${this.currentTab}`;
                       }
                       window.history.replaceState(
                           null,
                           'ES-PLM',
                           window.location.hash.replace(this.currentTab, tabClicked)
                       );
                       this.currentTab = tabClicked;
                   }

                   private async refreshModulePressed() {
                       try {
                           await ModuleService.forceRefresh(this.module);
                       } catch (err) {
                           this.showAlert('Failed to refresh module');
                       }
                       window.location.reload();
                   }

                   private async downloadPressed() {
                       if (!this.secondaryContent?.downloadAttachmentPath) {
                           return;
                       }
                       if (this.secondaryContent?.fileName) {
                           RequestHelper.logAnalytics(
                               AnalyticsRoutes.action,
                               new ActionDetails('pdf_download', this.secondaryContent.fileName)
                           );
                       }
                       try {
                           await ContentService.downloadContent(
                               this.secondaryContent.downloadAttachmentPath,
                               this.secondaryContent?.fileName ?? `${this.module.number}.pdf`
                           );
                       } catch (err) {
                           this.showAlert('Failed to download', 'error');
                       }
                   }

                   private alertDismissed() {
                       this.isAlertVisible = false;
                       this.dataUpdated();
                   }

                   //
                   //#endregion Callbacks
                   //

                   getHeaderActions(): JSX.Element[] {
                       return [
                           <Button
                               key="download_module_pdf"
                               disabled={this.isLoading || !this.secondaryContent?.downloadAttachmentPath}
                               onClick={this.downloadPressed}
                               children={'Download PDF'}
                           />,
                           <div key="spacer_12345" style={{ width: '14px' }} />,
                           <Button
                               variant="icon"
                               key="sync_module"
                               disabled={this.isLoading}
                               iconName="refresh"
                               onClick={this.refreshModulePressed}
                           />
                           // <Button key="download_module_model" disabled={this.isLoading} variant="primary" children={"Download 3D Model"}/>
                       ];
                   }

                   getTitle(): React.ReactNode {
                       return (
                           <SpaceBetween size="xs" direction="horizontal">
                               {this.getGoToLatestLink()}
                               <div key="module_header_details">{`${this._module.number}, ${this._module.name}, ${this._module.version}`}</div>
                               {UserInfoHelper.isAmazonEmployee ? (
                                   <Link key="module_href" href={this._module.getURL()} target="_blank">
                                       (View in Windchill)
                                   </Link>
                               ) : null}
                           </SpaceBetween>
                       );
                   }

                   getHeader(): JSX.Element {
                       if (this.isLoading) {
                           return <Header children={'Loading...'} actions={this.getHeaderActions()} />;
                       }
                       return <Header children={this.getTitle()} actions={this.getHeaderActions()} />;
                   }

                   getGoToLatestLink(): JSX.Element {
                       if (this.module._latest) {
                           return <></>;
                       }
                       return (
                           <Tooltip elementId="go-to-latest" text="Go to Latest Non Rejected Module">
                               <Link
                                   href={`#/${this.region}/modules/${this.module.number}/${
                                       this.latestVersion?.split(' ')[0]
                                   }/details`}
                                   onFollow={e => {
                                       window.location.hash = e.detail.href ?? window.location.hash;
                                       window.location.reload();
                                   }}
                               >
                                   <StatusIndicator colorOverride="red" type="warning">
                                       Go to Latest
                                   </StatusIndicator>
                               </Link>
                           </Tooltip>
                       );
                   }

                   render() {
                       if (this.encounteredCriticalError) {
                           return <ErrorView />;
                       }
                       return (
                           <>
                               <Alert
                                   type={this.alertType}
                                   visible={this.isAlertVisible}
                                   dismissible={true}
                                   onDismiss={this.alertDismissed}
                                   header={this.alertMessage}
                               />
                               {this.getHeader()}
                               {/* <div style={{height:"12px"}}/> */}
                               <NoLoadTabs
                                   initialTab={this.props.initialTab ?? this.props.match?.params?.initialTab}
                                   onTabChanged={this.tabChanged}
                                   tabs={[
                                       {
                                           id: 'details',
                                           label: 'Details',
                                           content: (
                                               <ModuleDetailsView
                                                   modulePromise={this._modulePromise}
                                                   getStructure={this.getStructure}
                                               />
                                           )
                                       },
                                       {
                                           id: 'structure',
                                           label: 'Structure',
                                           content: (
                                               <ModuleStructureView
                                                   modulePromise={this._modulePromise}
                                                   bom={this._bom}
                                               />
                                           )
                                       },
                                       {
                                           id: 'changes',
                                           label: 'Changes',
                                           content: <ModuleChangesView modulePromise={this._modulePromise} />
                                       },
                                       {
                                           id: 'history',
                                           label: 'History',
                                           content: <ModuleHistoryView modulePromise={this._modulePromise} />
                                       },
                                       {
                                           id: '3D',
                                           label: '3D',
                                           content: <ModuleModelView modulePromise={this._modulePromise} />
                                       }
                                   ]}
                               />
                           </>
                       );
                   }
               }