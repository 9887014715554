import React, { useState, useEffect } from 'react';
import Box from '@amzn/meridian/box';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import { Table, TableProps } from '@amzn/geist-ui-components';
import TuxWizard from '../../components/tux_core/tux_wizard';
import type { TuxProgressTrackerStep } from '../../components/tux_core/tux_progress_tracker';
import Link from '@amzn/meridian/link';
import { Pagination } from '@amzn/geist-ui-components';
import ExportToCSVButton from '../../components/export_to_csv_button';
import { SpaceBetween } from '@amzn/awsui-components-react';
import UpdateWorkflowRole from '../mdlUpdateWorkflowTask/mdl_update_workflow_task_view';

const steps: TuxProgressTrackerStep[] = [{ type: 'past', label: 'Step 1 - Manage Role and Submit' }];

const MDLReleaseTaskView: React.FC = () => {
    interface Item {
        moduleId: string;
        moduleName: string;
        moduleRev: string;
        currency: string;
        unitPrice: string;
        quantity: string;
        total: string;
        notes: string;
    }

    const items: Item[] = [
        {
            moduleId: '28144',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Deluxing Area_CAD',
            moduleRev: '1.3',
            currency: 'USD',
            unitPrice: '$5.17',
            quantity: '10.00',
            total: '$51.70',
            notes: '',
        },
        {
            moduleId: '28138',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Dock Door Chutes_CAD',
            moduleRev: '2.2',
            currency: 'USD',
            unitPrice: '$4,477.74',
            quantity: '11.00',
            total: '$49,255.18',
            notes: '',
        },
        {
            moduleId: '28145',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Packing Area_CAD',
            moduleRev: '1.0',
            currency: 'USD',
            unitPrice: '$6.00',
            quantity: '15.00',
            total: '$90.00',
            notes: '',
        },
        {
            moduleId: '28146',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Shipping Area_CAD',
            moduleRev: '2.0',
            currency: 'USD',
            unitPrice: '$7.00',
            quantity: '20.00',
            total: '$140.00',
            notes: '',
        },
        {
            moduleId: '28147',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Receiving Area_CAD',
            moduleRev: '3.0',
            currency: 'USD',
            unitPrice: '$8.00',
            quantity: '25.00',
            total: '$200.00',
            notes: '',
        },
        {
            moduleId: '28148',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Storage Area_CAD',
            moduleRev: '1.5',
            currency: 'USD',
            unitPrice: '$9.00',
            quantity: '30.00',
            total: '$270.00',
            notes: '',
        },
        {
            moduleId: '28149',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Sorting Area_CAD',
            moduleRev: '2.5',
            currency: 'USD',
            unitPrice: '$10.00',
            quantity: '35.00',
            total: '$350.00',
            notes: '',
        },
        {
            moduleId: '28150',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Quality Control_CAD',
            moduleRev: '3.5',
            currency: 'USD',
            unitPrice: '$11.00',
            quantity: '40.00',
            total: '$440.00',
            notes: '',
        },
        {
            moduleId: '28151',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Administration Area_CAD',
            moduleRev: '1.1',
            currency: 'USD',
            unitPrice: '$12.00',
            quantity: '45.00',
            total: '$540.00',
            notes: '',
        },
        {
            moduleId: '28152',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Maintenance Area_CAD',
            moduleRev: '2.1',
            currency: 'USD',
            unitPrice: '$13.00',
            quantity: '50.00',
            total: '$650.00',
            notes: '',
        },
        {
            moduleId: '28153',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Training Area_CAD',
            moduleRev: '3.1',
            currency: 'USD',
            unitPrice: '$14.00',
            quantity: '55.00',
            total: '$770.00',
            notes: '',
        },
        {
            moduleId: '28154',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Security Area_CAD',
            moduleRev: '1.2',
            currency: 'USD',
            unitPrice: '$15.00',
            quantity: '60.00',
            total: '$900.00',
            notes: '',
        },
        {
            moduleId: '28155',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS IT Area_CAD',
            moduleRev: '2.2',
            currency: 'USD',
            unitPrice: '$16.00',
            quantity: '65.00',
            total: '$1,040.00',
            notes: '',
        },
        {
            moduleId: '28156',
            moduleName: 'AMXL (XLDS)_Facilities_All_XLDS Break Area_CAD',
            moduleRev: '3.2',
            currency: 'USD',
            unitPrice: '$17.00',
            quantity: '70.00',
            total: '$1,190.00',
            notes: '',
        },
    ];

    const [filterText, setFilterText] = useState<string>('');
    const [sortColumn, setSortColumn] = useState<keyof Item>('moduleId');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);
    const [filteredItems, setFilteredItems] = useState<readonly Item[]>(items);
    const [paginatedItems, setPaginatedItems] = useState<readonly Item[]>([]);

    const handleFilterChange = (event: React.SetStateAction<string>) => {
        setFilterText(event);
    };

    const onSortingChange: TableProps['onSortingChange'] = (event) => {
        const sortingColumn = event.detail.sortingColumn.sortingField as keyof Item;
        if (sortColumn === sortingColumn) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(sortingColumn);
            setSortDirection('ascending');
        }
    };

    useEffect(() => {
        const lowerCaseFilter = filterText.toLowerCase();
        setFilteredItems(
            items.filter((item) =>
                Object.values(item).some((value) => value.toString().toLowerCase().includes(lowerCaseFilter)),
            ),
        );
    }, [filterText, items]);

    useEffect(() => {
        const sortedItems = [...filteredItems].sort((a, b) => {
            const aValue = a[sortColumn] as string | number;
            const bValue = b[sortColumn] as string | number;
            if (aValue < bValue) {
                return sortDirection === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortDirection === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setFilteredItems(sortedItems);
    }, [sortColumn, sortDirection, filteredItems]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * pageSize;
        const paginated = filteredItems.slice(startIndex, startIndex + pageSize);
        setPaginatedItems(paginated);
    }, [currentPage, pageSize, filteredItems]);

    const getExportables = (): any[] => {
        return filteredItems.map((item) => {
            const temp: any = {};
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    temp[key as keyof Item] = item[key as keyof Item];
                }
            }
            return temp;
        });
    };

    const paginationWithExport = (
        <SpaceBetween size="s" direction="horizontal">
            <Pagination
                currentPageIndex={currentPage}
                pagesCount={Math.ceil(filteredItems.length / pageSize)}
                onChange={(event) => setCurrentPage(event.detail.currentPageIndex)}
            />
            <ExportToCSVButton
                keys={Object.keys(items[0])}
                data={getExportables()}
                filename="mdl_modify_task_export.csv"
            />
            <Button type="secondary">Edit</Button>
        </SpaceBetween>
    );

    const tableProps: TableProps = {
        items: paginatedItems,
        columnDefinitions: [
            { id: 'moduleId', header: 'Module ID', cell: (item: Item) => item.moduleId, sortingField: 'moduleId' },
            {
                id: 'moduleName',
                header: 'Module name',
                cell: (item: Item) => item.moduleName,
                sortingField: 'moduleName',
            },
            { id: 'moduleRev', header: 'Module rev', cell: (item: Item) => item.moduleRev, sortingField: 'moduleRev' },
            { id: 'currency', header: 'Currency', cell: (item: Item) => item.currency, sortingField: 'currency' },
            { id: 'unitPrice', header: 'Unit price', cell: (item: Item) => item.unitPrice, sortingField: 'unitPrice' },
            { id: 'quantity', header: 'Quantity', cell: (item: Item) => item.quantity, sortingField: 'quantity' },
            { id: 'total', header: 'Total', cell: (item: Item) => item.total, sortingField: 'total' },
            { id: 'notes', header: 'Notes', cell: (item: Item) => item.notes, sortingField: 'notes' },
        ],
        sortingColumn: { sortingField: sortColumn },
        sortingDescending: sortDirection === 'descending',
        onSortingChange: onSortingChange,
        filter: (
            <Input placeholder="Filter by any column" value={filterText} onChange={handleFilterChange} width={500} />
        ),
        pagination: paginationWithExport,
    };

    const getBackTwoStepsUrl = () => {
        const url = window.location.href;
        const urlParts = url.split('/');
        const newUrlParts = urlParts.slice(0, -2);
        return newUrlParts.join('/');
    };

    const backTwoStepsUrl = getBackTwoStepsUrl();

    function handleCompare() {
        console.log('Compare MDG');
    }

    function handleRefresh() {
        console.log('Handle refresh');
    }

    return (
        <TuxWizard steps={steps}>
            <div>
                <h3>
                    <b>Release MDL</b>
                </h3>
                {<Link href={backTwoStepsUrl}>View MDL</Link>}
                <UpdateWorkflowRole />
            </div>
            <div>
                <h3>
                    <b>Release MDL</b>
                </h3>
            </div>
        </TuxWizard>
    );
};

export default MDLReleaseTaskView;
