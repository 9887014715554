import React, { useState, useEffect } from 'react';
import Button from '@amzn/meridian/button';
import Input from '@amzn/meridian/input';
import Text from '@amzn/meridian/text';
import Textarea from '@amzn/meridian/textarea';
import Sheet from '@amzn/meridian/sheet';
import Column from '@amzn/meridian/column';
import Row from '@amzn/meridian/row';
import Icon from '@amzn/meridian/icon';
import { SpaceBetween } from '@amzn/awsui-components-react';
import closeSmallTokens from '@amzn/meridian-tokens/base/icon/close-small';
import { Table, TableProps } from '@amzn/geist-ui-components';
import { Pagination } from '@amzn/geist-ui-components';
import ExportToCSVButton from '../../components/export_to_csv_button';
import { ButtonDropdownProps, ButtonDropdown } from '@amzn/geist-ui-components';

const UpdateWorkflowRole: React.FC = () => {
    const [editors, setEditors] = useState<string[]>(['Alvin Zhang', 'Allen McClendon']);
    const [approvers, setApprovers] = useState<string[]>(['Allen McClendon', 'Boba Komolafe']);
    const [admins, setAdmins] = useState<string[]>(['Alvin Zhang', 'Allen McClendon']);
    const [isSheetOpen, setIsSheetOpen] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [value, setValue] = useState<string>('');
    const [currentRole, setCurrentRole] = useState<'editor' | 'approver' | 'admin'>('editor');
    const [tableItems, setTableItems] = useState<{ familyName: string; givenName: string }[]>([]);
    const [filteredItems, setFilteredItems] = useState<{ familyName: string; givenName: string }[]>([]);
    const [paginatedItems, setPaginatedItems] = useState<{ familyName: string; givenName: string }[]>([]);
    const [filterText, setFilterText] = useState<string>('');
    const [sortColumn, setSortColumn] = useState<keyof { familyName: string; givenName: string }>('familyName');
    const [sortDirection, setSortDirection] = useState<'ascending' | 'descending'>('ascending');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [pageSize, setPageSize] = useState<number>(10);

    const handleOpenSheet = (role: 'editor' | 'approver' | 'admin') => {
        setCurrentRole(role);
        setIsSheetOpen(true);
    };

    const handleRemove = (role: 'editor' | 'approver' | 'admin', index: number) => {
        if (role === 'editor') {
            setEditors(editors.filter((_, i) => i !== index));
        } else if (role === 'approver') {
            setApprovers(approvers.filter((_, i) => i !== index));
        } else if (role === 'admin') {
            setAdmins(admins.filter((_, i) => i !== index));
        }
    };

    const handleAddUser = (user: string) => {
        if (currentRole === 'editor') {
            setEditors([...editors, user]);
        } else if (currentRole === 'approver') {
            setApprovers([...approvers, user]);
        } else if (currentRole === 'admin') {
            setAdmins([...admins, user]);
        }
        setIsSheetOpen(false);
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value);
    };

    useEffect(() => {
        const results = [
            { familyName: 'Doe', givenName: 'John' },
            { familyName: 'Smith', givenName: 'Jane' },
            { familyName: 'Brown', givenName: 'Bob' },
            { familyName: 'Johnson', givenName: 'Alice' },
        ].filter(
            ({ familyName, givenName }) =>
                familyName.toLowerCase().includes(searchQuery.toLowerCase()) ||
                givenName.toLowerCase().includes(searchQuery.toLowerCase()),
        );
        setTableItems(results);
        setFilteredItems(results);
    }, [searchQuery]);

    const handleFilterChange = (event: React.SetStateAction<string>) => {
        setFilterText(event);
    };

    const onSortingChange: TableProps['onSortingChange'] = (event) => {
        const sortingColumn = event.detail.sortingColumn.sortingField as keyof {
            familyName: string;
            givenName: string;
        };
        if (sortColumn === sortingColumn) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(sortingColumn);
            setSortDirection('ascending');
        }
    };

    useEffect(() => {
        const lowerCaseFilter = filterText.toLowerCase();
        setFilteredItems(
            tableItems.filter((item) =>
                Object.values(item).some((value) => value.toString().toLowerCase().includes(lowerCaseFilter)),
            ),
        );
    }, [filterText, tableItems]);

    useEffect(() => {
        const sortedItems = [...filteredItems].sort((a, b) => {
            const aValue = a[sortColumn] as string | number;
            const bValue = b[sortColumn] as string | number;
            if (aValue < bValue) {
                return sortDirection === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
                return sortDirection === 'ascending' ? 1 : -1;
            }
            return 0;
        });
        setFilteredItems(sortedItems);
    }, [sortColumn, sortDirection, filteredItems]);

    useEffect(() => {
        const startIndex = (currentPage - 1) * pageSize;
        const paginated = filteredItems.slice(startIndex, startIndex + pageSize);
        setPaginatedItems(paginated);
    }, [currentPage, pageSize, filteredItems]);

    const getExportables = (): any[] => {
        return filteredItems.map((item) => {
            const temp: any = {};
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    temp[key as keyof { familyName: string; givenName: string }] =
                        item[key as keyof { familyName: string; givenName: string }];
                }
            }
            return temp;
        });
    };

    const paginationWithExport = (
        <SpaceBetween size="s" direction="horizontal">
            <Pagination
                currentPageIndex={currentPage}
                pagesCount={Math.ceil(filteredItems.length / pageSize)}
                onChange={(event) => setCurrentPage(event.detail.currentPageIndex)}
            />
            <ExportToCSVButton
                keys={Object.keys(filteredItems[0] || {})}
                data={getExportables()}
                filename="search_results_export.csv"
            />
        </SpaceBetween>
    );

    const tableProps: TableProps = {
        items: paginatedItems,
        columnDefinitions: [
            {
                id: 'select',
                header: 'Select',
                cell: (item: { familyName: string; givenName: string }) => (
                    <Button
                        onClick={() => handleAddUser(`${item.givenName} ${item.familyName}`)}
                        size="small"
                        type="tertiary"
                    >
                        Select
                    </Button>
                ),
            },
            {
                id: 'familyName',
                header: 'Family name',
                cell: (item: { familyName: string; givenName: string }) => item.familyName,
                sortingField: 'familyName',
            },
            {
                id: 'givenName',
                header: 'Given name',
                cell: (item: { familyName: string; givenName: string }) => item.givenName,
                sortingField: 'givenName',
            },
        ],
        sortingColumn: { sortingField: sortColumn },
        sortingDescending: sortDirection === 'descending',
        onSortingChange: onSortingChange,
        filter: (
            <Input placeholder="Filter by any column" value={filterText} onChange={handleFilterChange} width={500} />
        ),
        pagination: paginationWithExport,
    };

    const dropdownItems: ButtonDropdownProps.ItemOrGroup[] = [
        { id: 'rework', text: 'Rework' },
        { id: 'modify-mdl', text: 'Modify MDL' },
        { id: 'select-mdg', text: 'Select MDG' },
    ];

    const onCloseSheet = () => setIsSheetOpen(false);

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                <SpaceBetween direction="vertical" size="l">
                    <SpaceBetween direction="vertical" size="s">
                        <Text type="h500">MDL Editor (follow the sequence)</Text>
                        <Text tag="ol" type="b500">
                            {editors.map((editor, index) => (
                                <li key={index}>
                                    {editor}
                                    <Button onClick={() => handleRemove('editor', index)} size="small" type="icon">
                                        <Icon tokens={closeSmallTokens} color="primary" />
                                    </Button>
                                </li>
                            ))}
                        </Text>
                        <Button onClick={() => handleOpenSheet('editor')} size="small" type="tertiary">
                            Add
                        </Button>
                    </SpaceBetween>
                    <SpaceBetween direction="vertical" size="s">
                        <Text type="h500">MDL Approver</Text>
                        <Text tag="ol" type="b500">
                            {approvers.map((approver, index) => (
                                <li key={index}>
                                    {approver}
                                    <Button onClick={() => handleRemove('approver', index)} size="small" type="icon">
                                        <Icon tokens={closeSmallTokens} color="primary" />
                                    </Button>
                                </li>
                            ))}
                        </Text>
                        <Button onClick={() => handleOpenSheet('approver')} size="small" type="tertiary">
                            Add
                        </Button>
                    </SpaceBetween>
                    <SpaceBetween direction="vertical" size="s">
                        <Text type="h500">Site Admin</Text>
                        <Text tag="ol" type="b500">
                            {admins.map((admin, index) => (
                                <li key={index}>
                                    {admin}
                                    <Button onClick={() => handleRemove('admin', index)} size="small" type="icon">
                                        <Icon tokens={closeSmallTokens} color="primary" />
                                    </Button>
                                </li>
                            ))}
                        </Text>
                        <Button onClick={() => handleOpenSheet('admin')} size="small" type="tertiary">
                            Add
                        </Button>
                    </SpaceBetween>
                    <SpaceBetween direction="vertical" size="s">
                        <Text type="h500">Additional Comment</Text>
                        <Textarea
                            value={value}
                            onChange={setValue}
                            placeholder="Enter value..."
                            label="Free Text"
                            error={false}
                            width="100%"
                        />
                    </SpaceBetween>
                    <SpaceBetween direction="horizontal" size="s">
                        <ButtonDropdown className="headerDropdown" items={dropdownItems}>
                            Rework
                        </ButtonDropdown>
                        <Button size="small" type="tertiary">
                            Release
                        </Button>
                    </SpaceBetween>
                </SpaceBetween>
            </div>

            <Sheet type="push" side="end" open={isSheetOpen} onClose={onCloseSheet}>
                <Column height="100%" width="80%" alignmentHorizontal="start">
                    <Row alignmentHorizontal="end">
                        <Button onClick={onCloseSheet} type="icon" size="small">
                            <Icon tokens={closeSmallTokens} color="primary" />
                        </Button>
                    </Row>
                    <Text type="h400">Add User</Text>
                    <Input placeholder="User Name (Alias)" onChange={handleSearch} width="100%" />
                    <Input placeholder="Email" width="100%" />
                    <Button>Search</Button>
                    <Table {...tableProps} />
                </Column>
            </Sheet>
        </div>
    );
};

export default UpdateWorkflowRole;
