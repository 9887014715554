import { BreadcrumbGroupProps, Button, ButtonDropdown, ButtonDropdownProps, Grid, NonCancelableCustomEvent, SideNavigationProps, SpaceBetween } from '@amzn/awsui-components-react-v3';
import TuxComponent from '../../components/tux_component';
import React from 'react';
import { BreadCrumbRoute } from '../../routing';
import { LEFT_NAV_FAVORITES, IS_PROD } from '../../utils/constants';
import { EventDispatcher } from '../../utils/events/event_dispatcher';
import { EventTypes } from '../../utils/events/event_types';
import StorageHelper from '../../utils/storage_helper';
import UserInfoHelper from '../../utils/user_info_helper';
import SessionHelper from '../../utils/session_helper';
import GlobalSearch from './global_search';
import {TUX_LOGO} from "../../resources/base_64_images";
import { RequestHelper } from '../../modules';
import { AnalyticsRoutes, ActionDetails } from '../../utils/request_helper';
import { TextContent } from '@amzn/awsui-components-react-v3';

interface MainHeaderProps {

}

const headerStyle = {
    background: "#232F3E",
    fontSize: "15pt",
    fontWeight: "bold",
    padding: "10px",
    textAlign: "left",
    width: "100%",
} as React.CSSProperties;

export default class PersistentHeader extends TuxComponent<MainHeaderProps, any>{
    private static ROUTING_CHANGE_KEY = "PERSISTENT_HEADER_ROUTING_CHANGE";
    private static RESET_PASSWORD : string = "RESET_PASSWORD";
    private static CLEAR_RECENTS : string = "CLEAR_RECENTS";
    private static CLEAR_FAVORITES : string = "CLEAR_FAVORITES";
    private static SIGNOUT : string = "SIGNOUT";

    private regionOptions: (ButtonDropdownProps.Item | ButtonDropdownProps.ItemGroup)[] = [];
    private currentPageName: string = "";

    constructor(props: MainHeaderProps) {
        super(props);
        this.bindAll(this);
        this.loadData();
        EventDispatcher.subscribe(EventTypes.RoutingChange, PersistentHeader.ROUTING_CHANGE_KEY, this.setCurrentPage);
    }

    private setCurrentPage(currentRoute: BreadcrumbGroupProps.Item){
        this.currentPageName = currentRoute.text;
        this.dataUpdated();
    }

    async loadData() {
        this.regionOptions = [
            {
                id: "Region",
                text: "Update current region:",
                items: UserInfoHelper.availableRegions.map((r) => ({id:r, text:r})),
            },
        ];
        this.dataUpdated();
    }

    get _favorites(): Set<string>{
        let output = new Set<string>();
        let favs = StorageHelper.getObject<SideNavigationProps.Link[]>(LEFT_NAV_FAVORITES) ?? [];

        for (let fav of favs){
            output.add(fav.text);
        }

        return output;
    }

    menuButtonPressed(event: any) {
        this.menuOpen = true;
        this.dataUpdated();
    }

    accountIconPressed(event: React.MouseEvent<HTMLElement>) {
        this.anchorEl = event.currentTarget;
        this.open = true;
        if (process.env.NODE_ENV !== 'production') {
            console.log("(DEBUG)", "Handle menu");
        }
        this.dataUpdated();
    }

    closeAccountMenu() {
        this.anchorEl = null;
        this.open = false;
        if (process.env.NODE_ENV !== 'production') {
            console.log("(DEBUG)", "Handle close");
        }
        this.dataUpdated();
    }

    /**
     * Updates the region and reloads the current page
     * @param e 
     * @returns 
     */
    onRegionChange(e: any) {
        if (process.env.NODE_ENV !== 'production') {
            console.log("(DEBUG)", e);
        }
        let newRegion: string = e.detail.id;
        let oldRegion = SessionHelper.getRegion();
        if (newRegion === SessionHelper.getRegion()) {
            return;
        }
        RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("region_changed", `${SessionHelper.getRegion()}>${e.detail.id}`));
        SessionHelper.setRegion(newRegion);
        window.location.hash = window.location.hash.replace(oldRegion, newRegion);
        window.location.reload();
    }

    favoritesPressed(){
        EventDispatcher.triggerEvent(EventTypes.FavoritesChange, BreadCrumbRoute.currentRoute);
        this.dataUpdated();
    }

    private clearCookies(){
        let cookies = document.cookie.split(";");

        for (let cookie of cookies){
            let name = cookie.split("=")[0];
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        }
    }

    userActionRequested(e: NonCancelableCustomEvent<ButtonDropdownProps.ItemClickDetails>){
        switch(e.detail.id){
            case(PersistentHeader.CLEAR_RECENTS):
                RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("button_click", "clear_recents"));
                EventDispatcher.triggerEvent(EventTypes.RecentsCleared);
                return;
            case(PersistentHeader.CLEAR_FAVORITES):
                RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("button_click", "clear_favorites"));
                StorageHelper.setObject(LEFT_NAV_FAVORITES, []);
                EventDispatcher.triggerEvent(EventTypes.FavoritesChange);
                this.dataUpdated();
                return;
            case(PersistentHeader.RESET_PASSWORD):
                window.location.href = `http${process.env.NODE_ENV !== 'production'?'':'s'}://${window.location.hostname}/forgotpassword`;
                return;
            case(PersistentHeader.SIGNOUT):
                this.clearCookies();
                window.location.href = `http${process.env.NODE_ENV !== 'production'?'':'s'}://${window.location.hostname}/logout`;
                // window.location.replace(`${window.location.hostname}/logout`);
                return;
        }
    }

    get userActions() {
        let output = [
            {id: PersistentHeader.CLEAR_RECENTS, text:"Clear Recents"},
            {id: PersistentHeader.CLEAR_FAVORITES, text:"Clear Favorites"},
            {id:"account_actions", text:"Account Actions", items:[
                { id: PersistentHeader.SIGNOUT, text: "Sign Out" },
            ].concat(UserInfoHelper.isAmazonEmployee ? [] : [{ id: PersistentHeader.RESET_PASSWORD, text: "Reset Password (External Only)" }])}
        ] as (ButtonDropdownProps.Item | ButtonDropdownProps.ItemGroup) [];

        return output;
    }

    getTuxLogo() {
        return (
            <img height="35px" src={`data:image/png;base64, ${TUX_LOGO}`} alt="TuxLogo" style={{backgroundColor: "transparent"}}></img>
            // // <div style={{ color: "#FFFFFF", fontSize: "15pt", fontWeight: "bold", height: "100%", display: "table" }}>
            //     {/* <span style={{ display: "table-cell", verticalAlign: "middle" }}>ES-PLM Tux</span> */}
            // // </div>
        );
    }

    getUserDropdown() {
        return (
            <ButtonDropdown
                className="headerDropdown"
                items={this.userActions}
                onItemClick={this.userActionRequested}
                children={`Welcome, ${UserInfoHelper.givenName}`}
            />
        );
    }

    getRegionDropdown() {
        return (
            <ButtonDropdown
                className="headerDropdown"
                items={this.regionOptions}
                onItemClick={this.onRegionChange}
                children={`Region: ${SessionHelper.getRegion()}`}
            />
        );
    }

    // Little heart for favoriting a page
    getFavoriteButton(){
        return (
            <div title={this._favorites.has(this.currentPageName) ? "Unfavorite" : "Favorite"}>
                <Button 
                    variant="icon"
                    iconName="heart"
                    className={this._favorites.has(this.currentPageName) ? "primaryIconButton" : undefined}
                    onClick={this.favoritesPressed}
                />
            </div>
        );
    }

    render(): JSX.Element {

        const nonProdBanner = IS_PROD ? null : <TextContent><h1 style={{color: "Yellow", marginRight: "40px"}}>This is NOT Production</h1></TextContent> ;

        return (
            <header style={headerStyle} className="header" id="header">
                <Grid gridDefinition={[{ colspan: 2 }, { colspan: 4 }, { colspan: 6 }]}>
                    {this.getTuxLogo()}
                    <GlobalSearch/>
                    <SpaceBetween size="xxs" direction="horizontal" className="floatRight">
                        {nonProdBanner}
                        {this.getFavoriteButton()}
                        {this.getUserDropdown()}
                        {this.getRegionDropdown()}
                        <div style={{ paddingRight: "24px" }} />
                    </SpaceBetween>
                </Grid>
            </header>
        );
    }
}
