import { int } from 'aws-sdk/clients/datapipeline';

export interface Version {
    groupId: string;
    version: int;
    iteration: int;
}

export enum SiteModelType {
    Site = 'Site',
    NonModuleList = 'NonModuleList',
    ModuleList = 'ModuleList',
    CustomQuote = 'CustomQuote',
    NonModuleProposal = 'NonModuleProposal',
    PostCARProposal = 'PostCARProposal',
    NonModuleProposalFolder ='NonModuleProposalFolder',
    PostCARProposalFolder='PostCARProposalFolder'
}
export const SiteModelDisplayNameType = new Map<string, string>(
    [[SiteModelType.Site, 'Site'],
    [SiteModelType.NonModuleList, 'Non Module List'],
    [SiteModelType.ModuleList, 'Module List'],
    [SiteModelType.CustomQuote, 'Custom Quote'],
    [SiteModelType.NonModuleProposal, 'Non Module Proposal'],
    [SiteModelType.PostCARProposal, 'Post CAR Proposal'],
    [SiteModelType.NonModuleProposalFolder, 'Non Module Proposals'],
    [SiteModelType.PostCARProposalFolder, 'Post CAR Proposals']]
    )

export abstract class SiteBaseModel {
    [key: string]: any;

    _systemId?: string;
    name?: string;
    number?: string;
    createdOn?: Date;
    lastModified?: Date;
    createdBy?: string;
    modifiedBy?: string;
    _version?: Version;
    _modelType?: SiteModelType;

    constructor(json: any) {
        this.init(json);
    }

    protected init(json: any) {
        for (let key of Object.keys(this)) {
            if (key.startsWith('_')) {
                //check _key in the json if not available check in json.attributes or json.structure
                if (json[key.substring(1)]) {
                    this[key] = json[key.substring(1)];
                } else if (json['properties'] && json['properties'][key]) {
                    this[key] = json['properties'][key.substring(1)];
                } else if (json['attributes'] && json['attributes'][key]) {
                    this[key] = json['attributes'][key.substring(1)];
                } else if (json['structure']){
                    this[key] = this.resolveStructureKey(key, json['structure'][key.substring(1)])
                }
            } else {
                //check key in the json if not available check in json.attributes or json.structure
                if (json[key]) {
                    this[key] = json[key];
                } else if (json['properties'] && json['properties'][key]) {
                    this[key] = json['properties'][key];
                } else if (json['attributes'] && json['attributes'][key]) {
                    this[key] = json['attributes'][key];
                } else if (json['structure']) {
                    this[key] = this.resolveStructureKey(key, json['structure'][key])
                } 
            }
        }
    }

    protected abstract resolveStructureKey(key: string, json: any): SiteBaseModel[];
}
