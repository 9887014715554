import React from "react";
import { RequestHelper } from "../modules";
import { AnalyticsRoutes, ErrorDetails } from "../utils/request_helper";
import TuxComponent from './tux_component';

export interface BreadCrumb {
    text: string;
    href: string;
}

export default abstract class TuxView<TuxViewProps, S> extends TuxComponent<TuxViewProps, S>{
    [key: string]: any;
    _isMounted: boolean = false;

    public constructor(props: TuxViewProps) {
        super(props);
        const origLoadData = this.loadData;

        this.loadData = async () => {
            try{
                await origLoadData.call(this);
            }catch(e){
                RequestHelper.logAnalytics(AnalyticsRoutes.error, new ErrorDetails((e as Error).message));
                throw e;
            }
        }

        const origRender = this.render;

        this.render = () => {
            try{
                return origRender.call(this);
            }catch(e){
                RequestHelper.logAnalytics(AnalyticsRoutes.error, new ErrorDetails((e as Error).message));
                throw e;
            }
        }

    }

    public get className(){
        return this.constructor.name;
    }

    public static get className() {
        return this.name;
    }

    render() : JSX.Element {
         return (<>{this.props.children || null}</>);
     }
}