import React from 'react';
import { BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import SessionHelper from '../../utils/session_helper';
import StorageHelper from '../../utils/storage_helper';
import { LEFT_NAV_FAVORITES } from '../../utils/constants';
import TuxComponent from '../../components/tux_component';
import { EventDispatcher } from '../../utils/events/event_dispatcher';
import { EventTypes } from '../../utils/events/event_types';
import SiteModelService from '../../data/site/site_model_service';
import SiteModel from '../../data/site/site_model';
import { SiteModelType } from '../../data/site/site_base_model';
import ModuleListModel from '../../data/site/module_list_module';
import NonModuleListModel from '../../data/site/non_module_list_model';
import PostCARProposalModel from '../../data/site/post_car_proposal_model';
import NonModuleProposalModel from '../../data/site/non_module_proposal_model';
import { SideNavigation, SideNavigationProps } from '@amzn/geist-ui-components';
import ModuleDesignGuideService from '../../data/module/module_design_guide_service';
import ModuleDesignGuideModel from '../../data/module/module_design_guide_model';

interface LeftNavProps {}

export default class LeftNav extends TuxComponent<LeftNavProps, any> {
    public static RECENTS_KEY = 'ESPLM_LEFT_NAV_RECENTS';
    public static RECENTS_CLEARED_KEY = 'ESPLM_LEFT_NAV_RECENTS_CLEARED';

    favoritesExpanded = false;
    recentsExpanded = false;
    changesExpanded = false;
    recents: SideNavigationProps.Link[] = LeftNav.globalRecents;
    favorites: SideNavigationProps.Link[] = LeftNav.globalFavorites;
    changes: SideNavigationProps.Link[] = LeftNav.globalChanges;

    constructor(props: LeftNavProps) {
        super(props);
        this.bindAll(this);

        EventDispatcher.subscribe(EventTypes.RoutingChange, LeftNav.RECENTS_KEY, this.addToRecents);
        // This is from constants since it's both the event key and the localStorage key
        EventDispatcher.subscribe(EventTypes.FavoritesChange, LEFT_NAV_FAVORITES, this.addToFavorites);
        EventDispatcher.subscribe(EventTypes.RecentsCleared, LeftNav.RECENTS_CLEARED_KEY, this.clearRecents);
    }

    public async loadData() {
        // throw new Error('Method not implemented.');
    }

    get defaultNavItems(): SideNavigationProps.Item[] {
        return [
            {
                type: 'expandable-link-group',
                text: 'Favorites',
                items: this.favorites,
                defaultExpanded: this.favoritesExpanded,
            },
            {
                type: 'expandable-link-group',
                text: 'Recents',
                items: this.recents,
                defaultExpanded: this.recentsExpanded,
            },
            {
                type: 'link',
                text: 'Modules',
                href: `#/${SessionHelper.getRegion()}/modules`,
            },
            {
                type: 'expandable-link-group',
                text: 'Changes',
                items: this.changes,
                defaultExpanded: this.changesExpanded,
            },
            {
                type: 'link',
                text: 'Sites',
                href: `#/${SessionHelper.getRegion()}/sites`,
            },
            {
                type: 'link',
                text: 'Module Design Guides',
                href: `#/${SessionHelper.getRegion()}/moduleDesignGuides`,
            },
        ] as SideNavigationProps.Item[];
    }

    get navItems(): SideNavigationProps.Item[] {
        const output = this.defaultNavItems;

        if (process.env.NODE_ENV !== 'production') {
            output.push({
                type: 'link',
                text: 'Test (Remove for Go-Live)',
                href: `#/test`,
            });
        }

        return output;
    }

    static get globalFavorites(): SideNavigationProps.Link[] {
        return StorageHelper.getObject<SideNavigationProps.Link[]>(LEFT_NAV_FAVORITES, []) ?? [];
    }

    static get globalRecents(): SideNavigationProps.Link[] {
        return StorageHelper.getObject<SideNavigationProps.Link[]>(LeftNav.RECENTS_KEY, []) ?? [];
    }

    static get globalChanges(): SideNavigationProps.Link[] {
        const changesLeftNav: SideNavigationProps.Link[] = [
            {
                type: 'link',
                text: 'Archive Changes',
                href: `#/${SessionHelper.getRegion()}/changes/archive`,
            },
            {
                type: 'link',
                text: 'Major Changes',
                href: `#/${SessionHelper.getRegion()}/changes/major`,
            },
            {
                type: 'link',
                text: 'Minor Changes',
                href: `#/${SessionHelper.getRegion()}/changes/minor`,
            },
        ];

        return changesLeftNav;
    }

    // Function for sites to add Site Name and MDG to add MDG Name to Recent instead of IDs
    async changeRouteIdToText(route: BreadcrumbGroupProps.Item) {
        if (route.href.includes('sites')) {
            let siteIdMatch = route.href.match('sites/([a-zA-Z0-9]*)/relatedItems/([a-zA-Z0-9]*)/([a-zA-Z0-9]*)') ?? [];
            if (siteIdMatch?.length === 0) {
                siteIdMatch = route.href.match('sites/([a-zA-Z0-9]*)/') ?? [];
                if (siteIdMatch?.length < 2) {
                    return route.text;
                }
                const siteId = siteIdMatch[1];
                const site: SiteModel = (await SiteModelService.fetchModel(SiteModelType.Site, siteId)) as SiteModel;
                return site.name ?? '';
            } else {
                if (siteIdMatch?.length < 2) {
                    return route.text;
                }
                const relatedItemType = siteIdMatch[2];
                const relatedItemId = siteIdMatch[3];

                if (relatedItemType === 'ModuleList') {
                    const mdl: ModuleListModel = (await SiteModelService.fetchModel(
                        SiteModelType.ModuleList,
                        relatedItemId,
                    )) as ModuleListModel;
                    return mdl.number ?? '';
                } else if (relatedItemType === 'NonModuleList') {
                    const nml: NonModuleListModel = (await SiteModelService.fetchModel(
                        SiteModelType.NonModuleList,
                        relatedItemId,
                    )) as NonModuleListModel;
                    return nml.number ?? '';
                } else if (relatedItemType === 'PostCARProposal') {
                    const pcp: PostCARProposalModel = (await SiteModelService.fetchModel(
                        SiteModelType.PostCARProposal,
                        relatedItemId,
                    )) as PostCARProposalModel;
                    return pcp.number ?? '';
                } else if (relatedItemType === 'NonModuleProposal') {
                    const nmp: NonModuleProposalModel = (await SiteModelService.fetchModel(
                        SiteModelType.NonModuleProposal,
                        relatedItemId,
                    )) as NonModuleProposalModel;
                    return nmp.number ?? '';
                }
            }
        } else if (route.href.includes('moduleDesignGuide')) {
            /* empty */
            const moduleDesignGuideIdMatch = route.href.match('moduleDesignGuide/([a-zA-Z0-9]*)/') ?? [];
            if (moduleDesignGuideIdMatch?.length < 2) {
                return route.text;
            }
            const moduleDesignGuideId = moduleDesignGuideIdMatch[1];
            const moduleDesignGuideModel: ModuleDesignGuideModel = (await ModuleDesignGuideService.getModuleDesignGuide(
                moduleDesignGuideId,
            )) as ModuleDesignGuideModel;
            return 'MDG - ' + moduleDesignGuideModel.name;
        }

        // Return route.text in default case
        return route.text;
    }

    async addToFavorites(route: BreadcrumbGroupProps.Item) {
        if (!route) {
            this.favorites = LeftNav.globalFavorites;
            this.dataUpdated();
            return;
        }

        if (!route.href || !route.text) {
            return;
        }

        this.favorites = LeftNav.globalFavorites;

        route.text = (await this.changeRouteIdToText(route)) ?? '';

        // Route was already in favorites, remove it
        if (this.favorites.find((f) => f.text === route.text)) {
            this.favorites = this.favorites.filter((f) => f.text !== route.text);
        } else {
            // Insert the new link as the top favorite
            this.favorites = [
                {
                    type: 'link',
                    text: route.text,
                    href: route.href,
                } as SideNavigationProps.Link,
            ].concat(this.favorites);
        }

        StorageHelper.setObject(LEFT_NAV_FAVORITES, this.favorites);
        this.dataUpdated();
    }

    async addToRecents(route: BreadcrumbGroupProps.Item) {
        if (!route.href || !route.text) {
            this.dataUpdated();
            return;
        }
        // Don't add links to home to recents
        if (route.href.endsWith('home') || route.href.endsWith('modules')) {
            this.dataUpdated();
            return;
        }

        route.text = (await this.changeRouteIdToText(route)) ?? '';

        this.recents = LeftNav.globalRecents;
        // Filter out the page if it's already present
        this.recents = this.recents.filter((r) => r.href !== route.href && r.text !== route.text);

        // Add the item to recents so it's the latest entry
        this.recents = [
            {
                type: 'link',
                text: route.text,
                href: route.href,
            } as SideNavigationProps.Link,
        ].concat(this.recents);

        if (this.recents.length > 10) {
            this.recents = this.recents.slice(0, 10);
        }

        StorageHelper.setObject(LeftNav.RECENTS_KEY, this.recents);
        this.dataUpdated();
    }

    clearRecents() {
        this.recents = [];
        StorageHelper.setObject(LeftNav.RECENTS_KEY, this.recents);
        this.dataUpdated();
    }

    navChanged(e: any) {
        if (e.detail.item.text === 'Recents') {
            this.recentsExpanded = e.detail.expanded;
        } else if (e.detail.item.text === 'Favorites') {
            this.favoritesExpanded = e.detail.expanded;
        } else if (e.detail.item.text === 'Changes') {
            this.changesExpanded = e.detail.expanded;
        }
    }

    render(): JSX.Element {
        return (
            <SideNavigation
                activeHref={window.location.hash}
                header={{ href: `#/${SessionHelper.getRegion()}/home`, text: 'Home' }}
                items={this.navItems}
                className="leftNav"
                onChange={this.navChanged}
            />
        );
    }
}
