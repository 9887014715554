import React from 'react';
import { TuxView, ValueWithLabel } from '../../components/components';
import {
    ColumnLayout,
    ExpandableSection,
    Grid,
    Header,
    SpaceBetween,
    StatusIndicator
} from '@amzn/awsui-components-react-v3';
import SiteModel from '../../data/site/site_model';
import SiteModelService from '../../data/site/site_model_service';
import { SiteModelType } from '../../data/site/site_base_model';
import { ModuleModel } from '../../data/data';

interface CompareSiteViewProps {
    siteA: string;
    siteB: string;
}

/**
 * Compares 2 Sites' atributes. Not used anywhere right now. Can be accessed only through URL.
 * Will be added to Sites Table Later.
 */
export default class CompareSiteView extends TuxView<CompareSiteViewProps, any> {

    props: CompareSiteViewProps;

    isLoading: boolean = true;
    siteA: SiteModel = SiteModel.LOADING_TEMPLATE;
    siteB: SiteModel = SiteModel.LOADING_TEMPLATE;
    siteAPromise: Promise<SiteModel>;
    siteBPromise: Promise<SiteModel>;
    siteASystemId: string;
    siteBSystemId: string;
    attributesExpanded: boolean = true;
    systemDetailsExpanded: boolean = true;

    constructor(props: CompareSiteViewProps) {
        super(props);
        this.props = props;

        this.siteASystemId = this.props.siteA;
        this.siteBSystemId = this.props.siteB;

        this.siteAPromise = SiteModelService.fetchModel(SiteModelType.Site, this.siteASystemId) as Promise<SiteModel>;
        this.siteBPromise = SiteModelService.fetchModel(SiteModelType.Site, this.siteBSystemId) as Promise<SiteModel>;
        this.bindAll(this);
        this.loadData();
    }

    async loadData() {
        this.siteA = await this.siteAPromise;
        this.siteB = await this.siteBPromise;
        this.isLoading = false;
        this.dataUpdated();
    }

    getHeader(siteObj: SiteModel): JSX.Element {
        if (this.isLoading) {
            return <Header children={'Loading...'} />;
        }
        return (
            <Header description={siteObj.program}>
                <SpaceBetween size="xs" direction="horizontal">
                    <div key="site_header_title">{`${siteObj.name}`}</div>
                </SpaceBetween>
            </Header>
        );
    }

    attributeExpansionChanged() {
        this.attributesExpanded = !this.attributesExpanded;
        this.dataUpdated();
    }

    systemDetailsExpansionChange() {
        this.systemDetailsExpanded = !this.systemDetailsExpanded;
        this.dataUpdated();
    }

    getAttributes(siteObj: SiteModel, otherSiteObj: SiteModel): JSX.Element {
        let header = <Header variant="h2">Attributes - {siteObj.name}</Header>;
        return (
            <>
                <ExpandableSection
                    variant="container"
                    header={header}
                    expanded={this.attributesExpanded}
                    defaultExpanded={true}
                    id={siteObj.name + '_attributes'}
                    onChange={this.attributeExpansionChanged}
                >
                    <ColumnLayout columns={3} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel hasChanged={siteObj.name !== otherSiteObj.name} label="Name">
                                {siteObj.name}
                            </ValueWithLabel>
                            <ValueWithLabel hasChanged={siteObj.shieldId !== otherSiteObj.shieldId} label="Shield ID">
                                {siteObj.shieldId}
                            </ValueWithLabel>
                            <ValueWithLabel hasChanged={siteObj.region !== otherSiteObj.region} label="Region">
                                {siteObj.region}
                            </ValueWithLabel>
                            <ValueWithLabel hasChanged={siteObj.country !== otherSiteObj.country} label="Country">
                                {siteObj.country}
                            </ValueWithLabel>
                            <ValueWithLabel hasChanged={siteObj.porYear !== otherSiteObj.porYear} label="POR Year">
                                {siteObj.porYear}
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel hasChanged={siteObj.program !== otherSiteObj.program} label="Program">
                                {siteObj.program}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={siteObj.buildingType !== otherSiteObj.buildingType}
                                label="Building Type"
                            >
                                {siteObj.buildingType}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={siteObj.facilityType !== otherSiteObj.facilityType}
                                label="Facility Type"
                            >
                                {siteObj.facilityType}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={siteObj.facilityName !== otherSiteObj.facilityName}
                                label="Facility Name"
                            >
                                {siteObj.facilityName}
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                hasChanged={siteObj.projectNumber !== otherSiteObj.projectNumber}
                                label="Project Number"
                            >
                                {siteObj.projectNumber}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={siteObj.projectStatus !== otherSiteObj.projectStatus}
                                label="Project Status"
                            >
                                <StatusIndicator type={ModuleModel.statusType(siteObj.projectStatus ?? '')}>
                                    {siteObj.projectStatus}
                                </StatusIndicator>
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={siteObj.firstReceive !== otherSiteObj.firstReceive}
                                label="First Receive"
                            >
                                {siteObj.firstReceive}
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExpandableSection>
            </>
        );
    }

    getSystem(siteObj: SiteModel, otherSiteObj: SiteModel): JSX.Element {
        let header = <Header variant="h2">System - {siteObj.name}</Header>;
        return (
            <>
                <ExpandableSection
                    variant="container"
                    header={header}
                    expanded={this.systemDetailsExpanded}
                    defaultExpanded={true}
                    id={siteObj.name + '_system'}
                    onChange={this.systemDetailsExpansionChange}
                >
                    <ColumnLayout columns={2} variant="text-grid">
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                hasChanged={siteObj.createdBy !== otherSiteObj.createdBy}
                                label="Created By"
                            >
                                {siteObj.createdBy}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={siteObj.createdOn !== otherSiteObj.createdOn}
                                label="Created On"
                            >
                                {siteObj.createdOn}
                            </ValueWithLabel>
                        </SpaceBetween>
                        <SpaceBetween size="l">
                            <ValueWithLabel
                                hasChanged={siteObj.modifiedBy !== otherSiteObj.modifiedBy}
                                label="Modified By"
                            >
                                {siteObj.modifiedBy}
                            </ValueWithLabel>
                            <ValueWithLabel
                                hasChanged={siteObj.lastModified !== otherSiteObj.lastModified}
                                label="Last Modified"
                            >
                                {siteObj.lastModified}
                            </ValueWithLabel>
                        </SpaceBetween>
                    </ColumnLayout>
                </ExpandableSection>
            </>
        );
    }

    getComparison(): JSX.Element {
        return (
            <div>
                <Header variant="h1">Compare: {`${this.siteA.name} & ${this.siteB.name}`}</Header>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <div>
                        {this.getHeader(this.siteA)}
                        <SpaceBetween size='l'>
                            {this.getAttributes(this.siteA, this.siteB)}
                            {this.getSystem(this.siteA, this.siteB)}
                        </SpaceBetween>
                    </div>
                    <div>
                        {this.getHeader(this.siteB)}
                        <SpaceBetween size='l'>
                            {this.getAttributes(this.siteB, this.siteA)}
                            {this.getSystem(this.siteA, this.siteB)}
                        </SpaceBetween>
                    </div>
                </Grid>
            </div>
        );
    }
    render() {
        return (
            <>
                <SpaceBetween size="s" direction="vertical">
                    {this.getComparison()}
                </SpaceBetween>
                <div style={{ height: '12px' }} />
            </>
        );
    }
}
