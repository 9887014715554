import React from 'react';
import { BOMEntryModel, BOMService, ContentModel, ContentService, CostBreakdown, ModuleModel } from '../../../data/data';
import { Alert, SpaceBetween } from '@amzn/awsui-components-react-v3';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { TuxComponent } from '../../../components/components';
import ExpandableProperties, { PropertiesEntry } from '../../../components/expandable_properties';
import DynamicExpandableProperties, {  KeyValueSection, KeyValueTemplate, } from '../../../components/dynamic_expandable_properties';
import RequestHelper from '../../../utils/request_helper';
import UserInfoHelper from '../../../utils/user_info_helper';
import { measuredAsync } from '../../../utils/decorators';
import { CookieHelper } from '../../../modules';
import { CookieNames } from '../../../utils/cookie_helper';

const MODULE_ATTR_TEMPLATE: KeyValueTemplate = {
    title: "Attributes",
    type: "key-value",
    defaultExpanded: true,
    sections: [
        [
            {title:"Name", key:"name"},
            {title:"State", key:"stateString", type:"status", states:{Released: "success", "Released - Minor":"success", Archived: "stopped", Rejected: "error", default:"pending", "Released - Archived":"stopped"}},
            {title:"Building Type", key:"buildingType", type:"new-line-separated", separator:",", sorted:true},
            {title:"Country", key:"country", type:"new-line-separated", separator:",", sorted:true},
            // {title:"Placement Notes", key:"placementNotes"},// (date, datetime, or time)
        ] as KeyValueSection[],
        [
            {title: "Check Out Status", key: "checkoutState"},
            // {title: "Module Drawing", key:"drawingNumber"},
            // {title: "Module PDF", key:"modulePDFLink"},
            {title: "On Composite", key:"onComposite"},
            {title: "Last Modified", key:"lastModified", type:"datetime"},
            {title: "Last Modified By", key:"modifiedBy"}
        ],
    ],
};

const MODULE_PDF_TEMPLATE: KeyValueTemplate = {
    title: "Drawing",
    type:"pdf",
    defaultExpanded: false,
    sections: [],
    sourceKey: "modulePDF",
    filenameKey: "name"
};

const TEMPLATES:KeyValueTemplate[] = [
    MODULE_ATTR_TEMPLATE,
    MODULE_PDF_TEMPLATE,
    // {
    //     title:"Test",
    //     type:"key-value",
    //     defaultExpanded: true,
    //     sections: []
    // }
];

interface ModuleDetailsViewProps{
    // module: ModuleModel;
    modulePromise: Promise<ModuleModel>;
    getStructure?: () => Promise<BOMEntryModel[]>;
}

export default class ModuleDetailsView extends TuxComponent<ModuleDetailsViewProps, any>{
    isLoading: boolean = true;
    costBreakdown?: CostBreakdown;
    module?: ModuleModel;
    bom?: BOMEntryModel[];
    pdfPageCount: number = 0;

    constructor(props: ModuleDetailsViewProps){
        super(props);

        this.bindAll(this);
        this.loadData();
    }

    @measuredAsync("load_time_tab")
    async loadData(){
        this.module = await this.props.modulePromise;
        try{
            this.costBreakdown = await BOMService.getCostBreakdown(this.module);
        }catch(err){
            if (process.env.NODE_ENV !== 'production'){
                console.log(err);
            }
        }

        if (!this.module.modulePDF){
            let drawingDetails: ContentModel[] = await ContentService.getContentForModule(this.module);

            if (process.env.NODE_ENV !== 'production'){
                console.log("(DEBUG)","Drawing Details", drawingDetails);
            }
            // Drawing URL should use the content system id
            if (drawingDetails.length > 0){
                let drawingId = drawingDetails.find((v) => v.role==="Primary")?.systemId;
                if (drawingId){
                    this.module.modulePDF = `${RequestHelper.CONTENT_HOST}/module/${this.module._systemId}/${drawingId}?id_token=${CookieHelper.getCookieByName(CookieNames.id_token)}`;
                    // this.module.modulePDF = `${RequestHelper.HOST}/content/${drawingId}/downloadBytes`;
                }
                console.log("loadData module in Details View:", this.module);
            }
        } else {
            console.log("Already had module pdf:", this.module.modulePDF);
        }

        this.isLoading = false;
        this.dataUpdated();
    }


    getCostBreakdown():React.ReactNode{
        if (!UserInfoHelper.isAmazonEmployee){
            return <></>
        }

        if (!this.costBreakdown && !this.isLoading){
            return <Alert type="warning" header="Failed to get cost breakdown"/>;
        }

        let items:PropertiesEntry[] = [];
        if (!this.isLoading){
            let breakdown = this.costBreakdown ?? new CostBreakdown();
            items.push({label:"Total Cost", value: `$${breakdown.totalCost?.toFixed(2)}`});

            for (let key of Object.keys(breakdown.carTabs)){
                items.push({label: key, value: `$${breakdown.carTabs[key].toFixed(2)}`});
            }
        } else {
            items.push({label: "Loading...", value:""})
        }

        return (
            <ExpandableProperties
                header="Cost Breakdown"
                defaultExpanded={false}
                onExpansionChanged={this.costBreakdownExpanded}
                items={[items]}
            />
        );
    }

    pdfLoaded(pdf: any){
        this.pdfPageCount = pdf.numPages;
        this.dataUpdated();
    }

    render(){
        // if (this.isLoading){
        //     return <Spinner size="big"/>
        // }
        if (!this.module && !this.isLoading){
            return <Alert type="error" header="Failed to get the specified module"></Alert>
        }

        return (
            <SpaceBetween size="m">
                {TEMPLATES.map((template) => <DynamicExpandableProperties key={Math.random()} object={this.module} template={template}/>)}
                {this.getCostBreakdown()}
            </SpaceBetween>
        );
    }
}
