// import LibraryModel from '../library/library_model';
import { pickRand as rand } from '../../utils/tools';
import AffectedItemModel from './affected_item_model';
import TaskModel from './task_model';
import TeamModel from './team_model';
import ChangeRequestBaseModel from './change_request_base_model';
import MinorChangeRequestModel from './minor_change_request_model';
import MajorChangeRequestModel from './major_change_request_module';
import ArchiveChangeRequestModel from './archive_change_request_model';
import { RequestHelper } from '../../modules';
import ContentModel from '../content/content_model';

export default class ChangeRequestService {
    static getModuleCount() {
        // Connection conn = ConnectionHelper.getConnection("/modules");
    }

    static cachedGetMajorChangesFromRegion(region: string, callback: (changes: MajorChangeRequestModel[]) => void): MajorChangeRequestModel[] {
        if (!region) {
            throw new Error("Cannot get changes from library with no region.");
        }

        let response: any[] = [];

        response = RequestHelper.cachedServiceRequest<any[]>(`/change/major/all/${region}`, (m) => callback(m.map((r) => new MajorChangeRequestModel(r)))) ?? [];

        return response.map((r) => new MajorChangeRequestModel(r));
    }

    static async getCRFromNumber(number: string, type: string): Promise<ChangeRequestBaseModel> {
        if (!number) {
            throw new Error("Cannot get cr without number");
        }

        let response: any = await RequestHelper.serviceRequest(`/change/${type}/${number}`);

        if (process.env.NODE_ENV !== 'production') {
            console.log("(DEBUG)", response);
        }

        if (type === "major")
            return new MajorChangeRequestModel(response);
        else if (type === "minor")
            return new MinorChangeRequestModel(response);
        else
            return new ArchiveChangeRequestModel(response);
    }

    static async getChangeRequestsFromRegion(region: string, type: string, page?: number, size?: number): Promise<ChangeRequestBaseModel[]>{
        if (!region){
            throw new Error("Cannot get changes from blank region.");
        }

        let endpointPath = `/change/${type}/all/${region}`

        if(page !== undefined && size !== undefined){
            endpointPath = `${endpointPath}?page=${page}&size=${size}`
        }

        let response: any[] = await RequestHelper.serviceRequest(endpointPath);

        if(type === "major")
            return response.map((r) => new MajorChangeRequestModel(r));
        else if (type === "minor")
            return response.map((r) => new MinorChangeRequestModel(r));
        else
            return response.map((r) => new ArchiveChangeRequestModel(r));

    }

    static async getAffectedItemsForCR(systemId: string, type: string): Promise<AffectedItemModel[]>{
        if (!systemId || !type){
            throw new Error("Cannot get affected items without systemId and type.");
        }

        let response: any[] = await RequestHelper.serviceRequest(`/change/${type}/affected/${systemId}`);

        return response.map((r) => AffectedItemModel.fromJSON(r));
    }

    static async getAttachmentsForCR(systemId: string, type: string): Promise<ContentModel[]> {
        if (!systemId || !type){
            throw new Error("Cannot get attachments without systemId and type.");
        }

        let response: any[] = await RequestHelper.serviceRequest(`/content/${type}/${systemId}`);

        return response.map((r) => ContentModel.fromJSON(r));
    }

    static async getTasksForCR(systemId: string, type: string) : Promise<TaskModel[]>{
        if (!systemId || !type){
            throw new Error("Cannot get tasks without systemId and type.");
        }

        let response: any[] = await RequestHelper.serviceRequest(`/change/${type}/${systemId}/tasks`);

        let workItemList: any[] = response ? response[0].workItemList : [];
       
        return workItemList.map((r) => TaskModel.fromJSON(r));
    }

    static async getTeamsForCR(systemId: string, type: string): Promise<TeamModel[]>{
        if (!systemId || !type){
            throw new Error("Cannot get tasks without systemId and type.");
        }

        let response: any = await RequestHelper.serviceRequest(`/change/${type}/${systemId}/team`);

        let rolePrincipalList: any[] =  response.rolePrincipalList;

        return rolePrincipalList.map((r) => TeamModel.fromJSON(r));
    }


    static async getRelatedChangeRequests(modSystemId: string): Promise<ChangeRequestBaseModel[]>{
        if (!modSystemId){
            throw new Error("Cannot get related CRs without module systemId");
        }

        let response: any[] = await RequestHelper.serviceRequest(`/change/any/affects/${modSystemId}`);

        return response.map((r) => ChangeRequestService.buildChangeRequest(r));
    }

    static buildChangeRequest(json: any): ChangeRequestBaseModel{
        let changeType = json["changeType"];

        if (changeType === "major")
            return new MajorChangeRequestModel(json);
        else if (changeType === "minor")
            return new MinorChangeRequestModel(json);
        
        return new ArchiveChangeRequestModel(json);
    }

    static getTestChangeRequest(region?: string): ChangeRequestBaseModel {
        let states = [
            'Open',
            'Submitted',
            'CCM Assigned',
            'CCM Unassigned',
            'Pending Approval',
            'Approved',
            'Implementation',
            'Rework',
            'Released',
            'Rejected'
        ];
        let names = [
            'Test module',
            'Air Conditioner',
            'AR Charging Station',
            'Docking Station',
            'Desk',
            'Extra Module Zone',
            'Learning Center'
        ];

        return new MajorChangeRequestModel({
            number: `CR-${Math.floor(Math.random() * 9999999)}`,
            name: rand(names),
            state: rand(states),
            createdOn: new Date()
        });
    }


    static getTestChangeRequests(count: number = 100, region?: string): ChangeRequestBaseModel[] {
        let output: ChangeRequestBaseModel[] = [];

        for (let i = 0; i < count; i++) {
            output.push(this.getTestChangeRequest(region));
        }
        return output;
    }

}
