// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ct__content {
    padding: 6px 10px;
    color: #cdd1e0;
    font-size: 14px;
    text-align: center;
    letter-spacing: .02em;
    line-height: 1em;
    font-weight: bold;
    word-break: break-word;
}

.tooltip_content{
    text-overflow: ellipsis;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/components/tooltip_style.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,iBAAiB;IACjB,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,gBAAgB;AACpB","sourcesContent":[".ct__content {\n    padding: 6px 10px;\n    color: #cdd1e0;\n    font-size: 14px;\n    text-align: center;\n    letter-spacing: .02em;\n    line-height: 1em;\n    font-weight: bold;\n    word-break: break-word;\n}\n\n.tooltip_content{\n    text-overflow: ellipsis;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
