import React from 'react';
import TuxComponent from './tux_component';
import { Tabs, TabsProps } from '@amzn/geist-ui-components';

interface NoLoadTabsProps {
    tabs: Tab[];
    initialTab?: string | null;
    onTabChanged?: (tab_id: string) => void;
    /** Replace the current tab id in the url with the new one, if not present, appends to end of hash */
    updateHash?: boolean;
}

export interface Tab {
    id: string;
    label: string;
    content: React.ReactNode;
}

export default class NoLoadTabs extends TuxComponent<NoLoadTabsProps, any> {
    static totalIds = 0;
    id: string;
    currentTab = 0;
    // _tabs: TabsProps.Tab[];
    currentlyRenderedTabs: string[] = [];

    public constructor(props: NoLoadTabsProps) {
        super(props);
        NoLoadTabs.totalIds++;
        this.id = 'NoLoadTabs' + NoLoadTabs.totalIds;
        // console.log(`Initial tab id: ${this.props.initialTab}`);
        this.currentTab = this.props.tabs.findIndex((t) => t.id === this.props.initialTab);
        // console.log(this.currentTab);

        if (this.currentTab < 0) {
            this.currentTab = 0;
        }
        // console.log(this.currentTab);

        this.tabChanged = this.tabChanged.bind(this);
    }

    get _tabs(): TabsProps.Tab[] {
        const tabs: TabsProps.Tab[] = [];
        for (const index in this.props.tabs) {
            const label = this.props.tabs[index].label;
            const id = this.props.tabs[index].id;
            tabs.push({
                label: label,
                id: id,
                content: <div />,
            });
        }
        return tabs;
    }

    async loadData() {
        // Do nothing
    }

    private tabChanged(e: any) {
        // Unhide content
        const newTabID = e.detail.activeTabId;
        const currentTabID = this._tabs[this.currentTab].id;

        if (this.props.updateHash) {
            if (!window.location.hash.includes(this._tabs[this.currentTab].id)) {
                window.location.hash += `/${this._tabs[this.currentTab].id}`;
            }
            window.history.replaceState(null, document.title, window.location.hash.replace(currentTabID, newTabID));
        }

        // console.log(`Current Tab: ${this.tabs[this.currentTab].id}`);
        // console.log(`New Tab: ${newTabID}`);
        const index = this._tabs.findIndex((t) => t.id === newTabID);
        document.getElementById(this._tabs[this.currentTab].id)?.setAttribute('hidden', 'true');
        document.getElementById(newTabID)?.setAttribute('hidden', 'false');
        this.currentTab = index;
        try {
            if (this.props.onTabChanged) {
                // console.log(`Calling onTabChanged(${newTabID})`);
                this.props.onTabChanged(newTabID);
            }
        } catch (e) {
            // do nothing
        }

        this.dataUpdated();
    }

    private get divs() {
        const output = [];
        if (!this.currentlyRenderedTabs.includes(this.props.tabs[this.currentTab].id)) {
            this.currentlyRenderedTabs.push(this.props.tabs[this.currentTab].id);
        }

        for (const index in this._tabs) {
            const id: string = this._tabs[index].id;
            // Push the content as long as it's in the list of currently rendered tabs
            output.push(
                <div
                    key={this._tabs[index].id}
                    id={this._tabs[index].id}
                    hidden={parseInt(index) !== this.currentTab}
                    style={{ height: 'calc(100% - 80px)' }}
                >
                    {this.currentlyRenderedTabs.includes(id) ? this.props.tabs[index].content : <div />}
                </div>,
            );
        }
        return output;
    }

    render(): JSX.Element {
        // console.log(`Active Tab: ${this.tabs[this.currentTab].id}`);
        return (
            <div id={this.id} style={{ height: 'calc(100% - 80px)' }}>
                <div style={{ maxHeight: '80px', overflow: 'hidden', marginBottom: '20px' }}>
                    <Tabs
                        className="centeredTabs"
                        tabs={this._tabs}
                        activeTabId={this._tabs[this.currentTab].id}
                        onChange={this.tabChanged}
                    />
                </div>
                {this.divs}
            </div>
        );
    }
}
