export default class ModuleDesignGuideModel {
    [key: string]: any;
    _systemId?: string;
    name?: string;
    buildingType?: string;
    gen?: string;
    floors?: string;
    capacityType?: string;
    capacityDescription?: string;
    country?: string;
    state?: string;

    constructor(json: any) {
        Object.assign(this, json);
        this.init(json);
    }

    protected init(json: any) {
        for (const key of Object.keys(this)) {
            {
                if (key.startsWith('_')) {
                    //check _key in the json if not available check in json.attributes or json.structure
                    if (json[key.substring(1)]) {
                        this[key] = json[key.substring(1)];
                    }
                } else {
                    if (json[key]) {
                        this[key] = json[key];
                    }
                }
            }
        }
    }

    public static LOADING_TEMPLATE: ModuleDesignGuideModel = new ModuleDesignGuideModel({
        systemId: 'Loading...',
        name: 'Loading...',
        attributes: {
            country: 'Loading...',
            buildingType: 'Loading...',
            gen: 'Loading...',
            floors: 'Loading...',
            capacityType: 'Loading...',
            capacityDescription: 'Loading...',
            state: 'Loading...',
        },
    });
}
