import { StatusIndicatorProps } from '@amzn/awsui-components-react';
import { SiteBaseModel } from './site_base_model';
import ModuleListModel from './module_list_module';
import NonModuleListModel from './non_module_list_model';
import NonModuleProposalModel from './non_module_proposal_model';
import PostCARProposalModel from './post_car_proposal_model';

export default class SiteModel extends SiteBaseModel {
    country?: string;
    region?: string;
    buildingType?: string;
    shieldId?: string;
    program?: string;
    porYear?: string;
    facilityName?: string;
    facilityType?: string;
    projectNumber?: string;
    siteSize?: any;
    projectStatus?: string;
    firstReceive?: string;
    privateAccess?: boolean;
    _latestModuleList?: ModuleListModel[];
    _moduleList?: ModuleListModel[];
    _latestNonModuleList?: NonModuleListModel[];
    _nonModuleList?: NonModuleListModel[];
    _nonModuleProposal?: NonModuleProposalModel[];
    _postCarProposal?: PostCARProposalModel[];
    _latestNonModuleProposal?: NonModuleProposalModel[];
    _latestPostCarProposal?: PostCARProposalModel[];
    mdgTemplate?: string;

    constructor(json: any) {
        super(json);
        this.init(json);
        this.mdgTemplate = this.resolveMDGTemplate();
    }

    protected resolveStructureKey(key: string, json: any): SiteBaseModel[] {
        if ((key.includes('latestModuleList') || key.includes('_moduleList') || key === 'moduleList') && json) {
            const result: ModuleListModel[] = [];

            json.map((mdlJson: any) => result.push(new ModuleListModel(mdlJson)));

            return result;
        } else if (
            (key.includes('latestNonModuleList') || key.includes('_nonModuleList') || key === 'nonModuleList') &&
            json
        ) {
            const result: NonModuleListModel[] = [];

            json.map((nmlJson: any) => result.push(new NonModuleListModel(nmlJson)));

            return result;
        } else if (
            (key.includes('latestNonModuleProposal') ||
                key.includes('_nonModuleProposal') ||
                key === 'nonModuleProposal') &&
            json
        ) {
            const result: NonModuleProposalModel[] = [];

            json.map((nmpJson: any) => result.push(new NonModuleProposalModel(nmpJson)));

            return result;
        } else if (
            (key.includes('latestPostCarProposal') || key.includes('_postCarProposal') || key === 'postCARProposal') &&
            json
        ) {
            const result: PostCARProposalModel[] = [];

            json.map((pcpJson: any) => result.push(new PostCARProposalModel(pcpJson)));

            return result;
        }

        return [];
    }

    private resolveMDGTemplate(): string {
        let mdgTemplate = '-';

        this._latestModuleList?.map((mdl: ModuleListModel) => {
            if (mdl.mdgTemplate && !mdl.mdgTemplate.includes('2.XX')) {
                mdgTemplate = mdl.mdgTemplate;
            }
        });
        return mdgTemplate;
    }

    static statusType(projectStatus: string): StatusIndicatorProps.Type {
        switch (projectStatus?.toLowerCase()) {
            case 'active':
                return 'in-progress';
            case 'completed':
                return 'success';
            case 'rejected':
                return 'error';
            case 'cancelled':
                return 'stopped';
            default:
                return 'pending';
        }
    }

    public static LOADING_TEMPLATE: SiteModel = new SiteModel({
        systemId: 'Loading...',
        name: 'Loading...',
        attributes: {
            country: 'Loading...',
            region: 'Loading...',
            buildingType: 'Loading...',
            shieldId: 'Loading...',
            program: 'Loading...',
            porYear: 'Loading...',
            facilityName: 'Loading...',
            facilityType: 'Loading...',
            projectNumber: 'Loading...',
            projectStatus: 'Loading...',
        },
    });
}
