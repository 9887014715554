export default class Bim360SiteInfo {
    [key: string]: any;

    token?: string;
    derivativeId?: string;
    bim360FilePath?: string;
    strategyType?: string;

    static fromJSON(json: any): Bim360SiteInfo {
        const output = new Bim360SiteInfo();

        const siteKeys = Object.keys(output);

        // Copy over the values of they keys present in a SiteInfo;
        for (const key of siteKeys) {
            output[key] = json[key];
        }

        return output;
    }

    toString(): string {
        return JSON.stringify(this);
    }
}
