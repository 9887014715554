import { StatusIndicatorProps } from "@amzn/awsui-components-react-v3";
import { KeyValueTemplate } from "../../components/dynamic_expandable_properties";
import SessionHelper from "../../utils/session_helper";


export default abstract class ChangeRequestBaseModel {
    [key: string]: any;

    _systemId?: string;
    _changeId?: string;
    number?: string;
    name?: string;
    buildingType?: string;
    _region?: string;
    state?: string;
    ccmStatus?: string;
    createdBy?: string;
    createdOn?: Date;
    modifiedBy?: string;
    lastModified?: Date;
    _changeType?: string;

    constructor(json: any) {
        this.init(json);
    }

    protected init(json: any) {
        for (let key of Object.keys(this)) {
            if (key.startsWith("_"))
                this[key] = json[key.substring(1)];            
            else                
                this[key] = json[key];
        }
    }

    public abstract keyValueTemplate(): KeyValueTemplate;

    public abstract getType(): string;

    public getURL() {
        // Instance in the format of: tuxdev.developer.eng-plm.a2z.com
        return `https://${SessionHelper.getWindchillInstance()}/Windchill/app/?identity_provider=COGNITO#ptc1/tcomp/infoPage?oid=OR%3Awt.change2.WTChangeRequest2%3A${this._changeId?.split(":")[2]}`;
    }


    get statusType(): StatusIndicatorProps.Type {
        return ChangeRequestBaseModel.statusType(this.state ?? "");
    }

    get stateString(): string {
        let stateMap: { [key: string]: string } = {
            "RELEASED": "Released",
            "INWORK": "In Work",
            "REJECTED": "Rejected",
            "CANCELLED": "Archived",
            "INWORKMINOR": "In Work - Minor",
            "UNDERREVIEW": "Under Review",
            "RELEASEDMINOR": "Released - Minor",
            "PENDING APPROVAL": "Pending Approval",
            "IMPLEMENTATION": "Implementation",
            "OPEN": "Open",
            "REWORK": "Rework",
            "APPROVED": "Approved"
        }
        return stateMap[this.state ?? ""] ?? this.state;
    }


    static statusType(state: string): StatusIndicatorProps.Type {
        switch (state?.toLowerCase()) {
            case "approved":
            case "released":
                return "success";

            case "rejected":
                return "error";

            case "rework":
                return "warning";

            case "implementation":
                return "in-progress";

            default:
                return "pending";
        }
    }

    toString(): string {
        return JSON.stringify(this);
    }
}