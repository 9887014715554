import React, { useState } from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import { KeyValueTemplate } from '../../../components/dynamic_expandable_properties';
import DynamicExpandableProperties from '../../../components/dynamic_expandable_properties';
import NonModuleListModel from '../../../data/site/non_module_list_model';
import { CostBreakdown, SiteModelService } from '../../../data/data';
import { SiteModelType } from '../../../data/site/site_base_model';
import ExpandableProperties, { PropertiesEntry } from '../../../components/expandable_properties';

const NML_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Number', key: 'number' },
            { title: 'Name', key: 'name' },
            { title: 'Version', key: 'displayVersion' },
        ],
        [
            {
                title: 'State',
                key: 'stateString',
                type: 'status',
                states: { Released: 'success', Approved: 'success', Closed: 'stopped', default: 'pending' },
            },
        ],
    ],
};

const NML_SYSTEM_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'System',
    type: 'key-value',
    defaultExpanded: false,
    sections: [
        [
            { title: 'Created By', key: 'createdBy' },
            { title: 'Created On', key: 'createdOn', type: 'datetime' },
        ],
        [
            { title: 'Modified By', key: 'modifiedBy' },
            { title: 'Last Modified', key: 'lastModified', type: 'datetime' },
        ],
    ],
};

const TEMPLATES: KeyValueTemplate[] = [NML_ATTR_TEMPLATE, NML_SYSTEM_ATTR_TEMPLATE];

export const NonModuleListDetailsView = ({ nonModuleList }: { nonModuleList: NonModuleListModel }) => {
    const [items, setItems] = useState<PropertiesEntry[]>([{ label: 'Loading...', value: '' }]);

    async function onExpansionChanged(): Promise<void> {
        let breakdown: CostBreakdown = new CostBreakdown();
        try {
            breakdown = await SiteModelService.getCostBreakdown(
                SiteModelType.NonModuleList,
                1,
                nonModuleList._systemId || '',
            );
        } catch (error) {
            console.error('Error fetching cost breakdown:', error);
        }
        const breakDownItems: PropertiesEntry[] = [];
        breakDownItems.push({ label: 'Total Cost', value: `$${breakdown?.totalCost}` });
        for (const key of Object.keys(breakdown?.carTabs ?? {})) {
            breakDownItems.push({ label: key, value: `$${breakdown?.carTabs[key]}` });
        }
        setItems(breakDownItems);
    }

    return (
        <SpaceBetween size="m">
            {TEMPLATES.map((template) => (
                <DynamicExpandableProperties key={template.title} object={nonModuleList} template={template} />
            ))}
            <ExpandableProperties
                header="Cost Breakdown"
                defaultExpanded={false}
                onExpansionChanged={onExpansionChanged}
                items={[items]}
            />
        </SpaceBetween>
    );
};
