import React from "react";
import { SpaceBetween } from '@amzn/awsui-components-react-v3';
import { DynamicExpandableProperties, KeyValueTemplate } from '../../../modules';
import PostCARProposalModel from '../../../data/site/post_car_proposal_model';

const PCP_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Number', key: 'number' },
            { title: 'Name', key: 'name' },
            { title: 'Version', key: 'displayVersion' },
            { title: "State", key: "stateString", type: "status", states: { Released: "success", Approved: "success", Closed: "stopped", default: "pending"} },
            { title: 'Add the Link to SIM or Laminar', key: 'simLink' },
            { title: 'Affected Module', key: 'affectedModule' },
            { title: 'Affected Sites', key: 'affectedSites' },
            { title: 'Affects Composite', key: 'affectsComposite' },
        ],
        [
            { title: 'Change Category', key: 'changeCategory' },
            { title: 'Change Origin', key: 'changeOrigin' },
            { title: 'Change Type', key: 'changeType' },
            { title: 'Cost Delta (USD)', key: 'costDelta' },
            { title: 'New Quantity', key: 'newQuantity' },
            { title: 'New Version', key: 'newVersion' },
            { title: 'Notes', key: 'notes' },
            { title: 'Requesting Team', key: 'requestingTeam' }
        ],
    ]
};

const PCP_SYSTEM_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'System',
    type: 'key-value',
    defaultExpanded: false,
    sections: [
        [
            { title: 'Created By', key: 'createdBy' },
            { title: 'Created On', key: 'createdOn', type: 'datetime' }
        ],
        [
            { title: 'Modified By', key: 'modifiedBy' },
            { title: 'Last Modified', key: 'lastModified', type: 'datetime' }
        ]
    ]
};

const TEMPLATES: KeyValueTemplate[] = [PCP_ATTR_TEMPLATE, PCP_SYSTEM_ATTR_TEMPLATE];

export const PostCARProposalDetailsView = ({ postCarProposal }: { postCarProposal: PostCARProposalModel }) => {

    return (
        <SpaceBetween size="m">
            {TEMPLATES.map(template => (
                <DynamicExpandableProperties key= {template.title} object={postCarProposal} template={template} />
            ))}
        </SpaceBetween>
    );
}