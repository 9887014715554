import ModuleDesignGuidePlacementModel from '../../../data/module/module_design_guide_placement_model';
import TuxView from '../../../components/tux_view';
import React from 'react';
import ItemTable, { CustomDisplayProps } from '../../../components/item_table';
import { Alert, AlertProps } from '@amzn/geist-ui-components';
import SessionHelper from '../../../utils/session_helper';
import ModuleDesignGuideService from '../../../data/module/module_design_guide_service';
import Box from '@amzn/meridian/box';

export class Placement {
    totalQuantityDeployed?: string;
    floorLevel?: string;
    moduleDeployedArea?: string;
    moduleDeployedProcess?: string;
    placementNotes?: string;
    supportDocuments?: string;
    siteTemplateId?: string;
    siteTemplateNumber?: string;
    siteTemplateName?: string;
    buildingType?: string;
    capacityDescription?: string;
    capacityType?: string;
    country?: string;
    floors?: string;
    gen?: string;
    state?: string;
}

interface ModuleDesignGuideViewProps {
    moduleDesignGuidePlacements: ModuleDesignGuidePlacementModel[];
    moduleGroupId: string;
}
class ModuleDesignGuideView extends TuxView<ModuleDesignGuideViewProps, any> {
    private isLoading = true;
    private placements: Placement[] = [];
    private customDisplays: CustomDisplayProps;

    private alertType: AlertProps.Type = 'info';
    private isAlertVisible = false;
    private alertMessage = '';

    constructor(props: ModuleDesignGuideViewProps) {
        super(props);
        this.customDisplays = {
            siteTemplateName: (n: string, item: Placement) => (
                <a
                    href={`#/${SessionHelper.getRegion()}/moduleDesignGuide/${item.siteTemplateId}/details`}
                    style={{ textDecoration: 'none' }}
                >
                    {n}
                </a>
            ),
        };
        this.bindAll(this);
        this.loadData();
    }

    async loadData(): Promise<void> {
        this.isLoading = true;
        this.dataUpdated();
        try {
            this.placements = this.getPlacements(
                this.props.moduleDesignGuidePlacements ??
                    (await ModuleDesignGuideService.getModuleDesignGuideModulePlacements(this.props.moduleGroupId)),
            );
        } catch (err) {
            this.showAlert(
                `Failed to fetch Module Design Guide. Please contact site administrator. ${(err as Error).message}`,
                'error',
            );
        }
        this.isLoading = false;
        this.dataUpdated();
    }

    getPlacements(placement: ModuleDesignGuidePlacementModel[]): Placement[] {
        return placement?.map(
            (placementModel) =>
                ({
                    totalQuantityDeployed: placementModel.totalQuantityDeployed,
                    floorLevel: placementModel.floorLevel,
                    moduleDeployedArea: placementModel.moduleDeployedArea,
                    placementNotes: placementModel.placementNotes,
                    supportDocuments: placementModel.supportDocuments,
                    siteTemplateNumber: placementModel.siteTemplate?.number,
                    siteTemplateName: placementModel.siteTemplate?.name,
                    buildingType: placementModel.siteTemplate?.buildingType,
                    capacityDescription: placementModel.siteTemplate?.capacityDescription,
                    capacityType: placementModel.siteTemplate?.capacityType,
                    country: placementModel.siteTemplate?.country,
                    floors: placementModel.siteTemplate?.floors,
                    gen: placementModel.siteTemplate?.gen,
                    siteTemplateId: placementModel.siteTemplate?.systemId,
                    state: placementModel.siteTemplate?.state,
                }) as Placement,
        );
    }

    private showAlert(message: string, type: AlertProps.Type = 'info') {
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }

    private alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }
    render() {
        return (
            <div>
                <Box spacingInset={this.isAlertVisible ? '400 0' : 0}>
                    <Alert
                        alertType={this.alertType}
                        visible={this.isAlertVisible}
                        dismissible={true}
                        onDismiss={this.alertDismissed}
                        header={this.alertMessage}
                    />
                </Box>
                <ItemTable
                    items={this.placements}
                    isLoading={this.isLoading}
                    customDisplays={this.customDisplays}
                    customWidths={{
                        region: 105,
                    }}
                    initiallyVisibleColumnCount={14}
                    initialColumnOrder={[
                        'siteTemplateName',
                        'buildingType',
                        'country',
                        'totalQuantityDeployed',
                        'floorLevel',
                        'floors',
                        'gen',
                        'capacityDescription',
                        'capacityType',
                        'moduleDeployedArea',
                        'moduleDeployedProcess',
                        'placementNotes',
                        'supportDocuments',
                        'state',
                    ]}
                    defaultSortBy="siteTemplateName"
                    canExport
                />
            </div>
        );
    }
}

export default ModuleDesignGuideView as React.ComponentType<ModuleDesignGuideViewProps>;
