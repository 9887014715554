import React, { useState } from 'react';
import { SpaceBetween } from '@amzn/awsui-components-react';
import DynamicExpandableProperties from '../../../components/dynamic_expandable_properties';
import { KeyValueTemplate } from '../../../components/dynamic_expandable_properties';
import ModuleListModel from '../../../data/site/module_list_module';
import BOMService from '../../../data/bom/bom_service';
import ExpandableProperties, { PropertiesEntry } from '../../../components/expandable_properties';
import { CostBreakdown } from '../../../data/data';

const MDL_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [
        [
            { title: 'Number', key: 'number' },
            { title: 'Name', key: 'name' },
            { title: 'Version', key: 'displayVersion' },
        ],
        [
            {
                title: 'State',
                key: 'stateString',
                type: 'status',
                states: { Released: 'success', Approved: 'success', Closed: 'stopped', default: 'pending' },
            },
            { title: 'MDG Template', key: 'mdgTemplate' },
            { title: 'ERP Status', key: 'erpStatus' },
            { title: 'Module Counts Template', key: 'moduleCountsTemplate' },
        ],
    ],
};

const MDL_SYSTEM_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'System',
    type: 'key-value',
    defaultExpanded: false,
    sections: [
        [
            { title: 'Created By', key: 'createdBy' },
            { title: 'Created On', key: 'createdOn', type: 'datetime' },
        ],
        [
            { title: 'Modified By', key: 'modifiedBy' },
            { title: 'Last Modified', key: 'lastModified', type: 'datetime' },
        ],
    ],
};

const TEMPLATES: KeyValueTemplate[] = [MDL_ATTR_TEMPLATE, MDL_SYSTEM_ATTR_TEMPLATE];

async function getCostBreakdown(moduleList: ModuleListModel): Promise<CostBreakdown> {
    const bomItemQuantities: Map<string, number> = new Map();
    moduleList._bom?.forEach((bomItem) => {
        if (bomItem.bomItem?._systemId && bomItem.quantity) {
            console.log('bomItem -> ', bomItem.bomItem?._systemId);
            bomItemQuantities.set(bomItem.bomItem?._systemId, bomItem.quantity);
        }
    });
    const response: any = await BOMService.getCostBreakdownOfModules(bomItemQuantities);
    return response;
}
export const ModuleListDetailsView = ({ moduleList }: { moduleList: ModuleListModel }) => {
    const [items, setItems] = useState<PropertiesEntry[]>([{ label: 'Loading...', value: '' }]);

    async function onExpansionChanged(isExpanded: boolean): Promise<void> {
        let breakdown: CostBreakdown = new CostBreakdown();
        try {
            breakdown = await getCostBreakdown(moduleList);
        } catch (error) {
            console.error('Error fetching cost breakdown:', error);
        }
        const breakDownItems: PropertiesEntry[] = [];
        breakDownItems.push({ label: 'Total Cost', value: `$${breakdown.totalCost}` });
        for (const key of Object.keys(breakdown.carTabs)) {
            breakDownItems.push({ label: key, value: `$${breakdown.carTabs[key]}` });
        }
        setItems(breakDownItems);
    }

    return (
        <SpaceBetween size="m">
            {TEMPLATES.map((template) => (
                <DynamicExpandableProperties key={template.title} object={moduleList} template={template} />
            ))}
            <ExpandableProperties
                header="Cost Breakdown"
                defaultExpanded={false}
                onExpansionChanged={onExpansionChanged}
                items={[items]}
            />
        </SpaceBetween>
    );
};
