import StorageHelper from './storage_helper';
import UserInfoHelper from './user_info_helper';
// 
// import sentryFetch from '@amzn/sentry-fetch';

export default class SessionHelper{
    private static SESSION_DETAILS = "session_details";
    private static DEFAULT_REGION = "NA";
    private static HAS_INITIALIZED: boolean = false;

    private static details: any = {};
    private static region: string;
    private static windchillInstance: string;

    /**
     * Initialize the session's state, if it hasn't already.
     */
    static initIfNot(){
        if (this.HAS_INITIALIZED){
            return;
        }
        this.init();
    }

    /**
     * Gets a copy of the URL to the windchill instance for this Tux instance
     * @returns URL to the corresponding windchill instance
     */
    static getWindchillInstance(): string{
        return `${SessionHelper.windchillInstance}`;
    }

    /**
     * Parse the current region from the URL
     * @returns Current region, null if not present
     */
    static getRegionFromURL(){
        let hash = window.location.hash;
        let sections = hash.split("/");
        // console.log("Hash Sections", sections);
        let regionParsed = sections.length >= 3 ? sections[1] : null;
        return regionParsed;
    }

    /**
     * **STATE SETTING** 
     * 
     * Updates the windchill instance 
     * returned by ```SessionHelper.getWindchillInstance()```
     */
    private static initWindchillInstance(){
        if (process.env.NODE_ENV !== 'production'){
            SessionHelper.windchillInstance = "sandbox.developer.eng-plm.a2z.com";
            return;
        }
        // TODO: Determine the Windchill URL to use here
        if (window.location.host.startsWith("dev")){
            SessionHelper.windchillInstance = "sandbox.developer.eng-plm.a2z.com";
        } else if (window.location.host.startsWith("stage")){
            SessionHelper.windchillInstance = "test.developer.eng-plm.a2z.com";
        } else {
            SessionHelper.windchillInstance = "eng-plm.corp.amazon.com/";
        }
        if (process.env.NODE_ENV !== 'production'){
            console.log("(DEBUG)", window.location.hostname);
        }
    }

    /**
     * Initialize the state for this session. Cached in localStorage.
     * 
     * Sets:
     *  1. Region
     *  2. Windchill instance
     */
    static init(){
        let details = StorageHelper.getObject<any>(SessionHelper.SESSION_DETAILS, {});
        let doStore = details == null;
        SessionHelper.details = details;
        if (UserInfoHelper.availableRegions.length > 1){
            let urlRegion = this.getRegionFromURL();
            SessionHelper.region = details?.region ?? SessionHelper.DEFAULT_REGION;
            if (urlRegion && urlRegion !== SessionHelper.region){
                SessionHelper.region = urlRegion;
            }
            SessionHelper.details.region = SessionHelper.region;
        } else {
            SessionHelper.details.region = UserInfoHelper.availableRegions[0] ?? "";
            SessionHelper.region = UserInfoHelper.availableRegions[0] ?? "";
        }

        // Determine the windchill instance to use when linking modules
        SessionHelper.initWindchillInstance();

        if (doStore){
            this.updateStorage();
        }

        // console.log(SessionHelper.details);
        SessionHelper.HAS_INITIALIZED = true;
    }

    /**
     * Update the region used for the session.
     * @param region New region to be used
     */
    static setRegion(region: string){
        this.region = region;
        SessionHelper.details.region = region;
        SessionHelper.region = region;
        this.updateStorage();
    }

    /**
     * Gets the current region.
     * @returns Region used by this session
     */
    static getRegion(){
        if (!this.region){
            this.init();
        }
        return this.region;
    }

    static sessionStart(): Date{
        return new Date(performance.timeOrigin);
    }
    
    static sessionDuration(): number{
        return new Date().getTime() - new Date(performance.timeOrigin).getTime();
    }

    /**
     * Write the current session details to localStorage
     */
    static updateStorage(){
        StorageHelper.setObject(SessionHelper.SESSION_DETAILS, SessionHelper.details);
    }
}