import React from 'react';
import SessionHelper from '../../utils/session_helper';
import { formatFromCamelCase, redirect } from '../../utils/tools';
import RequestHelper from '../../utils/request_helper';
import TuxComponent from '../tux_component';
import UserInfoHelper from '../../utils/user_info_helper';
import { Autosuggest, AutosuggestProps, NonCancelableCustomEvent } from '@amzn/geist-ui-components';
import { SiteModelType } from '../../data/site/site_base_model';

export interface GlobalSearchProps {}

class GlobalSearch extends TuxComponent<GlobalSearchProps, any> {
    private static typeMaps: { [key: string]: string } = {
        module: 'Modules',
        minor: 'Minor Changes',
        major: 'Major Changes',
        archive: 'Archive Requests',
        site: 'Sites',
        moduelist: 'ModuleLists',
        nonmodulelist: 'NonModuleLists',
    };
    private isLoading = false;
    private currentSearch = '';
    private isKeyPressed = false;
    private searchAPITimer?: number;
    private results: any[] = [];

    constructor(props: GlobalSearchProps) {
        super(props);
        this.bindAll(this);
        this.loadData();
    }

    async loadData() {
        this.isLoading = true;
        this.dataUpdated();

        this.isLoading = false;
        this.dataUpdated();
    }

    private get options(): (AutosuggestProps.Option | AutosuggestProps.OptionGroup)[] {
        const output: (AutosuggestProps.Option | AutosuggestProps.OptionGroup)[] = [];
        const entries: { [key: string]: AutosuggestProps.Option[] } = {};

        const seenObjects: Set<string> = new Set<string>();

        for (const result of this.results) {
            const duplicateCheckId = result.number ?? result.systemId;
            if (!duplicateCheckId) {
                continue;
            }

            if (seenObjects.has(duplicateCheckId)) {
                continue;
            }

            seenObjects.add(duplicateCheckId);
            if (!entries[result.type]) {
                entries[result.type] = [];
            }

            let link = `#/${SessionHelper.getRegion()}/`;

            if (result.type === 'module') {
                link += `modules/${result.number}`;
            } else if (['archive', 'minor', 'major'].includes(result.type)) {
                link += `changes/${result.type}/${result.number}`;
            } else if (result.type === 'site') {
                link += `sites/${result.systemId}/details`;
            } else if (result.type === 'modulelist') {
                link += `sites/${result.parentSystemId}/relatedItems/${SiteModelType.ModuleList}/${result.systemId}`;
            } else if (result.type === 'nonmodulelist') {
                link += `sites/${result.parentSystemId}/relatedItems/${SiteModelType.NonModuleList}/${result.systemId}`;
            }

            entries[result.type].push({
                label: `${result.number ? result.number + ' - ' : ''}${result.name}${result.buildingType && result.buildingType !== '' ? ' - ' + result.buildingType : ''}`,
                value: link,
            });
        }

        for (const title of Object.keys(entries)) {
            output.push({
                label: GlobalSearch.typeMaps[title] ?? `${formatFromCamelCase(title)}(s)`,
                options: entries[title],
            });
        }

        return output;
    }

    private async search(text: string) {
        if (this.searchAPITimer) {
            this.searchAPITimer = undefined;
        }
        if (text.length === 0) {
            this.isLoading = false;
            this.results = [];
            this.dataUpdated();
            return;
        }

        this.isLoading = true;
        this.results = [];
        this.dataUpdated();

        try {
            const response = await RequestHelper.serviceRequest(`/search/doSearch?text=${text}`);
            if (process.env.NODE_ENV !== 'production') {
                console.log(response);
            }

            if (Array.isArray(response)) {
                for (const res of response) {
                    this.results.push(res);
                }
            }
        } catch (e) {
            if (process.env.NODE_ENV !== 'production') {
                console.log('(DEBUG)', e);
            }
        }

        this.isLoading = false;
        this.dataUpdated();
    }

    private async searchChanged(event: NonCancelableCustomEvent<AutosuggestProps.ChangeDetail>) {
        // The search change is not from a keypress, normal changes will occur inbetween keyDown and keyUp
        if (!this.isKeyPressed) {
            // A search target has been selected,
            if (event.detail.value === this.currentSearch) {
                // They clicked the current search, ignore it
                return;
            }
            if (event.detail.value.length > 0) {
                redirect(event.detail.value);
                window.location.reload();
                return;
            }
        }

        if (this.searchAPITimer) {
            window.clearTimeout(this.searchAPITimer);
        }
        this.isLoading = true;
        this.searchAPITimer = window.setTimeout((e: any) => this.search(event.detail.value), 500);

        this.currentSearch = event.detail.value;
        this.dataUpdated();
    }

    render() {
        return (
            <Autosuggest
                disabled={UserInfoHelper.availableRegions.length === 0}
                id={'global-search'}
                statusType={this.isLoading ? 'loading' : 'finished'}
                enteredTextLabel={(val) => val}
                value={this.currentSearch}
                placeholder="Search Items..."
                onChange={this.searchChanged}
                options={this.options}
                onKeyUp={(e) => (this.isKeyPressed = false)}
                onKeyDown={(e) => (this.isKeyPressed = true)}
            />
        );
    }
}

export default GlobalSearch as React.ComponentType<GlobalSearchProps>;
