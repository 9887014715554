import { SiteBaseModel } from './site_base_model';

export default class CustomQuoteModel extends SiteBaseModel {
    carLine?: string;
    carTab?: string;
    currency?: string;
    desc?: string;
    partNumber?: string;
    price?: string;
    supplier?: string;
    uin?: string;

    constructor(json: any) {
        super(json);
        this.init(json);
    }

    protected resolveStructureKey(key: string, json: any): SiteBaseModel[] {
        return [];
    }
}
