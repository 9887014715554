import React from 'react';
import { Button, Alert, Header, AlertProps } from '@amzn/geist-ui-components';
import Input from '@amzn/meridian/input';
import { Container, SpaceBetween} from '@amzn/awsui-components-react';
import Select from '@amzn/meridian/select'
import SelectOption from '@amzn/meridian/select/select-option';
import { ItemTable, TuxComponent } from '../../components/components';
import { String } from 'aws-sdk/clients/cloudhsm';
import { SiteModelService } from '../../data/data';
import { SiteBaseModel, SiteModelType } from '../../data/site/site_base_model';
import { ModelInput } from '../../data/site/gql/mutations';
import { ShieldIntegrationAPIService, PreferenceService, PreferenceModel } from '../../data/data';
import Row from '@amzn/meridian/row';
import Box from '@amzn/meridian/box';
import { ItemTableController } from '../../components/item_table';
import SessionHelper from '../../utils/session_helper';
import Column from '@amzn/meridian/column';

interface CreateSiteScreenProps {
}
export interface ShieldProjectModel {
    shieldId: string;
    projectName: string;
    buildingType: string;
    facilityType: string;
    facilityName: string;
    porYear: string;
    region: string;
    projectStatus: string;
    program : string;
    country: string;
}
export default class CreateSiteScreen extends TuxComponent<CreateSiteScreenProps, any> {
    isLoading: boolean = false;
    errorMessage?: string;
    private shieldProjects: ShieldProjectModel[] = [];
    porYearOptions: string[] = [];
    businessUnitOptions: string[] = [];

    private alertType: AlertProps.Type = 'info';
    private isAlertVisible: boolean = false;
    private alertMessage: string = '';
    controller: ItemTableController = new ItemTableController();

    constructor(props: CreateSiteScreenProps) {
        super(props);
        this.state = {
            selectedPORYearOption: '',
            selectedBusinessUnitOption: '',
            givenFacilityName: '',
        };
        this.bindAll(this);
        this.loadData();
    }

    //Handle input changes
    handlePORYearDropDownChange = (event: string) => {
        this.setState({selectedPORYearOption: event});
    };
    handleBusinessUnitDropDownChange = (event: string) => {
        this.setState({selectedBusinessUnitOption: event});
    };

    handleFacilityNameInputChange = (event: any) => {
        this.setState({givenFacilityName: event});
    };

    //Load drop downs
    async loadData() {
        this.porYearOptions = await this.getPreferenceOptions('POR Year');
        this.businessUnitOptions = await this.getPreferenceOptions('Business Unit');
        this.dataUpdated();
    }

    private async getPreferenceOptions(preferenceName: String): Promise<String[]> {
        let options: string[] = [];
        let preferencePromise: Promise<PreferenceModel>;
        preferencePromise = PreferenceService.fetchPreferenceByName(preferenceName).catch((e) => {
            console.log('error -> ', e);
            return preferencePromise;
        }) as Promise<PreferenceModel>;
        let preference: PreferenceModel  = await preferencePromise;
        if (typeof preference.value === 'string') {
            options.push(preference.value);

        } else {
            preference.value?.map((prefValue: string) => {
                options.push(prefValue);
            }) 
        }
        return options;
    }

    disableSearchButton = (): boolean => {
        return (
            this.state.selectedPORYearOption !== '' && this.state.selectedBusinessUnitOption !== '' && this.state.givenFacilityName !== '' ? false : true
        );
    };

    private async searchShield(porYear: string, businessUnit: string, facilityName: string) {
        this.dataUpdated();
        this.shieldProjects = (await ShieldIntegrationAPIService.fetchProjectInfo(porYear, businessUnit, facilityName).catch((e) => {
            console.log('error -> ', e);
            this.showAlert(`Error while fetching Shield Data from Shield Service : ${(e as Error).message}`, 'error');
        })) as ShieldProjectModel[];
        this.isLoading = false
        this.dataUpdated();
    }

    private async createProject() {
        let selectedProject: ShieldProjectModel[] | undefined = this.controller.table?.getSelectedItems()
        if (!selectedProject){
            return;
        }
        let modelInput: ModelInput = this.buildSiteModelInput(selectedProject[0]);
        try {
            let sitePromise: SiteBaseModel = (await SiteModelService.saveModel(SiteModelType.Site, modelInput).catch((e) => {
                console.log('error -> ', e);
                this.showAlert(`Failed to Create Site : ${(e as Error).message}`, 'error');
            })) as SiteBaseModel;

            //If site created successfully, redirect the UI to created site details page
            if(sitePromise){
                window.location.href=`#/${SessionHelper.getRegion()}/sites/${sitePromise._systemId}/details`
            }
        } catch (err) {
            this.showAlert(`Failed to create Site, please contact site administrator. ${(err as Error).message}`, 'error');
        }
        
    }

    private buildSiteModelInput(project: ShieldProjectModel): ModelInput {
        let modelInput: ModelInput = {
            name: project.projectName,
            attributes: {
                porYear: project.porYear,
                program: project.program,
                facilityName: project.facilityName,
                shieldId: project.shieldId,
                country: project.country,
                region: project.region,
                facilityType: project.facilityType,
                projectStatus: project.projectStatus,
            },
        };

        return modelInput;
    }
    private showAlert(message: string, type: AlertProps.Type = 'info') {
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }
    private alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    render() {
        return (

                <Column width="100%">
                    <Row width="100%" widths="fill" alignmentVertical='center'>
                        <Box><Header>Site Creation</Header></Box>
                        <Row alignmentHorizontal='end' spacing='400'>
                                <Button 
                                    size="small"
                                    onClick={()=>(
                                        window.location.href=`#/${SessionHelper.getRegion()}/sites`
                                    )}
                                >
                                Cancel
                                </Button>
                        </Row>
                    </Row>
                    <Alert
                        alertType={this.alertType}
                        visible={this.isAlertVisible}
                        dismissible={true}
                        onDismiss={this.alertDismissed}
                        header={this.alertMessage}
                    />
                    <div >
                    <Row width="50%" widths="fill" alignmentVertical='top'>
                        <Box type="fill" spacingInset="400" minHeight={70}>
                            <SpaceBetween size='xl'>
                                <Select
                                    label="Launch Year/POR Year"
                                    value={this.state.selectedPORYearOption}
                                    onChange={this.handlePORYearDropDownChange}
                                    size="xlarge"
                                >
                                    {
                                        this.porYearOptions?.map((item:any)=>{
                                            return <SelectOption key={item} label={item} value={item}></SelectOption>
                                        })
                                    }
                                </Select>
                                <Select
                                    label="Business Unit"
                                    value={this.state.selectedBusinessUnitOption}
                                    onChange={this.handleBusinessUnitDropDownChange}
                                    size="xlarge"
                                >
                                    {
                                    this.businessUnitOptions?.map((item:any)=>{
                                            return <SelectOption key={item} label={item} value={item}></SelectOption>
                                        })
                                    }
                                </Select>
                                <Input
                                    label = "Facility Name"
                                    id="facility_name"
                                    value={this.state.givenFacilityName}
                                    onChange={this.handleFacilityNameInputChange}
                                    type="text"
                                    placeholder="Enter Facility Name..."
                                    size="xlarge"
                                />
                            </SpaceBetween>
                        </Box>
                    </Row>

                    </div>
                    <Row width="100%"  alignmentHorizontal='start'>
                        <Button
                            disabled ={this.disableSearchButton()}
                            variant="primary"
                            onClick={async () => {
                                this.isLoading = true;
                                this.searchShield(
                                    this.state.selectedPORYearOption,
                                    this.state.selectedBusinessUnitOption,
                                    this.state.givenFacilityName,
                                );
                            }}
                        >
                            Search
                        </Button>
                    </Row>
                    <ItemTable
                        isLoading={this.isLoading}
                        controller={this.controller}
                        title={`Projects`}
                        items={this.shieldProjects}
                        selectionLimit={1}
                        initialColumnOrder={[
                            'shieldId',
                            'projectName',
                            'projectStatus',
                            'porYear',
                            'program',
                            'facilityType',
                            'buildingType',
                            'facilityName',
                            'region',
                            'country',
                            
                        ]}
                        actions={[{ label: 'Create', primary: true, callback: this.createProject, enableAt: 1 }]}
                    />
                </Column>
        );
    }
}
