import RequestHelper from '../../utils/request_helper';
import ModuleDesignGuideModel from './module_design_guide_model';
import ModuleDesignGuidePlacementModel from './module_design_guide_placement_model';

export default class ModuleDesignGuideService {
    static async getAllModuleDesignGuides(
        region: string,
        page?: number,
        size?: number,
    ): Promise<ModuleDesignGuideModel[]> {
        let endpointPath = `/module/mdg/all/${region}`;
        if (page !== undefined && size !== undefined) {
            endpointPath = `${endpointPath}?page=${page}&size=${size}`;
        }

        const response: any[] = await RequestHelper.serviceRequest(endpointPath);
        return response.map((r) => new ModuleDesignGuideModel(r));
    }

    static async getModuleDesignGuidesCount(): Promise<number> {
        const endpointPath = `/module/mdg/all/count`;
        return await RequestHelper.serviceRequest(endpointPath);
    }

    static async getModuleDesignGuide(systemId: string): Promise<ModuleDesignGuideModel> {
        const endpointPath = `/module/mdg/${systemId}`;
        const response: ModuleDesignGuideModel = await RequestHelper.serviceRequest(endpointPath);
        return new ModuleDesignGuideModel(response);
    }

    static async getModuleDesignGuideSiteTemplatePlacements(
        systemId: string,
    ): Promise<ModuleDesignGuidePlacementModel[]> {
        const endpointPath = `/module/mdg/${systemId}/siteTemplatePlacements`;
        const response: any[] = await RequestHelper.serviceRequest(endpointPath);
        return response.map((r) => new ModuleDesignGuidePlacementModel(r));
    }

    static async getModuleDesignGuideModulePlacements(
        moduleGroupId: string,
    ): Promise<ModuleDesignGuidePlacementModel[]> {
        const endpointPath = `/module/mdg/${moduleGroupId}/modulePlacements`;
        const response: any[] = await RequestHelper.serviceRequest(endpointPath);
        return response.map((r) => new ModuleDesignGuidePlacementModel(r));
    }
}
