export enum EventTypes {
    RoutingChange = 'RoutingChange',
    RecentsCleared = 'RecentsCleared',
    FavoritesChange = 'FavoritesChange',
    ChangesChange = 'ChangesChange',
    SideNavExpansionChange = 'SideNavExpansionChange',
    SiteReload = 'SiteReload',
    ModuleReload = 'ModuleReload',
    ModuleDesignGideReload = 'ModuleDesignGideReload',
}
