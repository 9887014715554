import React from 'react';
import { ChangeRequestBaseModel, ChangeRequestService, ContentModel } from '../../../data/data';
import { TuxComponent } from '../../../components/components';
import DynamicExpandableProperties, { KeyValueTemplate } from '../../../components/dynamic_expandable_properties';
import { Link, SpaceBetween } from '@amzn/awsui-components-react';
import ExpandableTable from '../../../components/expandable_table';
import AffectedItemModel from '../../../data/change_request/affected_item_model';
import { CustomDisplayProps } from '../../../components/item_table';
import RequestHelper from '../../../utils/request_helper';
import CookieHelper from '../../../utils/cookie_helper';
import SessionHelper from '../../../utils/session_helper';
import { CookieNames } from '../../../utils/cookie_helper';
import { Alert, AlertProps, StatusIndicator } from '@amzn/geist-ui-components';

const CHANGE_ATTR_TEMPLATE: KeyValueTemplate = {
    title: 'Attributes',
    type: 'key-value',
    defaultExpanded: true,
    sections: [],
};

interface ChangeDetailsViewProps {
    changeRequestPromise: Promise<ChangeRequestBaseModel>;
}

export default class ChangeDetailsView extends TuxComponent<ChangeDetailsViewProps, any> {
    isLoading = true;
    isAlertVisible = false;
    changeRequest?: ChangeRequestBaseModel;
    affectedItemsCustomDisplays: CustomDisplayProps;
    attachmentsCustomDisplays: CustomDisplayProps;
    affectedItems?: AffectedItemModel[];
    attachments?: ContentModel[];

    constructor(props: ChangeDetailsViewProps) {
        super(props);

        this.affectedItemsCustomDisplays = {
            number: (n: string, item: AffectedItemModel) => (
                <Link href={`#/${SessionHelper.getRegion()}/${item._itemType}/${n}/${item.version?.split(' ')[0]}`}>
                    {n}
                </Link>
            ),
            state: (state: string, item: AffectedItemModel) => (
                <StatusIndicator type={AffectedItemModel.statusType(state)}>{item.state}</StatusIndicator>
            ),
        };
        this.attachmentsCustomDisplays = {
            fileName: (fileName: string, item: ContentModel) => this.getAttachmentDownloadLink(item),
            modifiedOn: (d: Date) => new Date(d).toLocaleString(),
            contentType: (type: string) => ContentModel.contentTypeString(type),
            fileSize: (size: number) => ContentModel.fileSize(size),
        };
        this.bindAll(this);
        this.loadData();
    }

    async loadData(): Promise<void> {
        this.changeRequest = await this.props.changeRequestPromise;
        this.dataUpdated();
        this.affectedItems = await ChangeRequestService.getAffectedItemsForCR(
            this.changeRequest._systemId ? this.changeRequest._systemId : '',
            this.changeRequest.getType(),
        );
        this.attachments = await ChangeRequestService.getAttachmentsForCR(
            this.changeRequest._systemId ? this.changeRequest._systemId : '',
            this.changeRequest.getType(),
        );
        this.isLoading = false;
        this.dataUpdated();
    }

    private showAlert(message: string, type: AlertProps.Type = 'info') {
        this.isAlertVisible = true;
        this.alertMessage = message;
        this.alertType = type;
        this.dataUpdated();
    }

    private alertDismissed() {
        this.isAlertVisible = false;
        this.dataUpdated();
    }

    getAttachmentDownloadLink(content: ContentModel): JSX.Element {
        if (content.contentType === 'url') {
            return (
                <Link href={content.contentUrl ?? ''} target="_blank">
                    {content.fileName}
                </Link>
            );
        } else {
            return <Link onFollow={(e) => this.onFollowLink(e, content)}>{content.fileName}</Link>;
        }
    }

    async onFollowLink(e: Event, content: ContentModel) {
        e.preventDefault();
        try {
            const link = `${RequestHelper.CONTENT_HOST}/${this.changeRequest?.getType()}/${
                this.changeRequest?._systemId
            }/${content.systemId}?id_token=${CookieHelper.getCookieByName(CookieNames.id_token)}`;
            RequestHelper.download(link, content.fileName);
            console.log();
            // await ContentService.downloadContent(content.downloadAttachmentPath, content.fileName);
        } catch (err) {
            this.showAlert('Failed to download', 'error');
        }
    }

    getAffectedItemsSection(): JSX.Element {
        return (
            <ExpandableTable
                header="Affected Items"
                tableCustomDisplays={this.affectedItemsCustomDisplays}
                tableItems={this.affectedItems ?? []}
                tableDisableSelection
                tableIsLoading={this.isLoading}
                defaultExpanded={false}
            />
        );
    }

    getAttachmentsSection(): JSX.Element {
        return (
            <ExpandableTable
                header="Attachments"
                tableItems={this.attachments ?? []}
                tableCustomDisplays={this.attachmentsCustomDisplays}
                tableDisableSelection
                tableIsLoading={this.isLoading}
                tableHiddenColumns={[
                    'systemId',
                    'createdOn',
                    'description',
                    'filePath',
                    'contentUrl',
                    'refId',
                    'refType',
                    'role',
                    'updatedOn',
                ]}
                tableInitialColumnOrder={['fileName', 'contentType', 'fileSize', 'modifiedOn']}
                defaultExpanded={false}
            />
        );
    }

    render() {
        return (
            <>
                <Alert
                    alertType={this.alertType}
                    visible={this.isAlertVisible}
                    dismissible={true}
                    onDismiss={this.alertDismissed}
                    header={this.alertMessage}
                />
                <SpaceBetween size="m">
                    {
                        <DynamicExpandableProperties
                            key={Math.random()}
                            object={this.changeRequest}
                            template={this.changeRequest ? this.changeRequest.keyValueTemplate() : CHANGE_ATTR_TEMPLATE}
                        />
                    }
                    {this.getAffectedItemsSection()}
                    {this.getAttachmentsSection()}
                </SpaceBetween>
            </>
        );
    }
}
