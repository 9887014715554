import ModuleModel from '../module/module_model';
import BOMEntryModel, { CostBreakdown } from './bom_model';
import RequestHelper from '../../utils/request_helper';

export default class BOMService {
    /**
     * Get the BOM for a ModuleModel
     * @param module
     * @returns
     */
    static async getModuleBOM(module: ModuleModel): Promise<BOMEntryModel[]> {
        if (!module._systemId) {
            throw new Error('Cannot get version history for module without System ID');
        }

        const response: any[] = await RequestHelper.serviceRequest(`/bom/${module._systemId}`);

        const output = response.map((r: any) => BOMEntryModel.fromJSON(r));

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', output);
        }

        return output;
    }

    static async getCostBreakdown(module: ModuleModel): Promise<CostBreakdown> {
        if (!module._systemId) {
            throw new Error('Cannot get totalCost for module without version ID');
        }

        const response: any = await RequestHelper.serviceRequest(`/bom/${module._systemId}/cost`);

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', response);
        }

        return CostBreakdown.fromJSON(response);
    }

    static async getCostBreakdownOfModules(bomItemQuantities: Map<string, number>): Promise<CostBreakdown> {
        if (!bomItemQuantities) {
            throw new Error('Cannot get totalCost without model IDs');
        }

        const jsonObject = Array.from(bomItemQuantities).reduce((acc, [key, value]) => {
            return { ...acc, [key]: value };
        }, {});

        const bodyString: string = JSON.stringify(jsonObject);
        console.log('bodyString -> ', bodyString);
        const opts: { [key: string]: any } = {
            headers: {
                'Content-Type': 'application/json', // Adjust as needed
            },
            method: 'post',
            body: bodyString,
        };

        const response: any = await RequestHelper.serviceRequest(`/bom/costOfModules`, opts);

        if (process.env.NODE_ENV !== 'production') {
            console.log('(DEBUG)', response);
        }

        return CostBreakdown.fromJSON(response);
    }
}
