import { ApolloClient, InMemoryCache } from '@apollo/client';
import CookieHelper, { CookieNames } from './cookie_helper';
import RequestHelper from './request_helper';

export const siteGQL = new ApolloClient({
    uri: `${RequestHelper.HOST}/site/graphql`,
    cache: new InMemoryCache({
        typePolicies: {
            Model: {
                keyFields: ['systemId', 'modelType']
            }
        }
    }),
    headers: {
        Authorization: CookieHelper.getCookieByName(CookieNames.id_token)
    }
});
