import { CAR_TABS } from '../../utils/constants';
import UINModel from '../uin/uin_model';

export class CostBreakdown {
    [key: string]: any;
    totalCost: string | null = null;
    carTabs: { [key: string]: string } = {};

    static fromJSON(json: any): CostBreakdown {
        const output = new CostBreakdown();
        const breakdown: any = json.costBreakdown ?? {};
        if (isNaN(json.totalCost) || !json.totalCost) {
            output.totalCost = json.totalCost;
        } else {
            output.totalCost = Number(Number(json.totalCost).toFixed(2)).toLocaleString('en-US');
        }

        const carTabDict: { [key: string]: string } = {};

        for (const key of Object.keys(breakdown).sort()) {
            let outputKey = key.replaceAll('_', ' ');

            if (key.startsWith('UIN - ')) {
                outputKey = key.split(' - ')[1];
            }

            carTabDict[outputKey] = isNaN(breakdown[key])
                ? breakdown[key]
                : Number(breakdown[key].toFixed(2)).toLocaleString('en-US');
        }

        CAR_TABS.forEach((carTab) => {
            output.carTabs[carTab] = carTabDict[carTab] ?? '0';
        });

        return output;
    }
}

export default class BOMEntryModel extends UINModel {
    [key: string]: any;

    quantity?: number;
    unit?: string;
    totalCost?: number;

    _moduleSsytemId?: string;
    _systemId?: string;
    _uinLinkId?: string;
    _uinSystemId?: string;

    static fromJSON(json: any): BOMEntryModel {
        const output = new BOMEntryModel();

        const bomKeys = Object.keys(output);
        // console.log("BOM Keys: " + JSON.stringify(bomKeys));

        const keySet: Set<string> = new Set(bomKeys);
        const uinItem = json.uinItem;

        // Copy over the values of they keys present in a BOMEntryModel;
        for (const key of bomKeys) {
            output[key] = json[key] ?? uinItem[key];
        }

        for (const key of Object.keys(json).concat(Object.keys(uinItem))) {
            if (key === 'uinItem') {
                continue;
            }
            // Ignore keys already present in BOMEntryModel
            if (!key.startsWith('_') && keySet.has(key)) {
                // console.log("Ignoring: " + key + " already: " + output[key]);
                continue;
            }
            // console.log(`Setting _${key} to ${JSON.stringify(json[key])}`)
            output['_' + key] = json[key] ?? uinItem[key];
        }

        return output;
    }
}
