import { ExpandableSection, Header } from '@amzn/awsui-components-react-v3';
import React, { ReactNode } from 'react';
import { ItemTable, TuxComponent } from './components';
import { CustomDisplayProps } from './item_table';

interface ExpandableTableProps {
    header: ReactNode;
    variant?: ('h1' | 'h2' | 'h3');
    defaultExpanded?: boolean;
    onExpansionChanged?: (isExpanded: boolean) => void;

    /**
     * Table Properties
     */
    tableItems: any[];
    tableCustomDisplays?: CustomDisplayProps;
    tableDisableSelection?: boolean;
    tableDisableSearch?: boolean;
    tableIsLoading?: boolean;
    tableInitiallyVisibleColumnCount?: number;
    tableInitialColumnOrder?: string[];
    tableCustomWidths?: { [key: string]: (string | number) };
    tableHiddenColumns?: string[];
    canExport?: boolean;
    defaultSortBy?: string;
}

export default class ExpandableTable extends TuxComponent<ExpandableTableProps, any>{


    constructor(props: ExpandableTableProps) {
        super(props);
        this.bindAll(this);
        this.loadData();
    }


    async loadData(): Promise<void> {
        //throw new Error('Method not implemented.');
    }

    render() {
        let header = (
            <Header variant={this.props.variant ?? "h2"}>
                {this.props.header}
            </Header>
        );
        return (
            <ExpandableSection header={header} variant="container" disableContentPaddings defaultExpanded={this.props.defaultExpanded ?? true} onChange={(e) => this.props.onExpansionChanged ? this.props.onExpansionChanged(e.detail.expanded) : null}>
                <ItemTable
                    items={this.props.tableItems}
                    customDisplays={this.props.tableCustomDisplays}
                    disableSelection={this.props.tableDisableSelection}
                    disableSearch={this.props.tableDisableSearch}
                    isLoading={this.props.tableIsLoading}
                    initiallyVisibleColumnCount={this.props.tableInitiallyVisibleColumnCount}
                    initialColumnOrder={this.props.tableInitialColumnOrder}
                    customWidths={this.props.tableCustomWidths}
                    hiddenColumns={this.props.tableHiddenColumns}
                    canExport={this.props.canExport}
                    defaultSortBy={this.props.defaultSortBy}
                />
            </ExpandableSection>
        );
    }

}