import React from 'react';
import NonModuleProposalModel from '../../../data/site/non_module_proposal_model';
import { ItemTable, RequestHelper, SessionHelper } from '../../../modules';
import { Link, StatusIndicator, StatusIndicatorProps } from '@amzn/awsui-components-react-v3';
import { ActionDetails, AnalyticsRoutes } from '../../../utils/request_helper';
import CompareNMPView from '../../compare/compare_nmp_view';

export interface HistoryItem {
    _systemId: string;
    number: string;
    name: string;
    version: string;
    state: string;
    createdOn: Date;
    lastModified: Date;
}

export const NonModuleProposalHistoryView = ({
    nonModuleProposalHistory,
    isLoading,
    siteSystemId
}: {
    nonModuleProposalHistory: NonModuleProposalModel[];
    isLoading: boolean;
    siteSystemId: string;
}) => {
    const historyItems: HistoryItem[] = nonModuleProposalHistory.map(
        nmp =>
            ({
                _systemId: nmp._systemId,
                number: nmp.number,
                name: nmp.name,
                version: nmp.displayVersion,
                state: nmp.stateString,
                createdOn: nmp.createdOn,
                lastModified: nmp.lastModified
            } as HistoryItem)
    );

    historyItems.sort((a, b) => {
        let aNum = Number(a.version?.split(' ')[0]);
        let bNum = Number(b.version?.split(' ')[0]);
        return aNum >= bNum ? (aNum === bNum ? 0 : -1) : 1;
    });

    const getStatus = (state: string): StatusIndicatorProps.Type => {
        if (state === 'Released' || state === 'Approved') return 'success';

        if (state === 'Closed') return 'stopped';

        return 'pending';
    };

    const comparePressed = (nmp: HistoryItem[]) => {

        RequestHelper.logAnalytics(AnalyticsRoutes.action, new ActionDetails("button_pressed", "compare_mdls"));

        let compareHash = CompareNMPView.generateHREF(nmp[0], nmp[1]);
        if (process.env.NODE_ENV !== 'production'){
            console.log("(DEBUG)", compareHash);
        }
        let href = window.location.href.replace(window.location.hash, compareHash);
        if (process.env.NODE_ENV !== 'production'){
            console.log(href);
        }

        window.open(href);
    }

    return (
        <ItemTable
            items={historyItems}
            isLoading={isLoading}
            initiallyVisibleColumnCount={5}
            actions={[{label:"Compare", callback:comparePressed, icon:"external", enableAt:2}]}
            selectionLimit={2}
            customDisplays={{
                number: (n: string, item: NonModuleProposalModel) => (
                    <Link
                        href={`#/${SessionHelper.getRegion()}/sites/${siteSystemId}/relatedItems/NonModuleProposal/${
                            item._systemId
                        }`}
                        onFollow={e => {
                            window.location.hash = e.detail.href ?? window.location.hash;
                            window.location.reload();
                        }}
                    >
                        {n}
                    </Link>
                ),
                state: (state: string) => <StatusIndicator type={getStatus(state)}>{state}</StatusIndicator>
            }}
        />
    );
};
