import React from 'react';
import { TuxView, ValueWithLabel, ItemTable } from '../../components/components';
import { ModuleModel } from '../../data/data';
import { Grid, SpaceBetween } from '@amzn/awsui-components-react';
import ModuleListModel from '../../data/site/module_list_module';
import BomModel from '../../data/site/bom_model';
import SiteModelService from '../../data/site/site_model_service';
import { SiteModelType } from '../../data/site/site_base_model';
import SessionHelper from '../../utils/session_helper';
import CompareUtils from '../../utils/compare_utils';
import { ColumnLayout, ExpandableSection, Header, StatusIndicator } from '@amzn/geist-ui-components';

interface CompareMDLViewProps {
    mdlA: string;
    mdlAVersion: string;
    mdlB: string;
    mdlBVersion: string;
}

export class MDLItem {
    _systemId?: string;
    number?: string;
    name?: string;
    version?: string;
    stateString?: string;
    createdOn?: Date;
    lastModified?: string;
    createdBy?: string;
    quantity?: number;
}

export default class CompareMDLView extends TuxView<CompareMDLViewProps, any> {
    mdlAModel: ModuleListModel;
    mdlBModel: ModuleListModel;
    mdlABOMOrig: BomModel[] = [];
    mdlBBOMOrig: BomModel[] = [];
    mdlABOM: MDLItem[] = [];
    mdlBBOM: MDLItem[] = [];
    mdlABOMNumbers: Set<string> = new Set<string>();
    mdlBBOMNumbers: Set<string> = new Set<string>();
    attributesExpanded = true;

    constructor(props: CompareMDLViewProps) {
        super(props);

        this.mdlAModel = ModuleListModel.LOADING_TEMPLATE;
        this.mdlBModel = ModuleListModel.LOADING_TEMPLATE;

        this.bindAll(this);
        this.loadData();
    }

    private async resolveModuleList(number: string, version: string): Promise<ModuleListModel> {
        return (await SiteModelService.fetchModelByNumberAndVersion(
            SiteModelType.ModuleList,
            number,
            version,
        )) as ModuleListModel;
    }

    /**
     * Convert BomModel Object (nested JSON) to simple JSON
     */
    flattenMDL(mdl: BomModel[]): MDLItem[] {
        return mdl.map((mdl) => ({
            _systemId: mdl._systemId,
            number: mdl.bomItem?.number,
            name: mdl.bomItem?.name,
            version:
                String(mdl.bomItem?._version?.version ?? '') + '.' + String(mdl.bomItem?._version?.iteration ?? ''),
            stateString: mdl.stateString,
            createdOn: mdl.createdOn,
            lastModified: mdl.createdBy,
            createdBy: mdl.createdBy,
            quantity: mdl.quantity,
        }));
    }

    async loadData() {
        this.mdlAModel = await this.resolveModuleList(this.props.mdlA, this.props.mdlAVersion);
        this.mdlBModel = await this.resolveModuleList(this.props.mdlB, this.props.mdlBVersion);

        this.mdlABOMOrig = this.mdlAModel._bom ?? [];
        this.mdlBBOMOrig = this.mdlBModel._bom ?? [];

        this.mdlABOM = this.flattenMDL(this.mdlABOMOrig);
        this.mdlBBOM = this.flattenMDL(this.mdlBBOMOrig);

        this.mdlABOMNumbers = new Set<string>(this.mdlABOM.map((b) => b.number ?? ''));
        this.mdlBBOMNumbers = new Set<string>(this.mdlBBOM.map((b) => b.number ?? ''));

        [this.mdlABOM, this.mdlBBOM] = CompareUtils.compareBOMs(this.mdlABOM, this.mdlBBOM) as MDLItem[][];

        this.isLoading = false;
        this.dataUpdated();
    }

    get mdlAId() {
        return `${this.mdlAModel.bomItem?.number}_${this.mdlAModel.displayVersion}`;
    }

    get mdlBId() {
        return `${this.mdlBModel.bomItem?.number}_${this.mdlBModel.displayVersion}`;
    }

    getMDLId(mdl: ModuleListModel) {
        return `${mdl.number}_${mdl.version}`;
    }

    attributeExpansionChanged() {
        this.attributesExpanded = !this.attributesExpanded;
        this.dataUpdated();
    }

    static generateHREF(mdlA: MDLItem, mdlB: MDLItem): string {
        return `#/${SessionHelper.getRegion()}/compare/mdl/${mdlA.number}/${mdlA.version}/${mdlB.number}/${
            mdlB.version
        }`;
    }

    private getAttributes(mdl: ModuleListModel, otherMDL: ModuleListModel): JSX.Element {
        const header = (
            <Header variant="h2">
                Attributes - {mdl.number} ({mdl.displayVersion})
            </Header>
        );

        return (
            <ExpandableSection
                variant="container"
                header={header}
                expanded={this.attributesExpanded}
                defaultExpanded={true}
                id={this.getMDLId(mdl) + '_attributes'}
                onChange={this.attributeExpansionChanged}
            >
                <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                        <ValueWithLabel hasChanged={mdl.name !== otherMDL.name} label="Name">
                            {mdl.name}
                        </ValueWithLabel>
                        <ValueWithLabel hasChanged={mdl.number !== otherMDL.number} label="Number">
                            {mdl.number}
                        </ValueWithLabel>
                        <ValueWithLabel hasChanged={mdl.stateString !== otherMDL.stateString} label="State">
                            <StatusIndicator type={ModuleModel.statusType(mdl.stateString)}>
                                {mdl.stateString}
                            </StatusIndicator>
                        </ValueWithLabel>
                        <ValueWithLabel hasChanged={mdl.displayVersion !== otherMDL.displayVersion} label="Version">
                            {mdl.displayVersion}
                        </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                        <ValueWithLabel hasChanged={mdl.mdgTemplate !== otherMDL.mdgTemplate} label="MDG Template">
                            {mdl.mdgTemplate}
                        </ValueWithLabel>
                        <ValueWithLabel hasChanged={mdl.erpStatus !== otherMDL.erpStatus} label="ERP Status">
                            {mdl.erpStatus}
                        </ValueWithLabel>
                        <ValueWithLabel
                            hasChanged={mdl.moduleCountsTemplate !== otherMDL.moduleCountsTemplate}
                            label="Module Counts Template"
                        >
                            {mdl.moduleCountsTemplate}
                        </ValueWithLabel>
                    </SpaceBetween>
                </ColumnLayout>
            </ExpandableSection>
        );
    }

    private _getAttributes() {
        return (
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                {this.getAttributes(this.mdlAModel, this.mdlBModel)}
                {this.getAttributes(this.mdlBModel, this.mdlAModel)}
            </Grid>
        );
    }

    private getBOM(
        mdl: ModuleListModel,
        bom: MDLItem[],
        bomNumbers: Set<string>,
        otherBOMNumbers: Set<string>,
        otherBOM: MDLItem[],
    ) {
        const temp: { [key: string]: any } = {};
        const getHasChanged = (val: number, b: MDLItem, param: string) =>
            val != null
                ? otherBOMNumbers.has(b.number ?? '')
                    ? (otherBOM.find(((_b) => _b.number === b.number) ?? temp) as any)[param] === (b as any)[param]
                        ? val
                        : CompareUtils.getChangedText(val)
                    : val
                : '-';
        return (
            <ItemTable
                title={`${mdl.number} (${mdl.displayVersion})` ?? ''}
                items={bom}
                initialColumnOrder={['number', 'name', 'quantity', 'version']}
                initiallyVisibleColumnCount={4}
                disableSearch={true}
                disableSelection={true}
                customDisplays={{
                    number: (val, bomItem: MDLItem) =>
                        otherBOMNumbers.has(bomItem.number ?? '')
                            ? bomNumbers.has(bomItem.number ?? '')
                                ? bomItem.number
                                : '-'
                            : CompareUtils.getChangedText(bomItem.number),
                    quantity: (quantity: number, bomItem: MDLItem) => getHasChanged(quantity, bomItem, 'quantity'),
                }}
                fixed
            />
        );
    }

    private _getBOMs() {
        return (
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                {this.getBOM(this.mdlAModel, this.mdlABOM, this.mdlABOMNumbers, this.mdlBBOMNumbers, this.mdlBBOM)}
                {this.getBOM(this.mdlBModel, this.mdlBBOM, this.mdlBBOMNumbers, this.mdlABOMNumbers, this.mdlABOM)}
            </Grid>
        );
    }

    render() {
        return (
            <>
                <Header variant="h1">
                    Compare:{' '}
                    {`${this.mdlAModel.number} (${this.mdlAModel.displayVersion}) & ${this.mdlBModel.number} (${this.mdlBModel.displayVersion})`}
                </Header>
                <SpaceBetween size="s" direction="vertical">
                    {this._getAttributes()}
                    {this._getBOMs()}
                </SpaceBetween>
                <div style={{ height: '12px' }} />
            </>
        );
    }
}
